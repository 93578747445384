import { Box, Grid, Typography } from "@mui/material"
import React from "react"

type IProps = {
  title: string
  icone: string
  subTitle: string
}

const divStyle = {
  fontSize: "70px",
  fontWeight: "bold",
}

export function TitlePageTitle({ title, icone, subTitle }: IProps): React.JSX.Element {
  return (
    <Grid item md={4} sx={{ display: "flex" }}>
      <Box className="icone" sx={{ alignSelf: "center", display: "flex" }}>
        <span className="material-symbols-outlined icone notranslate" style={divStyle}>
          {icone}
        </span>
      </Box>
      <Box sx={{ display: "block", pl: 5 }}>
        <Typography variant="h1" sx={{ fontSize: "310%", font: "Roboto" }}>
          <b>{title}</b>
        </Typography>
        <Typography
          className="notranslate"
          variant="subtitle1"
          sx={{ fontSize: "100%", font: "Roboto", textTransform: "uppercase" }}>
          {subTitle !== "" && subTitle !== undefined ? subTitle : "Pas de projet selectionné"}
        </Typography>
      </Box>
    </Grid>
  )
}
