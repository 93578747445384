import React from "react"
import "./google-icons.css"

function IconDownload(props: { style: any }): React.JSX.Element {
  const { style } = props

  return (
    <span
      translate="no"
      style={{
        fontVariationSettings: `'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48`,
        fontSize: style ? style.fontSize : "inherit",
        ...style,
      }}
      className="material-symbols-outlined">
      download
    </span>
  )
}

export default IconDownload
