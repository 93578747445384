import { Box, Modal, Typography } from "@mui/material"
import React from "react"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
}

type MaterialPageConfirmationModalProps = {
  isOpen: boolean
  handleClose: () => void
  title: string
  children: React.JSX.Element
}
export default function MaterialPageConfirmationModal({
  isOpen,
  handleClose,
  title,
  children,
}: MaterialPageConfirmationModalProps): React.JSX.Element {
  return (
    <div>
      <Modal
        keepMounted
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description">
        <Box sx={style}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h3" fontSize={17}>
            {title}
          </Typography>
          {children}
        </Box>
      </Modal>
    </div>
  )
}
