import CloseSharpIcon from "@mui/icons-material/CloseSharp"
import DeleteIcon from "@mui/icons-material/Delete"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material"
import React, { useContext, useMemo, useState } from "react"
import { useParams } from "react-router"
import { Link } from "react-router-dom"
import { adminPagesUrl, pagesUrl } from "../../../core/appConstants"
import { UserContext } from "../../../core/context/user/user-context"
import { Collaborator } from "../../../core/dto/user/collaborator"
import { CollaboratorTypeEnum } from "../../../core/enum/user/collaboratorTypeEnum"
import { OrganizationRoleEnum, roleOnOrganizationToLabel } from "../../../core/enum/user/organization-role-enum"
import { useUser } from "../../../core/hooks/use-user"
import { collaboratorTypeToLabel } from "../../../core/services/authentication-service"
import { formatToFrenchWithHours, stringToDate } from "../../../core/services/date-service"
import { resolveUrl } from "../../../core/services/http-service"
import CancelInvitationDialog from "../../../pages/project-page/team-page/component/cancel-invitation-dialog"
import { SuccessContext } from "../../layout/success-snackbar"
import UserTableDialog from "./user-table-dialog"

const pageSize = 5

interface DisplayUserColumn {
  id: "name" | "surname" | "email" | "phoneNumber" | "status" | "lastConnection" | "action"
  label: string
  minWidth?: number
  format?: (value: number) => string
}

type iProps = {
  page: number
  dataCollabList: Collaborator[]
  handleChangePage(event: unknown, newPage: number): void
  isWriting: boolean
  refreshCollab(): void
  isForUltimateUser: boolean
}

export default function CollaboratorDataTable({
  page,
  dataCollabList,
  handleChangePage,
  isWriting,
  refreshCollab,
  isForUltimateUser,
}: iProps): React.JSX.Element {
  const { organizationId } = useParams()
  const openSuccessSnackbar = useContext(SuccessContext)
  const { disableUser, adminDisableUser, cancelUserOrganisationInvitation } = useUser()
  const [selectedCollab, setSelectedCollab] = useState<Collaborator>()
  const { user } = useContext(UserContext)
  const [openDeleteUser, setOpenDeleteUser] = useState<boolean>(false)
  const [isCancelInvitationDialogOpen, setIsCancelInvitationRevokeDialogOpen] = useState<boolean>(false)

  const columns: DisplayUserColumn[] = isForUltimateUser
    ? [
        {
          id: "name",
          label: "Nom",
        },
        {
          id: "surname",
          label: "Prénom",
        },
        {
          id: "email",
          label: "E-mail",
        },
        {
          id: "phoneNumber",
          label: "N. de tel",
        },
        {
          id: "status",
          label: "Statut",
        },
        {
          id: "lastConnection",
          label: "Date de derniere connexion",
        },
        {
          id: "action",
          label: "",
        },
      ]
    : [
        {
          id: "name",
          label: "Nom",
        },
        {
          id: "surname",
          label: "Prénom",
        },
        {
          id: "email",
          label: "E-mail",
        },
        {
          id: "phoneNumber",
          label: "N. de tel",
        },
        {
          id: "status",
          label: "Statut",
        },
        {
          id: "action",
          label: "",
        },
      ]

  function openDeleteDialog(userToDelete: Collaborator): void {
    setSelectedCollab(userToDelete)
    setOpenDeleteUser(true)
  }

  function openCancelInvitationDialog(userToCancelInvitation: Collaborator): void {
    setSelectedCollab(userToCancelInvitation)
    setIsCancelInvitationRevokeDialogOpen(true)
  }

  function handleClose(): void {
    setOpenDeleteUser(false)
    setIsCancelInvitationRevokeDialogOpen(false)
  }

  function getValueForColumn(value: string, row: Collaborator): React.JSX.Element | string {
    switch (value) {
      case "name":
        return row.familyName
      case "surname":
        return row.givenName
      case "email":
        return row.email
      case "phoneNumber":
        return row.phone
      case "lastConnection":
        return formatToFrenchWithHours(stringToDate(row?.lastConnection)) || ""
      case "action":
        return "action"
      case "status":
        return (
          <>
            <span>{roleOnOrganizationToLabel(row.role)}</span>
            {row.type === CollaboratorTypeEnum.WAITING_CREATION && (
              <span style={{ color: "#FF4C0A" }}> ({collaboratorTypeToLabel(row.type)})</span>
            )}
          </>
        )
      default:
        return ""
    }
  }

  const userEditPageUrl = useMemo((): string => {
    if (user?.role === OrganizationRoleEnum.MEGA_USER) {
      return pagesUrl.MY_ORGANIZATION_EDIT_USER_PAGE
    } else if (user?.role === OrganizationRoleEnum.ULTIMATE_USER) {
      return resolveUrl(adminPagesUrl.ADMIN_ORGANIZATION_USER_PAGE, [organizationId])
    } else {
      return ""
    }
  }, [organizationId, user?.role])

  function onDeleteCollaborator(): Promise<void> {
    if (selectedCollab === undefined) {
      return Promise.resolve()
    }

    let promise: Promise<Response | undefined>
    if (user?.role === OrganizationRoleEnum.MEGA_USER) {
      promise = disableUser(selectedCollab.cognitoUserId)
    } else if (user?.role === OrganizationRoleEnum.ULTIMATE_USER && !!organizationId) {
      promise = adminDisableUser(selectedCollab.cognitoUserId, organizationId)
    } else {
      promise = Promise.resolve(undefined)
    }
    return promise.then(() => {
      handleClose()
      refreshCollab()
      openSuccessSnackbar("L'utilisateur a été désactivé avec succès")
    })
  }

  function cancelInvitation(): Promise<void> {
    if (selectedCollab) {
      cancelUserOrganisationInvitation(selectedCollab.email, selectedCollab.organizationId).then(() => refreshCollab())
    }
    return Promise.resolve(undefined)
  }

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440, minHeight: 200 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} align="left" style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody className="notranslate">
              {dataCollabList.slice(page * pageSize, page * pageSize + pageSize).map((collaborator: Collaborator) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={collaborator.email}>
                  {columns.map((column) => {
                    const value =
                      collaborator !== undefined && column !== undefined ? getValueForColumn(column.id, collaborator) : " "
                    return (
                      <TableCell key={column.id} align="left">
                        {value !== "action" ? (
                          value
                        ) : (
                          <Box display="flex" justifyContent="center">
                            {isWriting && collaborator.type === CollaboratorTypeEnum.CREATED && (
                              <>
                                <Link to={resolveUrl(userEditPageUrl, [collaborator.cognitoUserId])}>
                                  <IconButton aria-label="edit" color="primary">
                                    <ModeEditIcon />
                                  </IconButton>
                                </Link>
                                <IconButton
                                  aria-label="delete"
                                  color="primary"
                                  onClick={() => openDeleteDialog(collaborator)}>
                                  <DeleteIcon />
                                </IconButton>
                              </>
                            )}

                            {isWriting && collaborator.type === CollaboratorTypeEnum.WAITING_CREATION && (
                              <IconButton
                                aria-label="edit"
                                color="primary"
                                onClick={() => openCancelInvitationDialog(collaborator)}>
                                <CloseSharpIcon />
                              </IconButton>
                            )}
                          </Box>
                        )}
                      </TableCell>
                    )
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ display: "flex", align: "center", justifyContent: "center", alignItems: "center" }}
          component="div"
          rowsPerPageOptions={[]}
          count={dataCollabList.length}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
        />
      </Paper>
      <UserTableDialog openDeleteUser={openDeleteUser} handleClose={handleClose} onDelete={onDeleteCollaborator} />

      <CancelInvitationDialog
        handleClose={handleClose}
        cancelInvitation={cancelInvitation}
        open={isCancelInvitationDialogOpen}
      />
    </>
  )
}
