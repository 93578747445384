import React, { useContext, useEffect } from "react"
import { BSCategoriesContext } from "../../../../../../core/context/beem-shot/BSCategory/BSCategoriesContext"
import { BSItemContext } from "../../../../../../core/context/beem-shot/BSItems/BSItemContext"
import { CategoryAccordion } from "./CategoryAccordion"
import { useAccordion } from "./useAccordion"

export function AdvancedQuestionnaire(): React.JSX.Element {
  const { bsCategories } = useContext(BSCategoriesContext)
  const { selectedBSItem, selectedCodeExtrait, subCategoriesForCategory } = useContext(BSItemContext)

  const { expanded: categoryExpanded, expand, handleExpanded } = useAccordion()

  useEffect(() => {
    if (selectedCodeExtrait && selectedBSItem) {
      expand(selectedBSItem?.category.name)
    }
  }, [selectedBSItem, selectedCodeExtrait])

  return (
    <>
      {bsCategories
        .filter((x) => Object.keys(subCategoriesForCategory).includes(x.name))
        .map((category) => (
          <CategoryAccordion
            key={category.name}
            category={category}
            expandedCategory={categoryExpanded}
            handleExpandedCategory={handleExpanded}
          />
        ))}
    </>
  )
}
