import { Delete, Edit } from "@mui/icons-material"
import AddIcon from "@mui/icons-material/Add"
import AppsIcon from "@mui/icons-material/Apps"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import {
  Autocomplete,
  AutocompleteChangeReason,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material"
import React, { ChangeEvent, SetStateAction, useContext, useEffect, useState } from "react"
import { ErrorContext } from "../../../../components/layout/error-snackbar"
import {
  CodeAcvCompletionContext,
  CodeAcvCompletionStore,
} from "../../../../core/context/code-acv/code-acv-completion-context"
import { CodeCustomContext, CodeCustomStore } from "../../../../core/context/code-acv/code-custom-context"
import { CodesCompletionsContext, CodesCompletionsStore } from "../../../../core/context/code-acv/codes-completions-context"
import { ProjectContext } from "../../../../core/context/project/project-context"
import CodeCustom from "../../../../core/dto/code-acv-custom/code-custom"
import { CodeCustomUpdate } from "../../../../core/dto/code-acv-custom/code-custom-update"
import CodeReference from "../../../../core/dto/code-acv/code-reference"
import { CodeCompletion } from "../../../../core/dto/code-completion/code-completion"
import { CodeExtrait } from "../../../../core/dto/code-extrait/code-extrait"
import { useCode } from "../../../../core/hooks/use-code"
import AdditionalAndCompletionModal from "./additional-and-completion-modal"
import { materialModalstyle } from "./material-modal-style"

type IProps = {
  isModalOpen: boolean
  closeCodeCompletionSelectionModal(): void
  selectedCodeParent: CodeExtrait | CodeCompletion | undefined
  codesCompletionsList: CodeCompletion[]
  codeCompletionStringList: string[]
  submitNewList(newAcvList: CodeReference[], newCustomList: CodeCustom[]): void
  setOpenCodeCustomCreationModal(value: SetStateAction<boolean>): void
}
export default function CodeCompletionSelectionModal({
  isModalOpen,
  closeCodeCompletionSelectionModal,
  selectedCodeParent,
  codesCompletionsList,
  codeCompletionStringList,
  submitNewList,
  setOpenCodeCustomCreationModal,
}: IProps): React.JSX.Element {
  // This is all default code ACV from backend
  const { codesAcvForCompletions } = useContext<CodeAcvCompletionStore>(CodeAcvCompletionContext)
  // This is all the existing custom codes that can be used as completion on this projects
  const { codeCustomPerParentCode, setCodeCustomPerParentCode } = useContext<CodeCustomStore>(CodeCustomContext)

  const { setFetch } = useContext<CodesCompletionsStore>(CodesCompletionsContext)

  //This is the list of codeCompletion selected in the modal
  const [selectedCodesList, setSelectedCodesList] = useState<string[]>(codeCompletionStringList)

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />

  const [completionCustomForm, setCompletionCustomCodeForm] = useState<CodeCustomUpdate>(new CodeCustomUpdate())
  const [isCompletionCustomCodeModalOpen, setIsCompletionCustomCodeModalOpen] = useState<boolean>(false)

  const [indexSelectedCodeCompletion, setIndexSelectedCodeCompletion] = useState<number>(-1)

  const { updateCodeAcvCustom } = useCode()
  const { project } = useContext(ProjectContext)
  const openErrorSnackbar = useContext(ErrorContext)

  useEffect(() => {
    setSelectedCodesList(codeCompletionStringList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codesCompletionsList])

  function addToSelectedAcv(
    e: React.SyntheticEvent<Element, Event>,
    codeAcvToAddList: string[],
    reason: AutocompleteChangeReason
  ): void {
    if (reason === "clear") {
      setSelectedCodesList([])
    } else if (reason === "selectOption") {
      setSelectedCodesList(codeAcvToAddList)
    } else if (reason === "removeOption") {
      setSelectedCodesList(codeAcvToAddList)
    }
  }

  function addToSelectedCodeCustom(e: ChangeEvent<HTMLInputElement>, codeCustomToAdd: CodeCustom): void {
    if (e.target.checked) {
      setSelectedCodesList([...selectedCodesList, codeCustomToAdd.name])
    } else {
      setSelectedCodesList(selectedCodesList.filter((codeCompletion) => codeCompletion !== codeCustomToAdd.name))
    }
  }

  function isCustomChecked(codeCustom: CodeCustom): boolean {
    return selectedCodesList.some((codeCompletion: string) => codeCompletion === codeCustom.name)
  }

  function isCodeCompletionChecked(codeCompletionChecked: string): boolean {
    return selectedCodesList.some((codeCompletion) => codeCompletion === codeCompletionChecked)
  }

  function handleCloseModal(): void {
    // HandleClose is called when it is not the confirm button, so the modal list must be reset
    setSelectedCodesList(codeCompletionStringList)
    closeCodeCompletionSelectionModal()
  }

  function submit(): void {
    if (selectedCodeParent?.id) {
      const codeCustomList: CodeCustom[] = []
      const codeAcvList: CodeReference[] = []

      const codeCustomOptions: CodeCustom[] = codeCustomPerParentCode[selectedCodeParent.code] || []
      const codeReferenceOptions: CodeReference[] = codesAcvForCompletions.get(selectedCodeParent.code) || []

      selectedCodesList.forEach((name) => {
        // Look for the string in customs codes list
        const codeCustom: CodeCustom | undefined = codeCustomOptions.find(
          (codeCustomOption) => codeCustomOption.name === name
        )

        if (codeCustom) {
          codeCustomList.push(codeCustom)
        } else if (codeReferenceOptions) {
          // It was not found so look for the string in reference codes list
          const codeAcv: CodeReference | undefined = codeReferenceOptions.find(
            (codeAcvOption) => codeAcvOption.name === name
          )
          if (codeAcv) {
            codeAcvList.push(codeAcv)
          }
        }
      })
      submitNewList(codeAcvList, codeCustomList)
    }
  }

  function handleEditCompletionCode(codeCustom: CodeCustom): void {
    setIndexSelectedCodeCompletion(selectedCodesList.findIndex((item) => item === codeCustom.name))

    const updateForm = CodeCustomUpdate.from(codeCustom)
    if (updateForm) {
      setCompletionCustomCodeForm(codeCustom)
      setIsCompletionCustomCodeModalOpen(true)
    }
  }

  function handleCompletionCustomCodeCloseModal(): void {
    setIsCompletionCustomCodeModalOpen(false)
  }

  function handleChangeCompletionCustomForm(field: string, value: string | undefined): void {
    setCompletionCustomCodeForm({ ...completionCustomForm, [field]: value })
  }

  function verificationSameCustomCode(): boolean {
    let index = -1

    if (selectedCodeParent) {
      index = codeCustomPerParentCode[selectedCodeParent?.code]?.findIndex(
        (codeCustom) => codeCustom.name === completionCustomForm.name
      )
      if (index === -1) {
        return false
      } else if (index || completionCustomForm.id) {
        return codeCustomPerParentCode[selectedCodeParent?.code][index].id !== completionCustomForm.id
      }
    }

    return true
  }

  function handleCreateOrUpdateAdditional(): void {
    handleCompletionCustomCodeCloseModal()

    if (verificationSameCustomCode()) {
      openErrorSnackbar(new Error("Il ne peut pas y avoir 2 codes avec le même nom."))
      return
    }
    if (selectedCodeParent && project?.id && isCompletionCustomCodeModalOpen) {
      updateCodeAcvCustom(project?.id, completionCustomForm, completionCustomForm.parentCode).then((codeCustom) => {
        const list: CodeCustom[] = codeCustomPerParentCode[selectedCodeParent?.code]
        const indexToUpdate = list.findIndex((item) => item.id === codeCustom.id)

        if (indexToUpdate !== -1) {
          list.splice(indexToUpdate, 1, codeCustom)
        }

        if (project.id) {
          setFetch(true) // Fecth all code custom to update front
        }
        setSelectedCodesList((prevList) => {
          const updatedList = [...prevList]
          updatedList[indexSelectedCodeCompletion] = codeCustom.name
          return updatedList
        })
        setCodeCustomPerParentCode({ ...codeCustomPerParentCode, [selectedCodeParent?.code]: list })
      })
    }
  }

  return (
    <>
      <AdditionalAndCompletionModal
        isOpen={isCompletionCustomCodeModalOpen}
        handleCloseModal={handleCompletionCustomCodeCloseModal}
        handleChange={handleChangeCompletionCustomForm}
        formValues={completionCustomForm}
        handleCreateOrUpdate={handleCreateOrUpdateAdditional}
        isUpdate
      />
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={{ ...materialModalstyle, width: "45%", height: "inherit", maxHeight: "70%" }}>
          <Box>
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textTransform: "uppercase" }}>
              compléter la donnée
            </Typography>
            <Box>
              <Box sx={{ display: "flex", justifyContent: " flex-end" }}>
                <Button variant="outlined" onClick={handleCloseModal}>
                  RETOUR
                </Button>
                <Button onClick={submit} variant="contained" color="success" sx={{ color: "white", ml: 4 }}>
                  CONFIRMER
                </Button>
              </Box>
            </Box>
            <Grid container display="flex" alignItems="center" columnSpacing={2}>
              <AppsIcon fontSize="inherit" sx={{ fontSize: 75 }} />
              <Grid>
                <Typography sx={{ fontWeight: "bold", fontSize: 22 }}>{selectedCodeParent?.name}</Typography>
                <Typography sx={{ fontSize: 22 }}>{selectedCodeParent?.descriptionFromCodeAcv}</Typography>
              </Grid>
            </Grid>
            <Grid container columnSpacing={2} sx={{ mt: 4 }}>
              <Grid item xs={6}>
                <Typography sx={{ textTransform: "uppercase" }}>éléments proposés</Typography>

                {codesAcvForCompletions && selectedCodeParent?.id && (
                  <Autocomplete
                    className="notranslate"
                    options={
                      codesAcvForCompletions.get(selectedCodeParent?.code)?.map((codeAcv: CodeReference) => codeAcv.name) ||
                      []
                    }
                    multiple
                    sx={{ mt: 1, mr: 1 }}
                    value={selectedCodesList}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option}
                    noOptionsText="Pas d'éléments proposés..."
                    renderOption={(props, codeLabel, { selected = isCodeCompletionChecked(codeLabel) }) => (
                      <li {...props}>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                        {codeLabel}
                      </li>
                    )}
                    onChange={addToSelectedAcv}
                    renderInput={(params) => <TextField {...params} />}
                  />
                )}
              </Grid>
              <Grid item xs={6} sx={{ borderLeft: 1 }}>
                <Typography sx={{ textTransform: "uppercase" }}>Ajouter manuellement un élément</Typography>
                <FormGroup>
                  {codeCustomPerParentCode &&
                    selectedCodeParent?.code &&
                    codeCustomPerParentCode[selectedCodeParent.code]?.map((codeCustom) => (
                      <Box display="flex" justifyContent="space-between" key={codeCustom.id}>
                        <FormControlLabel
                          className="notranslate"
                          control={
                            <Checkbox
                              checked={isCustomChecked(codeCustom)}
                              onChange={(e) => addToSelectedCodeCustom(e, codeCustom)}
                            />
                          }
                          label={codeCustom.name}
                        />
                        <Box>
                          {false && (
                            <IconButton>
                              <Delete />
                            </IconButton>
                          )}

                          <IconButton
                            onClick={() => {
                              handleEditCompletionCode(codeCustom)
                            }}>
                            <Edit />
                          </IconButton>
                        </Box>
                      </Box>
                    ))}
                </FormGroup>
                <Button style={{ textTransform: "none" }} onClick={() => setOpenCodeCustomCreationModal(true)}>
                  <AddIcon sx={{ mr: 2 }} />
                  Ajouter un nouvel élément
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </>
  )
}
