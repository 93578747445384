import React from "react"

export const useCalculator = ({
  setResult,
}: {
  setResult: (value: React.SetStateAction<string>) => void
}): {
  validateInput: (event: React.KeyboardEvent<HTMLInputElement>) => void
} => {
  const validateInput = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    const authorizedKeyCode = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      ".",
      "Divide",
      "Comma",
      "Multiply",
      "Enter",
      "Subtract",
      "Add",
      "Period",
    ]
    const isNumpad = event.code.includes("Numpad")
    const isDigit = event.code.includes("Digit")

    let splittedKeyCode

    if (isNumpad) {
      splittedKeyCode = event.code.split("Numpad")
    } else if (isDigit) {
      splittedKeyCode = event.code.split("Digit")
    } else {
      splittedKeyCode = ""
    }

    if (splittedKeyCode === "" && authorizedKeyCode.includes(event.code)) {
      setResult((prev) => prev + event.key)
    } else if (splittedKeyCode !== "" && splittedKeyCode.length > 0) {
      const currentKeyCode = splittedKeyCode[1]
      if (authorizedKeyCode.includes(currentKeyCode)) {
        setResult((prev) => prev + event.key)
      }
    } else {
      event.preventDefault()
    }
    if (event.code === "Backspace") {
      setResult((prev) => prev.slice(0, -1))
    }
  }

  return { validateInput }
}
