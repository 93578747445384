export class LabelColor {
  r = 255
  g = 255
  b = 255
  a = 1

  constructor(r: number, g: number, b: number, a: number) {
    this.r = r
    this.g = g
    this.b = b
    if (a > 1) this.a = 1
    else if (a <= 0) this.a = 0
    else this.a = a
  }
}
