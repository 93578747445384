import { PhaseEnum } from "../../enum/phaseEnum"
import { generateColor } from "../../services/color-service"
import { BSItemCategory } from "../beem-shot/BSItem/BSItemCategory"
import { BSItemSubCategory } from "../beem-shot/BSItem/BSItemSubCategory"
import CodeReference from "../code-acv/code-reference"
import { Material } from "../material/material"
import PsetAcv from "../pset-acv"
import { Quantity } from "../quantity"
import { CodeExtraitDisplay } from "./CodeExtraitDisplay"

export class CodeExtrait implements CodeExtraitDisplay {
  id: string | undefined = undefined
  bimModelId: string | undefined
  code = ""
  name = ""
  occurence = ""
  lot = ""
  sousLot = ""
  lotLabel = ""
  sousLotLabel = ""
  descriptionFromCodeAcv = ""
  descriptionFromBimModel = ""
  descriptionBe = ""
  errors: string[] = []
  extractedQuantities: Quantity = new Quantity()
  overiddenQuantities: Quantity = new Quantity()
  order = ""
  color = ""
  projectId: string | undefined
  projectPhase: PhaseEnum | undefined
  amountVariant1: number | undefined
  amountVariant2: number | undefined
  materialIdVariant1: string | undefined
  materialIdVariant2: string | undefined
  materialVariant1: Material | undefined
  materialVariant2: Material | undefined
  reuseVariant1 = false
  reuseVariant2 = false
  filled = false
  sameValuesV1V2 = true
  awsFileKey1: string | undefined
  filename1: string | undefined
  awsFileKey2: string | undefined
  filename2: string | undefined
  category: BSItemCategory | undefined
  subCategory: BSItemSubCategory | undefined

  /**
   * The element ids in the IFC model that are linked to this code
   */
  elementIds: number[] = []
  hypothesisVariant2: string | undefined
  hypothesisVariant1: string | undefined
  fileType1: string | undefined
  fileType2: string | undefined

  static fromDto(codeExtraitDto: any): CodeExtrait {
    const codeExtrait = new CodeExtrait()

    codeExtrait.id = codeExtraitDto.id
    codeExtrait.bimModelId = codeExtraitDto.bimModelId
    codeExtrait.code = codeExtraitDto.code
    codeExtrait.name = codeExtraitDto.name
    codeExtrait.occurence = codeExtraitDto.occurence
    codeExtrait.color = codeExtraitDto.color
    codeExtrait.descriptionFromCodeAcv = codeExtraitDto.descriptionFromCodeAcv
    codeExtrait.descriptionFromBimModel = codeExtraitDto.descriptionFromBimModel
    codeExtrait.elementIds = codeExtraitDto.elementIds
    codeExtrait.errors.push(...codeExtraitDto.errors)
    codeExtrait.extractedQuantities = Quantity.clone(codeExtraitDto.extractedQuantities)
    codeExtrait.filled = codeExtraitDto.filled
    codeExtrait.sameValuesV1V2 = codeExtraitDto.sameValuesV1V2
    codeExtrait.lot = codeExtraitDto.lot
    codeExtrait.sousLot = codeExtraitDto.sousLot
    codeExtrait.descriptionBe = codeExtraitDto.descriptionBe
    codeExtrait.overiddenQuantities = Quantity.clone(codeExtraitDto.overiddenQuantities)
    codeExtrait.projectId = codeExtraitDto.projectId
    codeExtrait.projectPhase = codeExtraitDto.projectPhase
    codeExtrait.reuseVariant1 = codeExtraitDto.reuseVariant1
    codeExtrait.reuseVariant2 = codeExtraitDto.reuseVariant2
    codeExtrait.amountVariant1 = codeExtraitDto.amountVariant1
    codeExtrait.amountVariant2 = codeExtraitDto.amountVariant2
    codeExtrait.materialIdVariant1 = codeExtraitDto.materialIdVariant1
    codeExtrait.materialIdVariant2 = codeExtraitDto.materialIdVariant2
    codeExtrait.materialVariant1 = codeExtraitDto.materialVariant1
      ? Material.fromCodeDto(codeExtraitDto.materialVariant1)
      : undefined
    codeExtrait.materialVariant2 = codeExtraitDto.materialVariant2
      ? Material.fromCodeDto(codeExtraitDto.materialVariant2)
      : undefined
    codeExtrait.hypothesisVariant1 = codeExtraitDto.hypothesisVariant1
    codeExtrait.hypothesisVariant2 = codeExtraitDto.hypothesisVariant2
    codeExtrait.awsFileKey1 = codeExtraitDto.awsFileKey1
    codeExtrait.filename1 = codeExtraitDto.filename1
    codeExtrait.awsFileKey2 = codeExtraitDto.awsFileKey2
    codeExtrait.filename2 = codeExtraitDto.filename2
    codeExtrait.order = codeExtraitDto.order

    return codeExtrait
  }

  static from(codeAcv: CodeReference, psetAcv: PsetAcv): CodeExtrait {
    const codeExtrait = new CodeExtrait()

    codeExtrait.code = codeAcv.code
    codeExtrait.name = codeAcv.name
    codeExtrait.occurence = psetAcv.occurrence
    codeExtrait.lot = codeAcv.lot
    codeExtrait.sousLot = codeAcv.sousLot
    codeExtrait.lotLabel = codeAcv.lotLabel
    codeExtrait.sousLotLabel = codeAcv.sousLotLabel
    codeExtrait.descriptionFromCodeAcv = codeAcv.description
    codeExtrait.descriptionFromBimModel = psetAcv.description
    codeExtrait.order = codeAcv.order
    codeExtrait.extractedQuantities = Quantity.clone(psetAcv.quantity)
    codeExtrait.extractedQuantities.number = 1
    codeExtrait.color = generateColor(codeAcv.code, psetAcv.occurrence)
    codeExtrait.category = codeAcv.category
    codeExtrait.subCategory = codeAcv.subCategory
    codeExtrait.errors.push(...psetAcv.errors)
    codeExtrait.errors.push(...psetAcv.errors)
    if (psetAcv.elementIds) {
      codeExtrait.elementIds.push(...psetAcv.elementIds)
    } else {
      console.error(`Element code ${psetAcv.codeAcv}${psetAcv.occurrence} has no element ID. It should be impossible.`)
    }

    return codeExtrait
  }

  static newInvalidCodeExtrait(psetAcv: PsetAcv): CodeExtrait {
    const codeExtrait = new CodeExtrait()

    codeExtrait.code = psetAcv.codeAcv
    codeExtrait.name = psetAcv.codeAcv
    codeExtrait.color = "#faa107"
    codeExtrait.occurence = psetAcv.occurrence
    codeExtrait.descriptionFromBimModel = psetAcv.description
    codeExtrait.extractedQuantities = Quantity.clone(psetAcv.quantity)
    codeExtrait.extractedQuantities.number = 1
    codeExtrait.errors.push(...psetAcv.errors)
    if (psetAcv.elementIds) {
      codeExtrait.elementIds.push(...psetAcv.elementIds)
    } else {
      console.error(`Element code ${psetAcv.codeAcv}${psetAcv.occurrence} has no element ID. It should be impossible.`)
    }

    return codeExtrait
  }

  static addPsetInformationInformation(codeExtrait: CodeExtrait, psetAcv: PsetAcv): void {
    codeExtrait.errors.push(...psetAcv.errors)
    if (psetAcv.elementIds) {
      codeExtrait.elementIds.push(...psetAcv.elementIds)
    } else {
      console.error(`Element code ${psetAcv.codeAcv}${psetAcv.occurrence} has no element ID. It should be impossible.`)
    }
    Quantity.accumulate(codeExtrait.code, codeExtrait.extractedQuantities, psetAcv.quantity)
  }
}
