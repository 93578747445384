import AccessTimeIcon from "@mui/icons-material/AccessTime"
import { Box, Grid, Pagination, Typography } from "@mui/material"
import React, { useEffect, useMemo, useState } from "react"
import { Contact } from "../../core/dto/user/contact"
import { CollaboratorTypeEnum } from "../../core/enum/user/collaboratorTypeEnum"
import { useOrganization } from "../../core/hooks/use-organization"
import { getFullNameLabel } from "../../core/services/helper-service"
import { SearchAndFilter } from "../beem-pilot/components/SearchAndFilter"
import { RoleUserChip } from "../beem-pilot/rseeTeam/components/RoleUserChip"

const ITEMS_PER_PAGE = 5

export default function OrganizationContact(): React.JSX.Element {
  const [contactsList, setContactsList] = useState<Contact[]>([])
  const { fetchContacts } = useOrganization()

  const [searchState, setSearchState] = useState<string | undefined>(undefined)
  const [page, setPage] = useState(1)

  const startIndex = useMemo(() => (page - 1) * ITEMS_PER_PAGE, [page])
  const endIndex = useMemo(() => startIndex + ITEMS_PER_PAGE, [startIndex])

  useEffect(() => {
    fetchContacts().then((contact) => {
      setContactsList(contact)
    })
  }, [fetchContacts])

  const itemsToShow = useMemo(() => {
    const list = contactsList.filter(
      (x) =>
        x.firstName.toLowerCase().includes(searchState?.toLowerCase() ?? "") ||
        x.lastName.toLowerCase().includes(searchState?.toLowerCase() ?? "")
    )
    return list
  }, [contactsList, searchState])

  function handleChange(event: any, value: React.SetStateAction<number>): void {
    setPage(value)
  }

  function handleSearch(e: string): void {
    setPage(1)
    setSearchState(e)
  }

  return (
    <>
      <Box>
        <SearchAndFilter searchState={searchState} handleSearch={handleSearch} searchStateLabel="Rechercher par nom " />
        <Grid container alignItems="flex-start" height="100%" rowGap={1}>
          {itemsToShow.length > 0 ? (
            <>
              <Grid container px={2} pb={1}>
                <Grid item xs={3}>
                  <Typography variant="subtitle2" fontSize={12} sx={{ color: "#8F8F8F" }}>
                    Nom
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle2" fontSize={12} sx={{ color: "#8F8F8F" }}>
                    Adresse e-mail
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="subtitle2" fontSize={12} sx={{ color: "#8F8F8F" }}>
                    Agence
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="subtitle2" fontSize={12} sx={{ color: "#8F8F8F" }}>
                    Téléphone
                  </Typography>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={1}>
                  <Typography variant="subtitle2" fontSize={12} sx={{ color: "#8F8F8F" }}>
                    Role
                  </Typography>
                </Grid>
              </Grid>
              {itemsToShow.slice(startIndex, endIndex).map((x) => {
                const fullName = getFullNameLabel(x.lastName, x.firstName)
                return (
                  <Grid
                    display="flex"
                    alignItems="center"
                    container
                    key={x.cognitoUserId}
                    borderRadius={4}
                    p={2}
                    sx={{ boxShadow: "2px 4px 20px 0px #0000000D", background: "white" }}>
                    <Grid item xs={3}>
                      {fullName}
                    </Grid>
                    <Grid item xs={3} sx={{ color: "#8F8F8F" }}>
                      {x.email}
                    </Grid>
                    <Grid item xs={2}>
                      <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
                        {x.userOrganizationName}
                      </Box>
                    </Grid>
                    <Grid item xs={2}>
                      {x.phone}
                    </Grid>
                    <Grid item xs={1}>
                      {x.type === CollaboratorTypeEnum.WAITING_CREATION && <AccessTimeIcon sx={{ color: "#FF9800" }} />}
                    </Grid>
                    <Grid item xs={1}>
                      <RoleUserChip role={x.role} />
                    </Grid>
                  </Grid>
                )
              })}
            </>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              minHeight="40vh"
              width="100%">
              <Typography fontSize="24px" fontWeight={600} lineHeight="36px" textAlign="center" color="#374771">
                Aucun résultat
              </Typography>
              <Typography variant="body1">Modifier les options de recherche.</Typography>
            </Box>
          )}
        </Grid>
      </Box>

      <Box
        display="flex"
        flexGrow={1}
        justifyContent="right"
        alignItems="flex-end"
        alignContent="flex-end"
        alignSelf="flex-end">
        <Pagination
          count={Math.ceil(itemsToShow.length / ITEMS_PER_PAGE)}
          page={page}
          onChange={handleChange}
          color="primary"
        />
      </Box>
    </>
  )
}
