import { LineBasicMaterial, Mesh, MeshBasicMaterial, RingGeometry } from "three"
import { MaterialHelper } from "../helpers/MaterialHelper"

/**
 * Reusable constants and materials for the ifc viewer
 */
export const viewerConstants = {
  startFileLoadingProgress: 1,
  endFileLoadingProgress: 99, //make room for the final step when creating the new materials
  mathTolerance: 0.0001,
  clickTimeoutMilliseconds: 125,
  measurement: {
    DESCENDER_ADJUST: 1,
    pointDistanceTolerance: 0.3,
    pointFarDistanceTolerance: 0.8,
    lineDistanceTolerance: 0.8,
    marker: new Mesh(
      new RingGeometry(0.2, 0.26, 20),
      new MeshBasicMaterial({
        color: 0xae0000,
        transparent: true,
        depthTest: false,
      })
    ),
    lineMaterial: MaterialHelper.getBeforeMeasureLineMaterial("blue", 10),
    pointMaterial: MaterialHelper.getMeasurePointMaterial("blue", 10),
    textLabel: {
      highLightMaterial: MaterialHelper.getMeasureHilightMaterial("#ff0000"),
    },
    lineDimension: {
      pointMaterial: MaterialHelper.getMeasurePointMaterial("blue", 10),
      lineMaterial: MaterialHelper.getMeasureLineMaterial("blue", 3),
    },
    edgeDimension: {
      subLinematerial: new LineBasicMaterial({ color: 0x00aa00, transparent: true, opacity: 0.6 }),
    },
  },
}
