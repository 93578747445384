import { Box, Button, Grid } from "@mui/material"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { adminPagesUrl, pagesUrl } from "../../../core/appConstants"
import { ProjectContext } from "../../../core/context/project/project-context"
import { SelectedPhaseContext } from "../../../core/context/selected-phase-context"
import { ProjectRoleContext } from "../../../core/context/user/project-role-context"
import { SimpleContact } from "../../../core/dto/SimpleContact"
import { Team } from "../../../core/dto/team"
import { ProjectInvitation } from "../../../core/dto/user/projectInvitation"
import { ProjectStatusEnum } from "../../../core/enum/projectStatusEnum"
import { RoleEnum } from "../../../core/enum/roleEnum"
import { useTeams } from "../../../core/hooks/use-team"
import { useUser } from "../../../core/hooks/use-user"
import { resolveUrl } from "../../../core/services/http-service"
import TeamDisplayer from "./team-displayer"
import { InvitationTeamForm } from "./team-invitation-form"
import { TitlePageTitle } from "./team-page-title"

export function TeamPage(): React.JSX.Element {
  const navigate = useNavigate()
  const location = useLocation()
  const { hasRole, isOrganizationUltimateUser } = useContext(ProjectRoleContext)
  const { project } = useContext(ProjectContext)
  const { selectedPhase } = useContext(SelectedPhaseContext)
  const { getInvitationByProject } = useUser()
  const { fetchTeam } = useTeams()
  const { organizationId } = useParams()

  const [newTeam, setNewTeam] = useState<Team>(new Team())
  const [invitedUser, setInvitedUser] = useState<ProjectInvitation[]>([])
  const [searchCollab, setSearchCollab] = useState<SimpleContact | null>(null)

  const isCreation = useMemo(() => location.pathname.endsWith("/new"), [])
  const isWriting: boolean = useMemo(
    () => (hasRole([RoleEnum.ADMINISTRATOR]) || isCreation) && project.status !== ProjectStatusEnum.ARCHIVED,
    [hasRole, isCreation, project.status]
  )

  const refreshTeamInfo = useCallback((): void => {
    if (project?.id !== undefined) {
      getInvitationByProject(project.id).then((r) => {
        setInvitedUser(r)
      })

      fetchTeam(project?.id).then((team) => {
        setNewTeam(team)
      })
    }
  }, [getInvitationByProject, fetchTeam, project.id])

  useEffect(() => {
    if (project?.id !== "") {
      refreshTeamInfo()
    }
  }, [project?.id, refreshTeamInfo])

  function validateProject(): void {
    navigate(resolveUrl(pagesUrl.PROJECT_PHASE_STEPPER_CREATION, [project.id]))
  }

  function goToPreviousPage(): void {
    isOrganizationUltimateUser
      ? navigate(resolveUrl(adminPagesUrl.PROJECT_CONTROL_BOARD, [organizationId, project?.id]))
      : navigate(resolveUrl(pagesUrl.PROJECT_CONTROL_BOARD, [project?.id]))
  }

  return (
    <Box>
      <Grid container sx={{ pl: 10, pr: 6, pb: 0, pt: 0 }}>
        <TitlePageTitle
          title="Équipe"
          icone="person"
          subTitle={`${project?.name} ${selectedPhase ? `(${selectedPhase})` : ""}`}
        />
        <Grid item md={6} />

        <Grid
          item
          md={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          {isCreation ? (
            <Button variant="contained" onClick={validateProject}>
              Valider mon équipe
            </Button>
          ) : (
            <>
              <Button variant="outlined" onClick={goToPreviousPage}>
                Retour
              </Button>
              <Button
                sx={{
                  ml: 1,
                }}
                onClick={goToPreviousPage}
                variant="contained">
                Valider
              </Button>
            </>
          )}
        </Grid>
      </Grid>

      <Box sx={{ padding: "2% 5%", display: "flex" }}>
        <Grid container>
          <Grid item md={6}>
            <TeamDisplayer newTeam={newTeam} invitedUser={invitedUser} refreshTeamInfo={refreshTeamInfo} />
          </Grid>
          {isWriting && (
            <Grid item md={6}>
              <InvitationTeamForm
                searchCollab={searchCollab}
                setSearchCollab={setSearchCollab}
                refreshTeamInfo={refreshTeamInfo}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  )
}
