/**
 * Enumerates all the IFC types that are currently supported by IFC.js
 * The right way would be using the method 'getNameFromTypeCode' but it is currently broken when used with the webworkers copied from web-ifc-three
 * and always returns '<web-ifc-type-unknown>' for every type. This is why we are using the type codes directly.
 * according to this issue: https://github.com/IFCjs/web-ifc-three/issues/158
 */
export enum IfcTypes {
  IFCURIREFERENCE = 950732822,
  IFCTIME = 4075327185,
  IFCTEMPERATURERATEOFCHANGEMEASURE = 1209108979,
  IFCSOUNDPRESSURELEVELMEASURE = 3457685358,
  IFCSOUNDPOWERLEVELMEASURE = 4157543285,
  IFCPROPERTYSETDEFINITIONSET = 2798247006,
  IFCPOSITIVEINTEGER = 1790229001,
  IFCNONNEGATIVELENGTHMEASURE = 525895558,
  IFCLINEINDEX = 1774176899,
  IFCLANGUAGEID = 1275358634,
  IFCDURATION = 2541165894,
  IFCDAYINWEEKNUMBER = 3701338814,
  IFCDATETIME = 2195413836,
  IFCDATE = 937566702,
  IFCCARDINALPOINTREFERENCE = 1683019596,
  IFCBINARY = 2314439260,
  IFCAREADENSITYMEASURE = 1500781891,
  IFCARCINDEX = 3683503648,
  IFCYEARNUMBER = 4065007721,
  IFCWARPINGMOMENTMEASURE = 1718600412,
  IFCWARPINGCONSTANTMEASURE = 51269191,
  IFCVOLUMETRICFLOWRATEMEASURE = 2593997549,
  IFCVOLUMEMEASURE = 3458127941,
  IFCVAPORPERMEABILITYMEASURE = 3345633955,
  IFCTORQUEMEASURE = 1278329552,
  IFCTIMESTAMP = 2591213694,
  IFCTIMEMEASURE = 2726807636,
  IFCTHERMODYNAMICTEMPERATUREMEASURE = 743184107,
  IFCTHERMALTRANSMITTANCEMEASURE = 2016195849,
  IFCTHERMALRESISTANCEMEASURE = 857959152,
  IFCTHERMALEXPANSIONCOEFFICIENTMEASURE = 2281867870,
  IFCTHERMALCONDUCTIVITYMEASURE = 2645777649,
  IFCTHERMALADMITTANCEMEASURE = 232962298,
  IFCTEXTTRANSFORMATION = 296282323,
  IFCTEXTFONTNAME = 603696268,
  IFCTEXTDECORATION = 3490877962,
  IFCTEXTALIGNMENT = 1460886941,
  IFCTEXT = 2801250643,
  IFCTEMPERATUREGRADIENTMEASURE = 58845555,
  IFCSPECULARROUGHNESS = 361837227,
  IFCSPECULAREXPONENT = 2757832317,
  IFCSPECIFICHEATCAPACITYMEASURE = 3477203348,
  IFCSOUNDPRESSUREMEASURE = 993287707,
  IFCSOUNDPOWERMEASURE = 846465480,
  IFCSOLIDANGLEMEASURE = 3471399674,
  IFCSHEARMODULUSMEASURE = 408310005,
  IFCSECTIONALAREAINTEGRALMEASURE = 2190458107,
  IFCSECTIONMODULUSMEASURE = 3467162246,
  IFCSECONDINMINUTE = 2766185779,
  IFCROTATIONALSTIFFNESSMEASURE = 3211557302,
  IFCROTATIONALMASSMEASURE = 1755127002,
  IFCROTATIONALFREQUENCYMEASURE = 2133746277,
  IFCREAL = 200335297,
  IFCRATIOMEASURE = 96294661,
  IFCRADIOACTIVITYMEASURE = 3972513137,
  IFCPRESSUREMEASURE = 3665567075,
  IFCPRESENTABLETEXT = 2169031380,
  IFCPOWERMEASURE = 1364037233,
  IFCPOSITIVERATIOMEASURE = 1245737093,
  IFCPOSITIVEPLANEANGLEMEASURE = 3054510233,
  IFCPOSITIVELENGTHMEASURE = 2815919920,
  IFCPLANEANGLEMEASURE = 4042175685,
  IFCPLANARFORCEMEASURE = 2642773653,
  IFCPARAMETERVALUE = 2260317790,
  IFCPHMEASURE = 929793134,
  IFCNUMERICMEASURE = 2395907400,
  IFCNORMALISEDRATIOMEASURE = 2095195183,
  IFCMONTHINYEARNUMBER = 765770214,
  IFCMONETARYMEASURE = 2615040989,
  IFCMOMENTOFINERTIAMEASURE = 3114022597,
  IFCMOLECULARWEIGHTMEASURE = 1648970520,
  IFCMOISTUREDIFFUSIVITYMEASURE = 3177669450,
  IFCMODULUSOFSUBGRADEREACTIONMEASURE = 1753493141,
  IFCMODULUSOFROTATIONALSUBGRADEREACTIONMEASURE = 1052454078,
  IFCMODULUSOFLINEARSUBGRADEREACTIONMEASURE = 2173214787,
  IFCMODULUSOFELASTICITYMEASURE = 3341486342,
  IFCMINUTEINHOUR = 102610177,
  IFCMASSPERLENGTHMEASURE = 3531705166,
  IFCMASSMEASURE = 3124614049,
  IFCMASSFLOWRATEMEASURE = 4017473158,
  IFCMASSDENSITYMEASURE = 1477762836,
  IFCMAGNETICFLUXMEASURE = 2486716878,
  IFCMAGNETICFLUXDENSITYMEASURE = 286949696,
  IFCLUMINOUSINTENSITYMEASURE = 151039812,
  IFCLUMINOUSINTENSITYDISTRIBUTIONMEASURE = 2755797622,
  IFCLUMINOUSFLUXMEASURE = 2095003142,
  IFCLOGICAL = 503418787,
  IFCLINEARVELOCITYMEASURE = 3086160713,
  IFCLINEARSTIFFNESSMEASURE = 1307019551,
  IFCLINEARMOMENTMEASURE = 2128979029,
  IFCLINEARFORCEMEASURE = 191860431,
  IFCLENGTHMEASURE = 1243674935,
  IFCLABEL = 3258342251,
  IFCKINEMATICVISCOSITYMEASURE = 2054016361,
  IFCISOTHERMALMOISTURECAPACITYMEASURE = 3192672207,
  IFCIONCONCENTRATIONMEASURE = 3686016028,
  IFCINTEGERCOUNTRATEMEASURE = 3809634241,
  IFCINTEGER = 1939436016,
  IFCINDUCTANCEMEASURE = 2679005408,
  IFCILLUMINANCEMEASURE = 3358199106,
  IFCIDENTIFIER = 983778844,
  IFCHOURINDAY = 2589826445,
  IFCHEATINGVALUEMEASURE = 1158859006,
  IFCHEATFLUXDENSITYMEASURE = 3113092358,
  IFCGLOBALLYUNIQUEID = 3064340077,
  IFCFREQUENCYMEASURE = 3044325142,
  IFCFORCEMEASURE = 1361398929,
  IFCFONTWEIGHT = 2590844177,
  IFCFONTVARIANT = 2715512545,
  IFCFONTSTYLE = 1102727119,
  IFCENERGYMEASURE = 2078135608,
  IFCELECTRICVOLTAGEMEASURE = 2506197118,
  IFCELECTRICRESISTANCEMEASURE = 2951915441,
  IFCELECTRICCURRENTMEASURE = 3790457270,
  IFCELECTRICCONDUCTANCEMEASURE = 2093906313,
  IFCELECTRICCHARGEMEASURE = 3818826038,
  IFCELECTRICCAPACITANCEMEASURE = 1827137117,
  IFCDYNAMICVISCOSITYMEASURE = 69416015,
  IFCDOSEEQUIVALENTMEASURE = 524656162,
  IFCDIMENSIONCOUNT = 4134073009,
  IFCDESCRIPTIVEMEASURE = 1514641115,
  IFCDAYLIGHTSAVINGHOUR = 300323983,
  IFCDAYINMONTHNUMBER = 86635668,
  IFCCURVATUREMEASURE = 94842927,
  IFCCOUNTMEASURE = 1778710042,
  IFCCONTEXTDEPENDENTMEASURE = 3238673880,
  IFCCOMPOUNDPLANEANGLEMEASURE = 3812528620,
  IFCCOMPLEXNUMBER = 2991860651,
  IFCBOXALIGNMENT = 1867003952,
  IFCBOOLEAN = 2735952531,
  IFCAREAMEASURE = 2650437152,
  IFCANGULARVELOCITYMEASURE = 632304761,
  IFCAMOUNTOFSUBSTANCEMEASURE = 360377573,
  IFCACCELERATIONMEASURE = 4182062534,
  IFCABSORBEDDOSEMEASURE = 3699917729,
  IFCBEAMSTANDARDCASE = 2906023776,
  IFCWINDOWSTANDARDCASE = 486154966,
  IFCWALLELEMENTEDCASE = 4156078855,
  IFCSLABSTANDARDCASE = 3027962421,
  IFCSLABELEMENTEDCASE = 3127900445,
  IFCPLATESTANDARDCASE = 1156407060,
  IFCMEMBERSTANDARDCASE = 1911478936,
  IFCDOORSTANDARDCASE = 3242481149,
  IFCCOLUMNSTANDARDCASE = 905975707,
  IFCOPENINGSTANDARDCASE = 3079942009,
  IFCCONTROLLER = 25142252,
  IFCALARM = 3087945054,
  IFCACTUATOR = 4288193352,
  IFCUNITARYCONTROLELEMENT = 630975310,
  IFCSENSOR = 4086658281,
  IFCPROTECTIVEDEVICETRIPPINGUNIT = 2295281155,
  IFCGEOSLICE = 1971632696,
  IFCGEOMODEL = 2680139844,
  IFCFLOWINSTRUMENT = 182646315,
  IFCFIRESUPPRESSIONTERMINAL = 1426591983,
  IFCFILTER = 819412036,
  IFCFAN = 3415622556,
  IFCELECTRICTIMECONTROL = 1003880860,
  IFCELECTRICMOTOR = 402227799,
  IFCELECTRICGENERATOR = 264262732,
  IFCELECTRICFLOWTREATMENTDEVICE = 24726584,
  IFCELECTRICFLOWSTORAGEDEVICE = 3310460725,
  IFCELECTRICDISTRIBUTIONBOARD = 862014818,
  IFCELECTRICAPPLIANCE = 1904799276,
  IFCDUCTSILENCER = 1360408905,
  IFCDUCTSEGMENT = 3518393246,
  IFCDUCTFITTING = 342316401,
  IFCDISTRIBUTIONCIRCUIT = 562808652,
  IFCDISTRIBUTIONBOARD = 3693000487,
  IFCDAMPER = 4074379575,
  IFCCOOLINGTOWER = 3640358203,
  IFCCOOLEDBEAM = 4136498852,
  IFCCONVEYORSEGMENT = 3460952963,
  IFCCONDENSER = 2272882330,
  IFCCOMPRESSOR = 3571504051,
  IFCCOMMUNICATIONSAPPLIANCE = 3221913625,
  IFCCOIL = 639361253,
  IFCCHILLER = 3902619387,
  IFCCAISSONFOUNDATION = 3999819293,
  IFCCABLESEGMENT = 4217484030,
  IFCCABLEFITTING = 1051757585,
  IFCCABLECARRIERSEGMENT = 3758799889,
  IFCCABLECARRIERFITTING = 635142910,
  IFCBURNER = 2938176219,
  IFCBOREHOLE = 3314249567,
  IFCBOILER = 32344328,
  IFCBEARING = 4196446775,
  IFCAUDIOVISUALAPPLIANCE = 277319702,
  IFCALIGNMENT = 325726236,
  IFCAIRTOAIRHEATRECOVERY = 2056796094,
  IFCAIRTERMINALBOX = 177149247,
  IFCAIRTERMINAL = 1634111441,
  IFCWASTETERMINAL = 4237592921,
  IFCVALVE = 4207607924,
  IFCUNITARYEQUIPMENT = 4292641817,
  IFCUNITARYCONTROLELEMENTTYPE = 3179687236,
  IFCTUBEBUNDLE = 3026737570,
  IFCTRANSFORMER = 3825984169,
  IFCTRACKELEMENT = 3425753595,
  IFCTANK = 812556717,
  IFCSWITCHINGDEVICE = 1162798199,
  IFCSTRUCTURALLOADCASE = 385403989,
  IFCSTACKTERMINAL = 1404847402,
  IFCSPACEHEATER = 1999602285,
  IFCSOLARDEVICE = 3420628829,
  IFCSIGNAL = 991950508,
  IFCSHADINGDEVICE = 1329646415,
  IFCSANITARYTERMINAL = 3053780830,
  IFCREINFORCINGBARTYPE = 2572171363,
  IFCREINFORCEDSOIL = 3798194928,
  IFCRATIONALBSPLINECURVEWITHKNOTS = 1232101972,
  IFCRAIL = 3290496277,
  IFCPUMP = 90941305,
  IFCPROTECTIVEDEVICETRIPPINGUNITTYPE = 655969474,
  IFCPROTECTIVEDEVICE = 738039164,
  IFCPIPESEGMENT = 3612865200,
  IFCPIPEFITTING = 310824031,
  IFCPAVEMENT = 1383356374,
  IFCOUTLET = 3694346114,
  IFCOUTERBOUNDARYCURVE = 144952367,
  IFCNAVIGATIONELEMENT = 2182337498,
  IFCMOTORCONNECTION = 2474470126,
  IFCMOORINGDEVICE = 234836483,
  IFCMOBILETELECOMMUNICATIONSAPPLIANCE = 2078563270,
  IFCMEDICALDEVICE = 1437502449,
  IFCLIQUIDTERMINAL = 1638804497,
  IFCLINEARPOSITIONINGELEMENT = 1154579445,
  IFCLIGHTFIXTURE = 629592764,
  IFCLAMP = 76236018,
  IFCKERB = 2696325953,
  IFCJUNCTIONBOX = 2176052936,
  IFCINTERCEPTOR = 4175244083,
  IFCHUMIDIFIER = 2068733104,
  IFCHEATEXCHANGER = 3319311131,
  IFCGEOTECHNICALASSEMBLY = 2713699986,
  IFCFLOWMETER = 2188021234,
  IFCEXTERNALSPATIALELEMENT = 1209101575,
  IFCEVAPORATOR = 484807127,
  IFCEVAPORATIVECOOLER = 3747195512,
  IFCENGINE = 2814081492,
  IFCELECTRICFLOWTREATMENTDEVICETYPE = 2142170206,
  IFCELECTRICDISTRIBUTIONBOARDTYPE = 2417008758,
  IFCEARTHWORKSFILL = 3376911765,
  IFCEARTHWORKSELEMENT = 1077100507,
  IFCEARTHWORKSCUT = 3071239417,
  IFCDISTRIBUTIONSYSTEM = 3205830791,
  IFCDISTRIBUTIONBOARDTYPE = 479945903,
  IFCDEEPFOUNDATION = 3426335179,
  IFCCOURSE = 1502416096,
  IFCCONVEYORSEGMENTTYPE = 2940368186,
  IFCCOMMUNICATIONSAPPLIANCETYPE = 400855858,
  IFCCIVILELEMENT = 1677625105,
  IFCCHIMNEY = 3296154744,
  IFCCAISSONFOUNDATIONTYPE = 3203706013,
  IFCCABLEFITTINGTYPE = 2674252688,
  IFCBURNERTYPE = 2188180465,
  IFCBUILTSYSTEM = 3862327254,
  IFCBUILTELEMENT = 1876633798,
  IFCBUILDINGSYSTEM = 1177604601,
  IFCBUILDINGELEMENTPARTTYPE = 39481116,
  IFCBRIDGEPART = 963979645,
  IFCBRIDGE = 644574406,
  IFCBOUNDARYCURVE = 1136057603,
  IFCBEARINGTYPE = 3649138523,
  IFCBSPLINECURVEWITHKNOTS = 2461110595,
  IFCAUDIOVISUALAPPLIANCETYPE = 1532957894,
  IFCALIGNMENTVERTICAL = 1662888072,
  IFCALIGNMENTSEGMENT = 317615605,
  IFCALIGNMENTHORIZONTAL = 1545765605,
  IFCALIGNMENTCANT = 4266260250,
  IFCWORKCALENDAR = 4088093105,
  IFCWINDOWTYPE = 4009809668,
  IFCVOIDINGFEATURE = 926996030,
  IFCVIBRATIONISOLATOR = 2391383451,
  IFCVIBRATIONDAMPERTYPE = 3956297820,
  IFCVIBRATIONDAMPER = 1530820697,
  IFCVEHICLE = 840318589,
  IFCTRANSPORTATIONDEVICE = 1953115116,
  IFCTRACKELEMENTTYPE = 618700268,
  IFCTENDONTYPE = 2415094496,
  IFCTENDONCONDUITTYPE = 2281632017,
  IFCTENDONCONDUIT = 3663046924,
  IFCTENDONANCHORTYPE = 3081323446,
  IFCSYSTEMFURNITUREELEMENT = 413509423,
  IFCSURFACEFEATURE = 3101698114,
  IFCSTRUCTURALSURFACEACTION = 3657597509,
  IFCSTRUCTURALCURVEREACTION = 2757150158,
  IFCSTRUCTURALCURVEACTION = 1004757350,
  IFCSTAIRTYPE = 338393293,
  IFCSOLARDEVICETYPE = 1072016465,
  IFCSINESPIRAL = 42703149,
  IFCSIGNALTYPE = 1894708472,
  IFCSIGNTYPE = 3599934289,
  IFCSIGN = 33720170,
  IFCSHADINGDEVICETYPE = 4074543187,
  IFCSEVENTHORDERPOLYNOMIALSPIRAL = 1027922057,
  IFCSEGMENTEDREFERENCECURVE = 544395925,
  IFCSECONDORDERPOLYNOMIALSPIRAL = 3649235739,
  IFCSEAMCURVE = 2157484638,
  IFCROOFTYPE = 2781568857,
  IFCROADPART = 550521510,
  IFCROAD = 146592293,
  IFCRELADHERESTOELEMENT = 3818125796,
  IFCREINFORCINGMESHTYPE = 2310774935,
  IFCREINFORCINGELEMENTTYPE = 964333572,
  IFCREFERENT = 4021432810,
  IFCRATIONALBSPLINESURFACEWITHKNOTS = 683857671,
  IFCRAMPTYPE = 1469900589,
  IFCRAILWAYPART = 1891881377,
  IFCRAILWAY = 3992365140,
  IFCRAILTYPE = 1763565496,
  IFCPOSITIONINGELEMENT = 1946335990,
  IFCPOLYGONALFACESET = 2839578677,
  IFCPILETYPE = 1158309216,
  IFCPAVEMENTTYPE = 514975943,
  IFCNAVIGATIONELEMENTTYPE = 506776471,
  IFCMOORINGDEVICETYPE = 710110818,
  IFCMOBILETELECOMMUNICATIONSAPPLIANCETYPE = 1950438474,
  IFCMEDICALDEVICETYPE = 1114901282,
  IFCMARINEPART = 976884017,
  IFCMARINEFACILITY = 525669439,
  IFCLIQUIDTERMINALTYPE = 1770583370,
  IFCLINEARELEMENT = 2176059722,
  IFCKERBTYPE = 679976338,
  IFCINTERSECTIONCURVE = 3113134337,
  IFCINTERCEPTORTYPE = 3946677679,
  IFCINDEXEDPOLYCURVE = 2571569899,
  IFCIMPACTPROTECTIONDEVICETYPE = 3948183225,
  IFCIMPACTPROTECTIONDEVICE = 2568555532,
  IFCGRADIENTCURVE = 2898700619,
  IFCGEOTECHNICALSTRATUM = 1594536857,
  IFCGEOTECHNICALELEMENT = 4230923436,
  IFCGEOGRAPHICELEMENT = 3493046030,
  IFCFURNITURE = 1509553395,
  IFCFOOTINGTYPE = 1893162501,
  IFCFACILITYPARTCOMMON = 4228831410,
  IFCFACILITYPART = 1310830890,
  IFCFACILITY = 24185140,
  IFCEXTERNALSPATIALSTRUCTUREELEMENT = 2853485674,
  IFCEVENT = 4148101412,
  IFCENGINETYPE = 132023988,
  IFCELEMENTASSEMBLYTYPE = 2397081782,
  IFCDOORTYPE = 2323601079,
  IFCDIRECTRIXDERIVEDREFERENCESWEPTAREASOLID = 4234616927,
  IFCDEEPFOUNDATIONTYPE = 1306400036,
  IFCCYLINDRICALSURFACE = 1213902940,
  IFCCOURSETYPE = 4189326743,
  IFCCOSINESPIRAL = 2000195564,
  IFCCONSTRUCTIONPRODUCTRESOURCETYPE = 1525564444,
  IFCCONSTRUCTIONMATERIALRESOURCETYPE = 4105962743,
  IFCCONSTRUCTIONEQUIPMENTRESOURCETYPE = 2185764099,
  IFCCOMPOSITECURVEONSURFACE = 15328376,
  IFCCOMPLEXPROPERTYTEMPLATE = 3875453745,
  IFCCLOTHOID = 3497074424,
  IFCCIVILELEMENTTYPE = 3893394355,
  IFCCHIMNEYTYPE = 2197970202,
  IFCBUILTELEMENTTYPE = 1626504194,
  IFCBSPLINESURFACEWITHKNOTS = 167062518,
  IFCBSPLINESURFACE = 2887950389,
  IFCADVANCEDBREPWITHVOIDS = 2603310189,
  IFCADVANCEDBREP = 1635779807,
  IFCVEHICLETYPE = 3651464721,
  IFCTRIANGULATEDIRREGULARNETWORK = 1229763772,
  IFCTRIANGULATEDFACESET = 2916149573,
  IFCTRANSPORTATIONDEVICETYPE = 3665877780,
  IFCTOROIDALSURFACE = 1935646853,
  IFCTHIRDORDERPOLYNOMIALSPIRAL = 782932809,
  IFCTESSELLATEDFACESET = 2387106220,
  IFCTASKTYPE = 3206491090,
  IFCSURFACECURVE = 699246055,
  IFCSUBCONTRACTRESOURCETYPE = 4095615324,
  IFCSTRUCTURALSURFACEREACTION = 603775116,
  IFCSPIRAL = 2735484536,
  IFCSPHERICALSURFACE = 4015995234,
  IFCSPATIALZONETYPE = 2481509218,
  IFCSPATIALZONE = 463610769,
  IFCSPATIALELEMENTTYPE = 710998568,
  IFCSPATIALELEMENT = 1412071761,
  IFCSIMPLEPROPERTYTEMPLATE = 3663146110,
  IFCSECTIONEDSURFACE = 1356537516,
  IFCSECTIONEDSOLIDHORIZONTAL = 1290935644,
  IFCSECTIONEDSOLID = 1862484736,
  IFCREVOLVEDAREASOLIDTAPERED = 3243963512,
  IFCREPARAMETRISEDCOMPOSITECURVESEGMENT = 816062949,
  IFCRELSPACEBOUNDARY2NDLEVEL = 1521410863,
  IFCRELSPACEBOUNDARY1STLEVEL = 3523091289,
  IFCRELPOSITIONS = 1441486842,
  IFCRELINTERFERESELEMENTS = 427948657,
  IFCRELDEFINESBYTEMPLATE = 307848117,
  IFCRELDEFINESBYOBJECT = 1462361463,
  IFCRELDECLARES = 2565941209,
  IFCRELASSOCIATESPROFILEDEF = 1033248425,
  IFCRELASSIGNSTOGROUPBYFACTOR = 1027710054,
  IFCPROPERTYTEMPLATE = 3521284610,
  IFCPROPERTYSETTEMPLATE = 492091185,
  IFCPROJECTLIBRARY = 653396225,
  IFCPROCEDURETYPE = 569719735,
  IFCPREDEFINEDPROPERTYSET = 3967405729,
  IFCPOLYNOMIALCURVE = 3381221214,
  IFCPCURVE = 1682466193,
  IFCOFFSETCURVEBYDISTANCES = 2485787929,
  IFCOFFSETCURVE = 590820931,
  IFCLABORRESOURCETYPE = 428585644,
  IFCINDEXEDPOLYGONALTEXTUREMAP = 3465909080,
  IFCINDEXEDPOLYGONALFACEWITHVOIDS = 2294589976,
  IFCINDEXEDPOLYGONALFACE = 178912537,
  IFCGEOGRAPHICELEMENTTYPE = 4095422895,
  IFCFIXEDREFERENCESWEPTAREASOLID = 2652556860,
  IFCEXTRUDEDAREASOLIDTAPERED = 2804161546,
  IFCEVENTTYPE = 4024345920,
  IFCDIRECTRIXCURVESWEPTAREASOLID = 593015953,
  IFCCURVESEGMENT = 4212018352,
  IFCCURVEBOUNDEDSURFACE = 2629017746,
  IFCCREWRESOURCETYPE = 1815067380,
  IFCCONTEXT = 3419103109,
  IFCCONSTRUCTIONRESOURCETYPE = 2574617495,
  IFCCARTESIANPOINTLIST3D = 2059837836,
  IFCCARTESIANPOINTLIST2D = 1675464909,
  IFCCARTESIANPOINTLIST = 574549367,
  IFCAXIS2PLACEMENTLINEAR = 3425423356,
  IFCADVANCEDFACE = 3406155212,
  IFCTYPERESOURCE = 3698973494,
  IFCTYPEPROCESS = 3736923433,
  IFCTESSELLATEDITEM = 901063453,
  IFCSWEPTDISKSOLIDPOLYGONAL = 1096409881,
  IFCSEGMENT = 823603102,
  IFCRESOURCETIME = 1042787934,
  IFCRESOURCECONSTRAINTRELATIONSHIP = 1608871552,
  IFCRESOURCEAPPROVALRELATIONSHIP = 2943643501,
  IFCQUANTITYSET = 2090586900,
  IFCPROPERTYTEMPLATEDEFINITION = 1482703590,
  IFCPREDEFINEDPROPERTIES = 3778827333,
  IFCPOINTBYDISTANCEEXPRESSION = 2165702409,
  IFCOPENCROSSPROFILEDEF = 182550632,
  IFCMIRROREDPROFILEDEF = 2998442950,
  IFCMATERIALRELATIONSHIP = 853536259,
  IFCMATERIALPROFILESETUSAGETAPERING = 3404854881,
  IFCMATERIALPROFILESETUSAGE = 3079605661,
  IFCMATERIALCONSTITUENTSET = 2852063980,
  IFCMATERIALCONSTITUENT = 3708119000,
  IFCLINEARPLACEMENT = 388784114,
  IFCLAGTIME = 1585845231,
  IFCINDEXEDTRIANGLETEXTUREMAP = 2133299955,
  IFCINDEXEDTEXTUREMAP = 1437953363,
  IFCINDEXEDCOLOURMAP = 3570813810,
  IFCEXTERNALREFERENCERELATIONSHIP = 1437805879,
  IFCEXTENDEDPROPERTIES = 297599258,
  IFCEVENTTIME = 211053100,
  IFCCONVERSIONBASEDUNITWITHOFFSET = 2713554722,
  IFCCOLOURRGBLIST = 3285139300,
  IFCALIGNMENTHORIZONTALSEGMENT = 536804194,
  IFCALIGNMENTCANTSEGMENT = 3752311538,
  IFCWORKTIME = 1236880293,
  IFCTIMEPERIOD = 1199560280,
  IFCTEXTUREVERTEXLIST = 3611470254,
  IFCTEXTURECOORDINATEINDICESWITHVOIDS = 1010789467,
  IFCTEXTURECOORDINATEINDICES = 222769930,
  IFCTASKTIMERECURRING = 2771591690,
  IFCTASKTIME = 1549132990,
  IFCTABLECOLUMN = 2043862942,
  IFCSURFACEREINFORCEMENTAREA = 2934153892,
  IFCSTRUCTURALLOADORRESULT = 609421318,
  IFCSTRUCTURALLOADCONFIGURATION = 3478079324,
  IFCSCHEDULINGTIME = 1054537805,
  IFCRESOURCELEVELRELATIONSHIP = 2439245199,
  IFCREFERENCE = 2433181523,
  IFCRECURRENCEPATTERN = 3915482550,
  IFCQUANTITYNUMBER = 2691318326,
  IFCPROPERTYABSTRACTION = 986844984,
  IFCPROJECTEDCRS = 3843373140,
  IFCPRESENTATIONITEM = 677532197,
  IFCMATERIALUSAGEDEFINITION = 1507914824,
  IFCMATERIALPROFILEWITHOFFSETS = 552965576,
  IFCMATERIALPROFILESET = 164193824,
  IFCMATERIALPROFILE = 2235152071,
  IFCMATERIALLAYERWITHOFFSETS = 1847252529,
  IFCMATERIALDEFINITION = 760658860,
  IFCMAPCONVERSION = 3057273783,
  IFCEXTERNALINFORMATION = 4294318154,
  IFCCOORDINATEREFERENCESYSTEM = 1466758467,
  IFCCOORDINATEOPERATION = 1785450214,
  IFCCONNECTIONVOLUMEGEOMETRY = 775493141,
  IFCALIGNMENTVERTICALSEGMENT = 3633395639,
  IFCALIGNMENTPARAMETERSEGMENT = 2879124712,
  IFCREINFORCINGBAR = 979691226,
  IFCELECTRICDISTRIBUTIONPOINT = 3700593921,
  IFCDISTRIBUTIONCONTROLELEMENT = 1062813311,
  IFCDISTRIBUTIONCHAMBERELEMENT = 1052013943,
  IFCCONTROLLERTYPE = 578613899,
  IFCCHAMFEREDGEFEATURE = 2454782716,
  IFCBEAM = 753842376,
  IFCALARMTYPE = 3001207471,
  IFCACTUATORTYPE = 2874132201,
  IFCWINDOW = 3304561284,
  IFCWALLSTANDARDCASE = 3512223829,
  IFCWALL = 2391406946,
  IFCVIBRATIONISOLATORTYPE = 3313531582,
  IFCTENDONANCHOR = 2347447852,
  IFCTENDON = 3824725483,
  IFCSTRUCTURALANALYSISMODEL = 2515109513,
  IFCSTAIRFLIGHT = 4252922144,
  IFCSTAIR = 331165859,
  IFCSLAB = 1529196076,
  IFCSENSORTYPE = 1783015770,
  IFCROUNDEDEDGEFEATURE = 1376911519,
  IFCROOF = 2016517767,
  IFCREINFORCINGMESH = 2320036040,
  IFCREINFORCINGELEMENT = 3027567501,
  IFCRATIONALBEZIERCURVE = 3055160366,
  IFCRAMPFLIGHT = 3283111854,
  IFCRAMP = 3024970846,
  IFCRAILING = 2262370178,
  IFCPLATE = 3171933400,
  IFCPILE = 1687234759,
  IFCMEMBER = 1073191201,
  IFCFOOTING = 900683007,
  IFCFLOWTREATMENTDEVICE = 3508470533,
  IFCFLOWTERMINAL = 2223149337,
  IFCFLOWSTORAGEDEVICE = 707683696,
  IFCFLOWSEGMENT = 987401354,
  IFCFLOWMOVINGDEVICE = 3132237377,
  IFCFLOWINSTRUMENTTYPE = 4037862832,
  IFCFLOWFITTING = 4278956645,
  IFCFLOWCONTROLLER = 2058353004,
  IFCFIRESUPPRESSIONTERMINALTYPE = 4222183408,
  IFCFILTERTYPE = 1810631287,
  IFCFANTYPE = 346874300,
  IFCENERGYCONVERSIONDEVICE = 1658829314,
  IFCELECTRICALELEMENT = 857184966,
  IFCELECTRICALCIRCUIT = 1634875225,
  IFCELECTRICTIMECONTROLTYPE = 712377611,
  IFCELECTRICMOTORTYPE = 1217240411,
  IFCELECTRICHEATERTYPE = 1365060375,
  IFCELECTRICGENERATORTYPE = 1534661035,
  IFCELECTRICFLOWSTORAGEDEVICETYPE = 3277789161,
  IFCELECTRICAPPLIANCETYPE = 663422040,
  IFCEDGEFEATURE = 855621170,
  IFCDUCTSILENCERTYPE = 2030761528,
  IFCDUCTSEGMENTTYPE = 3760055223,
  IFCDUCTFITTINGTYPE = 869906466,
  IFCDOOR = 395920057,
  IFCDISTRIBUTIONPORT = 3041715199,
  IFCDISTRIBUTIONFLOWELEMENT = 3040386961,
  IFCDISTRIBUTIONELEMENT = 1945004755,
  IFCDISTRIBUTIONCONTROLELEMENTTYPE = 2063403501,
  IFCDISTRIBUTIONCHAMBERELEMENTTYPE = 1599208980,
  IFCDISCRETEACCESSORYTYPE = 2635815018,
  IFCDISCRETEACCESSORY = 1335981549,
  IFCDIAMETERDIMENSION = 4147604152,
  IFCDAMPERTYPE = 3961806047,
  IFCCURTAINWALL = 3495092785,
  IFCCOVERING = 1973544240,
  IFCCOOLINGTOWERTYPE = 2954562838,
  IFCCOOLEDBEAMTYPE = 335055490,
  IFCCONSTRUCTIONPRODUCTRESOURCE = 488727124,
  IFCCONSTRUCTIONMATERIALRESOURCE = 1060000209,
  IFCCONSTRUCTIONEQUIPMENTRESOURCE = 3898045240,
  IFCCONDITIONCRITERION = 1163958913,
  IFCCONDITION = 2188551683,
  IFCCONDENSERTYPE = 2816379211,
  IFCCOMPRESSORTYPE = 3850581409,
  IFCCOLUMN = 843113511,
  IFCCOILTYPE = 2301859152,
  IFCCIRCLE = 2611217952,
  IFCCHILLERTYPE = 2951183804,
  IFCCABLESEGMENTTYPE = 1285652485,
  IFCCABLECARRIERSEGMENTTYPE = 3293546465,
  IFCCABLECARRIERFITTINGTYPE = 395041908,
  IFCBUILDINGELEMENTPROXYTYPE = 1909888760,
  IFCBUILDINGELEMENTPROXY = 1095909175,
  IFCBUILDINGELEMENTPART = 2979338954,
  IFCBUILDINGELEMENTCOMPONENT = 52481810,
  IFCBUILDINGELEMENT = 3299480353,
  IFCBOILERTYPE = 231477066,
  IFCBEZIERCURVE = 1916977116,
  IFCBEAMTYPE = 819618141,
  IFCBSPLINECURVE = 1967976161,
  IFCASSET = 3460190687,
  IFCANGULARDIMENSION = 2470393545,
  IFCAIRTOAIRHEATRECOVERYTYPE = 1871374353,
  IFCAIRTERMINALTYPE = 3352864051,
  IFCAIRTERMINALBOXTYPE = 1411407467,
  IFCACTIONREQUEST = 3821786052,
  IFC2DCOMPOSITECURVE = 1213861670,
  IFCZONE = 1033361043,
  IFCWORKSCHEDULE = 3342526732,
  IFCWORKPLAN = 4218914973,
  IFCWORKCONTROL = 1028945134,
  IFCWASTETERMINALTYPE = 1133259667,
  IFCWALLTYPE = 1898987631,
  IFCVIRTUALELEMENT = 2769231204,
  IFCVALVETYPE = 728799441,
  IFCUNITARYEQUIPMENTTYPE = 1911125066,
  IFCTUBEBUNDLETYPE = 1600972822,
  IFCTRIMMEDCURVE = 3593883385,
  IFCTRANSPORTELEMENT = 1620046519,
  IFCTRANSFORMERTYPE = 1692211062,
  IFCTIMESERIESSCHEDULE = 1637806684,
  IFCTANKTYPE = 5716631,
  IFCSYSTEM = 2254336722,
  IFCSWITCHINGDEVICETYPE = 2315554128,
  IFCSUBCONTRACTRESOURCE = 148013059,
  IFCSTRUCTURALSURFACECONNECTION = 1975003073,
  IFCSTRUCTURALRESULTGROUP = 2986769608,
  IFCSTRUCTURALPOINTREACTION = 1235345126,
  IFCSTRUCTURALPOINTCONNECTION = 734778138,
  IFCSTRUCTURALPOINTACTION = 2082059205,
  IFCSTRUCTURALPLANARACTIONVARYING = 3987759626,
  IFCSTRUCTURALPLANARACTION = 1621171031,
  IFCSTRUCTURALLOADGROUP = 1252848954,
  IFCSTRUCTURALLINEARACTIONVARYING = 1721250024,
  IFCSTRUCTURALLINEARACTION = 1807405624,
  IFCSTRUCTURALCURVEMEMBERVARYING = 2445595289,
  IFCSTRUCTURALCURVEMEMBER = 214636428,
  IFCSTRUCTURALCURVECONNECTION = 4243806635,
  IFCSTRUCTURALCONNECTION = 1179482911,
  IFCSTRUCTURALACTION = 682877961,
  IFCSTAIRFLIGHTTYPE = 1039846685,
  IFCSTACKTERMINALTYPE = 3112655638,
  IFCSPACETYPE = 3812236995,
  IFCSPACEPROGRAM = 652456506,
  IFCSPACEHEATERTYPE = 1305183839,
  IFCSPACE = 3856911033,
  IFCSLABTYPE = 2533589738,
  IFCSITE = 4097777520,
  IFCSERVICELIFE = 4105383287,
  IFCSCHEDULETIMECONTROL = 3517283431,
  IFCSANITARYTERMINALTYPE = 1768891740,
  IFCRELASSIGNSTASKS = 2863920197,
  IFCRELAGGREGATES = 160246688,
  IFCRAMPFLIGHTTYPE = 2324767716,
  IFCRAILINGTYPE = 2893384427,
  IFCRADIUSDIMENSION = 3248260540,
  IFCPUMPTYPE = 2250791053,
  IFCPROTECTIVEDEVICETYPE = 1842657554,
  IFCPROJECTIONELEMENT = 3651124850,
  IFCPROJECTORDERRECORD = 3642467123,
  IFCPROJECTORDER = 2904328755,
  IFCPROCEDURE = 2744685151,
  IFCPORT = 3740093272,
  IFCPOLYLINE = 3724593414,
  IFCPLATETYPE = 4017108033,
  IFCPIPESEGMENTTYPE = 4231323485,
  IFCPIPEFITTINGTYPE = 804291784,
  IFCPERMIT = 3327091369,
  IFCPERFORMANCEHISTORY = 2382730787,
  IFCOUTLETTYPE = 2837617999,
  IFCORDERACTION = 3425660407,
  IFCOPENINGELEMENT = 3588315303,
  IFCOCCUPANT = 4143007308,
  IFCMOVE = 1916936684,
  IFCMOTORCONNECTIONTYPE = 977012517,
  IFCMEMBERTYPE = 3181161470,
  IFCMECHANICALFASTENERTYPE = 2108223431,
  IFCMECHANICALFASTENER = 377706215,
  IFCLINEARDIMENSION = 2506943328,
  IFCLIGHTFIXTURETYPE = 1161773419,
  IFCLAMPTYPE = 1051575348,
  IFCLABORRESOURCE = 3827777499,
  IFCJUNCTIONBOXTYPE = 4288270099,
  IFCINVENTORY = 2391368822,
  IFCHUMIDIFIERTYPE = 1806887404,
  IFCHEATEXCHANGERTYPE = 1251058090,
  IFCGROUP = 2706460486,
  IFCGRID = 3009204131,
  IFCGASTERMINALTYPE = 200128114,
  IFCFURNITURESTANDARD = 814719939,
  IFCFURNISHINGELEMENT = 263784265,
  IFCFLOWTREATMENTDEVICETYPE = 3009222698,
  IFCFLOWTERMINALTYPE = 2297155007,
  IFCFLOWSTORAGEDEVICETYPE = 1339347760,
  IFCFLOWSEGMENTTYPE = 1834744321,
  IFCFLOWMOVINGDEVICETYPE = 1482959167,
  IFCFLOWMETERTYPE = 3815607619,
  IFCFLOWFITTINGTYPE = 3198132628,
  IFCFLOWCONTROLLERTYPE = 3907093117,
  IFCFEATUREELEMENTSUBTRACTION = 1287392070,
  IFCFEATUREELEMENTADDITION = 2143335405,
  IFCFEATUREELEMENT = 2827207264,
  IFCFASTENERTYPE = 2489546625,
  IFCFASTENER = 647756555,
  IFCFACETEDBREPWITHVOIDS = 3737207727,
  IFCFACETEDBREP = 807026263,
  IFCEVAPORATORTYPE = 3390157468,
  IFCEVAPORATIVECOOLERTYPE = 3174744832,
  IFCEQUIPMENTSTANDARD = 3272907226,
  IFCEQUIPMENTELEMENT = 1962604670,
  IFCENERGYCONVERSIONDEVICETYPE = 2107101300,
  IFCELLIPSE = 1704287377,
  IFCELEMENTCOMPONENTTYPE = 2590856083,
  IFCELEMENTCOMPONENT = 1623761950,
  IFCELEMENTASSEMBLY = 4123344466,
  IFCELEMENT = 1758889154,
  IFCELECTRICALBASEPROPERTIES = 360485395,
  IFCDISTRIBUTIONFLOWELEMENTTYPE = 3849074793,
  IFCDISTRIBUTIONELEMENTTYPE = 3256556792,
  IFCDIMENSIONCURVEDIRECTEDCALLOUT = 681481545,
  IFCCURTAINWALLTYPE = 1457835157,
  IFCCREWRESOURCE = 3295246426,
  IFCCOVERINGTYPE = 1916426348,
  IFCCOSTSCHEDULE = 1419761937,
  IFCCOSTITEM = 3895139033,
  IFCCONTROL = 3293443760,
  IFCCONSTRUCTIONRESOURCE = 2559216714,
  IFCCONIC = 2510884976,
  IFCCOMPOSITECURVE = 3732776249,
  IFCCOLUMNTYPE = 300633059,
  IFCCIRCLEHOLLOWPROFILEDEF = 2937912522,
  IFCBUILDINGSTOREY = 3124254112,
  IFCBUILDINGELEMENTTYPE = 1950629157,
  IFCBUILDING = 4031249490,
  IFCBOUNDEDCURVE = 1260505505,
  IFCBOOLEANCLIPPINGRESULT = 3649129432,
  IFCBLOCK = 1334484129,
  IFCASYMMETRICISHAPEPROFILEDEF = 3207858831,
  IFCANNOTATION = 1674181508,
  IFCACTOR = 2296667514,
  IFCTRANSPORTELEMENTTYPE = 2097647324,
  IFCTASK = 3473067441,
  IFCSYSTEMFURNITUREELEMENTTYPE = 1580310250,
  IFCSURFACEOFREVOLUTION = 4124788165,
  IFCSURFACEOFLINEAREXTRUSION = 2809605785,
  IFCSURFACECURVESWEPTAREASOLID = 2028607225,
  IFCSTRUCTUREDDIMENSIONCALLOUT = 4070609034,
  IFCSTRUCTURALSURFACEMEMBERVARYING = 2218152070,
  IFCSTRUCTURALSURFACEMEMBER = 3979015343,
  IFCSTRUCTURALREACTION = 3689010777,
  IFCSTRUCTURALMEMBER = 530289379,
  IFCSTRUCTURALITEM = 3136571912,
  IFCSTRUCTURALACTIVITY = 3544373492,
  IFCSPHERE = 451544542,
  IFCSPATIALSTRUCTUREELEMENTTYPE = 3893378262,
  IFCSPATIALSTRUCTUREELEMENT = 2706606064,
  IFCRIGHTCIRCULARCYLINDER = 3626867408,
  IFCRIGHTCIRCULARCONE = 4158566097,
  IFCREVOLVEDAREASOLID = 1856042241,
  IFCRESOURCE = 2914609552,
  IFCRELVOIDSELEMENT = 1401173127,
  IFCRELSPACEBOUNDARY = 3451746338,
  IFCRELSERVICESBUILDINGS = 366585022,
  IFCRELSEQUENCE = 4122056220,
  IFCRELSCHEDULESCOSTITEMS = 1058617721,
  IFCRELREFERENCEDINSPATIALSTRUCTURE = 1245217292,
  IFCRELPROJECTSELEMENT = 750771296,
  IFCRELOVERRIDESPROPERTIES = 202636808,
  IFCRELOCCUPIESSPACES = 2051452291,
  IFCRELNESTS = 3268803585,
  IFCRELINTERACTIONREQUIREMENTS = 4189434867,
  IFCRELFLOWCONTROLELEMENTS = 279856033,
  IFCRELFILLSELEMENT = 3940055652,
  IFCRELDEFINESBYTYPE = 781010003,
  IFCRELDEFINESBYPROPERTIES = 4186316022,
  IFCRELDEFINES = 693640335,
  IFCRELDECOMPOSES = 2551354335,
  IFCRELCOVERSSPACES = 2802773753,
  IFCRELCOVERSBLDGELEMENTS = 886880790,
  IFCRELCONTAINEDINSPATIALSTRUCTURE = 3242617779,
  IFCRELCONNECTSWITHREALIZINGELEMENTS = 3678494232,
  IFCRELCONNECTSWITHECCENTRICITY = 504942748,
  IFCRELCONNECTSSTRUCTURALMEMBER = 1638771189,
  IFCRELCONNECTSSTRUCTURALELEMENT = 3912681535,
  IFCRELCONNECTSSTRUCTURALACTIVITY = 2127690289,
  IFCRELCONNECTSPORTS = 3190031847,
  IFCRELCONNECTSPORTTOELEMENT = 4201705270,
  IFCRELCONNECTSPATHELEMENTS = 3945020480,
  IFCRELCONNECTSELEMENTS = 1204542856,
  IFCRELCONNECTS = 826625072,
  IFCRELASSOCIATESPROFILEPROPERTIES = 2851387026,
  IFCRELASSOCIATESMATERIAL = 2655215786,
  IFCRELASSOCIATESLIBRARY = 3840914261,
  IFCRELASSOCIATESDOCUMENT = 982818633,
  IFCRELASSOCIATESCONSTRAINT = 2728634034,
  IFCRELASSOCIATESCLASSIFICATION = 919958153,
  IFCRELASSOCIATESAPPROVAL = 4095574036,
  IFCRELASSOCIATESAPPLIEDVALUE = 1327628568,
  IFCRELASSOCIATES = 1865459582,
  IFCRELASSIGNSTORESOURCE = 205026976,
  IFCRELASSIGNSTOPROJECTORDER = 3372526763,
  IFCRELASSIGNSTOPRODUCT = 2857406711,
  IFCRELASSIGNSTOPROCESS = 4278684876,
  IFCRELASSIGNSTOGROUP = 1307041759,
  IFCRELASSIGNSTOCONTROL = 2495723537,
  IFCRELASSIGNSTOACTOR = 1683148259,
  IFCRELASSIGNS = 3939117080,
  IFCRECTANGULARTRIMMEDSURFACE = 3454111270,
  IFCRECTANGULARPYRAMID = 2798486643,
  IFCRECTANGLEHOLLOWPROFILEDEF = 2770003689,
  IFCPROXY = 3219374653,
  IFCPROPERTYSET = 1451395588,
  IFCPROJECTIONCURVE = 4194566429,
  IFCPROJECT = 103090709,
  IFCPRODUCT = 4208778838,
  IFCPROCESS = 2945172077,
  IFCPLANE = 220341763,
  IFCPLANARBOX = 603570806,
  IFCPERMEABLECOVERINGPROPERTIES = 3566463478,
  IFCOFFSETCURVE3D = 3505215534,
  IFCOFFSETCURVE2D = 3388369263,
  IFCOBJECT = 3888040117,
  IFCMANIFOLDSOLIDBREP = 1425443689,
  IFCLINE = 1281925730,
  IFCLSHAPEPROFILEDEF = 572779678,
  IFCISHAPEPROFILEDEF = 1484403080,
  IFCGEOMETRICCURVESET = 987898635,
  IFCFURNITURETYPE = 1268542332,
  IFCFURNISHINGELEMENTTYPE = 4238390223,
  IFCFLUIDFLOWPROPERTIES = 3455213021,
  IFCFILLAREASTYLETILES = 315944413,
  IFCFILLAREASTYLETILESYMBOLWITHSTYLE = 4203026998,
  IFCFILLAREASTYLEHATCHING = 374418227,
  IFCFACEBASEDSURFACEMODEL = 2047409740,
  IFCEXTRUDEDAREASOLID = 477187591,
  IFCENERGYPROPERTIES = 80994333,
  IFCELLIPSEPROFILEDEF = 2835456948,
  IFCELEMENTARYSURFACE = 2777663545,
  IFCELEMENTTYPE = 339256511,
  IFCELEMENTQUANTITY = 1883228015,
  IFCEDGELOOP = 1472233963,
  IFCDRAUGHTINGPREDEFINEDCURVEFONT = 4006246654,
  IFCDRAUGHTINGPREDEFINEDCOLOUR = 445594917,
  IFCDRAUGHTINGCALLOUT = 3073041342,
  IFCDOORSTYLE = 526551008,
  IFCDOORPANELPROPERTIES = 1714330368,
  IFCDOORLININGPROPERTIES = 2963535650,
  IFCDIRECTION = 32440307,
  IFCDIMENSIONCURVETERMINATOR = 4054601972,
  IFCDIMENSIONCURVE = 606661476,
  IFCDEFINEDSYMBOL = 693772133,
  IFCCURVEBOUNDEDPLANE = 2827736869,
  IFCCURVE = 2601014836,
  IFCCSGSOLID = 2147822146,
  IFCCSGPRIMITIVE3D = 2506170314,
  IFCCRANERAILFSHAPEPROFILEDEF = 194851669,
  IFCCRANERAILASHAPEPROFILEDEF = 4133800736,
  IFCCOMPOSITECURVESEGMENT = 2485617015,
  IFCCLOSEDSHELL = 2205249479,
  IFCCIRCLEPROFILEDEF = 1383045692,
  IFCCARTESIANTRANSFORMATIONOPERATOR3DNONUNIFORM = 1416205885,
  IFCCARTESIANTRANSFORMATIONOPERATOR3D = 3331915920,
  IFCCARTESIANTRANSFORMATIONOPERATOR2DNONUNIFORM = 3486308946,
  IFCCARTESIANTRANSFORMATIONOPERATOR2D = 3749851601,
  IFCCARTESIANTRANSFORMATIONOPERATOR = 59481748,
  IFCCARTESIANPOINT = 1123145078,
  IFCCSHAPEPROFILEDEF = 2898889636,
  IFCBOXEDHALFSPACE = 2713105998,
  IFCBOUNDINGBOX = 2581212453,
  IFCBOUNDEDSURFACE = 4182860854,
  IFCBOOLEANRESULT = 2736907675,
  IFCAXIS2PLACEMENT3D = 2740243338,
  IFCAXIS2PLACEMENT2D = 3125803723,
  IFCAXIS1PLACEMENT = 4261334040,
  IFCANNOTATIONSURFACE = 1302238472,
  IFCANNOTATIONFILLAREAOCCURRENCE = 2265737646,
  IFCANNOTATIONFILLAREA = 669184980,
  IFCANNOTATIONCURVEOCCURRENCE = 3288037868,
  IFCZSHAPEPROFILEDEF = 2543172580,
  IFCWINDOWSTYLE = 1299126871,
  IFCWINDOWPANELPROPERTIES = 512836454,
  IFCWINDOWLININGPROPERTIES = 336235671,
  IFCVERTEXLOOP = 2759199220,
  IFCVECTOR = 1417489154,
  IFCUSHAPEPROFILEDEF = 427810014,
  IFCTYPEPRODUCT = 2347495698,
  IFCTYPEOBJECT = 1628702193,
  IFCTWODIRECTIONREPEATFACTOR = 1345879162,
  IFCTRAPEZIUMPROFILEDEF = 2715220739,
  IFCTEXTLITERALWITHEXTENT = 3124975700,
  IFCTEXTLITERAL = 4282788508,
  IFCTERMINATORSYMBOL = 3028897424,
  IFCTSHAPEPROFILEDEF = 3071757647,
  IFCSWEPTSURFACE = 230924584,
  IFCSWEPTDISKSOLID = 1260650574,
  IFCSWEPTAREASOLID = 2247615214,
  IFCSURFACESTYLERENDERING = 1878645084,
  IFCSURFACE = 2513912981,
  IFCSUBEDGE = 2233826070,
  IFCSTRUCTURALSTEELPROFILEPROPERTIES = 3653947884,
  IFCSTRUCTURALPROFILEPROPERTIES = 3843319758,
  IFCSTRUCTURALLOADSINGLEFORCEWARPING = 1190533807,
  IFCSTRUCTURALLOADSINGLEFORCE = 1597423693,
  IFCSTRUCTURALLOADSINGLEDISPLACEMENTDISTORTION = 1973038258,
  IFCSTRUCTURALLOADSINGLEDISPLACEMENT = 2473145415,
  IFCSTRUCTURALLOADPLANARFORCE = 2668620305,
  IFCSTRUCTURALLOADLINEARFORCE = 1595516126,
  IFCSPACETHERMALLOADPROPERTIES = 390701378,
  IFCSOUNDVALUE = 1202362311,
  IFCSOUNDPROPERTIES = 2485662743,
  IFCSOLIDMODEL = 723233188,
  IFCSLIPPAGECONNECTIONCONDITION = 2609359061,
  IFCSHELLBASEDSURFACEMODEL = 4124623270,
  IFCSERVICELIFEFACTOR = 2411513650,
  IFCSECTIONEDSPINE = 1509187699,
  IFCROUNDEDRECTANGLEPROFILEDEF = 2778083089,
  IFCRELATIONSHIP = 478536968,
  IFCREINFORCEMENTDEFINITIONPROPERTIES = 3765753017,
  IFCREGULARTIMESERIES = 3413951693,
  IFCRECTANGLEPROFILEDEF = 3615266464,
  IFCPROPERTYTABLEVALUE = 110355661,
  IFCPROPERTYSINGLEVALUE = 3650150729,
  IFCPROPERTYSETDEFINITION = 3357820518,
  IFCPROPERTYREFERENCEVALUE = 941946838,
  IFCPROPERTYLISTVALUE = 2752243245,
  IFCPROPERTYENUMERATEDVALUE = 4166981789,
  IFCPROPERTYDEFINITION = 1680319473,
  IFCPROPERTYBOUNDEDVALUE = 871118103,
  IFCPRODUCTDEFINITIONSHAPE = 673634403,
  IFCPREDEFINEDPOINTMARKERSYMBOL = 179317114,
  IFCPREDEFINEDDIMENSIONSYMBOL = 433424934,
  IFCPREDEFINEDCURVEFONT = 2559016684,
  IFCPREDEFINEDCOLOUR = 759155922,
  IFCPOLYGONALBOUNDEDHALFSPACE = 2775532180,
  IFCPOLYLOOP = 2924175390,
  IFCPOINTONSURFACE = 1423911732,
  IFCPOINTONCURVE = 4022376103,
  IFCPOINT = 2067069095,
  IFCPLANAREXTENT = 1663979128,
  IFCPLACEMENT = 2004835150,
  IFCPIXELTEXTURE = 597895409,
  IFCPHYSICALCOMPLEXQUANTITY = 3021840470,
  IFCPATH = 2519244187,
  IFCPARAMETERIZEDPROFILEDEF = 2529465313,
  IFCORIENTEDEDGE = 1029017970,
  IFCOPENSHELL = 2665983363,
  IFCONEDIRECTIONREPEATFACTOR = 2833995503,
  IFCOBJECTDEFINITION = 219451334,
  IFCMECHANICALCONCRETEMATERIALPROPERTIES = 1430189142,
  IFCMATERIALDEFINITIONREPRESENTATION = 2022407955,
  IFCMAPPEDITEM = 2347385850,
  IFCLOOP = 1008929658,
  IFCLOCALPLACEMENT = 2624227202,
  IFCLIGHTSOURCESPOT = 3422422726,
  IFCLIGHTSOURCEPOSITIONAL = 1520743889,
  IFCLIGHTSOURCEGONIOMETRIC = 4266656042,
  IFCLIGHTSOURCEDIRECTIONAL = 2604431987,
  IFCLIGHTSOURCEAMBIENT = 125510826,
  IFCLIGHTSOURCE = 1402838566,
  IFCIRREGULARTIMESERIES = 3741457305,
  IFCIMAGETEXTURE = 3905492369,
  IFCHYGROSCOPICMATERIALPROPERTIES = 2445078500,
  IFCHALFSPACESOLID = 812098782,
  IFCGRIDPLACEMENT = 178086475,
  IFCGEOMETRICSET = 3590301190,
  IFCGEOMETRICREPRESENTATIONSUBCONTEXT = 4142052618,
  IFCGEOMETRICREPRESENTATIONITEM = 2453401579,
  IFCGEOMETRICREPRESENTATIONCONTEXT = 3448662350,
  IFCGENERALPROFILEPROPERTIES = 1446786286,
  IFCGENERALMATERIALPROPERTIES = 803998398,
  IFCFUELPROPERTIES = 3857492461,
  IFCFILLAREASTYLE = 738692330,
  IFCFAILURECONNECTIONCONDITION = 4219587988,
  IFCFACESURFACE = 3008276851,
  IFCFACEOUTERBOUND = 803316827,
  IFCFACEBOUND = 1809719519,
  IFCFACE = 2556980723,
  IFCEXTENDEDMATERIALPROPERTIES = 1860660968,
  IFCEDGECURVE = 476780140,
  IFCEDGE = 3900360178,
  IFCDRAUGHTINGPREDEFINEDTEXTFONT = 4170525392,
  IFCDOCUMENTREFERENCE = 3732053477,
  IFCDIMENSIONPAIR = 1694125774,
  IFCDIMENSIONCALLOUTRELATIONSHIP = 2273265877,
  IFCDERIVEDPROFILEDEF = 3632507154,
  IFCCURVESTYLE = 3800577675,
  IFCCONVERSIONBASEDUNIT = 2889183280,
  IFCCONTEXTDEPENDENTUNIT = 3050246964,
  IFCCONNECTIONPOINTECCENTRICITY = 45288368,
  IFCCONNECTIONCURVEGEOMETRY = 1981873012,
  IFCCONNECTEDFACESET = 370225590,
  IFCCOMPOSITEPROFILEDEF = 1485152156,
  IFCCOMPLEXPROPERTY = 2542286263,
  IFCCOLOURRGB = 776857604,
  IFCCLASSIFICATIONREFERENCE = 647927063,
  IFCCENTERLINEPROFILEDEF = 3150382593,
  IFCBLOBTEXTURE = 616511568,
  IFCARBITRARYPROFILEDEFWITHVOIDS = 2705031697,
  IFCARBITRARYOPENPROFILEDEF = 1310608509,
  IFCARBITRARYCLOSEDPROFILEDEF = 3798115385,
  IFCANNOTATIONTEXTOCCURRENCE = 2297822566,
  IFCANNOTATIONSYMBOLOCCURRENCE = 3612888222,
  IFCANNOTATIONSURFACEOCCURRENCE = 962685235,
  IFCANNOTATIONOCCURRENCE = 2442683028,
  IFCWATERPROPERTIES = 1065908215,
  IFCVIRTUALGRIDINTERSECTION = 891718957,
  IFCVERTEXPOINT = 1907098498,
  IFCVERTEXBASEDTEXTUREMAP = 3304826586,
  IFCVERTEX = 2799835756,
  IFCUNITASSIGNMENT = 180925521,
  IFCTOPOLOGYREPRESENTATION = 1735638870,
  IFCTOPOLOGICALREPRESENTATIONITEM = 1377556343,
  IFCTIMESERIESVALUE = 581633288,
  IFCTIMESERIESREFERENCERELATIONSHIP = 1718945513,
  IFCTIMESERIES = 3101149627,
  IFCTHERMALMATERIALPROPERTIES = 3317419933,
  IFCTEXTUREVERTEX = 1210645708,
  IFCTEXTUREMAP = 2552916305,
  IFCTEXTURECOORDINATEGENERATOR = 1742049831,
  IFCTEXTURECOORDINATE = 280115917,
  IFCTEXTSTYLEWITHBOXCHARACTERISTICS = 1484833681,
  IFCTEXTSTYLETEXTMODEL = 1640371178,
  IFCTEXTSTYLEFORDEFINEDFONT = 2636378356,
  IFCTEXTSTYLEFONTMODEL = 1983826977,
  IFCTEXTSTYLE = 1447204868,
  IFCTELECOMADDRESS = 912023232,
  IFCTABLEROW = 531007025,
  IFCTABLE = 985171141,
  IFCSYMBOLSTYLE = 1290481447,
  IFCSURFACETEXTURE = 626085974,
  IFCSURFACESTYLEWITHTEXTURES = 1351298697,
  IFCSURFACESTYLESHADING = 846575682,
  IFCSURFACESTYLEREFRACTION = 1607154358,
  IFCSURFACESTYLELIGHTING = 3303107099,
  IFCSURFACESTYLE = 1300840506,
  IFCSTYLEDREPRESENTATION = 3049322572,
  IFCSTYLEDITEM = 3958052878,
  IFCSTYLEMODEL = 2830218821,
  IFCSTRUCTURALLOADTEMPERATURE = 3408363356,
  IFCSTRUCTURALLOADSTATIC = 2525727697,
  IFCSTRUCTURALLOAD = 2162789131,
  IFCSTRUCTURALCONNECTIONCONDITION = 2273995522,
  IFCSIMPLEPROPERTY = 3692461612,
  IFCSHAPEREPRESENTATION = 4240577450,
  IFCSHAPEMODEL = 3982875396,
  IFCSHAPEASPECT = 867548509,
  IFCSECTIONREINFORCEMENTPROPERTIES = 4165799628,
  IFCSECTIONPROPERTIES = 2042790032,
  IFCSIUNIT = 448429030,
  IFCROOT = 2341007311,
  IFCRIBPLATEPROFILEPROPERTIES = 3679540991,
  IFCREPRESENTATIONMAP = 1660063152,
  IFCREPRESENTATIONITEM = 3008791417,
  IFCREPRESENTATIONCONTEXT = 3377609919,
  IFCREPRESENTATION = 1076942058,
  IFCRELAXATION = 1222501353,
  IFCREINFORCEMENTBARPROPERTIES = 1580146022,
  IFCREFERENCESVALUEDOCUMENT = 2692823254,
  IFCQUANTITYWEIGHT = 825690147,
  IFCQUANTITYVOLUME = 2405470396,
  IFCQUANTITYTIME = 3252649465,
  IFCQUANTITYLENGTH = 931644368,
  IFCQUANTITYCOUNT = 2093928680,
  IFCQUANTITYAREA = 2044713172,
  IFCPROPERTYENUMERATION = 3710013099,
  IFCPROPERTYDEPENDENCYRELATIONSHIP = 148025276,
  IFCPROPERTYCONSTRAINTRELATIONSHIP = 3896028662,
  IFCPROPERTY = 2598011224,
  IFCPROFILEPROPERTIES = 2802850158,
  IFCPROFILEDEF = 3958567839,
  IFCPRODUCTSOFCOMBUSTIONPROPERTIES = 2267347899,
  IFCPRODUCTREPRESENTATION = 2095639259,
  IFCPRESENTATIONSTYLEASSIGNMENT = 2417041796,
  IFCPRESENTATIONSTYLE = 3119450353,
  IFCPRESENTATIONLAYERWITHSTYLE = 1304840413,
  IFCPRESENTATIONLAYERASSIGNMENT = 2022622350,
  IFCPREDEFINEDTEXTFONT = 1775413392,
  IFCPREDEFINEDTERMINATORSYMBOL = 3213052703,
  IFCPREDEFINEDSYMBOL = 990879717,
  IFCPREDEFINEDITEM = 3727388367,
  IFCPOSTALADDRESS = 3355820592,
  IFCPHYSICALSIMPLEQUANTITY = 2226359599,
  IFCPHYSICALQUANTITY = 2483315170,
  IFCPERSONANDORGANIZATION = 101040310,
  IFCPERSON = 2077209135,
  IFCOWNERHISTORY = 1207048766,
  IFCORGANIZATIONRELATIONSHIP = 1411181986,
  IFCORGANIZATION = 4251960020,
  IFCOPTICALMATERIALPROPERTIES = 1227763645,
  IFCOBJECTIVE = 2251480897,
  IFCOBJECTPLACEMENT = 3701648758,
  IFCNAMEDUNIT = 1918398963,
  IFCMONETARYUNIT = 2706619895,
  IFCMETRIC = 3368373690,
  IFCMECHANICALSTEELMATERIALPROPERTIES = 677618848,
  IFCMECHANICALMATERIALPROPERTIES = 4256014907,
  IFCMEASUREWITHUNIT = 2597039031,
  IFCMATERIALPROPERTIES = 3265635763,
  IFCMATERIALLIST = 2199411900,
  IFCMATERIALLAYERSETUSAGE = 1303795690,
  IFCMATERIALLAYERSET = 3303938423,
  IFCMATERIALLAYER = 248100487,
  IFCMATERIALCLASSIFICATIONRELATIONSHIP = 1847130766,
  IFCMATERIAL = 1838606355,
  IFCLOCALTIME = 30780891,
  IFCLIGHTINTENSITYDISTRIBUTION = 1566485204,
  IFCLIGHTDISTRIBUTIONDATA = 4162380809,
  IFCLIBRARYREFERENCE = 3452421091,
  IFCLIBRARYINFORMATION = 2655187982,
  IFCIRREGULARTIMESERIESVALUE = 3020489413,
  IFCGRIDAXIS = 852622518,
  IFCEXTERNALLYDEFINEDTEXTFONT = 3548104201,
  IFCEXTERNALLYDEFINEDSYMBOL = 3207319532,
  IFCEXTERNALLYDEFINEDSURFACESTYLE = 1040185647,
  IFCEXTERNALLYDEFINEDHATCHSTYLE = 2242383968,
  IFCEXTERNALREFERENCE = 3200245327,
  IFCENVIRONMENTALIMPACTVALUE = 1648886627,
  IFCDRAUGHTINGCALLOUTRELATIONSHIP = 3796139169,
  IFCDOCUMENTINFORMATIONRELATIONSHIP = 770865208,
  IFCDOCUMENTINFORMATION = 1154170062,
  IFCDOCUMENTELECTRONICFORMAT = 1376555844,
  IFCDIMENSIONALEXPONENTS = 2949456006,
  IFCDERIVEDUNITELEMENT = 1045800335,
  IFCDERIVEDUNIT = 1765591967,
  IFCDATEANDTIME = 1072939445,
  IFCCURVESTYLEFONTPATTERN = 3510044353,
  IFCCURVESTYLEFONTANDSCALING = 2367409068,
  IFCCURVESTYLEFONT = 1105321065,
  IFCCURRENCYRELATIONSHIP = 539742890,
  IFCCOSTVALUE = 602808272,
  IFCCOORDINATEDUNIVERSALTIMEOFFSET = 1065062679,
  IFCCONSTRAINTRELATIONSHIP = 347226245,
  IFCCONSTRAINTCLASSIFICATIONRELATIONSHIP = 613356794,
  IFCCONSTRAINTAGGREGATIONRELATIONSHIP = 1658513725,
  IFCCONSTRAINT = 1959218052,
  IFCCONNECTIONSURFACEGEOMETRY = 2732653382,
  IFCCONNECTIONPORTGEOMETRY = 4257277454,
  IFCCONNECTIONPOINTGEOMETRY = 2614616156,
  IFCCONNECTIONGEOMETRY = 2859738748,
  IFCCOLOURSPECIFICATION = 3264961684,
  IFCCLASSIFICATIONNOTATIONFACET = 3639012971,
  IFCCLASSIFICATIONNOTATION = 938368621,
  IFCCLASSIFICATIONITEMRELATIONSHIP = 1098599126,
  IFCCLASSIFICATIONITEM = 1767535486,
  IFCCLASSIFICATION = 747523909,
  IFCCALENDARDATE = 622194075,
  IFCBOUNDARYNODECONDITIONWARPING = 2069777674,
  IFCBOUNDARYNODECONDITION = 1387855156,
  IFCBOUNDARYFACECONDITION = 3367102660,
  IFCBOUNDARYEDGECONDITION = 1560379544,
  IFCBOUNDARYCONDITION = 4037036970,
  IFCAPPROVALRELATIONSHIP = 3869604511,
  IFCAPPROVALPROPERTYRELATIONSHIP = 390851274,
  IFCAPPROVALACTORRELATIONSHIP = 2080292479,
  IFCAPPROVAL = 130549933,
  IFCAPPLIEDVALUERELATIONSHIP = 1110488051,
  IFCAPPLIEDVALUE = 411424972,
  IFCAPPLICATION = 639542469,
  IFCADDRESS = 618182010,
  IFCACTORROLE = 3630933823,
  FILE_DESCRIPTION = 599546466,
  FILE_NAME = 1390159747,
  FILE_SCHEMA = 1109904537,
}
