import React, { useCallback, useContext } from "react"
import { Navigate, useLocation } from "react-router-dom"
import { adminPagesUrl, pagesUrl } from "../../core/appConstants"
import { OrganizationContext } from "../../core/context/organization/organization-context"
import { UserContext } from "../../core/context/user/user-context"
import { OrganizationRoleEnum } from "../../core/enum/user/organization-role-enum"

function isAdminPage(pathname: string | undefined): boolean {
  return !!pathname?.startsWith("/admin/")
}

type IProps = {
  children: React.JSX.Element | null
}

export default function RequireNoAuth({ children }: IProps): React.JSX.Element | null {
  const { user } = useContext(UserContext)
  const { organization } = useContext(OrganizationContext)
  const location = useLocation()

  const getHomePageUrl = useCallback((): string => {
    if (user?.role === OrganizationRoleEnum.ULTIMATE_USER) {
      return adminPagesUrl.ADMIN_ORGANIZATION_LIST_PAGE
    } else if (organization?.beemShotOption) {
      return pagesUrl.HOME
    } else {
      return pagesUrl.PROJECTS_PAGE
    }
  }, [organization?.beemShotOption, user?.role])

  const previousPage = location.state?.from

  if (user) {
    if (user.role === OrganizationRoleEnum.ULTIMATE_USER) {
      if (previousPage && isAdminPage(previousPage)) {
        return <Navigate to={previousPage} replace />
      } else {
        return <Navigate to={getHomePageUrl()} replace />
      }
    } else if (previousPage && !isAdminPage(previousPage)) {
      return <Navigate to={previousPage} replace />
    } else if (organization) {
      return <Navigate to={getHomePageUrl()} replace />
    } else if (!organization && user.role === OrganizationRoleEnum.TEST_USER) {
      return <Navigate to={pagesUrl.PROJECTS_PAGE} replace />
    }
  }

  return children
}
