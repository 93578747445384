import { useCallback, useContext, useEffect } from "react"
import { CalculationCarbonAdditionalDataContext } from "../../context/calculation/calculation-carbon-additional-data"
import { useHttp } from "../use-http"

type CalculCarbonInformationProjectUpdate = {
  loading: boolean
  updateCalculateCarbonAdditionalData: () => Promise<Response>
}

export const useUpdateCalculateCarbonAdditionalData = (): CalculCarbonInformationProjectUpdate => {
  const { put } = useHttp()
  const { loading, setLoading } = useContext(CalculationCarbonAdditionalDataContext)

  useEffect(() => {
    setLoading(false)
  }, [setLoading])

  const updateCalculateCarbonAdditionalData = useCallback(async (): Promise<Response> => {
    setLoading(true)

    return put("https://jsonplaceholder.typicode.com/posts/1", {
      id: 1,
      title: "foo",
      body: "bar",
      userId: 1,
    })
      .then((response) => response.json())
      .then((res) => res)
      .finally(() => {
        setLoading(false)
      })
  }, [put, setLoading])

  return {
    loading,
    updateCalculateCarbonAdditionalData,
  }
}
