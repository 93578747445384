import React from "react"
import { Navigate, useParams } from "react-router-dom"
import { adminPagesUrl } from "../../core/appConstants"

type IProps = {
  children: React.JSX.Element | null
}
export default function RequireOrganization({ children }: IProps): React.JSX.Element | null {
  const { organizationId } = useParams()

  if (!organizationId || organizationId === "") {
    return <Navigate to={adminPagesUrl.ADMIN_ORGANIZATION_LIST_PAGE} />
  }
  return children
}
