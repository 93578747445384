import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import { Box, IconButton } from "@mui/material"
import React, { useState } from "react"
import { Document, Page, pdfjs } from "react-pdf"
import "react-pdf/dist/cjs/Page/AnnotationLayer.css"
import "react-pdf/dist/cjs/Page/TextLayer.css"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const options = {
  cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
}

type iProps = { file: File | undefined }

export function PdfDisplayer({ file }: iProps): React.JSX.Element {
  const [numPage, setNumPages] = useState<number>(0)
  const [pageNumber, setPageNumber] = useState<number>(1)

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages)
    setPageNumber(1)
  }

  function changePage(offset: number): void {
    setPageNumber((prevPageNumber) => prevPageNumber + offset)
  }

  function previousPage(): void {
    changePage(-1)
  }

  function nextPage(): void {
    changePage(1)
  }

  return (
    <Box>
      <Box sx={{ border: 1, display: "flex", justifyContent: "center" }}>
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          options={options}
          error="Erreur lors du chargement du PDF">
          <Page pageNumber={pageNumber} width={800} className="pdf-page" error="Erreur lors du chargement de la page" />
        </Document>
      </Box>
      <Box display="flex" justifyContent="center">
        <IconButton size="small" disabled={pageNumber <= 1} onClick={previousPage}>
          <ArrowBackIosIcon fontSize="inherit" />
        </IconButton>
        <Box>
          Page {pageNumber || (numPage ? 1 : "--")} sur {numPage || "--"}
        </Box>
        <IconButton size="small" disabled={pageNumber >= numPage} onClick={nextPage}>
          <ArrowForwardIosIcon fontSize="inherit" />
        </IconButton>
      </Box>
    </Box>
  )
}
