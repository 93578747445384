import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import React from "react"

type IProps = {
  id?: string
  title: string
  contentText: string
  handleClose(): void
  open: boolean
}
export default function WarningDialog({ id = "", title, contentText, handleClose, open }: IProps): React.JSX.Element {
  return (
    <Box>
      <Dialog open={open} onClose={handleClose} aria-describedby={id}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id={id} sx={{ whiteSpace: "pre-wrap" }}>
            {contentText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
