/**
 * Ray casting different types of point and line selection
 */
export enum RaySelectMode {
  ONE_POINT,
  TWO_POINTS,
  LINE,
  AREA,
  OBJECT_EDGES,
  FACE,
  NONE,
}
