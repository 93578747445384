export class DeleteObjectsCommand {
  selectedElements: Array<any> = []
  scene: THREE.Scene

  constructor(selectedElementsList: Array<any>, scene: THREE.Scene) {
    this.scene = scene
    this.selectedElements = selectedElementsList
  }

  execute(): void {
    for (const element of this.selectedElements) {
      this.scene.remove(element)
    }
  }

  undo(): void {
    for (const object of this.selectedElements) {
      this.scene.add(object)
    }
  }

  redo(): void {
    this.execute()
  }

  remove(): void {
    this.selectedElements = []
  }

  dispose(): void {
    for (const element of this.selectedElements) {
      if (
        Object.prototype.hasOwnProperty.call(element, "assosiatedObject") &&
        typeof element.assosiatedObject?.dispose === "function"
      ) {
        element.assosiatedObject?.dispose()
      }
      this.scene.remove(element)
      element.clear()
    }
    this.selectedElements = []
  }
}
