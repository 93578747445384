import React, { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { Children } from "../../../components/miscellianous/children"
import { Organization } from "../../dto/organization/organization"
import { useOrganization } from "../../hooks/use-organization"

export const AdminOrganizationContext = React.createContext<AdminOrganizationStore>({} as AdminOrganizationStore)

export default function AdminOrganizationContextProvider({ children }: Children): React.JSX.Element {
  const { organizationId } = useParams()
  const [organization, setOrganization] = useState<Organization | undefined>(undefined)
  const [isOrganizationLoading, setIsOrganizationLoading] = useState<boolean>(false)
  const { adminFetchOrganization } = useOrganization()

  useEffect(() => {
    if (organizationId && organizationId !== "new") {
      setIsOrganizationLoading(true)
      adminFetchOrganization(organizationId)
        .then((newOrganization: Organization) => setOrganization(newOrganization))
        .finally(() => {
          setIsOrganizationLoading(false)
        })
    }
  }, [adminFetchOrganization, organizationId])

  const organizationStore = useMemo(
    () => ({ organization, setOrganization, isOrganizationLoading }),
    [isOrganizationLoading, organization]
  )

  return <AdminOrganizationContext.Provider value={organizationStore}>{children}</AdminOrganizationContext.Provider>
}

type AdminOrganizationStore = {
  organization: Organization | undefined
  setOrganization: (organization: Organization | undefined) => void
  isOrganizationLoading: boolean
}
