import React, { useContext, useMemo } from "react"
import { MaterialPage } from "../../../components/material-lib/material-page"
import { ProjectContext } from "../../../core/context/project/project-context"
import { ProjectRoleContext } from "../../../core/context/user/project-role-context"
import { ProjectStatusEnum } from "../../../core/enum/projectStatusEnum"
import { RoleEnum } from "../../../core/enum/roleEnum"

export default function ProjectMaterialLibPage(): React.JSX.Element {
  const { hasRole } = useContext(ProjectRoleContext)
  const { project } = useContext(ProjectContext)

  const isWriting: boolean = useMemo(
    () => hasRole([RoleEnum.ADMINISTRATOR]) && project.status !== ProjectStatusEnum.ARCHIVED,
    [hasRole, project.status]
  )

  return <MaterialPage isWriting={isWriting} />
}
