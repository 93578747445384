import { currentCodeVersion } from "../../../appConstants"
import { BsProjectRoleEnum } from "../../../enum/bs-project-role-enum"
import { CodesVersion } from "../../../enum/codesVersionEnum"
import { ProjectStatusEnum } from "../../../enum/projectStatusEnum"
import { stringToDate } from "../../../services/date-service"
import { Address } from "../../address"
import { BSProjectInvitation } from "../BSBimModel/BSProjectInvitation"

export class BSProject {
  id: string | undefined
  organizationId = ""
  name = ""
  businessCode = ""
  licenceDate?: Date
  address: Address = new Address()
  codesVersion: CodesVersion = currentCodeVersion
  status: ProjectStatusEnum = ProjectStatusEnum.IN_PROGRESS
  coverImgAwsFileKey = ""
  coverImgFileName = ""
  coverImgFileType = ""
  createdByUser = ""
  lastModifiedDate?: Date
  role?: BsProjectRoleEnum
  actorInvited?: BSProjectInvitation

  static fromDto(data: any): BSProject {
    const bsProject = new BSProject()
    if (data === undefined) {
      return new BSProject()
    }
    bsProject.id = data.id
    bsProject.organizationId = data.organizationId ?? ""
    bsProject.name = data.name ?? ""
    bsProject.businessCode = data.businessCode ?? ""
    bsProject.licenceDate = stringToDate(data.licenceDate)
    bsProject.address = data.address
    bsProject.codesVersion = data.codesVersion ?? CodesVersion.VERSION_1_8
    bsProject.status = data.status
    bsProject.coverImgAwsFileKey = data.coverImgAwsFileKey
    bsProject.coverImgFileName = data.coverImgFileName
    bsProject.coverImgFileType = data.coverImgFileType
    bsProject.createdByUser = data.createdByUser
    bsProject.lastModifiedDate = stringToDate(data.lastModifiedDate)
    bsProject.role = data.role
    bsProject.actorInvited = data.actorInvited

    return bsProject
  }
}
