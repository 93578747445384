import React from "react"
import { ReactComponent as LogoTTBSvg } from "../../../assets/LogoTTB.svg"

type IProps = {
  style?: any
}

export default function LogoTTBTransparent({ style }: IProps): React.JSX.Element {
  // return <img src={LogoTTBTransparentPng} alt='Logo of Time to BEEM' style={{ ...style }} />
  return <LogoTTBSvg focusable="false" style={{ ...style }} role="img" aria-label="Logo of Time to BEEM" />
}
