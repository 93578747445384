/**
 * Subset types enum for the subsets
 */
export enum SubsetTypes {
  /** Subset type for the subsets that are not associated with a code */
  WITHOUT_CODE = 0,
  /** Subset type for the subsets that are associated with a code but the code is invalid */
  INVALID_CODE = 1,
  /** Subset type for the subsets that are associated with a code and the code is valid */
  VALID_CODE = 2,
  /** Subset tYpe for the subsets that are not associated with a code and are not part of the ifc types we check for codes on */
  ORPHAN = 3,
}
