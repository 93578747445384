import { Box } from "@mui/material"
import React from "react"

type ImageDisplayerProps = { file: File | undefined; width: number | string; height: number | string }

export function ImageDisplayer({ file, width, height }: ImageDisplayerProps): React.JSX.Element {
  return (
    <Box
      component="img"
      src={file ? URL.createObjectURL(file) : ""}
      width={width}
      height={height}
      sx={{
        border: 1,
      }}
    />
  )
}
