import React from "react"
import { KairnialGeneralDashboard } from "./KairnialGeneralDashboard"
import { KairnialDashboardUrlSelector } from "./KairnialDashboardUrlSelector"

export function KairnialProjectsComponent(): React.JSX.Element {
  return (
    <>
      <KairnialDashboardUrlSelector />
      <KairnialGeneralDashboard />
    </>
  )
}
