interface Name {
  original: string
  value: string
  namespace: string
}

/**
 * Event emitter
 */
export class EventsEmitter {
  callbacks: any = {}

  constructor() {
    this.callbacks = {}
    this.callbacks.base = {}
  }

  /**
   * resolve event name
   * @param _names event name
   * @returns correct name
   */
  static resolveNames(_names: string) {
    let names: string | string[] = _names
    names = names.replace(/[^a-zA-Z0-9 ,/.]/g, "")
    names = names.replace(/[,/]+/g, " ")
    names = names.split(" ")

    return names
  }

  /**
   * resolve event name
   * @param name event name
   * @returns correct name
   */
  static resolveName(name: string) {
    const newName: Partial<Name> = {}
    const parts = name.split(".")

    newName.original = name
    newName.value = parts[0]
    newName.namespace = "base" // Base namespace

    // Specified namespace
    if (parts.length > 1 && parts[1] !== "") {
      newName.namespace = parts[1]
    }

    return newName as Name
  }

  /**
   * Check if name is valid
   * @param name given name
   * @returns true if valid
   */
  static checkValidName(name: string) {
    return typeof name === "undefined" || name === ""
  }

  /**
   * On event subscription
   * @param _names event name
   * @param callback call back function
   */
  on(_names: string, callback: any) {
    // Errors
    if (EventsEmitter.checkValidName(_names)) {
      console.warn("wrong names")
      return false
    }

    if (typeof callback === "undefined") {
      console.warn("wrong callback")
      return false
    }

    // Resolve names
    const names = EventsEmitter.resolveNames(_names)

    // Each name
    names.forEach((_name) => {
      // Resolve name
      const name = EventsEmitter.resolveName(_name)

      // Create namespace if not exist
      if (!(this.callbacks[name.namespace] instanceof Object)) this.callbacks[name.namespace] = {}

      // Create callback if not exist
      if (!(this.callbacks[name.namespace][name.value] instanceof Array)) this.callbacks[name.namespace][name.value] = []

      // Add callback
      this.callbacks[name.namespace][name.value].push(callback)
    })

    return this
  }

  /**
   * Un-subscripe to event
   * @param _names event name
   */
  off(_names: string) {
    // Errors
    if (EventsEmitter.checkValidName(_names)) {
      console.warn("wrong name")
      return false
    }

    // Resolve names
    const names = EventsEmitter.resolveNames(_names)

    // Each name
    names.forEach((_name) => {
      // Resolve name
      const name = EventsEmitter.resolveName(_name)

      // Remove namespace
      if (name.namespace !== "base" && name.value === "") {
        delete this.callbacks[name.namespace]
      }

      // Remove specific callback in namespace
      else {
        this.removeSpecificCallbackInNamespace(name)
      }
    })

    return this
  }

  /**
   * Event trigger
   * @param _name event name
   * @param _args additional objects array to be passed with trigger
   */
  trigger(_name: string, _args?: any[]) {
    // Errors
    if (EventsEmitter.checkValidName(_name)) {
      console.warn("wrong name")
      return false
    }

    let finalResult: any = null
    let result = null

    // Default args
    const args = !(_args instanceof Array) ? [] : _args

    // Resolve names (should on have one event)
    const nameArray = EventsEmitter.resolveNames(_name)

    // Resolve name
    const name = EventsEmitter.resolveName(nameArray[0])

    // Default namespace
    if (name.namespace === "base") {
      // Try to find callback in each namespace
      finalResult = this.callbackForEach(this.callbacks, name, args)
    }

    // Specified namespace
    else if (this.callbacks[name.namespace] instanceof Object) {
      if (name.value === "") {
        console.warn("wrong name")
        return this
      }

      this.callbacks[name.namespace][name.value].forEach((callback: any) => {
        result = callback.apply(this, args)

        if (typeof finalResult === "undefined") finalResult = result
      })
    }

    return finalResult
  }

  callbackForEach(callbacks: any, name: any, args: any[]): any | undefined {
    let finalResult: any | undefined

    for (const namespace in callbacks) {
      if (callbacks[namespace] instanceof Object && callbacks[namespace][name.value] instanceof Array) {
        const value = callbacks[namespace][name.value]
        if (value) {
          for (const callback of value) {
            finalResult = typeof finalResult === "undefined" ? callback.apply(this, args) : finalResult
          }
        }
      }
    }

    return finalResult
  }

  /**
   * Remove call bak namespaces
   * @param name given event name
   */
  removeSpecificCallbackInNamespace(name: Name) {
    // Default
    if (name.namespace === "base") {
      // Try to remove from each namespace
      for (const namespace in this.callbacks) {
        if (Object.prototype.hasOwnProperty.call(this.callbacks, namespace)) {
          this.removeFromNameSpaces(namespace, name)
        }
      }
    }

    // Specified namespace
    else if (this.removeSpecifiedName(name)) {
      delete this.callbacks[name.namespace][name.value]

      // Remove namespace if empty
      if (Object.keys(this.callbacks[name.namespace]).length === 0) delete this.callbacks[name.namespace]
    }
  }

  /**
   * remove names from namespaces
   * @param namespace namespace
   * @param name event name
   */
  private removeFromNameSpaces(namespace: any, name: Name) {
    if (this.callbacks[namespace] instanceof Object && this.callbacks[namespace][name.value] instanceof Array) {
      delete this.callbacks[namespace][name.value]

      // Remove namespace if empty
      if (Object.keys(this.callbacks[namespace]).length === 0) delete this.callbacks[namespace]
    }
  }

  /**
   * remove specified name
   * @param name given name of event
   */
  private removeSpecifiedName(name: Name) {
    return this.callbacks[name.namespace] instanceof Object && this.callbacks[name.namespace][name.value] instanceof Array
  }
}
