import { useCallback, useState } from "react"

type AccordionHook = {
  itemChecked: boolean
  expanded: string
  itemHandleChange(): void
  handleExpanded(toExpand?: string): void
  expand(toExpand: string): void
}

export function useAccordion(): AccordionHook {
  const [itemChecked, setItemChecked] = useState<boolean>(true)
  const [expanded, setExpanded] = useState<string>("")

  const itemHandleChange = useCallback((): void => {
    setItemChecked((prevState) => !prevState)
  }, [])

  const handleExpanded = useCallback(
    (toExpand: string): void => {
      if (expanded === toExpand) {
        setExpanded("")
        return
      }
      setExpanded(toExpand)
    },
    [expanded]
  )

  const expand = useCallback(
    (toExpand: string): void => {
      if (expanded !== toExpand) {
        setExpanded(toExpand)
      }
    },
    [expanded]
  )

  return {
    itemChecked,
    expanded,
    itemHandleChange,
    handleExpanded,
    expand,
  }
}
