import "./google-icons.css"
import React from "react"

function IconSelect(props: { style: any }): React.JSX.Element {
  const { style } = props

  return (
    <span
      translate="no"
      style={{
        fontVariationSettings: `'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48`,
        fontSize: style ? style.fontSize : "inherit",
        ...style,
      }}
      className="material-symbols-outlined">
      select
    </span>
  )
}

export default IconSelect
