import React from "react"
import { useNavigate } from "react-router-dom"
import { BSSubmitDialog } from "../../../../components/dialog/BSSubmitDialog"
import { resolveUrl } from "../../../../core/services/http-service"
import { pagesUrl } from "../../../../core/appConstants"

interface IProps {
  bsProjectId: string | undefined
  bsProjectName: string | undefined
  bsVariantId: string | undefined
  isOpen: boolean

  handleClose(): void
}

export function BSCancelDialog({ bsProjectId, bsProjectName, bsVariantId, handleClose, isOpen }: IProps): React.JSX.Element {
  const navigate = useNavigate()

  function handlePauseProject(): void {
    handleClose()
    return navigate(resolveUrl(pagesUrl.BEEM_SHOT_PROJECT, [bsProjectId, bsVariantId]))
  }

  return (
    <BSSubmitDialog
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={handlePauseProject}
      titleText="Êtes-vous sûr ?"
      contentText={
        <>
          Vous souhaitez remettre à plus tard la création/mise à jour du projet "{bsProjectName}" ? <br />
          Vous pouvez reprendre son édition à tout moment depuis la liste des projets Beem Shot.
        </>
      }
      cancelButtonLabel="Annuler"
      submitButtonLabel="Mettre en pause"
    />
  )
}
