import { Grid } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { customPalette } from "../../../../theme"
import { pagesUrl } from "../../../core/appConstants"
import {
  MaterialLibOrganizationIndicatorContext,
  OrganizationIndicatorStore,
} from "../../../core/context/material/material-lib-organization-indicator-context"
import { OrganizationContext } from "../../../core/context/organization/organization-context"
import { useMaterialLibOrganizationIndicator } from "../../../core/hooks/material-lib/use-fetch-material-lib-organization-indicator"
import { useProjects } from "../../../core/hooks/projects/use-projects"
import { displayNumber } from "../../../core/services/helper-service"
import "../IlluminatedButton.css"
import InfoCard from "../indicator-card"
import { UserContext } from "../../../core/context/user/user-context"
import { isMegaUser } from "../../../core/services/authentication-service"
import { ProjectTypeEnum } from "../../../core/enum/project/projectTypeEnum"

type IProps = {
  projectType: ProjectTypeEnum
}

export function MegaUserIndicatorProjectHome({ projectType }: IProps): React.JSX.Element {
  const { user } = useContext(UserContext)
  const { organization } = useContext(OrganizationContext)
  const { getUserProjectsCount, getSumCarbonImpactWholeOrganization } = useProjects()

  const [projectUserCount, setProjectUserCount] = useState<string>("")
  const [userOrganizationCount, setUserOrganizationCount] = useState<string>("")
  const [materialCount, setMaterialCount] = useState<string>("")
  const [impactCO2, setImpactCO2] = useState<string>("")

  const { organizationIndicator: materialOrganizationData } = useContext<OrganizationIndicatorStore>(
    MaterialLibOrganizationIndicatorContext
  )
  const { fetchMaterialOrganizationIndicator } = useMaterialLibOrganizationIndicator()

  useEffect(() => {
    getUserProjectsCount().then((projectCount) => setProjectUserCount(projectCount.toString()))
  }, [getUserProjectsCount])

  useEffect(() => {
    if (organization?.licenceCounter !== undefined && organization?.maxLicenceCount !== undefined) {
      setUserOrganizationCount(`${organization?.licenceCounter}/${organization?.maxLicenceCount}`)
    } else {
      setUserOrganizationCount("0/0")
    }
  }, [organization?.licenceCounter, organization?.maxLicenceCount])

  useEffect(() => {
    if (isMegaUser(user)) {
      getSumCarbonImpactWholeOrganization().then((carbonImpact) => setImpactCO2(displayNumber(carbonImpact).toString()))
    }
  }, [getSumCarbonImpactWholeOrganization])

  useEffect(() => {
    fetchMaterialOrganizationIndicator().then(() => setMaterialCount(materialOrganizationData.total.toString()))
  }, [fetchMaterialOrganizationIndicator, materialOrganizationData.total])

  return (
    <Grid container columnSpacing={3}>
      <Grid item md={6} lg={3}>
        <InfoCard
          icone="list_alt"
          title="Collaborateurs"
          backgroundcolor={customPalette.lightGreen6}
          text={userOrganizationCount}
          buttonLabel="Gérer mes collaborateurs"
          url={pagesUrl.MY_ORGANIZATION_PAGE}
        />
      </Grid>
      <Grid item md={6} lg={3}>
        <InfoCard
          icone="list_alt"
          title="Matériaux"
          backgroundcolor={customPalette.lightGreen6}
          text={materialCount}
          buttonLabel="Gérer mes matériaux"
          url={pagesUrl.MY_MATERIAL_PAGE}
        />
      </Grid>
      <Grid item md={6} lg={3}>
        {projectType === ProjectTypeEnum.BIM_ORGANIZATION && (
          <InfoCard
            icone="list_alt"
            title="Projets de mon organisation"
            backgroundcolor={customPalette.lightGreen6}
            text={projectUserCount}
          />
        )}
      </Grid>
      {projectType === ProjectTypeEnum.BIM_ORGANIZATION && (
        <Grid item md={6} lg={3}>
          <InfoCard
            icone="list_alt"
            title="Impact CO² de mon organisation"
            backgroundcolor={customPalette.lightYellow1}
            text={impactCO2}
          />
        </Grid>
      )}
    </Grid>
  )
}
