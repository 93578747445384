import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export function IconPencil(props: SvgIconProps): React.JSX.Element {
  return (
    <SvgIcon {...props}>
      {/*  SVG from https://tablericons.com/ */}
      <svg width="24" height="24" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_812_85679)">
          <path
            // eslint-disable-next-line max-len
            d="M4.5 28.5002H9.83333L27.1667 11.1669C27.8739 10.4597 28.2712 9.50043 28.2712 8.50024C28.2712 7.50005 27.8739 6.54082 27.1667 5.83357C26.4594 5.12633 25.5002 4.729 24.5 4.729C23.4998 4.729 22.5406 5.12633 21.8333 5.83357L4.5 23.1669V28.5002Z"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.833 7.8335L25.1663 13.1668"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.5 11.1667L9.83333 4.5L4.5 9.83333L11.1667 16.5"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.83301 11.1665L7.83301 13.1665"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.8333 16.5L28.5 23.1667L23.1667 28.5L16.5 21.8333"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.833 23.1665L19.833 25.1665"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_812_85679">
            <rect width="32" height="32" fill="white" transform="translate(0.5 0.5)" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}
