export enum ModuleTypeEnum {
  BEEM_SHOT = "BEEM_SHOT",
  BEEM_MASTER = "BEEM_MASTER",
}

export function getModulTypeEnum(value: string | undefined): ModuleTypeEnum {
  if (Object.values(ModuleTypeEnum).includes(value as ModuleTypeEnum)) {
    return value as ModuleTypeEnum
  }
  return ModuleTypeEnum.BEEM_MASTER // or throw an error if you want to handle it
}
