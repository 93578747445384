import AccessTimeIcon from "@mui/icons-material/AccessTime"
import { Grid, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import React, { useContext, useEffect, useMemo } from "react"
import { Link, useParams } from "react-router-dom"
import { customPalette } from "../../../../theme"
import { adminPagesUrl, pagesUrl } from "../../../core/appConstants"
import { BimModelContext } from "../../../core/context/bim-model/bim-model-context"
import { CalculationContext } from "../../../core/context/calculation/calculation-context"
import { AdminOrganizationContext } from "../../../core/context/organization/admin-organization-context"
import { ProjectContext } from "../../../core/context/project/project-context"
import { TutorialEnabledContext } from "../../../core/context/tutoriel-enabled-context"
import { ProjectRoleContext } from "../../../core/context/user/project-role-context"
import { CalculationStatusEnum } from "../../../core/enum/calculationStatusEnum"
import { PreviousPage } from "../../../core/enum/previousPage"
import { ProjectStatusEnum } from "../../../core/enum/projectStatusEnum"
import { RoleEnum } from "../../../core/enum/roleEnum"
import { useProjects } from "../../../core/hooks/projects/use-projects"
import { resolveUrl } from "../../../core/services/http-service"
import CardSuccessItem from "./card-success-item"
import ControlBoardHeaderHome from "./control-board-header-home"
import ListPhaseItem from "./list-phase-item"
import { BimModelFileContext } from "../../../core/context/bim-model/bim-model-file-context"

export default function ProjectControlBoard(): React.JSX.Element {
  const { project, setProject } = useContext(ProjectContext)
  const { hasRole, isOrganizationMegaUser, isOrganizationUltimateUser } = useContext(ProjectRoleContext)
  const { isLoading: isCalculationLoading, calculation } = useContext(CalculationContext)
  const { tutorialEnabled } = useContext(TutorialEnabledContext)
  const { bimModel } = useContext(BimModelContext)
  const { loading } = useContext(BimModelFileContext)
  const { getProject } = useProjects()
  const { organization } = useContext(AdminOrganizationContext)
  const { organizationId } = useParams()

  const projectName = useMemo(() => `${project?.name}`, [project?.name])
  const isArchived = useMemo(() => project?.status === ProjectStatusEnum.ARCHIVED, [project?.status])

  useEffect(() => {
    if (project?.id !== undefined) {
      window.localStorage.setItem("visitedBreadCumbs", JSON.stringify(["infos"]))
    }
  }, [getProject, setProject])

  function getBimModelPageUrl(): string {
    if (isOrganizationUltimateUser) {
      return resolveUrl(adminPagesUrl.ADMIN_PROJECT_BIM_MODEL_IMPORT, [organization?.id, project?.id])
    } else {
      return tutorialEnabled
        ? resolveUrl(pagesUrl.PROJECT_BIM_MODEL_IMPORT_TUTO, [project?.id])
        : resolveUrl(pagesUrl.PROJECT_BIM_MODEL_IMPORT, [project?.id])
    }
  }

  function getCalculationPageUrl(): string {
    if (isOrganizationUltimateUser) {
      return bimModel?.id ? resolveUrl(adminPagesUrl.PROJECT_CALCULATION_CORE_DATA, [organization?.id, project?.id]) : "#"
    } else {
      return bimModel?.id ? resolveUrl(pagesUrl.PROJECT_CALCULATION_CORE_DATA, [project?.id]) : "#"
    }
  }

  return (
    <Box sx={{ ml: 20, mr: 20 }}>
      <ControlBoardHeaderHome projectName={projectName} />
      <Box display="flex" flexDirection="column">
        <Grid container columnSpacing={12}>
          <Grid item xs={4}>
            <CardSuccessItem
              title="Informations générales"
              iconId="generale"
              isWritingMode={!isArchived && hasRole([RoleEnum.ADMINISTRATOR])}
              isReadingMode={isOrganizationMegaUser || isOrganizationUltimateUser}
              pageUrl={
                isOrganizationUltimateUser
                  ? resolveUrl(adminPagesUrl.PROJECT_INFO, [organization?.id, project?.id])
                  : resolveUrl(`${pagesUrl.PROJECT_CONTROL_BOARD}${pagesUrl.PROJECT_INFO}`, [project?.id])
              }
            />
          </Grid>
          <Grid item xs={4}>
            <CardSuccessItem
              title="Equipe"
              iconId="team"
              isWritingMode={!isArchived && hasRole([RoleEnum.ADMINISTRATOR])}
              isReadingMode={
                hasRole([RoleEnum.BE, RoleEnum.MOA, RoleEnum.CONSULTANT, RoleEnum.ARCHITECT, RoleEnum.ADMINISTRATOR]) ||
                isOrganizationMegaUser ||
                isOrganizationUltimateUser
              }
              pageUrl={
                isOrganizationUltimateUser
                  ? resolveUrl(adminPagesUrl.PROJECT_TEAM_MANAGEMENT, [organization?.id, project?.id])
                  : resolveUrl(pagesUrl.PROJECT_TEAM_MANAGEMENT, [project?.id])
              }
            />
          </Grid>
          <Grid item xs={4}>
            <CardSuccessItem
              title="Matériauthèque projet"
              iconId="materiautheque"
              isWritingMode={
                !isArchived &&
                hasRole([RoleEnum.BE, RoleEnum.MOA, RoleEnum.CONSULTANT, RoleEnum.ARCHITECT, RoleEnum.ADMINISTRATOR])
              }
              isReadingMode={isOrganizationMegaUser || isOrganizationUltimateUser}
              pageUrl={
                isOrganizationUltimateUser
                  ? resolveUrl(adminPagesUrl.PROJECT_MATERIAL_PAGE, [organizationId, project?.id])
                  : resolveUrl(`${pagesUrl.PROJECT_PAGE}/:projectId/control-board/project-material-lib`, [project?.id])
              }
            />
          </Grid>
        </Grid>
        <Box sx={{ mt: 5, mb: 7 }}>
          <Grid container columnSpacing={12}>
            <Grid item xs={4}>
              <Box
                sx={{
                  bgcolor: customPalette.default,
                  padding: "0px 40px",
                  height: 85,
                  borderRadius: 0.25,
                  boxShadow: "0px 3px 5px #e2e1e1",
                }}
                display="flex"
                justifyContent="space-between"
                alignItems="center">
                <Box display="flex" flexDirection="column" justifyContent="space-evenly" height="inherit">
                  <Typography
                    sx={{
                      fontWeight: 700,
                    }}>
                    Phases
                  </Typography>

                  {!isArchived && hasRole([RoleEnum.ADMINISTRATOR]) && (
                    <Link
                      to={resolveUrl(pagesUrl.PROJECT_PHASE_STEPPER, [project.id])}
                      state={{ previousPage: PreviousPage.CONTROL_BOARD }}
                      style={{
                        textTransform: "uppercase",
                        fontWeight: 600,
                        fontSize: 12,
                        cursor: "pointer",
                        color: "inherit",
                      }}>
                      Gérer
                    </Link>
                  )}
                </Box>
                <Box display="flex" flexDirection="column">
                  <AccessTimeIcon fontSize="large" />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={8} sx={{ pt: 0 }}>
              <ListPhaseItem />
            </Grid>
          </Grid>
        </Box>
        {/* last line multiple cards */}
        <Grid container columnSpacing={12} mt="inherit">
          <Grid item xs={4}>
            <CardSuccessItem
              title="Maquette numérique"
              iconId="model"
              isWritingMode={!isArchived && hasRole([RoleEnum.ADMINISTRATOR, RoleEnum.ARCHITECT])}
              isReadingMode={hasRole([RoleEnum.BE, RoleEnum.MOA]) || isOrganizationMegaUser || isOrganizationUltimateUser}
              pageUrl={getBimModelPageUrl()}
            />
          </Grid>
          <Grid item xs={4} pt="inherit" sx={{ pt: "inherit" }}>
            <CardSuccessItem
              title="Calculs carbone"
              iconId="calculate"
              isWritingMode={!isArchived && (hasRole([RoleEnum.ADMINISTRATOR, RoleEnum.BE]) || isCalculationLoading)}
              isReadingMode={isOrganizationMegaUser || isOrganizationUltimateUser}
              pageUrl={getCalculationPageUrl()}
              tooltipTitle={bimModel?.id ? "" : "Pas encore de maquette"}
              isError={!bimModel?.id}
              disabled={loading}
              isReady={!loading}
              warningDialogContent={{
                title: "Pas encore de maquette",
                msg: "L'architecte n'a pas encore ajouté de maquette BIM sur cette phase du projet.",
              }}
            />
          </Grid>
          <Grid item xs={4} pt="inherit" sx={{ pt: "inherit" }}>
            <CardSuccessItem
              title="Synthèse"
              iconId="synthesis"
              isWritingMode={!isArchived && (!isOrganizationMegaUser || !isOrganizationUltimateUser)}
              isReadingMode={isOrganizationMegaUser || isOrganizationUltimateUser}
              pageUrl={
                isOrganizationUltimateUser
                  ? resolveUrl(adminPagesUrl.ADMIN_PROJECT_DASHBOARD_TABLEAU, [organizationId, project?.id])
                  : resolveUrl(pagesUrl.PROJECT_DASHBOARD, [project?.id])
              }
              isError={calculation?.calculationStatus !== CalculationStatusEnum.DONE}
              warningDialogContent={{
                title: "Pas encore de résultat",
                msg: "Le tableau de synthèse n'existe pas encore pour cette phase",
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
