import { Box, CircularProgress, Grid } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { SubsetInfo } from "../../../../components/ifc-displayer/models/SubsetInfo"
import {
  BimModelImportContext,
  BimModelImportContextStore,
} from "../../../../core/context/bim-model/bim-model-import-context"
import { CodeReferenceContext } from "../../../../core/context/code-acv/CodeReferenceContext"
import { CodeExtrait } from "../../../../core/dto/code-extrait/code-extrait"
import { codeStateEnum } from "../../../../core/enum/codeStateEnum"
import { CodesVersion } from "../../../../core/enum/codesVersionEnum"
import { useBimModel } from "../../../../core/hooks/bim-model/use-bim-model"
import { useParseBim } from "../../../../core/hooks/bim-model/use-parse-bim"
import { BimModelAnalyzer } from "../components/bim-model-analyzer/bim-model-analyzer"
import BimModelHeader from "../project-bim-model-header"
import TutorialBimModelGuide from "./tutorial-section/tutorial-bim-guide"
import TutorialBimModelVideo from "./tutorial-section/tutorial-bim-video"
import { codeToKey } from "../../../../core/services/code-service"

export default function TutorialPage(): React.JSX.Element {
  const { viewer, codesExtraits } = useContext<BimModelImportContextStore>(BimModelImportContext)
  const { isCodeAcvLoading } = useContext(CodeReferenceContext)
  const { fetchTutorialModel } = useBimModel()

  const [progress, setProgress] = useState<number>(0)
  // We fix to code version 1.6 because it's tutorial model which is an old model
  const { analyzeModel } = useParseBim({ version: CodesVersion.VERSION_1_6, setProgress })

  const [tutorialFile, setTutorialFile] = useState<File | undefined>(undefined)

  const [selectedCodeExtrait, setSelectedCodeExtrait] = useState<CodeExtrait | undefined>(undefined)

  useEffect(() => {
    fetchTutorialModel().then((file: File | undefined) => {
      setTutorialFile(file)
    })
  }, [fetchTutorialModel])

  function handleClickAcv(codeExtract: CodeExtrait, disableViewerHighlight?: boolean): void {
    if (selectedCodeExtrait && codeToKey(codeExtract) === codeToKey(selectedCodeExtrait)) {
      if (!disableViewerHighlight) {
        viewer?.manager.subsetsManager.unHighlightCodeExtrait(codeExtract)
      }
      setSelectedCodeExtrait(undefined)
      return
    }
    if (!disableViewerHighlight) {
      viewer?.manager.subsetsManager.highlightCodeExtrait(codeExtract)
    }
    setSelectedCodeExtrait(codeExtract)
  }

  function handleClickCodeManquant(codeManquant: SubsetInfo): void {
    setSelectedCodeExtrait(undefined)
    viewer?.manager.subsetsManager.toggleSubsetHighlight(codeManquant)
  }

  function handleCodeVariantChange(codeVariant: codeStateEnum, unhighlight?: boolean): void {
    viewer?.manager.subsetsManager.updateCodeState(codeVariant, unhighlight ?? false)
    if (unhighlight) {
      setSelectedCodeExtrait(undefined)
    }
  }

  function tutorialModel(): React.JSX.Element {
    if (tutorialFile && !isCodeAcvLoading) {
      return (
        <BimModelAnalyzer
          bimModelFile={tutorialFile}
          reset={() => undefined}
          isTree
          analyzeModel={analyzeModel}
          codesExtraits={codesExtraits}
          setProgress={setProgress}
          handleClickAcv={handleClickAcv}
          selectedCodeExtrait={selectedCodeExtrait}
          handleClickCodeManquant={handleClickCodeManquant}
          handleCodeVariantChange={handleCodeVariantChange}
          isTutorialPage
        />
      )
    } else {
      return <CircularProgress />
    }
  }

  return (
    <Box sx={{ pl: 10, pr: 10 }}>
      <BimModelHeader />
      <Grid container columnSpacing={2} rowSpacing={2}>
        <Grid item xs={12}>
          {tutorialModel()}
        </Grid>
        <Grid item xs={12} component="div" display="flex" flexDirection="column">
          <TutorialBimModelGuide />
        </Grid>
        <Grid item xs={12} component="div" display="flex" flexDirection="column">
          <TutorialBimModelVideo />
        </Grid>
      </Grid>
    </Box>
  )
}
