/* eslint-disable jsx-a11y/anchor-is-valid */
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import { Button, LinearProgress, LinearProgressProps, Link, Typography } from "@mui/material"
import { Box } from "@mui/system"
import React, { Dispatch, MutableRefObject, SetStateAction, useContext } from "react"
import FileInput from "../../../../../components/inputs/file-input/file-input"
import { BimModelFileContext, BimModelFileStore } from "../../../../../core/context/bim-model/bim-model-file-context"
import { ProjectContext } from "../../../../../core/context/project/project-context"
import { ProjectRoleContext } from "../../../../../core/context/user/project-role-context"
import { CodeExtrait } from "../../../../../core/dto/code-extrait/code-extrait"
import { ProjectStatusEnum } from "../../../../../core/enum/projectStatusEnum"
import { RoleEnum } from "../../../../../core/enum/roleEnum"

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }): React.JSX.Element {
  const { value } = props
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  )
}

type IProps = {
  bimModelFile: File | undefined
  setBimModelFile: Dispatch<SetStateAction<File | undefined>>
  progress: number
  setProgress(progress: number): void
  setActiveStep: React.Dispatch<React.SetStateAction<number>>
  setCodesExtraits: React.Dispatch<React.SetStateAction<CodeExtrait[]>>
  cancelUpload: () => void
  isModelUpdateRef: MutableRefObject<boolean>
  isWriting: boolean
}

export function BimModelUploader({
  bimModelFile,
  setBimModelFile,
  setActiveStep,
  setCodesExtraits,
  progress,
  setProgress,
  cancelUpload,
  isModelUpdateRef,
  isWriting,
}: IProps): React.JSX.Element {
  const { setIsCompressed } = useContext<BimModelFileStore>(BimModelFileContext)
  const { hasRole } = useContext(ProjectRoleContext)
  const { project } = useContext(ProjectContext)

  function onChange(modelFile: File): void {
    setActiveStep(0)
    setCodesExtraits([])
    setProgress(0)
    isModelUpdateRef.current = true
    setBimModelFile(modelFile)
    setIsCompressed(modelFile?.name?.endsWith(".ifczip"))
    console.info("Changing Bim Model")
  }

  function handleFileDownload(): void {
    if (bimModelFile) {
      const url = window.URL.createObjectURL(bimModelFile)
      const link = document.createElement("a")
      link.href = url
      link.download = `${bimModelFile.name}`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    }
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100px " }}>
      <Box>
        <FileInput
          onChange={onChange}
          file={bimModelFile}
          progress={progress}
          disabled={!hasRole([RoleEnum.ARCHITECT, RoleEnum.ADMINISTRATOR]) || project.status === ProjectStatusEnum.ARCHIVED}
          fileType=".ifc,.ifczip"
        />

        {bimModelFile !== undefined && (
          <Box sx={{ pt: 2 }}>
            <LinearProgressWithLabel value={progress} />
          </Box>
        )}
        {project.status !== ProjectStatusEnum.ARCHIVED && bimModelFile !== undefined && (
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography>{bimModelFile.name}</Typography>
            {isWriting && <Link onClick={cancelUpload}>Annuler le téléchargement</Link>}
            {!isWriting && (
              <Button
                variant="contained"
                startIcon={<CloudUploadIcon />}
                onClick={handleFileDownload}
                disabled={progress !== 100}>
                Télécharger le fichier
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Box>
  )
}
