import { Box, Checkbox, Grid, Typography } from "@mui/material"
import React, { useContext, useMemo, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ConfirmationDialog } from "../../../components/dialog/confirmation-dialog"
import TeamDetail from "../../../components/miscellianous/team/team-details"
import TeamInvitationDetails from "../../../components/miscellianous/team/team-invitation-details"
import { pagesUrl } from "../../../core/appConstants"
import { ProjectContext } from "../../../core/context/project/project-context"
import { ProjectRoleContext, RoleStore } from "../../../core/context/user/project-role-context"
import { UserContext } from "../../../core/context/user/user-context"
import { Team } from "../../../core/dto/team"
import { ProjectInvitation } from "../../../core/dto/user/projectInvitation"
import { ProjectTypeEnum } from "../../../core/enum/project/projectTypeEnum"
import { RoleEnum } from "../../../core/enum/roleEnum"
import { useUser } from "../../../core/hooks/use-user"
import { resolveUrl } from "../../../core/services/http-service"
import CancelInvitationDialog from "./component/cancel-invitation-dialog"
import RevokeDialog from "./component/revoke-dialog"

const guestRole: string[] = ["Administrateur du projet", "Moa", "Architecte", "Ingénieur d'étude", "Consultants"]

function getColor(role: RoleEnum): string {
  switch (role) {
    case RoleEnum.ADMINISTRATOR:
      return "#F09C9C"
    case RoleEnum.MOA:
      return "#9CBCF0"
    case RoleEnum.ARCHITECT:
      return "#AFEB99"
    case RoleEnum.BE:
      return "#C39CF0"
    case RoleEnum.CONSULTANT:
      return "#FFDD80"
    default:
      return "#FFDD80"
  }
}

type IProps = {
  newTeam: Team
  invitedUser: ProjectInvitation[]
  refreshTeamInfo(): void
}

export default function TeamDisplayer({ newTeam, invitedUser, refreshTeamInfo }: IProps): React.JSX.Element {
  const navigate = useNavigate()
  const { project } = useContext(ProjectContext)
  const { user } = useContext(UserContext)
  const { role, setRole, hasRole } = useContext<RoleStore>(ProjectRoleContext)
  const { getUserRight, revokeUserProjectRight, grantSelfRight, cancelUserInvitation } = useUser()

  const [isRevokeDialogOpen, setIsRevokeDialogOpen] = useState<boolean>(false)
  const [isCancelInvitationDialogOpen, setIsCancelInvitationRevokeDialogOpen] = useState<boolean>(false)
  const [openAddRole, setOpenAddRole] = useState<boolean>(false)
  const [actualRole, setActualRole] = useState<RoleEnum | undefined>(undefined)
  const selectedCognitoIdRef = useRef<string | undefined>(undefined)
  const selectedUserNameRef = useRef<string | undefined>(undefined)
  const selectedInvitedUserEmailRef = useRef<string | undefined>(undefined)

  const labelActualRole = useMemo(() => {
    if (actualRole === RoleEnum.ADMINISTRATOR || actualRole === RoleEnum.ARCHITECT) {
      return `'${getLabelText(actualRole)}`
    } else {
      return `e ${getLabelText(actualRole)}`
    }
  }, [actualRole])

  function refreshUserRights(): Promise<void> {
    if (project?.id) {
      return getUserRight(project.id).then((r) => {
        setRole(r)
      })
    } else {
      return Promise.resolve(undefined)
    }
  }

  function getLabelText(value: RoleEnum | undefined): string {
    if (value === RoleEnum.ADMINISTRATOR) return "administrateur du projet"
    if (value === RoleEnum.ARCHITECT) return "architecte"
    if (value === RoleEnum.BE) return "bureau d'étude"
    if (value === RoleEnum.CONSULTANT) return "consultant"
    if (value === RoleEnum.MOA) return "MOA"

    return ""
  }

  function onCheckRole(roleToCheck: RoleEnum, isActive: boolean): void {
    setActualRole(roleToCheck)

    if (!isActive) {
      setOpenAddRole(true)
    } else {
      selectedCognitoIdRef.current = user?.cognitoUserId
      setIsRevokeDialogOpen(true)
    }
  }

  function addRoleToSelf(): Promise<void> {
    if (project.id !== undefined && user?.cognitoUserId !== undefined && actualRole !== undefined) {
      return grantSelfRight(project.id, actualRole.toString()).then(() => {
        refreshTeamInfo()
        return refreshUserRights()
      })
    } else {
      return Promise.resolve()
    }
  }

  function handleClose(): void {
    setIsRevokeDialogOpen(false)
    setOpenAddRole(false)
    setIsCancelInvitationRevokeDialogOpen(false)
  }

  function revokeFunctionFactory(
    userRole: RoleEnum
  ): (cognitoUserId: string, firstName: string | undefined, lastName: string | undefined) => void {
    return (cognitoUserId: string, firstName: string | undefined, lastName: string | undefined) =>
      openRevokeDialog(userRole, cognitoUserId, firstName, lastName)
  }

  function openRevokeDialog(
    userRole: RoleEnum,
    cognitoUserId: string,
    firstName: string | undefined,
    lastName: string | undefined
  ): void {
    selectedCognitoIdRef.current = cognitoUserId
    selectedUserNameRef.current = `${firstName} ${lastName}`
    setActualRole(userRole)
    setIsRevokeDialogOpen(true)
  }

  function openCancelInvitationDialog(selectedInvitedUser: ProjectInvitation): void {
    selectedInvitedUserEmailRef.current = selectedInvitedUser.email
    setIsCancelInvitationRevokeDialogOpen(true)
  }

  function revokeRole(): Promise<void> {
    if (project.id !== undefined && selectedCognitoIdRef.current !== undefined && actualRole !== undefined) {
      return revokeUserProjectRight(project.id, selectedCognitoIdRef.current, actualRole).then((newRole: RoleEnum[]) => {
        refreshTeamInfo()
        if (selectedCognitoIdRef.current === user?.cognitoUserId) {
          setRole(newRole)
          if (actualRole === RoleEnum.ADMINISTRATOR) {
            if (newRole && newRole.length === 0) {
              navigate(resolveUrl(pagesUrl.PROJECTS_PAGE, [], { type: ProjectTypeEnum.BIM }))
            } else {
              navigate(`${pagesUrl.PROJECT_PAGE}/${project.id}/control-board`)
            }
          }
        }
      })
    } else {
      return Promise.resolve(undefined)
    }
  }

  function cancelInvitation(): Promise<void> {
    if (project.id && selectedInvitedUserEmailRef.current) {
      return cancelUserInvitation(selectedInvitedUserEmailRef.current, project.id, project.organizationId).then(() =>
        refreshTeamInfo()
      )
    } else {
      return Promise.resolve(undefined)
    }
  }

  return (
    <Box>
      {/* ADMINISTRATOR */}
      <Typography component="a" sx={{ fontWeight: "bold", fontSize: "20px", display: "flex", padding: "1% 0%" }}>
        {guestRole[0].toLocaleUpperCase()}
      </Typography>
      <Grid item md={12} sx={{ minHeight: "50px" }}>
        <Grid container rowSpacing={0} columnSpacing={0}>
          {newTeam?.admins.map((projectUser) => (
            <Grid item md={4} key={projectUser.id}>
              <TeamDetail
                cognitoUserId={projectUser.cognitoUserId}
                firstName={projectUser.firstName}
                lastName={projectUser.lastName}
                revokeAction={revokeFunctionFactory(RoleEnum.ADMINISTRATOR)}
                colorAvatar={getColor(RoleEnum.ADMINISTRATOR)}
                revokeButton={hasRole([RoleEnum.ADMINISTRATOR])}
              />
            </Grid>
          ))}
          {invitedUser?.map(
            (invited) =>
              invited.roles.includes(RoleEnum.ADMINISTRATOR) && (
                <Grid item md={4} key={invited.email}>
                  <TeamInvitationDetails
                    selectedUser={invited}
                    cancelButton={hasRole([RoleEnum.ADMINISTRATOR])}
                    openCancelInvitationDialog={openCancelInvitationDialog}
                  />
                </Grid>
              )
          )}
        </Grid>
      </Grid>

      <Typography>
        <Checkbox
          disabled={!hasRole([RoleEnum.ADMINISTRATOR])}
          onClick={() => onCheckRole(RoleEnum.ADMINISTRATOR, role.includes(RoleEnum.ADMINISTRATOR))}
          checked={role.includes(RoleEnum.ADMINISTRATOR)}
        />
        Je suis l'administrateur du projet
      </Typography>

      <hr style={{ width: "20%", display: "flex" }} />
      {/*MOA */}
      <Typography component="a" sx={{ fontWeight: "bold", fontSize: "20px", display: "flex", padding: "1% 0%" }}>
        {guestRole[1].toLocaleUpperCase()}
      </Typography>
      <Grid item md={12} sx={{ minHeight: "50px" }}>
        <Grid container rowSpacing={0} columnSpacing={0}>
          {newTeam?.moa.map((projectUser) => (
            <Grid item md={4} key={projectUser.id}>
              <TeamDetail
                cognitoUserId={projectUser.cognitoUserId}
                firstName={projectUser.firstName}
                lastName={projectUser.lastName}
                revokeAction={revokeFunctionFactory(RoleEnum.MOA)}
                colorAvatar={getColor(RoleEnum.MOA)}
                revokeButton={hasRole([RoleEnum.ADMINISTRATOR])}
              />
            </Grid>
          ))}
          {invitedUser?.map(
            (invited) =>
              invited.roles.includes(RoleEnum.MOA) && (
                <Grid item md={4} key={invited.email}>
                  <TeamInvitationDetails
                    selectedUser={invited}
                    cancelButton={hasRole([RoleEnum.ADMINISTRATOR])}
                    openCancelInvitationDialog={openCancelInvitationDialog}
                  />
                </Grid>
              )
          )}
        </Grid>
      </Grid>

      <Typography>
        <Checkbox
          disabled={!hasRole([RoleEnum.ADMINISTRATOR])}
          onClick={() => onCheckRole(RoleEnum.MOA, role.includes(RoleEnum.MOA))}
          checked={role.includes(RoleEnum.MOA)}
        />
        Je suis le MOA du projet
      </Typography>

      {/*ARCHITECTE */}
      <Typography component="a" sx={{ fontWeight: "bold", fontSize: "20px", display: "flex", padding: "1% 0%" }}>
        {guestRole[2].toLocaleUpperCase()}
      </Typography>
      <Grid item md={12} sx={{ minHeight: "50px" }}>
        <Grid container rowSpacing={0} columnSpacing={0}>
          {newTeam?.architects.map((projectUser) => (
            <Grid item md={4} key={projectUser.id}>
              <TeamDetail
                cognitoUserId={projectUser.cognitoUserId}
                firstName={projectUser.firstName}
                lastName={projectUser.lastName}
                revokeAction={revokeFunctionFactory(RoleEnum.ARCHITECT)}
                colorAvatar={getColor(RoleEnum.ARCHITECT)}
                revokeButton={hasRole([RoleEnum.ADMINISTRATOR])}
              />
            </Grid>
          ))}
          {invitedUser?.map(
            (invited) =>
              invited.roles.includes(RoleEnum.ARCHITECT) && (
                <Grid item md={4} key={invited.email}>
                  <TeamInvitationDetails
                    selectedUser={invited}
                    cancelButton={hasRole([RoleEnum.ADMINISTRATOR])}
                    openCancelInvitationDialog={openCancelInvitationDialog}
                  />
                </Grid>
              )
          )}
        </Grid>
      </Grid>

      <Typography>
        <Checkbox
          disabled={!hasRole([RoleEnum.ADMINISTRATOR])}
          onClick={() => onCheckRole(RoleEnum.ARCHITECT, role.includes(RoleEnum.ARCHITECT))}
          checked={role.includes(RoleEnum.ARCHITECT)}
        />
        Je suis l'architecte du projet
      </Typography>

      <hr style={{ width: "20%", display: "flex" }} />
      {/*INGENIEUR D'ETUDES */}
      <Typography component="a" sx={{ fontWeight: "bold", fontSize: "20px", display: "flex", padding: "1% 0%" }}>
        {guestRole[3].toLocaleUpperCase()}
      </Typography>
      <Grid item md={12} sx={{ minHeight: "50px" }}>
        <Grid container rowSpacing={0} columnSpacing={0}>
          {newTeam?.be.map((projectUser) => (
            <Grid item md={4} key={projectUser.id}>
              <TeamDetail
                cognitoUserId={projectUser.cognitoUserId}
                firstName={projectUser.firstName}
                lastName={projectUser.lastName}
                revokeAction={revokeFunctionFactory(RoleEnum.BE)}
                colorAvatar={getColor(RoleEnum.BE)}
                revokeButton={hasRole([RoleEnum.ADMINISTRATOR])}
              />
            </Grid>
          ))}
          {invitedUser?.map(
            (invited) =>
              invited.roles.includes(RoleEnum.BE) && (
                <Grid item md={4} key={invited.email}>
                  <TeamInvitationDetails
                    selectedUser={invited}
                    cancelButton={hasRole([RoleEnum.ADMINISTRATOR])}
                    openCancelInvitationDialog={openCancelInvitationDialog}
                  />
                </Grid>
              )
          )}
        </Grid>
      </Grid>

      <Typography>
        <Checkbox
          disabled={!hasRole([RoleEnum.ADMINISTRATOR])}
          onClick={() => onCheckRole(RoleEnum.BE, role.includes(RoleEnum.BE))}
          checked={role.includes(RoleEnum.BE)}
        />
        Je suis l'ingénieur d'études du projet
      </Typography>

      <hr style={{ width: "20%" }} />
      {/*CONSULTANTS */}
      <Typography component="a" sx={{ fontWeight: "bold", fontSize: "20px", display: "flex", padding: "1% 0%" }}>
        {guestRole[4].toLocaleUpperCase()}
      </Typography>
      <Grid item md={12} sx={{ minHeight: "50px" }}>
        <Grid container rowSpacing={0} columnSpacing={0}>
          {newTeam?.watchers.map((projectUser) => (
            <Grid item md={4} key={projectUser.id}>
              <TeamDetail
                cognitoUserId={projectUser.cognitoUserId}
                firstName={projectUser.firstName}
                lastName={projectUser.lastName}
                revokeAction={revokeFunctionFactory(RoleEnum.CONSULTANT)}
                colorAvatar={getColor(RoleEnum.CONSULTANT)}
                revokeButton={hasRole([RoleEnum.ADMINISTRATOR])}
              />
            </Grid>
          ))}
          {invitedUser?.map(
            (invited) =>
              invited.roles.includes(RoleEnum.CONSULTANT) && (
                <Grid item md={4} key={invited.email}>
                  <TeamInvitationDetails
                    selectedUser={invited}
                    cancelButton={hasRole([RoleEnum.ADMINISTRATOR])}
                    openCancelInvitationDialog={openCancelInvitationDialog}
                  />
                </Grid>
              )
          )}
        </Grid>
      </Grid>

      <Typography>
        <Checkbox
          disabled={!hasRole([RoleEnum.ADMINISTRATOR])}
          onClick={() => onCheckRole(RoleEnum.CONSULTANT, role.includes(RoleEnum.CONSULTANT))}
          checked={role.includes(RoleEnum.CONSULTANT)}
        />
        Je suis le consultant du projet
      </Typography>

      <hr style={{ width: "20%" }} />

      <ConfirmationDialog
        id="addRole"
        title="Ajout de rôle"
        contentText={` Êtes-vous sûr de vouloir ajouter le rôle d${labelActualRole} à votre utilisateur ? `}
        handleClose={handleClose}
        action={addRoleToSelf}
        open={openAddRole}
      />

      <RevokeDialog
        selectedCognitoId={selectedCognitoIdRef.current}
        selectedUserName={selectedUserNameRef.current}
        labelActualRole={labelActualRole}
        handleClose={handleClose}
        revokeRole={revokeRole}
        open={isRevokeDialogOpen}
      />

      <CancelInvitationDialog
        handleClose={handleClose}
        cancelInvitation={cancelInvitation}
        open={isCancelInvitationDialogOpen}
      />
    </Box>
  )
}
