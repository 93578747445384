import React from "react"
import { ConfirmationDialog } from "../../../../components/dialog/confirmation-dialog"

type IProps = {
  open: boolean
  handleClose(): void
  cancelInvitation(): Promise<void>
}

export default function CancelInvitationDialog({ open, handleClose, cancelInvitation }: IProps): React.JSX.Element {
  return (
    <ConfirmationDialog
      id="revokeRole"
      title="Annulation de l'invitation"
      contentText="Cette action entrainera l'annulation des invitations en cours. Êtes-vous sûr de vouloir continuer ?"
      handleClose={handleClose}
      action={() => cancelInvitation()}
      open={open}
    />
  )
}
