import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted"
import React, { useContext, useState } from "react"
import { ProjectListEmpty } from "../../../components/project-list-empty/projectListEmpty"
import { BSProjectListContext } from "../../../core/context/beem-shot/BSProject/BSProjectListContext"
import { BSProjectList } from "./BSProjectList"
import { CustomTabPanel, TabPanelProps } from "./CustomTabPanel"

export function BSProjectListContainer(): React.JSX.Element {
  const { bsProjectList, isOneProjectCreated, projectCount } = useContext(BSProjectListContext)

  const [tabs, setTabs] = React.useState("BS_PROJECTS")

  function handleChange(event: React.SyntheticEvent, newValue: string): void {
    setTabs(newValue)
  }

  const panels: TabPanelProps[] = [
    {
      id: "BS_PROJECTS",
      Icon: FormatListBulletedIcon,
      label: "Projets",
      chips: projectCount.toString(),
      content: <BSProjectList />,
    },
  ]

  return !isOneProjectCreated.current ? (
    <ProjectListEmpty />
  ) : (
    <CustomTabPanel panels={panels} tabs={tabs} handleChange={handleChange} />
  )
}
