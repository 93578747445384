/* eslint-disable jsx-a11y/anchor-is-valid */
import { Grid, Grow, Switch, Typography } from "@mui/material"
import { Box } from "@mui/system"
import React, { ChangeEvent, Dispatch, FormEvent, SetStateAction, useCallback, useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import FileInput from "../../../components/inputs/file-input/file-input"
import { NumberInput } from "../../../components/inputs/number-input/number-input"
import { TextInput } from "../../../components/inputs/text-input/text-input"
import { ErrorContext } from "../../../components/layout/error-snackbar"
import { DefaultTypography } from "../../../components/typography/default-typography"
import { pagesUrl } from "../../../core/appConstants"
import { BsModelContext } from "../../../core/context/beem-shot/BSBimModel/BSBimModelContext"
import { BSModelImportContext } from "../../../core/context/beem-shot/BSBimModel/BSBimModelImportContext"
import { BSInputContext } from "../../../core/context/beem-shot/BSInputContext/BSInputContext"
import { BSProjectContext } from "../../../core/context/beem-shot/BSProject/BSProjectContext"
import { BSVariantContext } from "../../../core/context/beem-shot/BSVariant/BSVariantContext"
import { BSBimModel } from "../../../core/dto/beem-shot/BSBimModel/BSBimModel"
import { BSBimModelCreateOrUpdate } from "../../../core/dto/beem-shot/BSBimModel/BSBimModelCreateOrUpdate"
import { BSInput } from "../../../core/dto/beem-shot/BSInput/BSInput"
import { CalculStatusEnum } from "../../../core/enum/calculStatusEnum"
import { useForm } from "../../../core/hooks/form/use-form"
import { nonZero, required } from "../../../core/hooks/form/validation"
import { resolveUrl } from "../../../core/services/http-service"

export type IBSModelForm = {
  version: string | undefined
}

export type IBSInputForm = {
  surfaceShab: number
  surfacePlancher: number
  empriseAuSol: number
  surfaceComble: number | undefined
  surfaceCombleB: boolean
}

function bimModelDtoToForm(bsBimModel: BSBimModel): IBSModelForm {
  return {
    version: bsBimModel.version === "" ? undefined : bsBimModel.version,
  }
}

function bsInputDtoToForm(bsInput: BSInput | undefined): IBSInputForm {
  return {
    surfaceShab: bsInput?.surfaceHabitable ?? 0,
    surfacePlancher: bsInput?.surfacePlancher ?? 0,
    empriseAuSol: bsInput?.empriseAuSol ?? 0,
    surfaceComble: bsInput?.surfaceComble ?? 0,
    surfaceCombleB: bsInput?.surfaceCombleB ?? false,
  }
}

function formToDto(bsInputForm: IBSInputForm, bsModelForm: IBSModelForm): BSBimModelCreateOrUpdate {
  return {
    version: bsModelForm.version ? bsModelForm.version : "0.0",
    surfaceShab: bsInputForm.surfaceShab,
    surfacePlancher: bsInputForm.surfacePlancher,
    empriseAuSol: bsInputForm.empriseAuSol,
    surfaceComble: bsInputForm.surfaceComble,
    surfaceCombleB: bsInputForm.surfaceCombleB,
    modelHashFile: "",
    fileName: "",
  }
}

type IProps = {
  bsModelFile: File | undefined
  setBsModelFile: Dispatch<SetStateAction<File | undefined>>
}

export default function BSUploader({ bsModelFile, setBsModelFile }: IProps): React.JSX.Element {
  const navigate = useNavigate()

  const { bsProject } = useContext(BSProjectContext)
  const { selectedVariant, updateCalculStatus } = useContext(BSVariantContext)
  const { bsBimModel } = useContext(BsModelContext)
  const { bsInput, refreshBSInput } = useContext(BSInputContext)
  const { sendBimModelInformation, setIsSubmitting, isFormUpdateRef, isModelUpdateRef } = useContext(BSModelImportContext)
  const openErrorSnackbar = useContext(ErrorContext)

  const [progress, setProgress] = useState<number>(100)

  const { bsProjectId, bsVariantId } = useParams()

  function submitNothingForBSInput(form: IBSInputForm): Promise<any> {
    throw new Error("Function not implemented.")
  }

  function submitNothingForBSBimModel(form: IBSModelForm): Promise<any> {
    throw new Error("Function not implemented.")
  }

  function additionalHandleChange(): void {
    isFormUpdateRef.current = true
  }

  const {
    form: bsModelForm,
    errors: errorsForBSBimModel,
    validate: validateForBSBimModel,
  } = useForm(bsBimModel, bimModelDtoToForm, [], submitNothingForBSBimModel, additionalHandleChange)

  const {
    form: bsInputForm,
    errors: errorsForBSInput,
    handleChange: handleChangeForBSInput,
    setRules: setRulesForBSInput,
    validate: validateForBSInput,
  } = useForm(
    bsInput,
    bsInputDtoToForm,
    [required("surfaceShab"), required("empriseAuSol"), required("surfacePlancher")],
    submitNothingForBSInput,
    additionalHandleChange
  )

  useEffect(() => {
    bsInputForm.surfaceCombleB
      ? setRulesForBSInput([
          required("surfaceShab"),
          required("empriseAuSol"),
          required("surfacePlancher"),
          required("surfaceComble"),
          nonZero("surfaceShab"),
          nonZero("empriseAuSol"),
          nonZero("surfacePlancher"),
          nonZero("surfaceComble"),
          {
            fieldName: "surfacePlancher",
            rule: () => bsInputForm.surfacePlancher > bsInputForm.surfaceShab,
            errorMessage: "La surface plancher doit être plus grande que la surface SHAB.",
          },
          {
            fieldName: "surfacePlancher",
            rule: () => bsInputForm.surfacePlancher >= bsInputForm.empriseAuSol,
            errorMessage: "La surface plancher doit être plus grande ou égal à l'emprise au sol.",
          },
        ])
      : setRulesForBSInput([
          required("surfaceShab"),
          required("empriseAuSol"),
          required("surfacePlancher"),
          nonZero("surfaceShab"),
          nonZero("empriseAuSol"),
          nonZero("surfacePlancher"),
          {
            fieldName: "surfacePlancher",
            rule: () => bsInputForm.surfacePlancher > bsInputForm.surfaceShab,
            errorMessage: "La surface plancher doit être plus grande que la surface SHAB.",
          },
          {
            fieldName: "surfacePlancher",
            rule: () => bsInputForm.surfacePlancher >= bsInputForm.empriseAuSol,
            errorMessage: "La surface plancher doit être plus grande ou égal à l'emprise au sol.",
          },
        ])
  }, [
    bsInputForm.empriseAuSol,
    bsInputForm.surfaceCombleB,
    bsInputForm.surfacePlancher,
    bsInputForm.surfaceShab,
    setRulesForBSInput,
  ])

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement> | FormEvent<HTMLDivElement>) => {
      event.preventDefault()
      if (!bsProject?.id) {
        openErrorSnackbar(new Error("Erreur: il n'y a pas de projet Beem Shot sélectionné"))
        return Promise.resolve()
      }
      if (!bsModelFile) {
        openErrorSnackbar(new Error("Erreur: il n'y a pas de maquette bim chargée"))
        return Promise.resolve()
      }

      if (validateForBSInput() && validateForBSBimModel()) {
        setIsSubmitting(true)
        return sendBimModelInformation(
          bsModelFile,
          formToDto(bsInputForm, bsModelForm),
          isModelUpdateRef.current,
          isFormUpdateRef.current
        )
          .then(() => {
            const promise1 = refreshBSInput()
            const promise2 = updateCalculStatus(CalculStatusEnum.WAITING_FOR_CONTROL)
            return Promise.all([promise1, promise2])
          })
          .then(() => {
            isModelUpdateRef.current = false
            isFormUpdateRef.current = false
            navigate(resolveUrl(pagesUrl.BEEM_SHOT_BIM_MODEL_CONTROL, [bsProjectId, bsVariantId]))
          })
          .finally(() => setIsSubmitting(false))
      } else {
        return Promise.resolve()
      }
    },
    [
      bsProject?.id,
      bsModelFile,
      validateForBSInput,
      validateForBSBimModel,
      openErrorSnackbar,
      setIsSubmitting,
      sendBimModelInformation,
      bsInputForm,
      bsModelForm,
      isModelUpdateRef,
      isFormUpdateRef,
      refreshBSInput,
      updateCalculStatus,
      navigate,
      bsProjectId,
      bsVariantId,
    ]
  )

  function onChange(modelFile: File): void {
    setBsModelFile(modelFile)
    isModelUpdateRef.current = true
    console.info('Changing BS File"')
  }

  function onChangeSwitch(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void {
    const e: ChangeEvent<HTMLInputElement> = {
      target: {
        id: event.target.id,
        checked,
        type: "checkbox",
      },
    } as ChangeEvent<HTMLInputElement>
    handleChangeForBSInput(e)
  }

  return (
    <Grow in>
      {bsInputForm && (
        <Box
          id="bsModelFormId"
          component="form"
          onSubmit={handleSubmit}
          boxShadow="2px 4px 20px 0px rgba(0, 0, 0, 0.05)"
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            borderRadius: "25px",
            p: 3,
          }}>
          <Box>
            <FileInput onChange={onChange} file={bsModelFile} progress={progress} fileType=".ifc,.ifczip" disabled={false} />
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextInput
                fieldName="version"
                form={bsModelForm}
                alternativeValue="1.0"
                label="Version de maquette"
                disabled
                errors={errorsForBSBimModel}
              />
            </Grid>
          </Grid>

          <DefaultTypography label="Renseignez les surfaces en m²" />

          <Grid container spacing={2} py={2}>
            <Grid item xs={4}>
              <NumberInput
                variant="outlined"
                backgroundColor="white"
                id="surfaceShab"
                label="Surface utile ou SHAB *"
                mode="event"
                handleEventChange={handleChangeForBSInput}
                value={bsInputForm.surfaceShab}
                error={errorsForBSInput}
                unit="m²"
              />
            </Grid>

            <Grid item xs={4}>
              <NumberInput
                variant="outlined"
                backgroundColor="white"
                id="surfacePlancher"
                label="Surface plancher *"
                mode="event"
                handleEventChange={handleChangeForBSInput}
                value={bsInputForm.surfacePlancher}
                error={errorsForBSInput}
                unit="m²"
              />
            </Grid>

            <Grid item xs={4}>
              <NumberInput
                variant="outlined"
                backgroundColor="white"
                id="empriseAuSol"
                label="Emprise au sol *"
                mode="event"
                handleEventChange={handleChangeForBSInput}
                value={bsInputForm.empriseAuSol}
                error={errorsForBSInput}
                unit="m²"
              />
            </Grid>
          </Grid>

          <Box display="flex" alignItems="center">
            <Switch
              id="surfaceCombleB"
              checked={bsInputForm.surfaceCombleB}
              // value={bsInputForm.surfaceCombleB}
              onChange={onChangeSwitch}
            />
            <DefaultTypography label="Le batiment comporte des combles aménagés" />
          </Box>

          {bsInputForm.surfaceCombleB && (
            <Grid container spacing={2} pt={2}>
              <Grid item xs={5}>
                <NumberInput
                  variant="outlined"
                  backgroundColor="white"
                  id="surfaceComble"
                  label="Surface des combles aménagés *"
                  mode="event"
                  handleEventChange={handleChangeForBSInput}
                  value={bsInputForm.surfaceComble}
                  error={errorsForBSInput}
                  unit="m²"
                />
              </Grid>
            </Grid>
          )}

          <Typography> Tous les champs sont obligatoires.</Typography>
        </Box>
      )}
    </Grow>
  )
}
