import { CircularProgress } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useParams } from "react-router"
import { useNavigate } from "react-router-dom"
import UserFormComponent from "../../../components/forms/user-invitation-form/user-form-component"
import { ErrorContext } from "../../../components/layout/error-snackbar"
import { SuccessContext } from "../../../components/layout/success-snackbar"
import { pagesUrl } from "../../../core/appConstants"
import { OrganizationContext } from "../../../core/context/organization/organization-context"
import { UserContext } from "../../../core/context/user/user-context"
import { User } from "../../../core/dto/user/user"
import { UserForm } from "../../../core/dto/user/user-form"
import { UserUpdateDto } from "../../../core/dto/user/user-update-dto"
import { OrganizationRoleEnum } from "../../../core/enum/user/organization-role-enum"
import { useUser } from "../../../core/hooks/use-user"
import { isMegaUser } from "../../../core/services/authentication-service"
import { roleSuperUserSelectOptions, roleUpdateSelectOptions } from "../../../core/services/user-service"

export default function UserUpdatePage(): React.JSX.Element {
  const navigate = useNavigate()
  const { userId: userIdToUpdate } = useParams()
  const { user: currentUser } = useContext(UserContext)
  const { adminUpdateUser, adminGetUser } = useUser()
  const { refreshOrganization } = useContext(OrganizationContext)
  const openSuccessSnackbar = useContext(SuccessContext)
  const openErrorSnackbar = useContext(ErrorContext)
  const [isLoadingUserToUpdate, setIsLoadingUserToUpdate] = useState<boolean>(true)
  const [userToUpdate, setUserToUpdate] = useState<User | undefined>(undefined)

  useEffect(() => {
    if (userIdToUpdate) {
      setIsLoadingUserToUpdate(true)
      adminGetUser(userIdToUpdate)
        .then((user) => {
          setUserToUpdate(user)
        })
        .finally(() => setIsLoadingUserToUpdate(false))
    }
  }, [userIdToUpdate, adminGetUser])

  function updateUser(userForm: UserForm): Promise<void> {
    if (userIdToUpdate === undefined) {
      openErrorSnackbar(new Error("Pas d'utilisateur sélectionné"))
      navigate(pagesUrl.MY_ORGANIZATION_PAGE)
      return Promise.resolve()
    }

    const userUpdateDto: UserUpdateDto = {
      cognitoUserId: userIdToUpdate,
      givenName: userForm.firstName,
      familyName: userForm.lastName,
      phone: userForm.phone,
      email: userForm.email,
      role: userForm.userOrganizationRole,
    }

    return adminUpdateUser(userUpdateDto)
      .then(() => refreshOrganization())
      .then(() => {
        openSuccessSnackbar("Utilisateur modifié")
        navigate(pagesUrl.MY_ORGANIZATION_PAGE)
      })
  }

  if (isLoadingUserToUpdate) {
    return <CircularProgress />
  } else {
    return (
      <UserFormComponent
        onSubmit={updateUser}
        selectOptions={isMegaUser(currentUser) ? roleUpdateSelectOptions : roleSuperUserSelectOptions}
        defaultRole={OrganizationRoleEnum.NORMAL_USER}
        initUser={userToUpdate}
      />
    )
  }
}
