import React, { useMemo, useState } from "react"
import { Children } from "../../../components/miscellianous/children"
import { CalculationCarbonAdditionalDataStore } from "../../store/calculation-carbon-additional-data-store"

export const CalculationCarbonAdditionalDataContext = React.createContext<CalculationCarbonAdditionalDataStore>(
  {} as CalculationCarbonAdditionalDataStore
)

export default function CalculationCarbonAdditionalDataProvider({ children }: Children): React.JSX.Element {
  const [data, setData] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | undefined>(undefined)

  const CalculationCarbonAdditionalDatatsStore = useMemo(
    () => new CalculationCarbonAdditionalDataStore(data, setData, loading, setLoading, error, setError),
    [error, loading, data]
  )

  return (
    <CalculationCarbonAdditionalDataContext.Provider value={CalculationCarbonAdditionalDatatsStore}>
      {children}
    </CalculationCarbonAdditionalDataContext.Provider>
  )
}
