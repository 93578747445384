import { Checkbox, CircularProgress, FormControlLabel, Grid } from "@mui/material"
import React, { ChangeEvent } from "react"

type IProps = {
  isSynchronizingCode: boolean
  isChecked: boolean
  handleUpdateIsSameValuesV1V2(e: ChangeEvent<HTMLInputElement>): void
  disabled: boolean
}

export default function CheckIsSame({
  isSynchronizingCode,
  isChecked,
  handleUpdateIsSameValuesV1V2,
  disabled,
}: IProps): React.JSX.Element {
  if (!isSynchronizingCode) {
    return (
      <Grid item xs={2}>
        <FormControlLabel
          control={<Checkbox checked={isChecked} onChange={handleUpdateIsSameValuesV1V2} disabled={disabled} />}
          label="Utiliser les valeurs de la BASE"
          labelPlacement="end"
        />
      </Grid>
    )
  } else {
    return <CircularProgress />
  }
}
