/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export function IconRseeGlobalDashboard({ width, height, ...props }: SvgIconProps): React.JSX.Element {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" width={width} height={height}>
      {/*  SVG from https://tablericons.com/ */}
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 4H10V12H4V4Z" stroke="#8398A6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4 16H10V20H4V16Z" stroke="#8398A6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14 12H20V20H14V12Z" stroke="#8398A6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14 4H20V8H14V4Z" stroke="#8398A6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </SvgIcon>
  )
}
