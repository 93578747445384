import { Grid } from "@mui/material"
import React, { useContext, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router"
import { GenericOption, SelectFilterTableau } from "../../components/autocomplete/select-filter-tableau"
import { Filter, TableauDashboard } from "../../components/tableau-dashboards/tableau-dashboard"
import { pagesUrl } from "../../core/appConstants"
import { OrganizationContext } from "../../core/context/organization/organization-context"
import { RseeDocument } from "../../core/dto/rsee/rsee-document"
import { RseeProject } from "../../core/dto/rsee/rsee-project"
import { ProjectStatusEnum, projectStatusToLabel } from "../../core/enum/projectStatusEnum"
import { useRsee } from "../../core/hooks/rsee/use-rsee"
import { useOrganization } from "../../core/hooks/use-organization"
import { resolveUrl } from "../../core/services/http-service"
import { FilterUpdateType, TableauViz } from "../../core/services/tableau-service"

const projectStatusList: GenericOption[] = [
  { id: ProjectStatusEnum.ARCHIVED, label: projectStatusToLabel(ProjectStatusEnum.ARCHIVED) },
  { id: ProjectStatusEnum.FINISHED, label: projectStatusToLabel(ProjectStatusEnum.FINISHED) },
  { id: ProjectStatusEnum.IN_PROGRESS, label: projectStatusToLabel(ProjectStatusEnum.IN_PROGRESS) },
]

const documentStatusList: GenericOption[] = [
  { id: ProjectStatusEnum.FINISHED, label: projectStatusToLabel(ProjectStatusEnum.FINISHED) },
  { id: ProjectStatusEnum.IN_PROGRESS, label: projectStatusToLabel(ProjectStatusEnum.IN_PROGRESS) },
]

enum filterName {
  PROJECT_ID = "projectId",
  DOCUMENT_ID = "documentId",
  PROJECT_STATUS = "projectStatus",
  DOCUMENT_STATUS = "documentStatus",
}

export default function OrganizationDashboardPage(): React.JSX.Element {
  const { fetchOrganizationDashboardUrl } = useOrganization()
  const { organization } = useContext(OrganizationContext)
  const [dashboardUrl, setDashboardUrl] = useState<string | undefined>(undefined)
  const navigate = useNavigate()

  const { fetchRseeProjectNameList, fetchRseeDocumentNameList } = useRsee()

  const [rseeProject, setRseeProject] = useState<RseeProject[]>([])
  const [rseeDocument, setRseeDocument] = useState<RseeDocument[]>([])

  const [addFilter, setAddFilter] = useState<Filter[]>([])

  const [selectedProjectId, setSelectedProjectId] = useState<string[]>([])
  const [selectedDocumentId, setSelectedDocumentId] = useState<string[]>([])
  const [selectedProjectStatus, setSelectedProjectStatus] = useState<string[]>([ProjectStatusEnum.IN_PROGRESS])
  const [selectedDocumentStatus, setSelectedDocumentStatus] = useState<string[]>([ProjectStatusEnum.IN_PROGRESS])

  const [error, setError] = useState<any>({})

  const viz: React.MutableRefObject<any> = useRef<any>(new TableauViz())

  useEffect(() => {
    fetchOrganizationDashboardUrl()
      .then((url) => {
        setDashboardUrl(url)
      })
      .catch(() => {
        navigate(resolveUrl(pagesUrl.PROJECTS_PAGE, [], {}))
      })
  }, [fetchOrganizationDashboardUrl, navigate])

  useEffect(() => {
    fetchRseeProjectNameList()
      .then((rseeProjectList: RseeProject[]) => {
        setRseeProject(rseeProjectList)
        setSelectedProjectId(rseeProjectList.map((project) => project.id))
        return rseeProjectList
      })
      .then(() => {
        fetchRseeDocumentNameList().then((rseeDocumentList: RseeDocument[]) => {
          const rseeDocumentListInitializer = rseeDocumentList.map((projectId) => projectId.id)

          setRseeDocument(rseeDocumentList)
          setSelectedDocumentId(rseeDocumentListInitializer)
          setAddFilter([
            { name: filterName.PROJECT_ID, value: rseeDocumentListInitializer },
            { name: filterName.DOCUMENT_ID, value: rseeDocumentListInitializer },
          ])
        })
      })
  }, [])

  function addRseeProject(value: string[]): void {
    setError({ ...error, comboRseeProject: undefined })
    setSelectedProjectId(value)

    if (selectedDocumentId.length !== 0) {
      const updateSelectedDocumentId = selectedDocumentId.filter((documentId) => {
        const rseeDoc = rseeDocument.find((document) => document.id === documentId)
        return rseeDoc !== undefined && value.includes(rseeDoc.projectId)
      })

      setSelectedDocumentId(updateSelectedDocumentId)
    }
  }

  function addRseeDocument(value: string[]): void {
    setError({ ...error, comboRseeDocument: undefined })
    setSelectedDocumentId(value)
  }

  function addProjectStatus(value: string[]): void {
    setError({ ...error, comboStatusRsee: undefined })
    setSelectedProjectStatus(value)
  }

  function addDocumentStatus(value: string[]): void {
    setError({ ...error, comboStatusRseeDocument: undefined })
    setSelectedDocumentStatus(value)
  }

  function filters(): React.ReactNode {
    return (
      <Grid container>
        <Grid item xs={2} />
        <Grid item xs={2}>
          <SelectFilterTableau
            id="comboStatusRsee"
            label="STATUT DU PROJET"
            options={projectStatusList.map((status: GenericOption) => ({
              id: status.id,
              label: status.label,
            }))}
            value={selectedProjectStatus}
            tooltip=""
            onChange={addProjectStatus}
            error={!!error.comboStatusRsee}
            helperText={error.comboStatusRsee}
            applySync={async () => {
              if (selectedProjectStatus.length === 0) {
                const newError: Record<string, any> = {}
                newError.comboStatusRsee = "Sélectionner au moins une valeur"
                setError(newError)
                return
              }
              await viz.current.workbook.activeSheet.applyFilterAsync(
                filterName.PROJECT_STATUS,
                selectedProjectStatus,
                FilterUpdateType.Replace
              )
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <SelectFilterTableau
            id="comboRseeProject"
            label="CHOISIR LE(S) PROJET(S)"
            options={rseeProject.map((project: RseeProject) => ({
              id: project.id,
              label: project.projectName,
            }))}
            value={selectedProjectId}
            tooltip=""
            onChange={addRseeProject}
            error={!!error.comboRseeProject}
            helperText={error.comboRseeProject}
            applySync={async () => {
              if (selectedProjectId.length === 0) {
                const newError: Record<string, any> = {}
                newError.comboRseeProject = "Sélectionner au moins une valeur"
                setError(newError)
                return
              }

              if (selectedDocumentId.length !== 0) {
                await viz.current.workbook.activeSheet.applyFilterAsync(
                  filterName.PROJECT_ID,
                  selectedProjectId,
                  FilterUpdateType.Replace
                )
                await viz.current.workbook.activeSheet.applyFilterAsync(
                  filterName.DOCUMENT_ID,
                  selectedDocumentId,
                  FilterUpdateType.Replace
                )
              } else {
                await viz.current.workbook.activeSheet.applyFilterAsync(
                  filterName.PROJECT_ID,
                  selectedProjectId,
                  FilterUpdateType.Replace
                )
              }
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <SelectFilterTableau
            id="comboStatusRseeDocument"
            label="STATUT DU DOCUMENT"
            options={documentStatusList.map((status: GenericOption) => ({
              id: status.id,
              label: status.label,
            }))}
            value={selectedDocumentStatus}
            tooltip=""
            onChange={addDocumentStatus}
            error={!!error.comboStatusRseeDocument}
            helperText={error.comboStatusRseeDocument}
            applySync={async () => {
              if (selectedDocumentStatus.length === 0) {
                const newError: Record<string, any> = {}
                newError.comboStatusRseeDocument = "Sélectionner au moins une valeur"
                setError(newError)
                return
              }

              await viz.current.workbook.activeSheet.applyFilterAsync(
                filterName.DOCUMENT_STATUS,
                selectedDocumentStatus,
                FilterUpdateType.Replace
              )
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <SelectFilterTableau
            disabled={selectedProjectId.length === 0}
            id="comboRseeDocument"
            label="CHOISIR LE(S) DOCUMENT(S)"
            options={rseeDocument
              .filter((r) => selectedProjectId.includes(r.projectId))
              .map((document: RseeDocument) => ({
                id: document.id,
                label: document.documentName,
              }))}
            value={selectedDocumentId}
            tooltip={selectedProjectId.length === 0 ? "Il faut d'abord sélectionner un projet" : ""}
            onChange={addRseeDocument}
            error={!!error.comboRseeDocument}
            helperText={error.comboRseeDocument}
            applySync={async () => {
              if (selectedDocumentId.length === 0) {
                const newError: Record<string, any> = {}
                newError.comboRseeDocument = "Sélectionner au moins une valeur"
                setError(newError)
                return
              }

              await viz.current.workbook.activeSheet.applyFilterAsync(
                filterName.DOCUMENT_ID,
                selectedDocumentId,
                FilterUpdateType.Replace
              )
            }}
          />
        </Grid>
        <Grid item xs={2} />
      </Grid>
    )
  }

  return (
    <>
      {false && filters()}
      <Grid container>
        <Grid item xs={12} />
        <Grid item xs={12}>
          {dashboardUrl && organization?.id && (
            <TableauDashboard
              dashboardUrl={dashboardUrl}
              isDataReady
              tableauParameter={[{ name: "0P_Organization_Id", value: [organization.id] }]}
              filterToAdd={addFilter}
              vizRef={viz}
            />
          )}
        </Grid>
      </Grid>
    </>
  )
}
