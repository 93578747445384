import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined"
import { Box, Grid, Toolbar, Typography } from "@mui/material"
import React, { useContext, useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { theme } from "../../../theme"
import { appConstants, pagesUrl } from "../../core/appConstants"
import { RseeProjectContext } from "../../core/context/beem-pilot/rsee/rsee-project-context"
import { BSProjectContext } from "../../core/context/beem-shot/BSProject/BSProjectContext"
import { AppNavBarContext } from "../../core/context/nav-bar/AppNavBarContext"
import { OrganizationContext } from "../../core/context/organization/organization-context"
import { AppSideBarContext, UrlStateEnum } from "../../core/context/side-bar/side-bar-context"
import { UserContext, UserStore } from "../../core/context/user/user-context"
import { isUltimateUser } from "../../core/services/authentication-service"
import { ProjectCardStatus } from "../card/project-status-card"
import MenuNotification from "../notifications/notifications-menu"
import { SettingsMenu } from "../settings/settings-menu"
import { DefaultTypography } from "../typography/default-typography"
import "./app-navbar-v2.scss"

export function DefaultNavbar(): React.JSX.Element {
  const location = useLocation()
  const { user } = React.useContext<UserStore>(UserContext)
  const { organization } = useContext(OrganizationContext)
  const { title, icon, previousUrl, showProjectStatus, pagination } = useContext(AppNavBarContext)
  const { bsProject } = useContext(BSProjectContext)
  const { rseeProject } = useContext(RseeProjectContext)
  const { urlState } = useContext(AppSideBarContext)

  const isHomePage: boolean = useMemo(() => location.pathname.endsWith(pagesUrl.HOME), [location.pathname])

  const navigate = useNavigate()

  /* Definition initial utilisateur */
  const fullName = user ? user.givenName + appConstants.utils.space + user.familyName : ""
  const ultimateUser = isUltimateUser(user)

  const today = new Date()

  const formattedDate = today.toLocaleDateString("fr-FR", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  })

  return (
    <Toolbar disableGutters sx={{ color: "black", ...theme.mixins.toolbar }}>
      <Grid container columnSpacing={1} display="flex" justifyContent="space-between" alignItems="center">
        <Grid item xs={9} sx={{ display: "flex", alignItems: "center" }}>
          {!isHomePage && <ArrowBackOutlinedIcon onClick={() => navigate(previousUrl)} sx={{ cursor: "pointer" }} />}

          <Box
            display="flex"
            alignItems="center"
            sx={{ borderRadius: 2, background: "#b7ffe3", p: 1, m: 1, border: 3, borderColor: "white" }}>
            {icon}
          </Box>

          {urlState === UrlStateEnum.HOME_PAGE ? (
            <Box display="flex" flexDirection="column" width="100%">
              <Box display="flex" flexDirection="row" alignItems="center" alignContent="center" columnGap={1}>
                <Typography variant="h6">{`Bonjour ${user?.givenName},`}</Typography>
              </Box>

              <Box display="flex" flexDirection="row" alignItems="center" columnGap={2}>
                <Typography variant="body2" fontSize={12}>
                  {`Aujourd'hui ${formattedDate}`}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box display="flex" flexDirection="column" width="100%">
              <Box display="flex" flexDirection="row" alignItems="center" alignContent="center" columnGap={1}>
                {pagination.map((item) => (
                  <Box key={item} display="flex" flexDirection="row" gap={1}>
                    <DefaultTypography
                      label={item}
                      color="#08CA8F"
                      fontSize="14px"
                      fontWeight={400}
                      textDecoration="underline"
                    />
                    <DefaultTypography label=" > " color="#08CA8F" fontSize="14px" fontWeight={400} textDecoration="none" />
                  </Box>
                ))}
              </Box>

              <Box display="flex" flexDirection="row" alignItems="center" columnGap={2}>
                <DefaultTypography label={title} fontSize="20px" lineHeight="20px" fontWeight={700} />
                {showProjectStatus && bsProject?.status && <ProjectCardStatus status={bsProject?.status} />}
                {showProjectStatus && rseeProject?.projectStatus && (
                  <ProjectCardStatus status={rseeProject?.projectStatus} />
                )}
              </Box>
            </Box>
          )}
        </Grid>
        <Grid item xs={3} sx={{ display: "flex", padding: "0% 1%", alignItems: "center", justifyContent: "flex-end" }}>
          {!ultimateUser && <MenuNotification />}
          <SettingsMenu />
          <Box display="flex" flexDirection="column">
            <DefaultTypography label={fullName} color="#0BE2A0" fontWeight={700} fontSize="14px" />
            <DefaultTypography label={organization?.businessName ?? ""} />
          </Box>
        </Grid>
      </Grid>
    </Toolbar>
  )
}
