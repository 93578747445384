import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined"
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined"
import { Box, Grid } from "@mui/material"
import React from "react"
import { Link } from "react-router-dom"
import { pagesUrl } from "../../../core/appConstants"
import { RseeProjectCardDto } from "../../../core/dto/rsee/rsee-project-card"
import { formatToFrench } from "../../../core/services/date-service"
import { resolveUrl } from "../../../core/services/http-service"
import { IconFileAnalytics } from "../../custom-icons/icon-file-analytics"
import IconFolder from "../../custom-icons/icon-folder"
import { DefaultTypography } from "../../typography/default-typography"
import { ProjectCardStatus } from "../project-status-card"

type IProps = {
  rseeProjectCard: RseeProjectCardDto
}

export function RseeProjectCard({ rseeProjectCard }: Readonly<IProps>): React.JSX.Element {
  function getNavigateUrl(): string {
    return resolveUrl(pagesUrl.BEEM_PILOT_PROJECT, [rseeProjectCard.projectId])
  }

  return (
    <Link to={getNavigateUrl()} style={{ width: "100%", minHeight: 100, textDecoration: "none", color: "inherit" }}>
      <Grid
        container
        display="flex"
        alignItems="center"
        flexDirection="column"
        rowGap={1}
        width="100%"
        sx={{
          backgroundColor: "#fff",
          borderRadius: 3,
          p: 3,
          boxShadow: "2px 4px 20px 0px rgba(0, 0, 0, 0.05)",
        }}>
        <Grid container item display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <Grid item xs={1}>
            <Box
              display="flex"
              alignContent="center"
              justifyContent="center"
              borderRadius={2}
              p={1}
              px={3}
              sx={{ background: "#E6F0FB" }}>
              <IconFolder style={{ fontSize: "30px" }} />
            </Box>
          </Grid>
          <Grid display="flex" justifyContent="flex-end" item xs={3}>
            <ProjectCardStatus status={rseeProjectCard.projectStatus} />
          </Grid>
        </Grid>

        <Grid container display="flex" justifyContent="left" alignItems="center" width="100%">
          <DefaultTypography label={rseeProjectCard.projectName} fontSize="16px" lineHeight="24px" fontWeight={600} />
        </Grid>

        <Grid container flexGrow={1} display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            sx={{ border: 2, borderColor: "#F5F8F8", borderRadius: 2, p: 1 }}>
            <DefaultTypography label="N° Affaire" fontSize="11px" lineHeight="26.5px" fontWeight={400} color="#8398A6" />
            <DefaultTypography
              label={rseeProjectCard.businessCode || ""}
              fontSize="12px"
              lineHeight="18px"
              fontWeight={400}
            />
          </Grid>
        </Grid>

        <Grid container display="flex" justifyContent="space-between" width="100%" alignItems="center">
          <Box>
            <Box display="flex" flexDirection="row" alignItems="center">
              <HomeWorkOutlinedIcon />
              <Box sx={{ pl: 1 }}>
                <DefaultTypography
                  label={rseeProjectCard.projectOrganizationBusinessName || ""}
                  fontSize="12px"
                  lineHeight="18px"
                  fontWeight={400}
                />
              </Box>
            </Box>

            <DefaultTypography
              label={`Mise à jour le ${formatToFrench(new Date(rseeProjectCard.lastModifiedDate))}`}
              fontSize="12px"
              lineHeight="18px"
              fontWeight={400}
              color="#8398A6"
            />
          </Box>

          <Box display="flex" flexDirection="row" gap={2}>
            <Box display="flex" alignItems="center" gap={1} sx={{ background: "#5376BD", px: 1, py: 0.5, borderRadius: 2 }}>
              <IconFileAnalytics style={{ color: "#ffffff" }} />
              <DefaultTypography
                label={rseeProjectCard.numberDocument.toString() ?? "0"}
                fontSize="14px"
                lineHeight="20.02px"
                fontWeight={400}
                color="#ffffff"
              />
            </Box>

            <Box display="flex" alignItems="center" gap={1} sx={{ background: "#000", px: 1, py: 0.5, borderRadius: 2 }}>
              <PersonOutlinedIcon fontSize="small" sx={{ color: "#fff" }} />
              <DefaultTypography
                label={rseeProjectCard.teamNumber ?? "1"}
                fontSize="14px"
                lineHeight="20.02px"
                fontWeight={400}
                color="#ffffff"
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Link>
  )
}
