import { Dispatch, SetStateAction } from "react"
import { IfcViewerAPI } from "web-ifc-viewer"
import { viewerConstants } from "./constants/ViewerConstants"
import { ViewerTypes } from "./enums/ViewerTypes"
import { RayCastingHelper } from "./helpers/RayCastingHelper"
import { CameraManager } from "./managers/CameraManager"
import { MeasurementManager } from "./managers/MeasurementManager"
import { SectionsManager } from "./managers/SectionsManager"
import { SubsetManagerProps, SubsetsManager } from "./managers/SubsetsManager"
import { IfcSelection } from "./models/IfcSelection"
import { SubsetInfo } from "./models/SubsetInfo"
import { DimensionFactory } from "./models/measurement/DimensionFactory"
import { DimensionCommand } from "./models/measurement/base/DimensionCommand"
import { PointCoordinatesCommand } from "./models/measurement/commands/PointCoordinatesCommand"
import { CodeExtraitDisplay } from "../../core/dto/code-extrait/CodeExtraitDisplay"

export interface ViewerIFCManagerProps {
  type: ViewerTypes
  codesExtraits: CodeExtraitDisplay[]
  setSelectedCodeExtrait: (code: CodeExtraitDisplay, disableViewerHilight?: boolean) => void
  viewer: IfcViewerAPI
  container: HTMLElement | null
  modelId: number
  showMessage: (message: string) => void
  setCodeManquantElements?: (codeManquantElements: SubsetInfo[]) => void
  setSelectedCodeManquantElement?: (codeManquant: SubsetInfo) => void
  setContentDrawer?: Dispatch<SetStateAction<string>>
  updateIsInHidingIrellevantCodesState?: (isHide: boolean) => void
  updateIsInHideState?: (isHide: boolean) => void
  setViewerBarProgress?: Dispatch<SetStateAction<number>>
  setProgress?: Dispatch<SetStateAction<number>>
}

/**  ViewerIFCManager is a class that manages the IFC viewer features */
export class ViewerIFCManager {
  viewer: IfcViewerAPI
  container: HTMLElement | null
  modelId: number
  /** Subsets manager */
  subsetsManager: SubsetsManager
  /** Camera controller manager */
  cameras: CameraManager
  sections: SectionsManager
  measurements: MeasurementManager
  mousePushedTimeMilliseconds = 0
  setContentDrawer?: Dispatch<SetStateAction<string>>
  showMessage: (message: string) => void

  private handleModelClickBound = this.handleModelClick.bind(this)
  private handleModelHoverBound = this.handleModelHover.bind(this)
  private handleModelMouseUpBound = this.handleModelMouseUp.bind(this)
  private handleModelMouseWheelBound = this.handleModelMouseWheel.bind(this)
  private handleModelMouseDownBound = this.handleModelMouseDown.bind(this)
  private handleDoubleClickBound = this.handleDoubleClick.bind(this)
  private handleKeyDownBound = this.handleKeyDown.bind(this)

  constructor(props: ViewerIFCManagerProps) {
    this.viewer = props.viewer
    this.container = props.container
    this.cameras = new CameraManager(props.viewer, props.container)
    this.sections = new SectionsManager(props.viewer, props.showMessage)
    this.measurements = new MeasurementManager(props.viewer, props.showMessage)
    this.subsetsManager = new SubsetsManager({ ...props, sectionsManager: this.sections } as SubsetManagerProps)
    this.modelId = props.modelId
    this.setContentDrawer = props.setContentDrawer
    this.showMessage = props.showMessage
    this.attachEvents()
  }

  /**
   * Handle the click on the model elements so we can find which subset and code extrait is being clicked
   */
  async handleModelClick(event: MouseEvent): Promise<void> {
    if (this.mousePushedTimeMilliseconds < viewerConstants.clickTimeoutMilliseconds)
      if (this.measurements.rayCastGeometry && !this.sections.isCreatingSectionPlane) {
        this.measurements.GeometryRayCaster?.mouseDown(event)
      } else {
        this.getSelectedExpressId()
        if (this.sections.isCreatingSectionPlane) {
          this.measurements.abortDimentioning()
          this.viewer.clipper.createPlane()
          this.sections.isCreatingSectionPlane = false
        }
      }

    this.sections.deleteAllHelperPlanes()
  }

  async handleDoubleClick(): Promise<void> {
    this.select()
  }

  async handleModelHover(): Promise<void> {
    if (this.measurements.rayCastGeometry) {
      RayCastingHelper.mouseMove()
    } else {
      await this.viewer?.IFC.selector.prePickIfcItem()
    }
  }

  async handleModelMouseUp(): Promise<void> {
    this.mousePushedTimeMilliseconds = new Date().getTime() - this.mousePushedTimeMilliseconds
    if (this.sections.isActive()) {
      PointCoordinatesCommand.hidePointsOutsideOfClippingView(
        this.viewer?.context.getClippingPlanes(),
        this.viewer?.context.getScene()
      )
      DimensionFactory.hideLinesOutsideOfClippingView(
        this.viewer?.context.getClippingPlanes(),
        this.viewer?.context.getScene()
      )
    }
  }

  async handleModelMouseDown(): Promise<void> {
    this.mousePushedTimeMilliseconds = new Date().getTime()
  }

  async handleModelMouseWheel(): Promise<void> {
    if (this.measurements.rayCastGeometry) {
      RayCastingHelper.mouseWheel()
    }

    PointCoordinatesCommand.addedPoints.forEach((point) => {
      point.assosiatedObject.updateTextSize()
    })
    DimensionCommand.addedLines.forEach((line) => {
      line.assosiatedObject.updateTextSize()
    })
  }

  async handleKeyDown(event: KeyboardEvent): Promise<void> {
    // Check if the combination is Shift+C
    if (event.shiftKey && event.code === "KeyC") {
      if (this.subsetsManager.lastSelection) this.showProperties(this.subsetsManager.lastSelection)
      else this.showMessage("Veuillez sélectionner un élément d'abord afin d'afficher ses propriétés")
    } else if (event.key === "Escape" || event.code === "Escape") {
      this.measurements.abortDimentioning()
    }
  }

  async showProperties(selection: IfcSelection): Promise<void> {
    const props = await this.viewer.IFC.getProperties(selection.modelID, selection.expressID, true, false)
    if (this.setContentDrawer) this.setContentDrawer(JSON.stringify(props, null, 2))
  }

  async select(): Promise<void> {
    const selection = await this.getSelectedExpressId()
    this.subsetsManager.handleModelSelection(selection?.expressID)
    this.sections.deleteAllHelperPlanes()
  }

  /**
   * get the expressID of the element the user clicked on the viewer
   * @returns the expressID of the selected element
   */
  async getSelectedExpressId(): Promise<IfcSelection | undefined> {
    this.viewer.IFC.selector.unpickIfcItems()
    const result = await this.viewer.IFC.selector.pickIfcItem(false)
    if (!result) {
      return undefined
    }
    this.viewer.IFC.selector.unpickIfcItems()
    const selection = { modelID: result.modelID, expressID: result.id } as IfcSelection
    this.subsetsManager.lastSelection = selection
    return selection
  }

  deattachEvents(): void {
    this.container?.removeEventListener("click", this.handleModelClickBound)
    this.container?.removeEventListener("mousemove", this.handleModelHoverBound)
    this.container?.removeEventListener("mouseup", this.handleModelMouseUpBound)
    this.container?.removeEventListener("wheel", this.handleModelMouseWheelBound)
    this.container?.removeEventListener("mousedown", this.handleModelMouseDownBound)
    this.container?.removeEventListener("dblclick", this.handleDoubleClickBound)
    window.removeEventListener("keydown", this.handleKeyDownBound)
  }

  attachEvents(): void {
    this.container?.addEventListener("click", this.handleModelClickBound)
    this.container?.addEventListener("mousemove", this.handleModelHoverBound)
    this.container?.addEventListener("mouseup", this.handleModelMouseUpBound)
    this.container?.addEventListener("wheel", this.handleModelMouseWheelBound)
    this.container?.addEventListener("mousedown", this.handleModelMouseDownBound)
    this.container?.addEventListener("dblclick", this.handleDoubleClickBound)
    window.addEventListener("keydown", this.handleKeyDownBound)
  }

  dispose(): void {
    this.measurements.deleteAllPointCoordinates(this.modelId, true)
    this.subsetsManager.dispose()
    this.subsetsManager.subsets = []
    this.deattachEvents()
  }
}
