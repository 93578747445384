import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import React, { ReactNode, useState } from "react"
import CancelButton from "../buttons/cancel-button/cancel-button"
import { ValidateButton } from "../buttons/validate-button/validate-button"

type IProps = {
  id: string
  title: string
  contentText: ReactNode
  handleClose: () => void
  action: () => Promise<any>
  open: boolean
}

export function ConfirmationDialog({ id, title, contentText, handleClose, action, open }: IProps): React.JSX.Element {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  function onAction(): void {
    setIsSubmitting(true)
    action()
      .then(() => {
        handleClose()
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  return (
    <Box>
      <Dialog open={open} onClose={handleClose} aria-describedby={id}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id={id}>{contentText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box sx={{ px: 1 }}>
            <CancelButton action={handleClose} label="Annuler" />
          </Box>
          <ValidateButton action={onAction} label="Confirmer" isSubmiting={isSubmitting} />
        </DialogActions>
      </Dialog>
    </Box>
  )
}
