import { appConstants } from "../../appConstants"

export function required<T>(fieldName: keyof T): ValidationRule<T> {
  return {
    fieldName,
    rule: (value: any) => value !== undefined && value !== null && value !== "",
    errorMessage: "Ce champ est obligatoire",
  }
}

export function nonZero<T>(fieldName: keyof T): ValidationRule<T> {
  return {
    fieldName,
    rule: (value: any) => value !== 0,
    errorMessage: "Ce champ ne doit pas être égal à zéro",
  }
}

export function email<T>(fieldName: keyof T): ValidationRule<T> {
  return {
    fieldName,
    rule: (value: any) => {
      const isString = !!value && typeof value === "string"
      if (isString) {
        return appConstants.regex.email.test(value)
      } else {
        return false
      }
    },
    errorMessage: "Le format de l'email est incorrect",
  }
}

export function phone<T>(fieldName: keyof T, isRequired: boolean): ValidationRule<T> {
  return {
    fieldName,
    rule: (value: any) => {
      if (!isRequired && !value) {
        // If not required, empty field is ok
        return true
      } else {
        const isString = !!value && typeof value === "string"
        if (isString) {
          const phoneNumber = value
          if (phoneNumber.startsWith("+") && (phoneNumber.length === 12 || phoneNumber.length === 13)) {
            return appConstants.regex.onlyDigits.test(phoneNumber.substring(1))
          } else {
            return phoneNumber.length === 10 && appConstants.regex.onlyDigits.test(phoneNumber)
          }
        } else {
          return false
        }
      }
    },
    errorMessage: "Le format du numéro de téléphone est incorrect",
  }
}

export function zipCode<T>(fieldName: keyof T): ValidationRule<T> {
  return {
    fieldName,
    rule: (value: any) => appConstants.regex.zipCode.test(value),
    errorMessage: "Ce champ est obligatoire",
  }
}

export type ValidationRule<T> = {
  fieldName: keyof T
  errorMessage: string
  rule(field: any): boolean
}
