import { Box, debounce, Grid } from "@mui/material"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"
import MaterialLibDeclarationTypeContextProvider from "../../core/context/material/material-lib-context"
import { DeclarationTypeEnum } from "../../core/enum/declarationTypeEnum"
import { MaterialLibPage } from "../../core/enum/materialLibPage"
import { useFetchMaterialDeclarationFilter as useFetchIniesMaterialDeclarationFilter } from "../../core/hooks/material-lib/use-fetch-material-lib-declaration-filter"
import { useOrganizationRecordsFiltered } from "../../core/hooks/material-lib/use-organisation-records-filtered"
import { useProjectRecordsFiltered } from "../../core/hooks/material-lib/use-project-records-filtered"
import InventoryCard from "./components/inventory-card"
// eslint-disable-next-line import/no-cycle
import {
  MaterialLibOrganizationIndicatorContext,
  OrganizationIndicatorStore,
} from "../../core/context/material/material-lib-organization-indicator-context"
import {
  MaterialLibProjectIndicatorContext,
  ProjectMaterialIndicatorStore,
} from "../../core/context/material/material-lib-project-indicator-context"
import MaterialRecordCreation from "../../core/dto/material/material-record-creation"
import { MaterialLibIndicator } from "../../core/dto/organization/organization-indicator"
import { DeclarationFilter } from "../../core/enum/declarationFilter"
import { useMaterialLibOrganizationIndicator } from "../../core/hooks/material-lib/use-fetch-material-lib-organization-indicator"
import { useProjectMaterialIndicator } from "../../core/hooks/material-lib/use-fetch-project-material-lib-indicator"
import { MaterialCardModal } from "./components/material-card-modal"
import { MaterialFilterForm } from "./material-filter-form"
import MaterialPageHeader from "./material-page-header"
import MaterialPageTable from "./material-page-table"
import "./material-page.css"
import MaterialSearchByProductName from "./material-search-by-product-name"
import { IniesRecord } from "../../core/dto/material/IniesRecord"
import { MaterialRecord } from "../../core/dto/material/MaterialRecord"

type IProps = {
  isWriting: boolean
}

export function MaterialPage({ isWriting }: Readonly<IProps>): React.JSX.Element {
  const location = useLocation()

  const { organizationIndicator: materialOrganizationData } = useContext<OrganizationIndicatorStore>(
    MaterialLibOrganizationIndicatorContext
  )
  const { projectMaterialIndicator: materialProjectData } = useContext<ProjectMaterialIndicatorStore>(
    MaterialLibProjectIndicatorContext
  )

  const { fetchMaterialOrganizationIndicator } = useMaterialLibOrganizationIndicator()
  const { fetchMaterialProjectIndicator } = useProjectMaterialIndicator()
  const { iniesData: nomenclatureFilterIniesData } = useFetchIniesMaterialDeclarationFilter()
  const {
    search: searchOrganization,
    materialRecords: organizationsRecords,
    reset: resetMaterialOrganization,
    limit: limitOrganization,
    setLimit: setLimitOrganization,
  } = useOrganizationRecordsFiltered()

  const {
    search: searchProject,
    materialRecords: projectRecords,
    reset: resetMaterialProject,
    limit: limitProject,
    setLimit: setLimitProject,
  } = useProjectRecordsFiltered()

  const [selectedLvl1, setSelectedLvl1] = useState<string>()
  const [selectedLvl2, setSelectedLvl2] = useState<string>()
  const [selectedLvl3, setSelectedLvl3] = useState<string>()
  const [selectedLvl4, setSelectedLvl4] = useState<string>()
  const [responsibleOrganism, setResponsibleOrganism] = useState<string>()
  const [selectedTypologieDeclaration, setSelectedTypologieDeclaration] = useState<string>()
  const [search, setSearch] = useState<string | undefined>(undefined)
  const [selectedRow, setSelectedRow] = useState<IniesRecord | MaterialRecord | undefined>(undefined)

  const [pageMaterial, setPageMaterial] = useState(0)

  const [lastMaterial, setLastMaterial] = useState<string | undefined>(undefined)
  const [lastPrevMaterial, setLastPrevMaterial] = useState<string[]>([])
  const [isEditable, setIsEditable] = useState<boolean>(isWriting)

  const [materialCreation, setMaterialCreation] = useState<MaterialRecordCreation>(new MaterialRecordCreation())

  const [openAddMaterial, setOpenAddMaterial] = useState(false)
  const [openCard, setOpenCard] = useState(false)

  const typeMaterialLib = useMemo<MaterialLibPage>(() => getTypeMaterialLib(), [location.pathname])
  const [selectedLvl0, setSelectedLvl0] = useState<DeclarationFilter | undefined>(undefined)

  const getRowPerPage = useCallback(
    (): number => (typeMaterialLib === MaterialLibPage.MATERIAL_LIB_PROJECT ? limitProject : limitOrganization),
    [limitOrganization, limitProject, typeMaterialLib]
  )

  const setRowsPerPage = useCallback(
    (newLimit: number): void =>
      typeMaterialLib === MaterialLibPage.MATERIAL_LIB_PROJECT ? setLimitProject(newLimit) : setLimitOrganization(newLimit),
    [setLimitOrganization, setLimitProject, typeMaterialLib]
  )

  function getTypeMaterialLib(): MaterialLibPage {
    return location.pathname.endsWith("project-material-lib")
      ? MaterialLibPage.MATERIAL_LIB_PROJECT
      : MaterialLibPage.MATERIAL_LIB_ORGANIZATION
  }

  const searchOrganizationsNomenclatures = useCallback(
    (newSelectedLvl0: DeclarationFilter | undefined, containedInName?: string, lastFdesName?: string) =>
      searchOrganization({
        filter: {
          containedInName,
          classificationLvl0: newSelectedLvl0,
          classificationLvl1: Number(selectedLvl1) || undefined,
          classificationLvl2: Number(selectedLvl2) || undefined,
          classificationLvl3: Number(selectedLvl3) || undefined,
          classificationLvl4: Number(selectedLvl4) || undefined,
          responsibleOrganism: responsibleOrganism ?? undefined,
          declarationType: selectedTypologieDeclaration ?? undefined,
        },
        lastFdesName,
      }).then((record) => {
        if (record?.length) {
          setLastMaterial(record[record.length - 1].fdesName)
        }
      }),
    [
      responsibleOrganism,
      searchOrganization,
      selectedLvl1,
      selectedLvl2,
      selectedLvl3,
      selectedLvl4,
      selectedTypologieDeclaration,
    ]
  )

  const searchProjectNomenclatures = useCallback(
    (newSelectedLvl0: DeclarationFilter | undefined, containedInName?: string, lastFdesName?: string) =>
      searchProject({
        filter: {
          containedInName,
          classificationLvl0: newSelectedLvl0,
          classificationLvl1: Number(selectedLvl1) || undefined,
          classificationLvl2: Number(selectedLvl2) || undefined,
          classificationLvl3: Number(selectedLvl3) || undefined,
          classificationLvl4: Number(selectedLvl4) || undefined,
          responsibleOrganism: responsibleOrganism ?? undefined,
          declarationType: selectedTypologieDeclaration ?? undefined,
        },
        lastFdesName,
      }).then((records: MaterialRecord[]) => {
        if (records?.length) {
          setLastMaterial(records[records.length - 1].fdesName)
        }
      }),
    [
      responsibleOrganism,
      searchProject,
      selectedLvl1,
      selectedLvl2,
      selectedLvl3,
      selectedLvl4,
      selectedTypologieDeclaration,
    ]
  )

  useEffect(() => {
    if (openAddMaterial) {
      setLastMaterial(undefined)
      setLastPrevMaterial([])
      setPageMaterial(0)
    } else if (location.pathname.endsWith("project-material-lib")) {
      searchProjectNomenclatures(selectedLvl0)
      fetchMaterialProjectIndicator()
    } else if (location.pathname.endsWith("material-lib")) {
      searchOrganizationsNomenclatures(selectedLvl0)
      fetchMaterialOrganizationIndicator()
      setIsEditable(isWriting)
    } else if (location.pathname.endsWith("material-lib/admin")) {
      searchOrganizationsNomenclatures(selectedLvl0)
      fetchMaterialOrganizationIndicator()
      setIsEditable(false)
    }
  }, [
    openAddMaterial,
    location.pathname,
    selectedLvl0,
    searchProjectNomenclatures,
    fetchMaterialProjectIndicator,
    searchOrganizationsNomenclatures,
    fetchMaterialOrganizationIndicator,
    isWriting,
  ])

  function resetFilters(): void {
    setSelectedLvl0(undefined)
    setSelectedLvl1(undefined)
    setSelectedLvl2(undefined)
    setSelectedLvl3(undefined)
    setSelectedLvl4(undefined)
    setResponsibleOrganism(undefined)
    setSelectedTypologieDeclaration(undefined)
  }

  const handleSearch = useCallback(
    (value: string | undefined) => {
      setPageMaterial(0)
      setLastMaterial(undefined)
      setSearch(value)

      if (typeMaterialLib === MaterialLibPage.MATERIAL_LIB_ORGANIZATION) {
        resetMaterialOrganization()
        searchOrganizationsNomenclatures(selectedLvl0, value)
      } else if (typeMaterialLib === MaterialLibPage.MATERIAL_LIB_PROJECT) {
        resetMaterialProject()
        searchProjectNomenclatures(selectedLvl0, value)
      }
    },
    [
      selectedLvl0,
      resetMaterialOrganization,
      resetMaterialProject,
      searchOrganizationsNomenclatures,
      searchProjectNomenclatures,
      typeMaterialLib,
    ]
  )

  useEffect(() => {
    if (typeMaterialLib === MaterialLibPage.MATERIAL_LIB_ORGANIZATION) {
      resetMaterialOrganization()
      searchOrganizationsNomenclatures(selectedLvl0, search)
    } else if (typeMaterialLib === MaterialLibPage.MATERIAL_LIB_PROJECT) {
      resetMaterialProject()
      searchProjectNomenclatures(selectedLvl0, search)
    }
  }, [
    selectedLvl0,
    resetMaterialOrganization,
    resetMaterialProject,
    search,
    searchOrganizationsNomenclatures,
    searchProjectNomenclatures,
    typeMaterialLib,
  ])

  const handleSearchOrganizationPaginated = useCallback(
    (newPage: number) => {
      if (newPage > pageMaterial) {
        searchOrganizationsNomenclatures(selectedLvl0, search, lastMaterial).then(() => {
          if (lastMaterial) setLastPrevMaterial([...lastPrevMaterial, lastMaterial])
        })
      } else if (newPage <= pageMaterial) {
        lastPrevMaterial.pop()
        const newLastPrevInie = [...lastPrevMaterial]
        const lastFdesName = newPage === 0 ? undefined : newLastPrevInie[newLastPrevInie.length - 1]
        searchOrganizationsNomenclatures(selectedLvl0, search, lastFdesName)
      }
    },
    [selectedLvl0, lastMaterial, lastPrevMaterial, pageMaterial, search, searchOrganizationsNomenclatures]
  )

  const handleSearchProjectPaginated = useCallback(
    (newPage: number) => {
      if (newPage > pageMaterial) {
        searchProjectNomenclatures(selectedLvl0, search, lastMaterial).then(() => {
          if (lastMaterial) setLastPrevMaterial([...lastPrevMaterial, lastMaterial])
        })
      } else if (newPage <= pageMaterial) {
        lastPrevMaterial.pop()
        const newLastPrevInie = [...lastPrevMaterial]
        const lastFdesName = newPage === 0 ? undefined : newLastPrevInie[newLastPrevInie.length - 1]
        searchProjectNomenclatures(selectedLvl0, search, lastFdesName)
      }
    },
    [selectedLvl0, lastMaterial, lastPrevMaterial, pageMaterial, search, searchProjectNomenclatures]
  )
  const handleSearchPaginated = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      resetMaterialOrganization()
      resetMaterialProject()

      if (typeMaterialLib === MaterialLibPage.MATERIAL_LIB_ORGANIZATION) {
        handleSearchOrganizationPaginated(newPage)
      } else if (typeMaterialLib === MaterialLibPage.MATERIAL_LIB_PROJECT) {
        handleSearchProjectPaginated(newPage)
      }
      setPageMaterial(newPage)
    },
    [
      handleSearchOrganizationPaginated,
      handleSearchProjectPaginated,
      resetMaterialOrganization,
      resetMaterialProject,
      typeMaterialLib,
    ]
  )

  const handleSetSelectedRow = useCallback((row: IniesRecord | MaterialRecord | undefined) => {
    setSelectedRow(row)
    setOpenCard(true)
  }, [])

  const handleSearchDelayed = useMemo(
    () =>
      debounce((value) => {
        handleSearch(value)
      }, 700),
    [handleSearch]
  )

  function handleChangeSelectedLvl0(selected: DeclarationFilter.FDES | DeclarationFilter.PEP | undefined): void {
    setSelectedLvl0(selected)
    resetMaterialOrganization()
    resetMaterialProject()
    if (typeMaterialLib === MaterialLibPage.MATERIAL_LIB_ORGANIZATION) {
      searchOrganizationsNomenclatures(selected, search)
    } else if (typeMaterialLib === MaterialLibPage.MATERIAL_LIB_PROJECT) {
      searchProjectNomenclatures(selected, search)
    }
  }

  const getTypologyBackground = useCallback((typologie: DeclarationTypeEnum): string => {
    switch (typologie) {
      case DeclarationTypeEnum.COLLECTIVE:
        return "#D2691E"
      case DeclarationTypeEnum.FORFAITAIRE:
        return "#20B2AA"
      case DeclarationTypeEnum.INDIVIDUELLE:
        return "#FF7F50"
      case DeclarationTypeEnum.FICHE_CONFIGUREE:
        return "#92D050"
      default:
        return "#4169E1"
    }
  }, [])

  const getIndicator = useCallback((): MaterialLibIndicator | undefined => {
    if (typeMaterialLib === MaterialLibPage.MATERIAL_LIB_ORGANIZATION) {
      return materialOrganizationData
    } else if (typeMaterialLib === MaterialLibPage.MATERIAL_LIB_PROJECT) {
      return materialProjectData
    }
    return undefined
  }, [materialOrganizationData, materialProjectData, typeMaterialLib])

  function getRecords(): MaterialRecord[] | IniesRecord[] {
    if (typeMaterialLib === MaterialLibPage.MATERIAL_LIB_ORGANIZATION) {
      return organizationsRecords
    } else if (typeMaterialLib === MaterialLibPage.MATERIAL_LIB_PROJECT) {
      return projectRecords
    }
    return []
  }

  function handleCloseMaterialCardModal(): void {
    if (typeMaterialLib === MaterialLibPage.MATERIAL_LIB_ORGANIZATION) {
      searchOrganizationsNomenclatures(selectedLvl0)
    } else if (typeMaterialLib === MaterialLibPage.MATERIAL_LIB_PROJECT) {
      searchProjectNomenclatures(selectedLvl0)
    }
    setOpenCard(false)
  }

  return (
    <Box sx={{ pl: 10, pr: 10 }}>
      <MaterialPageHeader typeMaterialLib={typeMaterialLib} />
      <Box
        sx={{
          margin: "56px 0",
        }}>
        <Grid container columnSpacing={12}>
          <Grid item xs={4} position="relative">
            <InventoryCard getIndicator={getIndicator()} />
            <MaterialLibDeclarationTypeContextProvider>
              <MaterialFilterForm
                isWriting={isWriting}
                selectedLvl0={selectedLvl0}
                setSelectedLvl0={handleChangeSelectedLvl0}
                nomenclatureFilterData={nomenclatureFilterIniesData}
                selectedLvl1={selectedLvl1}
                setSelectedLvl1={setSelectedLvl1}
                selectedLvl2={selectedLvl2}
                setSelectedLvl2={setSelectedLvl2}
                selectedLvl3={selectedLvl3}
                setSelectedLvl3={setSelectedLvl3}
                selectedLvl4={selectedLvl4}
                setSelectedLvl4={setSelectedLvl4}
                responsibleOrganism={responsibleOrganism}
                setResponsibleOrganism={setResponsibleOrganism}
                selectedTypologieDeclaration={selectedTypologieDeclaration}
                setSelectedTypologieDeclaration={setSelectedTypologieDeclaration}
                typeMaterialLib={typeMaterialLib}
                open={openAddMaterial}
                setOpen={setOpenAddMaterial}
                setMaterialCreation={setMaterialCreation}
                materialCreation={materialCreation}
              />
            </MaterialLibDeclarationTypeContextProvider>
          </Grid>
          <Grid item xs={8}>
            <MaterialSearchByProductName handleSearchDelayed={handleSearchDelayed} search={search} setSearch={setSearch} />
            <MaterialPageTable
              handleSetSelectedRow={handleSetSelectedRow}
              getTypologyBackground={getTypologyBackground}
              handleSearchPaginated={handleSearchPaginated}
              page={pageMaterial}
              records={getRecords()}
              setPage={setPageMaterial}
              rowsPerPage={getRowPerPage()}
              setRowsPerPage={setRowsPerPage}
            />
            <MaterialCardModal
              openCard={openCard}
              selectedRow={selectedRow}
              reset={resetMaterialOrganization}
              setSelectedRow={setSelectedRow}
              getTypologyBackground={getTypologyBackground}
              isEditable={isEditable}
              isDeletable={isWriting}
              setMaterialCreation={setMaterialCreation}
              materialCreation={materialCreation}
              isModalCard={false}
              handleCloseMaterialCardModal={handleCloseMaterialCardModal}
              typeMaterialLib={typeMaterialLib}
              tab={1}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
