import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material"
import { Box } from "@mui/system"
import React, { useState } from "react"
import SelectInput from "../../../components/inputs/select-input/select-input"
import { Collaborator } from "../../../core/dto/user/collaborator"

type iProps = {
  open: boolean
  handleClose: () => void
  action: (selectedUser: string) => void
  userList: Collaborator[]
  isDuplicating: boolean
}

export default function AssignTestProjectDialog<T>({
  open,
  handleClose,
  action,
  userList,
  isDuplicating,
}: iProps): React.JSX.Element {
  const selectOptions = userList.map((user: Collaborator) => ({
    value: user.email,
    label: `${user.givenName} ${user.familyName}`,
  }))

  const [selectedUser, setSelectedUSer] = useState<string>("")

  function handleChangeOption(selectedStatus: string): void {
    setSelectedUSer(selectedStatus)
  }

  return (
    <Box>
      <Dialog open={open} onClose={handleClose} aria-describedby="id">
        <DialogTitle>Affecter le projet test à un utilisateur:</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ whiteSpace: "pre-wrap", p: 4 }}>
            <Grid container rowSpacing={2}>
              <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                {isDuplicating ? (
                  <CircularProgress />
                ) : (
                  <SelectInput
                    id="user"
                    mode="direct"
                    handleChange={handleChangeOption}
                    label="Sélectionner l'utilisateur à affecter :"
                    options={selectOptions}
                    selectedOption={selectedUser}
                  />
                )}
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isDuplicating}>
            Annuler
          </Button>
          <Button onClick={() => action(selectedUser)} disabled={isDuplicating}>
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
