import { Box, Button, Modal, Stack } from "@mui/material"
import { style } from "@mui/system"
import React, { Dispatch, SetStateAction } from "react"
import Calculator from "./calculator"
import { materialModalstyle } from "../../components/material-modal-style"

type IProps = {
  isOpen: boolean
  attachViewer(element: any): void
  calculatorResult: string
  setCalculatorResult: Dispatch<SetStateAction<string>>
  handleCloseModal(): void
  handleSetCalculatorQty(): void
}

// Here we need a class component to avoid a warning : [Invalid prop `children`. Can't hold forward ref] because it is inside a modal
export default class CalculatorModal extends React.Component<IProps> {
  render(): React.JSX.Element {
    const { isOpen, attachViewer, calculatorResult, setCalculatorResult, handleCloseModal, handleSetCalculatorQty } =
      this.props
    return (
      <Modal
        open={isOpen}
        onClose={handleCloseModal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "transparent",
          zIndex: 1101,
        }}
        id="calculator-modal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box
          sx={{
            ...style,
            ...materialModalstyle,
            minWidth: "80%",
            overflow: "hidden",
            minHeight: "90%",
            display: "flex",
            justifyContent: "flex-end",
          }}>
          <Box
            sx={{
              borderRight: "1px solid gray",
              width: "100%",
            }}>
            <div ref={attachViewer} />
          </Box>
          <Box
            style={{
              opacity: 1,
            }}
            width="500px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center">
            <Calculator calculatorResult={calculatorResult} setValue={setCalculatorResult} />
            <Stack width="100%" px={2} display="flex" spacing={2} flexDirection="row">
              <Button
                sx={{
                  alignSelf: "flex-end",
                  mx: 1,
                }}
                fullWidth
                variant="outlined"
                color="primary"
                onClick={handleCloseModal}>
                Annuler
              </Button>
              <Button
                sx={{
                  alignSelf: "flex-end",
                  mx: 1,
                }}
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleSetCalculatorQty}>
                Ajouter
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>
    )
  }
}
