export enum CalculStatusEnum {
  WAITING_FOR_MODEL = "WAITING_FOR_MODEL",
  WAITING_FOR_CONTROL = "WAITING_FOR_CONTROL",
  WAITING_FOR_CONFIGURATION = "WAITING_FOR_CONFIGURATION",
  CALCUL_IN_PROGRESS = "CALCUL_IN_PROGRESS",
  READY = "READY",
}

export function calculStatusToLabel(status: CalculStatusEnum): string {
  switch (status) {
    case CalculStatusEnum.WAITING_FOR_MODEL:
      return "En attente d'une maquette"
    case CalculStatusEnum.WAITING_FOR_CONFIGURATION:
      return "En attente de configuration"
    case CalculStatusEnum.WAITING_FOR_CONTROL:
      return "En attente de contrôle de la maquette"
    case CalculStatusEnum.CALCUL_IN_PROGRESS:
      return "En cours de calcul"
    case CalculStatusEnum.READY:
      return "Seuil 2022"
    default:
      return ""
  }
}
