import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Children } from "../../../components/miscellianous/children"
import { CodesCompletionsPerCodeParent } from "../../data-structures/codes-completions-per-code-parent"
import { useCode } from "../../hooks/use-code"
import { CalculationContext } from "../calculation/calculation-context"
import { ProjectContext } from "../project/project-context"

export const CodesCompletionsContext = React.createContext<CodesCompletionsStore>({} as CodesCompletionsStore)

export default function CodesCompletionsContextProvider({ children }: Children): React.JSX.Element {
  const { calculation } = useContext(CalculationContext)
  const { project } = useContext(ProjectContext)
  const { fetchCodeCompletionForVariant } = useCode()

  const [loading, setLoading] = useState<boolean>(false)

  const [codesCompletionsForVariant1, setCodesCompletionsForVariant1] = useState<CodesCompletionsPerCodeParent>(
    new CodesCompletionsPerCodeParent()
  )
  const [codesCompletionsForVariant2, setCodesCompletionsForVariant2] = useState<CodesCompletionsPerCodeParent>(
    new CodesCompletionsPerCodeParent()
  )

  const refreshCodeCompletion: () => Promise<any> = useCallback(() => {
    if (calculation.id && project.id) {
      const promise = fetchCodeCompletionForVariant(project.id, calculation.variantId1).then((codesCompletions) =>
        setCodesCompletionsForVariant1(codesCompletions)
      )

      const promise1 = fetchCodeCompletionForVariant(project.id, calculation.variantId2).then((codesCompletions) =>
        setCodesCompletionsForVariant2(codesCompletions)
      )

      return Promise.all([promise, promise1]).finally(() => setLoading(false))
    } else {
      return Promise.resolve()
    }
  }, [calculation.id, calculation.variantId1, calculation.variantId2, fetchCodeCompletionForVariant, project.id, loading])

  useEffect(() => {
    refreshCodeCompletion()
  }, [refreshCodeCompletion])

  const codesCompletionsStore: CodesCompletionsStore = useMemo(
    () => ({
      codesCompletionsPerCodeParentV1: codesCompletionsForVariant1,
      setCodesCompletionsPerCodeParentV1: setCodesCompletionsForVariant1,
      codesCompletionsPerCodeParentV2: codesCompletionsForVariant2,
      setCodesCompletionsPerCodeParentV2: setCodesCompletionsForVariant2,
      setFetch: setLoading,
      fetchCodeCompletionForVariant,
      refreshCodeCompletion,
    }),
    [codesCompletionsForVariant1, codesCompletionsForVariant2, setLoading, fetchCodeCompletionForVariant]
  )
  return <CodesCompletionsContext.Provider value={codesCompletionsStore}>{children}</CodesCompletionsContext.Provider>
}

export type CodesCompletionsStore = {
  codesCompletionsPerCodeParentV1: CodesCompletionsPerCodeParent
  codesCompletionsPerCodeParentV2: CodesCompletionsPerCodeParent
  setCodesCompletionsPerCodeParentV1: Dispatch<SetStateAction<CodesCompletionsPerCodeParent>>
  setCodesCompletionsPerCodeParentV2: Dispatch<SetStateAction<CodesCompletionsPerCodeParent>>
  setFetch: React.Dispatch<React.SetStateAction<boolean>>
  fetchCodeCompletionForVariant: (projectId: string, variantId: string) => Promise<CodesCompletionsPerCodeParent>
  refreshCodeCompletion(): Promise<any>
}
