import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { ReactNode } from "react"

type IProps = {
  name: string
  currentValue: string | undefined
  label: string
  valuesList: string[]
  onChange: (event: SelectChangeEvent, child: ReactNode) => void
  disabled?: boolean
}

export default function GenericSelect({
  name,
  currentValue,
  label,
  valuesList,
  onChange,
  disabled,
}: IProps): React.JSX.Element {
  return (
    <FormControl fullWidth>
      <InputLabel id={`add-${name}-select-label`}>{label}</InputLabel>
      <Select
        id={`add-${name}-select`}
        name={name}
        labelId={`add-${name}-select-label`}
        value={currentValue}
        onChange={onChange}
        label={label}
        required
        disabled={disabled}
        fullWidth>
        {valuesList.length === 0 ? (
          <MenuItem>Pas de valeurs possibles</MenuItem>
        ) : (
          valuesList.map((value: string) => (
            <MenuItem key={value} disabled={value !== "Architecture"} value={value}>
              {value}
            </MenuItem>
          ))
        )}
      </Select>
    </FormControl>
  )
}
