import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from "react"
import { ErrorContext } from "../../../components/layout/error-snackbar"
import { Children } from "../../../components/miscellianous/children"
import { BimModel } from "../../dto/bim-model/bim-model"
import { useBimModel } from "../../hooks/bim-model/use-bim-model"
import { ProjectContext } from "../project/project-context"
import { SelectedPhaseContext } from "../selected-phase-context"

export const BimModelContext = React.createContext<BimModelStore>({} as BimModelStore)

export default function BimModelContextProvider({ children }: Children): React.JSX.Element {
  const openErrorSnackbar = useContext(ErrorContext)
  const { project } = useContext(ProjectContext)
  const { selectedPhase } = useContext(SelectedPhaseContext)
  const { fetchBimModelByProjectIdAndPhases } = useBimModel()
  const [bimModel, setBimModel] = useState<BimModel>(new BimModel())

  useEffect(() => {
    if (project?.id && selectedPhase) {
      fetchBimModelByProjectIdAndPhases(project.id, selectedPhase)
        .then((bimModelResponse) => {
          if (bimModelResponse) {
            setBimModel(bimModelResponse)
          } else {
            setBimModel(new BimModel())
          }
        })
        .catch((e) => {
          openErrorSnackbar(e)
        })
    }
  }, [fetchBimModelByProjectIdAndPhases, openErrorSnackbar, project.id, selectedPhase])

  const bimModelStore: BimModelStore = useMemo(() => ({ bimModel, setBimModel }), [bimModel])
  return <BimModelContext.Provider value={bimModelStore}>{children}</BimModelContext.Provider>
}

export type BimModelStore = {
  bimModel: BimModel
  setBimModel: Dispatch<SetStateAction<BimModel>>
}
