import { Box, Button, CircularProgress, Grid } from "@mui/material"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { SuccessContext } from "../../../../components/layout/success-snackbar"
import { adminPagesUrl, pagesUrl } from "../../../../core/appConstants"
import { CalculationContext } from "../../../../core/context/calculation/calculation-context"
import { ProjectContext } from "../../../../core/context/project/project-context"
import { ProjectRoleContext } from "../../../../core/context/user/project-role-context"
import { VariantContext } from "../../../../core/context/variant-context"
import WorksiteIc from "../../../../core/dto/calculation/worksite-ic"
import { CalculationStepsEnum } from "../../../../core/enum/calculationStepsEnum"
import { ProjectStatusEnum } from "../../../../core/enum/projectStatusEnum"
import { RoleEnum } from "../../../../core/enum/roleEnum"
import { useCalculation } from "../../../../core/hooks/use-calculation"
import { resolveUrl } from "../../../../core/services/http-service"
import ProjectCarbonBreadcrumb from "../components/project-carbon-breadcrumb"
import ProjectCarbonCalculatorHeader from "../components/project-carbon-calculator-header"
import SelectVariant from "../components/select-variant"
import WorksiteForm from "./worksite-form"

export default function WorksitePage(): React.JSX.Element {
  const openSuccessSnackbar: (message: string) => void = useContext(SuccessContext)
  const navigate = useNavigate()
  const { project } = useContext(ProjectContext)
  const { variant1, setVariant1, variant2, setVariant2 } = useContext(VariantContext)
  const { calculation, refreshCalculation } = useContext(CalculationContext)
  const { hasRole, isOrganizationUltimateUser } = useContext(ProjectRoleContext)

  const { organizationId } = useParams()

  const { startCalculation, updateWorksiteCI } = useCalculation()

  const [variantCategorie, setVariantCategorie] = useState<1 | 2>(1)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isReady, setIsReady] = useState<boolean>(!!variant1?.id && !!variant2?.id)
  const [worksiteCiPerVariantForm, setWorksiteCiPerVariantForm] = useState<{
    1: WorksiteIc
    2: WorksiteIc
  }>({ 1: { ...variant1.icChantier }, 2: { ...variant2.icChantier } })
  const isWriting = useMemo(
    () => hasRole([RoleEnum.ADMINISTRATOR, RoleEnum.BE]) && project.status !== ProjectStatusEnum.ARCHIVED,
    [hasRole, project.status]
  )

  useEffect(() => {
    setIsReady(!!variant1?.id && !!variant2?.id)
    setWorksiteCiPerVariantForm({ 1: { ...variant1.icChantier }, 2: { ...variant2.icChantier } })
  }, [variant1, variant2])

  const handleChangeVariant = useCallback((variant: 1 | 2): void => {
    setVariantCategorie(variant)
  }, [])

  async function updateWorksiteCi(showMessage: boolean): Promise<boolean> {
    if (variant1.id && variant2.id) {
      await updateWorksiteCI({
        "1": {
          id: variant1.id,
          data: worksiteCiPerVariantForm[1],
        },
        "2": {
          id: variant2.id,
          data: worksiteCiPerVariantForm[2],
        },
      })
        .then(([v1, v2]) => {
          setVariant1((prev) => ({
            ...prev,
            ...v1,
          }))
          setVariant2((prev) => ({
            ...prev,
            ...v2,
          }))
          return [v1, v2]
        })
        .then((res) => {
          if (res && showMessage) {
            openSuccessSnackbar("Informations Chantier Sauvegardées")
          }
        })
    }
    return true
  }

  async function handleStartCalculation(): Promise<void> {
    if (!isSubmitting) {
      setIsSubmitting(true)
      await updateWorksiteCi(false).catch((e: Error) => {
        setIsSubmitting(false)
        throw e
      })

      if (project?.id && calculation?.id) {
        startCalculation(calculation?.id)
          .then(() => {
            refreshCalculation().then(() => navigate(`/project/${project?.id}/control-board`))
          })
          .finally(() => setIsSubmitting(false))
      }
    }
  }

  const handleClickPreviousStep = useCallback((): void => {
    isOrganizationUltimateUser
      ? navigate(resolveUrl(adminPagesUrl.PROJECT_CALCULATION_ADDITIONAL_DATA, [organizationId, project?.id]))
      : navigate(resolveUrl(pagesUrl.PROJECT_CALCULATION_ADDITIONAL_DATA, [project?.id]))
  }, [isOrganizationUltimateUser, navigate, project?.id])

  function calculateButton(): React.JSX.Element {
    if (isSubmitting) {
      return <CircularProgress />
    } else {
      return (
        <Button variant="contained" onClick={handleStartCalculation} disabled={!isReady}>
          Calculer
        </Button>
      )
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={3}>
          <ProjectCarbonCalculatorHeader />
        </Grid>
        <Grid item xs={7}>
          <ProjectCarbonBreadcrumb
            selected={CalculationStepsEnum.WORKSITE}
            submit={() => updateWorksiteCi(true)}
            isWriting={isWriting}
          />
        </Grid>
        <Grid item xs={2}>
          <Box
            sx={{
              width: 200,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}>
            <Button variant="outlined" onClick={handleClickPreviousStep}>
              Retour
            </Button>
            {isWriting && calculateButton()}
          </Box>
        </Grid>
      </Grid>
      <SelectVariant selectedVariant={variantCategorie} handleChangeVariant={handleChangeVariant} />
      <WorksiteForm
        variantForm={worksiteCiPerVariantForm}
        setVariantForm={setWorksiteCiPerVariantForm}
        selectedVariant={variantCategorie}
        disabled={!isWriting}
      />
    </>
  )
}
