/**
 * Camera orientations in a viewer.
 */
export enum CameraOrientations {
  FRONT,
  BACK,
  RIGHT,
  LEFT,
  BOTTOM,
  TOP,
}
