export enum PhaseEnum {
  ESQ = "ESQ",
  APS = "APS",
  APD = "APD",
  PRO = "PRO",
  DCE = "DCE",
}

export const phaseList = [PhaseEnum.ESQ, PhaseEnum.APS, PhaseEnum.APD, PhaseEnum.PRO, PhaseEnum.DCE]

export function renderPhaseEnum(type: PhaseEnum): string {
  switch (type) {
    case PhaseEnum.ESQ:
      return "ESQ" as string
    case PhaseEnum.APS:
      return "APS" as string
    case PhaseEnum.APD:
      return "APD" as string
    case PhaseEnum.PRO:
      return "PRO" as string
    case PhaseEnum.DCE:
      return "DCE" as string
    default:
      return ""
  }
}

export function phaseToLabel(type: PhaseEnum): string {
  switch (type) {
    case PhaseEnum.ESQ:
      return "Esquisse"
    case PhaseEnum.APS:
      return "Avant Projet Sommaire"
    case PhaseEnum.APD:
      return "Avant Projet Définitif"
    case PhaseEnum.PRO:
      return "Projet"
    case PhaseEnum.DCE:
      return "DCE"
    default:
      return ""
  }
}

export function stringToPhase(phase: string | null): PhaseEnum | undefined {
  switch (phase) {
    case "ESQ":
      return PhaseEnum.ESQ
    case "APS":
      return PhaseEnum.APS
    case "APD":
      return PhaseEnum.APD
    case "PRO":
      return PhaseEnum.PRO
    case "DCE":
      return PhaseEnum.DCE
    default:
      return undefined
  }
}
