import { Search } from "@mui/icons-material"
import { Autocomplete, InputAdornment, TextField } from "@mui/material"
import React from "react"

type IProps<T> = {
  fieldName?: string
  label: string
  value: T | null
  options: T[] | undefined
  onChange(event: any, newValue: T | null): void
  getOptionLabel(option: T): string
  disablePortal?: boolean
  errors?: Record<string, string | undefined>
}

export function SearchField<T>({
  fieldName,
  label,
  value,
  options,
  onChange,
  getOptionLabel,
  disablePortal = true,
  errors,
}: Readonly<IProps<T>>): React.JSX.Element {
  const isError: boolean = fieldName ? !!errors?.[fieldName] : false

  return (
    <Autocomplete
      fullWidth
      disablePortal={disablePortal}
      id="searchBar"
      value={value}
      options={options ?? []}
      onChange={onChange}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option, valueToCompare) => option === valueToCompare}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={label}
          fullWidth
          error={isError}
          helperText={fieldName && errors?.[fieldName] ? errors?.[fieldName] : undefined}
          sx={{
            borderRadius: "4px",
            background: "#0000000F 0% 0% no-repeat padding-box;",
            boxSizing: "border-box",
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  )
}
