import React, { Dispatch, SetStateAction, useState } from "react"
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { ShareForm, SharingFormButtons } from "./share-form"
import { ShareLink } from "../../../../core/dto/rsee/share-link"

type IProps = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  shareLink: ShareLink | undefined
  refresh(): void
}
export default function ShareLinkUpdateModal({ open, setOpen, shareLink, refresh }: IProps): React.JSX.Element {
  const [errors, setErrors] = useState<Record<string, string>>({})
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  function handleClose(): void {
    setOpen(false)
  }

  function onSubmitSuccess(): void {
    refresh()
    handleClose()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Lien du partage</DialogTitle>
      <DialogContent>
        <ShareForm
          errors={errors}
          setErrors={setErrors}
          rseeDocumentId={undefined}
          shareLink={shareLink}
          setIsSubmitting={setIsSubmitting}
          onSubmitSuccess={onSubmitSuccess}
        />
      </DialogContent>
      <DialogActions>
        <SharingFormButtons cancel={handleClose} isSubmitting={isSubmitting} />
      </DialogActions>
    </Dialog>
  )
}
