import JSZip from "jszip"
import { Dispatch, SetStateAction } from "react"

export function unzipOneFile(
  file: File,
  setFile: Dispatch<SetStateAction<File | undefined>>,
  openErrorSnackbar: (err: Error) => void,
  setIsCompressed?: Dispatch<SetStateAction<boolean>>,
  cancelUpload?: () => void
): void {
  const zip = new JSZip()
  const fileName = file.name
  zip
    .loadAsync(file)
    .then((zipTmp) => {
      const fileCount = Object.keys(zipTmp.files).length
      if (fileCount !== 1) {
        openErrorSnackbar(new Error("Le zip ne doit contenir qu'un seul fichier"))
        if (cancelUpload) cancelUpload()
        return
      }
      zipTmp.forEach((relativePath, fileContent) => {
        fileContent.async("string").then((content) => {
          const newFile = new File([content], fileName, { type: "application/ifc" })
          setFile(newFile)
        })
      })
    })
    .finally(() => {
      if (setIsCompressed) {
        setIsCompressed(false)
      }
    })
    .catch(() => {
      openErrorSnackbar(new Error("Erreur lors du chargement du fichier zip"))
    })
}

/**
 * Be careful, this function can throw errors. Don't forget to catch them
 * @param zippedFile
 */
export function unzipFiles(zippedFile: File): Promise<File[]> {
  const zip = new JSZip()
  const fileName = zippedFile.name
  return zip.loadAsync(zippedFile).then((zipTmp) => {
    const fileList: File[] = []
    zipTmp.forEach((relativePath, fileContent) => {
      fileContent.async("string").then((content) => {
        const newFile = new File([content], fileName, { type: "application/ifc" })
        fileList.push(newFile)
      })
    })
    return fileList
  })
}

export function zipFile(file: File, setFile: React.Dispatch<React.SetStateAction<File | undefined>>): void {
  const zip = new JSZip()
  if (file) {
    zip.file(file.name, file.text())
    zip
      .generateAsync({ type: "blob" })
      .then((content) => {
        const newFile = new File([content], file.name, { type: "application/ifczip" })
        setFile(newFile)
      })
      .catch(() => {
        console.error("Erreur lors de la génération du fichier zip")
      })
  }
}
