import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Children } from "../../../components/miscellianous/children"
import { appConstants } from "../../appConstants"
import { useHttp } from "../../hooks/use-http"

export const DomainNameValidatorContext = React.createContext<DomainNameValidatorStore>({} as DomainNameValidatorStore)

export default function DomainNameValidatorContextProvider({ children }: Children): React.JSX.Element {
  const [forbiddenDomainNames, setForbiddenDomainNames] = useState<string[]>([])
  const [loading, setLoading] = useState(true)
  const { get } = useHttp()

  useEffect(() => {
    setLoading(true)
    get(appConstants.apiEndpoints.FORBIDDEN_DOMAIN_NAMES)
      .then((response) => response.json())
      .then((domainNameList) => {
        setForbiddenDomainNames(domainNameList)
      })
      .finally(() => setLoading(false))
  }, [get])

  const extractDomainName = useCallback((email: string) => {
    if (email && email.length > 1) {
      return email.substring(email.indexOf("@") + 1, email.length)
    } else {
      return ""
    }
  }, [])

  const isDomainNameInvalid = useCallback(
    (email: string) => {
      const domainName: string = extractDomainName(email)
      if (loading) {
        throw new Error("DOMAIN_LIST_IS_LOADING")
      }
      return forbiddenDomainNames.includes(domainName)
    },
    [extractDomainName, forbiddenDomainNames, loading]
  )

  const domainNameValidatorStore: DomainNameValidatorStore = useMemo(
    () => ({
      isDomainNameInvalid,
    }),
    [isDomainNameInvalid]
  )

  return (
    <DomainNameValidatorContext.Provider value={domainNameValidatorStore}>{children}</DomainNameValidatorContext.Provider>
  )
}

export type DomainNameValidatorStore = {
  isDomainNameInvalid(email: string): boolean
}
