import { Typography } from "@mui/material"
import React from "react"

type IProps = {
  text: string
}

export default function TitleH2({ text }: IProps): React.JSX.Element {
  return (
    <Typography variant="h4" gutterBottom color="primary">
      {text}
    </Typography>
  )
}
