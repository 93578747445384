export enum FacadeCoverEnum {
  BARDAGE_ACIER_DOUBLE_PEAU = "BARDAGE_ACIER_DOUBLE_PEAU",
  BARDAGE_PIERRE_NATURELLE = "BARDAGE_PIERRE_NATURELLE",
  PAREMENT_TERRE_CUITE = "PAREMENT_TERRE_CUITE",
  BARDAGE_LAMES_BOIS = "BARDAGE_LAMES_BOIS",
  BARDAGE_ACIER = "BARDAGE_ACIER",
  BARDAGE_ALU = "BARDAGE_ALU",
  BARDAGE_CIMENT_BOIS = "BARDAGE_CIMENT_BOIS",
  BARDAGE_PVC = "BARDAGE_PVC",
  BARDAGE_BETON = "BARDAGE_BETON",
  PEINTURE = "PEINTURE",
  ENDUIT = "ENDUIT",
  BARDAGE_VERRE = "BARDAGE_VERRE",
  BARDAGE_FIBRE_CIMENT = "BARDAGE_FIBRE_CIMENT",
}

export const facadeCoverEnumLabels = {
  [FacadeCoverEnum.BARDAGE_ACIER_DOUBLE_PEAU]: "Bardage en acier double peau",
  [FacadeCoverEnum.BARDAGE_PIERRE_NATURELLE]: "Bardage en pierre naturelle",
  [FacadeCoverEnum.PAREMENT_TERRE_CUITE]: "Parement en terre cuite",
  [FacadeCoverEnum.BARDAGE_LAMES_BOIS]: "Bardage en lames de bois",
  [FacadeCoverEnum.BARDAGE_ACIER]: "Bardage en acier",
  [FacadeCoverEnum.BARDAGE_ALU]: "Bardage en aluminium",
  [FacadeCoverEnum.BARDAGE_CIMENT_BOIS]: "Bardage composite ciment/bois",
  [FacadeCoverEnum.BARDAGE_PVC]: "Bardage en PVC",
  [FacadeCoverEnum.BARDAGE_BETON]: "Bardage en béton",
  [FacadeCoverEnum.PEINTURE]: "Peinture",
  [FacadeCoverEnum.ENDUIT]: "Enduit minéral",
  [FacadeCoverEnum.BARDAGE_VERRE]: "Bardage en verre",
  [FacadeCoverEnum.BARDAGE_FIBRE_CIMENT]: "Bardage en fibre de ciment",
}
