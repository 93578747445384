import { ButtonProps } from "@mui/material"
import React from "react"
import NavigateButton from "../navigate-button/navigate-button"

interface IProps extends ButtonProps {
  path: string
  label?: string
  fullwidth?: boolean
}

export default function BackButton(props: IProps, fullwidth = true): React.JSX.Element {
  const { path, label, ...buttonProps } = props

  return <NavigateButton label={label || "Retour"} path={path} variant="outlined" fullwidth={fullwidth} {...buttonProps} />
}
