import { Grid } from "@mui/material"
import React, { Dispatch, SetStateAction } from "react"
import FilterButton from "../../../../components/buttons/filter-button/filter-button"
import { ProjectStatusEnum } from "../../../../core/enum/projectStatusEnum"

type IProps = {
  currentStatus: ProjectStatusEnum
  setCurrentStatus: Dispatch<SetStateAction<ProjectStatusEnum>>
}

export default function ProjectStatusSelectButtons({ currentStatus, setCurrentStatus }: IProps): React.JSX.Element {
  return (
    <Grid container sx={{ width: "90%" }} columnSpacing={3} rowSpacing={3}>
      <Grid item xs={12} sm={2}>
        <FilterButton
          value={ProjectStatusEnum.IN_PROGRESS}
          currentValue={currentStatus}
          label="Projets en cours"
          onClick={() => setCurrentStatus(ProjectStatusEnum.IN_PROGRESS)}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <FilterButton
          value={ProjectStatusEnum.FINISHED}
          currentValue={currentStatus}
          label="Projets terminés"
          onClick={() => setCurrentStatus(ProjectStatusEnum.FINISHED)}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <FilterButton
          value={ProjectStatusEnum.ARCHIVED}
          currentValue={currentStatus}
          label="Projets Archivés"
          onClick={() => setCurrentStatus(ProjectStatusEnum.ARCHIVED)}
        />
      </Grid>
    </Grid>
  )
}
