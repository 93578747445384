import { Button } from "@mui/material"
import React from "react"

type IProps = {
  action(): void
  label: string | undefined
  fullWidth?: boolean
}

export default function CancelButton({ action, label, fullWidth = false }: IProps): React.JSX.Element {
  return (
    <Button variant="contained" color="secondary" size="medium" onClick={action} fullWidth={fullWidth}>
      {label}
    </Button>
  )
}
