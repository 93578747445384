export enum OfficeTypeEnum {
  CLOISONNE = "CLOISONNE",
  MIXTE = "MIXTE",
  OPEN_SPACE = "OPEN_SPACE",
}

export const officeTypeEnumLabels = {
  [OfficeTypeEnum.CLOISONNE]: "Cloisonné",
  [OfficeTypeEnum.MIXTE]: "Mixte",
  [OfficeTypeEnum.OPEN_SPACE]: "Open Space",
}
