import { Box, Button, Grid } from "@mui/material"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { ErrorContext } from "../../../../components/layout/error-snackbar"
import { adminPagesUrl, pagesUrl } from "../../../../core/appConstants"
import { CalculationContext } from "../../../../core/context/calculation/calculation-context"
import { ProjectContext, ProjectStore } from "../../../../core/context/project/project-context"
import { SelectedPhaseContext } from "../../../../core/context/selected-phase-context"
import { ProjectRoleContext } from "../../../../core/context/user/project-role-context"
import { VariantContext } from "../../../../core/context/variant-context"
import { CalculationStepsEnum } from "../../../../core/enum/calculationStepsEnum"
import { CalculationTypesEnum } from "../../../../core/enum/calculationTypeEnum"
import { RoleEnum } from "../../../../core/enum/roleEnum"
import {
  CalculationForm,
  useUpdateCalculCarbonInformationProject,
} from "../../../../core/hooks/calcul-carbon/use-calcul-carbon-information-project-mutation"
import { resolveUrl } from "../../../../core/services/http-service"
import ProjectCarbonBreadcrumb from "../components/project-carbon-breadcrumb"
import ProjectCarbonCalculatorHeader from "../components/project-carbon-calculator-header"
import GeneralInformationForm from "./general-information-form"

const initialFormValue: CalculationForm = {
  variantName1: "",
  variantName2: "",
  usage: undefined,
  climaticZone: "",
  altitude: 0,
  calculationType: CalculationTypesEnum.RE2020,
  phase: "",
  projectId: "",
}

export default function GeneralInformationPage(): React.JSX.Element {
  const navigate = useNavigate()
  const { calculation } = useContext(CalculationContext)
  const { variant1, variant2, refreshVariant } = useContext(VariantContext)
  const errorMessage = useContext(ErrorContext)
  const { project } = useContext<ProjectStore>(ProjectContext)
  const { selectedPhase } = useContext(SelectedPhaseContext)
  const { hasRole, isOrganizationUltimateUser } = useContext(ProjectRoleContext)
  const { organizationId } = useParams()

  const {
    updateCalculCarbonInformationProject,
    createCalculCarbonInformationProject,
    loading: loadingUpdateCalculCarbon,
  } = useUpdateCalculCarbonInformationProject()

  const [variantCategory, setVariantCategory] = useState<1 | 2>(1)
  const [errors, setErrors] = useState<Record<string, string>>({})
  const [calculationFormValue, setCalculationFormValue] = useState<CalculationForm>(initialFormValue)

  const isWriting = useMemo(() => hasRole([RoleEnum.ADMINISTRATOR, RoleEnum.BE]), [hasRole])

  useEffect(() => {
    setErrors({})
  }, [calculationFormValue, setErrors])

  useEffect(() => {
    setCalculationFormValue((prev) => ({
      ...prev,
      altitude: +calculation.altitude ?? prev.altitude,
      calculationType: calculation.calculationType ?? prev.calculationType,
      climaticZone: calculation.climaticZone ?? prev.climaticZone,
      phase: calculation.phase ?? prev.phase,
      projectId: calculation.projectId ?? prev.projectId,
      usage: calculation.usage ?? prev.usage,
      variantName1: variant1.name,
      variantName2: variant2.name,
    }))
  }, [calculation, variant1, variant2])

  const handleChangeVariantCategory = useCallback((variant: 1 | 2): void => {
    setVariantCategory(variant)
  }, [])

  function handleChangeForm(field: string, value: string | number): void {
    setCalculationFormValue((prevState) => ({
      ...prevState,
      [field]: value,
    }))
  }

  function handleCheckError(): Record<string, string> {
    const allKeysErrors: (keyof CalculationForm)[] = ["altitude", "climaticZone", "usage", "variantName1", "variantName2"]
    let errorsResponse: Record<string, string> = {}
    allKeysErrors.forEach((key) => {
      errorsResponse = {
        ...errorsResponse,
        [key]: !`${calculationFormValue[key] || ""}`.toString().length ? "Ce champ est obligatoire" : "",
      }
    })

    errorsResponse = {
      ...errorsResponse,
      altitude: !`${calculationFormValue.altitude || 0}`.toString().length ? "Ce champ est obligatoire" : "",
    }
    setErrors(errorsResponse)
    return errorsResponse
  }

  function navigateToNextPage(): void {
    if (isWriting) {
      onSubmit().then((success: boolean) => {
        if (success) {
          isOrganizationUltimateUser
            ? navigate(resolveUrl(adminPagesUrl.PROJECT_CALCULATION_FLAT_RATES, [organizationId, project?.id]))
            : navigate(resolveUrl(pagesUrl.PROJECT_CALCULATION_FLAT_RATES, [project?.id]))
        }
      })
    }
  }

  function onSubmit(): Promise<boolean> {
    const errorsResponse = handleCheckError()
    const haveError = Object.keys(errorsResponse).some((key) => Boolean(errorsResponse[key].length !== 0))
    if (haveError) {
      errorMessage(
        new Error(
          errorsResponse.variantName2 !== ""
            ? "Renseignez le nom de la variante avant de passer à l'étape suivante"
            : "Veuillez remplir tous les champs"
        )
      )
      return Promise.resolve(false)
    }
    if (!project?.id) {
      return Promise.resolve(false)
    }

    if (!calculation.id && selectedPhase) {
      return createCalculCarbonInformationProject({
        ...calculationFormValue,
        phase: selectedPhase,
        projectId: `${project.id}`,
      })
        .then(() => {
          variant1.name = calculationFormValue.variantName1
          variant2.name = calculationFormValue.variantName2
        })
        .then(() => {
          refreshVariant() // asynchronously refresh variant
          return true
        })
    } else if (calculation.id && calculation.phase) {
      return updateCalculCarbonInformationProject(
        {
          id: calculation.id,
          phase: calculation.phase as any,
          calculationType: calculationFormValue.calculationType || calculation.calculationType,
          climaticZone: calculationFormValue.climaticZone || calculation.climaticZone,
          altitude: +`${calculationFormValue.altitude || calculation.altitude}`,
          usage: calculationFormValue.usage || calculation.usage,
          variantName1: calculationFormValue.variantName1,
          variantName2: calculationFormValue.variantName2,
        },
        `${project?.id}`
      )
        .then(() => {
          variant1.name = calculationFormValue.variantName1
          variant2.name = calculationFormValue.variantName2
        })
        .then(() => {
          refreshVariant() // asynchronously refresh variant
          return true
        })
    } else {
      return Promise.resolve(false)
    }
  }

  function handleClickPrevStep(): void {
    isOrganizationUltimateUser
      ? navigate(resolveUrl(adminPagesUrl.PROJECT_CONTROL_BOARD, [organizationId, project?.id]))
      : navigate(resolveUrl(`${pagesUrl.PROJECT_PAGE}/:projectId/control-board`, [project?.id]))
  }

  return (
    <>
      <Grid container>
        <Grid item xs={3}>
          <ProjectCarbonCalculatorHeader />
        </Grid>
        <Grid item xs={7}>
          <ProjectCarbonBreadcrumb selected={CalculationStepsEnum.INFO} submit={onSubmit} isWriting={isWriting} />
        </Grid>
        <Grid item xs={2}>
          <Box
            sx={{
              width: 200,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}>
            <Button variant="outlined" onClick={handleClickPrevStep}>
              Retour
            </Button>
            <Button variant="contained" onClick={navigateToNextPage} disabled={loadingUpdateCalculCarbon}>
              Suivant
            </Button>
          </Box>
        </Grid>
      </Grid>
      <GeneralInformationForm
        handleChangeVariant={handleChangeVariantCategory}
        formValueVariant={calculationFormValue}
        selectedVariant={variantCategory}
        handleChangeForm={handleChangeForm}
        errors={errors}
        disabled={!isWriting}
      />
    </>
  )
}
