import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline"
import RotateLeftIcon from "@mui/icons-material/RotateLeft"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material"
import React, { useContext, useState } from "react"
import { ConfirmationDialog } from "../../../components/dialog/confirmation-dialog"
import { BSCategoriesContext } from "../../../core/context/beem-shot/BSCategory/BSCategoriesContext"
import { BSItemContext } from "../../../core/context/beem-shot/BSItems/BSItemContext"
import { BSMaterialResultContext } from "../../../core/context/beem-shot/BSMaterialResult/BSMaterialResultContext"
import { BSProjectContext } from "../../../core/context/beem-shot/BSProject/BSProjectContext"
import { BSVariantContext } from "../../../core/context/beem-shot/BSVariant/BSVariantContext"
import { BSItem } from "../../../core/dto/beem-shot/BSItem/BSItem"
import { BSMaterialResult } from "../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { ProjectStatusEnum } from "../../../core/enum/projectStatusEnum"
import { codeToKey } from "../../../core/services/code-service"
import { displayDecimal, stringToNumber } from "../../../core/services/helper-service"
import { useAccordion } from "../BSCalculationPage/BSCalculationForm/components/AdvancedQuestionnaire/useAccordion"
import { CodeDisplayer } from "./BSBimModelExtractedCode/CodeDisplayer"
import { BSMaterialInfoDialog } from "./BSMaterialInfoDialog/BSMaterialInfoDialog"
import { BSMaterialLibDialog } from "./BsMaterialLib/BSMaterialLibDialog"

export function BSCalculDetailPage(): React.JSX.Element {
  const { bsMaterialResults, resetMaterial } = useContext(BSMaterialResultContext)
  const { bsItems } = useContext(BSItemContext)
  const { selectedVariant } = useContext(BSVariantContext)
  const { bsProject } = useContext(BSProjectContext)

  const [open, setOpen] = useState<boolean>(false)
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false)
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false)
  const [openMaterialDetail, setOpenMaterialDetail] = useState<boolean>(false)

  const [selectedBsMaterialResult, setSelectedBsMaterialResult] = useState<BSMaterialResult>()

  const { expanded: categoryExpanded, expand: expandCategory, handleExpanded: handleExpandedCategory } = useAccordion()
  const {
    expanded: subcategoryExpanded,
    expand: extanSubCategory,
    handleExpanded: handleSubCategoryExpanded,
  } = useAccordion()
  const { expanded: expandedBsItem, handleExpanded: handleBsExpanded } = useAccordion()

  const texteASupprimer = " - DONNEE ENVIRONNEMENTALE PAR DEFAUT"

  const { bsCategories } = useContext(BSCategoriesContext)
  const { subCategoriesForCategory } = useContext(BSItemContext)

  function sortBsItem(a: BSItem, b: BSItem): number {
    if (a.type.label < b.type.label) {
      return -1
    } else if (a.type.label > b.type.label) {
      return 1
    } else {
      return stringToNumber(a.codeExtrait?.occurence) - stringToNumber(b.codeExtrait?.occurence)
    }
  }

  function handleClose(): void {
    setOpen(false)
    setOpenConfirmationDialog(false)
  }

  return (
    <>
      <Box display="flex" flexDirection="column" gap={1} pt={2}>
        <Typography variant="h6">Personnalisez vos calculs</Typography>

        {bsCategories
          .filter((bsCategory) => Object.keys(subCategoriesForCategory).includes(bsCategory.name))
          .map((bsCategory) => (
            <Accordion
              id={bsCategory.name}
              key={bsCategory.name}
              expanded={categoryExpanded === bsCategory.name}
              onChange={() => {
                handleSubCategoryExpanded("")
                handleExpandedCategory(bsCategory.name)
              }}
              disableGutters
              square
              sx={{
                borderRadius: 6,
                minWidth: "100%",
              }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>{bsCategory.label}</AccordionSummary>
              <AccordionDetails>
                {bsCategory?.subCategories
                  ?.filter((subCategory) =>
                    subCategoriesForCategory[bsCategory.name]?.some((sc) => sc.name === subCategory.name)
                  )
                  .map((subCategory) => (
                    <Accordion
                      id={subCategory.name}
                      key={subCategory.name}
                      expanded={subcategoryExpanded === subCategory.name}
                      onChange={() => {
                        handleSubCategoryExpanded(subCategory.name)
                      }}
                      elevation={0}
                      square
                      disableGutters
                      TransitionProps={{ unmountOnExit: true }}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>{subCategory.label}</AccordionSummary>
                      <AccordionDetails>
                        {bsItems
                          .filter((bsItem) => bsItem.subCategory.name === subCategory.name)
                          .sort(sortBsItem)
                          .map((bsItem) => (
                            <Accordion
                              id={bsItem.id}
                              key={bsItem.id}
                              expanded={expandedBsItem === bsItem.id}
                              onChange={() => handleBsExpanded(bsItem.id)}
                              elevation={1}
                              square
                              disableGutters
                              TransitionProps={{ unmountOnExit: true }}
                              sx={{
                                m: 1,
                                position: "inherit",
                                borderRadius: 6,
                                minWidth: "100%",
                              }}>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Box
                                  id={bsItem.codeExtrait ? codeToKey(bsItem.codeExtrait) : ""}
                                  sx={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                                  <CodeDisplayer
                                    codeTitle={bsItem.type.label}
                                    codeDescription={bsItem.codeExtrait?.descriptionFromBimModel}
                                    codeExtrait={bsItem.codeExtrait}
                                    codeColor={bsItem.codeExtrait?.color ?? "red"}
                                    showBorder={false}
                                  />
                                </Box>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Grid container display="flex" rowGap={1}>
                                  <Grid item xs={2}>
                                    <Typography
                                      variant="subtitle2"
                                      fontSize={12}
                                      sx={{ color: "#8F8F8F", textAlign: "center" }}>
                                      Identifiant
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={7}>
                                    <Typography
                                      variant="subtitle2"
                                      fontSize={12}
                                      sx={{ color: "#8F8F8F", textAlign: "center" }}>
                                      Nom du produit
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <Typography
                                      variant="subtitle2"
                                      fontSize={12}
                                      sx={{ color: "#8F8F8F", textAlign: "center" }}>
                                      Quantité
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={1} />

                                  {bsMaterialResults
                                    .filter(
                                      (bsMaterialResult: BSMaterialResult) => bsMaterialResult.itemType === bsItem.type.name
                                    )
                                    .filter((bsMaterialResult: BSMaterialResult) => {
                                      if (bsItem.codeExtrait) {
                                        return bsMaterialResult.codeOccurrence === codeToKey(bsItem.codeExtrait)
                                      } else {
                                        return bsMaterialResult.codeOccurrence === undefined
                                      }
                                    })
                                    .map((material: BSMaterialResult) => (
                                      <Grid
                                        item
                                        container
                                        pt={1}
                                        pb={1}
                                        xs={12}
                                        borderRadius={3}
                                        key={material.id}
                                        boxShadow={5}
                                        onClick={() => {
                                          setOpenMaterialDetail(true)
                                          setSelectedBsMaterialResult(material)
                                        }}
                                        sx={{ ":hover": { cursor: "pointer", boxShadow: 10 } }}>
                                        {isSubmiting && material.id === selectedBsMaterialResult?.id ? (
                                          <CircularProgress />
                                        ) : (
                                          <>
                                            <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
                                              <Tooltip title={material.ficheId} arrow>
                                                <Typography
                                                  variant="subtitle2"
                                                  fontSize={12}
                                                  noWrap
                                                  style={{
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                  }}>
                                                  {material.ficheId}
                                                </Typography>
                                              </Tooltip>
                                            </Grid>
                                            <Grid item xs={7} display="flex" flexDirection="row" alignItems="center">
                                              {bsProject?.status === ProjectStatusEnum.IN_PROGRESS && (
                                                <Tooltip title="Modifier les informations">
                                                  <IconButton
                                                    onClick={(event) => {
                                                      event.stopPropagation()
                                                      setSelectedBsMaterialResult(material)
                                                      setOpen(true)
                                                    }}>
                                                    <ModeEditOutlineIcon fontSize="small" />
                                                  </IconButton>
                                                </Tooltip>
                                              )}
                                              <Typography variant="subtitle2" fontSize={12}>
                                                {material.nomProduit.endsWith(" - DONNEE ENVIRONNEMENTALE PAR DEFAUT")
                                                  ? material.nomProduit.slice(0, -texteASupprimer.length)
                                                  : material.nomProduit}
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
                                              <Typography variant="subtitle2" fontSize={12} sx={{ textAlign: "center" }}>
                                                {`${displayDecimal(material.quantity, 1)} ${material.ficheUniteLabel}`}
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={1} display="flex" justifyContent="center" alignItems="center">
                                              {material.resettable && (
                                                <Tooltip title="Réinitialiser la valeur par défaut">
                                                  <IconButton>
                                                    <RotateLeftIcon
                                                      onClick={(event) => {
                                                        event.stopPropagation()
                                                        setSelectedBsMaterialResult(material)
                                                        setOpenConfirmationDialog(true)
                                                      }}
                                                    />
                                                  </IconButton>
                                                </Tooltip>
                                              )}
                                            </Grid>
                                          </>
                                        )}
                                      </Grid>
                                    ))}
                                </Grid>
                              </AccordionDetails>
                            </Accordion>
                          ))}
                      </AccordionDetails>
                    </Accordion>
                  ))}
              </AccordionDetails>
            </Accordion>
          ))}
      </Box>

      <BSMaterialInfoDialog
        openMaterialDetail={openMaterialDetail}
        setOpenMaterialDetail={setOpenMaterialDetail}
        selectedVariant={selectedVariant}
        selectedBsMaterialResult={selectedBsMaterialResult}
        setSelectedBsMaterialResult={setSelectedBsMaterialResult}
        setIsSubmiting={setIsSubmiting}
        isSubmiting={isSubmiting}
        isReadingMode={bsProject?.status === ProjectStatusEnum.ARCHIVED}
      />

      {selectedBsMaterialResult && (
        <BSMaterialLibDialog selectedBsMaterialResult={selectedBsMaterialResult} open={open} handleClose={handleClose} />
      )}

      <ConfirmationDialog
        id="reset-bs-material-result"
        title="Confirmation de la suppression"
        contentText="Êtes-vous sûr de vouloir rétablir les réglages par défaut ?"
        handleClose={handleClose}
        action={() => {
          if (selectedBsMaterialResult?.id && selectedVariant?.id) {
            return resetMaterial(selectedVariant?.id, selectedBsMaterialResult.id).then(() =>
              setOpenConfirmationDialog(false)
            )
          }
          return Promise.resolve()
        }}
        open={openConfirmationDialog}
      />
    </>
  )
}
