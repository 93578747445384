import CodeReference from "../code-acv/code-reference"
import PsetAcv from "../pset-acv"
import { Quantity } from "../quantity"
import { CodeExtrait } from "./code-extrait"

export class CodeExtraitCreation {
  code = ""
  occurence = ""
  descriptionFromCodeAcv = ""
  lot = ""
  sousLot = ""
  descriptionFromBimModel = ""
  color = ""
  extractedQuantities: Quantity = new Quantity()
  errors: string[] = []

  /**
   * The element ids in the IFC model that are linked to this code
   */
  elementIds: number[] = []

  static fromCodeExtrait(codeExtrait: CodeExtrait): CodeExtraitCreation {
    const codeExtraitCreation = new CodeExtraitCreation()

    codeExtraitCreation.code = codeExtrait.code
    codeExtraitCreation.occurence = codeExtrait.occurence
    codeExtraitCreation.descriptionFromCodeAcv = codeExtrait.descriptionFromCodeAcv
    codeExtraitCreation.descriptionFromBimModel = codeExtrait.descriptionFromBimModel
    codeExtraitCreation.lot = codeExtrait.lot
    codeExtraitCreation.sousLot = codeExtrait.sousLot
    codeExtraitCreation.extractedQuantities.length = codeExtrait.extractedQuantities.length || 0
    codeExtraitCreation.extractedQuantities.height = codeExtrait.extractedQuantities.height || 0
    codeExtraitCreation.extractedQuantities.number = codeExtrait.extractedQuantities.number || 0
    codeExtraitCreation.extractedQuantities.surface = codeExtrait.extractedQuantities.surface || 0
    codeExtraitCreation.color = codeExtrait.color
    codeExtraitCreation.elementIds = codeExtrait.elementIds
    codeExtraitCreation.errors = []

    return codeExtraitCreation
  }

  static from(codeAcv: CodeReference, psetAcv: PsetAcv): CodeExtraitCreation {
    const codeExtraitCreation = new CodeExtraitCreation()

    codeExtraitCreation.code = codeAcv.code
    codeExtraitCreation.occurence = psetAcv.occurrence
    codeExtraitCreation.lot = codeAcv.lot
    codeExtraitCreation.sousLot = codeAcv.sousLot
    codeExtraitCreation.descriptionFromCodeAcv = codeAcv.description
    codeExtraitCreation.descriptionFromBimModel = psetAcv.description
    codeExtraitCreation.extractedQuantities = Quantity.clone(psetAcv.quantity)
    if (psetAcv.elementIds) {
      codeExtraitCreation.elementIds.push(...psetAcv.elementIds)
    }
    codeExtraitCreation.errors.push(...psetAcv.errors)

    return codeExtraitCreation
  }
}
