import Settings from "@mui/icons-material/Settings"
import { Box, Grid, Typography } from "@mui/material"
import LinearProgress from "@mui/material/LinearProgress"
import * as React from "react"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { pagesUrl } from "../../core/appConstants"
import { AppNavBarContext } from "../../core/context/nav-bar/AppNavBarContext"
import { OrganizationContext } from "../../core/context/organization/organization-context"
import { AppSideBarContext } from "../../core/context/side-bar/side-bar-context"
import { formatToFrench } from "../../core/services/date-service"
import { isNumber } from "../../core/services/helper-service"

export function MyOfferPage(): React.JSX.Element {
  const location = useLocation()

  const { organization } = React.useContext(OrganizationContext)
  const { setShowAppSide } = React.useContext(AppSideBarContext)
  const { setTitle, setPreviousUrl, setPagination, setIcon } = React.useContext(AppNavBarContext)

  useEffect(() => {
    setShowAppSide(true)
    setTitle("Mon offre")
    setIcon(<Settings />)
    setPagination(["Mon compte"])
    setPreviousUrl(pagesUrl.HOME)
  }, [setShowAppSide, location, setTitle, setPreviousUrl, setIcon, setPagination])

  function getNumberIndicator(firstNum?: number, secondNum?: number, isActive = true): React.JSX.Element {
    return (
      <Box display="flex" flexDirection="row" gap={1} py={1}>
        <Typography fontSize={32} fontWeight={600}>
          {firstNum}
        </Typography>

        <Typography fontSize={18} fontWeight={600} pt={0.5}>
          / {isActive ? formatMax(secondNum) : 0}
        </Typography>
      </Box>
    )
  }

  function formatMax(max: number | undefined): number | string | React.JSX.Element {
    if (max === undefined || !isNumber(max)) {
      return 0
    } else if (max < 0) {
      // This means infinite licence available
      return <span style={{ fontWeight: 900 }}>∞</span>
    } else {
      return max
    }
  }

  function normalizeTo100(value: number, max: number): number {
    if (max === 0 || max === undefined) {
      return 100
    } else if (max === -1) {
      return 0
    }
    return (value / max) * 100
  }

  return (
    <Grid
      container
      display="flex"
      justifyContent="space-around"
      alignItems="flex-start"
      borderRadius={3}
      gap={3}
      p={3}
      sx={{ backgroundColor: "white" }}>
      <Grid item xs={12}>
        <Typography fontSize={24} fontWeight={600}>
          Inclus dans votre offre
        </Typography>
      </Grid>
      <Grid item xs={12} display="flex" flexDirection="row" alignItems="center" gap={1}>
        {organization?.createdDate && (
          <>
            <Typography variant="body2">Depuis le</Typography>
            <Typography fontSize={14} fontWeight={600}>
              {formatToFrench(new Date(organization?.createdDate))}
            </Typography>
          </>
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">Votre abonnement vous permet d’accéder aux services Time To Beem :</Typography>
      </Grid>
      <Grid container display="flex" justifyContent="space-around">
        <Grid item xs={3.9} borderRadius={3} p={3} spacing={3} sx={{ backgroundColor: "#E6F0FB" }}>
          {getNumberIndicator(organization?.bsProjectCounter, organization?.bsMaxProjectCount, organization?.beemShotOption)}
          <Typography variant="body1">Projet Beem Shot</Typography>
          <LinearProgress
            variant="determinate"
            value={normalizeTo100(organization?.bsProjectCounter ?? 0, organization?.bsMaxProjectCount || 0)}
            sx={{
              height: 10,
              borderRadius: 5,
              backgroundColor: (theme) =>
                theme.palette.mode === "dark" ? theme.palette.grey[800] : theme.palette.grey[200],
              "& .MuiLinearProgress-bar": {
                borderRadius: 5,
                backgroundColor: "#08CA8F",
              },
            }}
          />
        </Grid>
        <Grid item xs={3.9} borderRadius={3} p={3} sx={{ backgroundColor: "#E6F0FB" }}>
          {getNumberIndicator(organization?.projectCounter, organization?.maxProjectCount, organization?.beemMasterOption)}
          <Typography variant="body1">Projet Beem Master</Typography>
          <LinearProgress
            variant="determinate"
            value={normalizeTo100(organization?.projectCounter ?? 0, organization?.maxProjectCount || 0)}
            sx={{
              height: 10,
              borderRadius: 5,
              backgroundColor: (theme) =>
                theme.palette.mode === "dark" ? theme.palette.grey[800] : theme.palette.grey[200],
              "& .MuiLinearProgress-bar": {
                borderRadius: 5,
                backgroundColor: "#08CA8F",
              },
            }}
          />
        </Grid>
        {organization?.beemPilotOption ? (
          <Grid item xs={3.9} borderRadius={3} p={3} sx={{ backgroundColor: "#E6F0FB" }}>
            {getNumberIndicator(organization?.rseeProjectCounter, organization?.maxRseeProjectCount)}
            <Typography variant="body1"> Projet Beem Pilot</Typography>
            <LinearProgress
              variant="determinate"
              value={normalizeTo100(organization?.rseeProjectCounter ?? 0, organization?.maxRseeProjectCount ?? 0)}
              sx={{
                height: 10,
                borderRadius: 5,
                backgroundColor: (theme) =>
                  theme.palette.mode === "dark" ? theme.palette.grey[800] : theme.palette.grey[200],
                "& .MuiLinearProgress-bar": {
                  borderRadius: 5,
                  backgroundColor: "#08CA8F",
                },
              }}
            />
          </Grid>
        ) : (
          <Grid item xs={3.9} />
        )}
      </Grid>
      <Grid container display="flex" justifyContent="space-around">
        <Grid item xs={3.9} borderRadius={3} p={3} sx={{ backgroundColor: "#0D6E58", color: "white" }}>
          {getNumberIndicator(organization?.licenceCounter, organization?.maxLicenceCount)}
          <Typography variant="body1"> Collaborateurs</Typography>
          <LinearProgress
            variant="determinate"
            value={normalizeTo100(organization?.licenceCounter ?? 0, organization?.maxLicenceCount ?? 0)}
            sx={{
              height: 10,
              borderRadius: 5,
              backgroundColor: (theme) =>
                theme.palette.mode === "dark" ? theme.palette.grey[800] : theme.palette.grey[200],
              "& .MuiLinearProgress-bar": {
                borderRadius: 5,
                backgroundColor: "#08CA8F",
              },
            }}
          />
        </Grid>
        {organization?.beemMasterOption ? (
          <Grid item xs={3.9} border={2} borderRadius={3} p={3} sx={{ backgroundColor: "#374771", color: "white" }}>
            {getNumberIndicator(organization?.invitedCounter, organization?.maxInvitedCount)}
            <Typography variant="body1">Invités </Typography>
            <LinearProgress
              variant="determinate"
              value={normalizeTo100(organization?.invitedCounter ?? 0, organization?.maxInvitedCount ?? 0)}
              sx={{
                height: 10,
                borderRadius: 5,
                backgroundColor: (theme) =>
                  theme.palette.mode === "dark" ? theme.palette.grey[800] : theme.palette.grey[200],
                "& .MuiLinearProgress-bar": {
                  borderRadius: 5,
                  backgroundColor: "#08CA8F",
                },
              }}
            />
          </Grid>
        ) : (
          <Grid item xs={3.9} />
        )}
        <Grid item xs={3.9} />
      </Grid>
    </Grid>
  )
}
