import React, { useContext, useEffect, useMemo, useState } from "react"
import { Children } from "../../components/miscellianous/children"
import { useUser } from "../hooks/use-user"
import { TutorialEnabledStore } from "../store/tutorial-enabled-store"
import { ProjectRoleContext } from "./user/project-role-context"

export const TutorialEnabledContext = React.createContext<TutorialEnabledStore>({} as TutorialEnabledStore)

export default function TutorialEnabledContextProvider({ children }: Children): React.JSX.Element {
  const [tutorialEnabled, setTutorialEnabled] = useState<boolean>(false)
  const { getIsBimModelTutorialEnabled } = useUser()
  const { isOrganizationUltimateUser } = useContext(ProjectRoleContext)

  useEffect(() => {
    if (!isOrganizationUltimateUser)
      getIsBimModelTutorialEnabled().then((response) => setTutorialEnabled(response.bimModelTutorialEnabled))
  }, [getIsBimModelTutorialEnabled])

  const tutorialEnabledStore = useMemo(
    () => new TutorialEnabledStore(tutorialEnabled, setTutorialEnabled),
    [tutorialEnabled]
  )
  return <TutorialEnabledContext.Provider value={tutorialEnabledStore}>{children}</TutorialEnabledContext.Provider>
}
