import { ContactMail, ContactPhone } from "@mui/icons-material"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material"
import React, { Dispatch, SetStateAction, useContext } from "react"
import { appConstants } from "../../../core/appConstants"
import { OrganizationContext } from "../../../core/context/organization/organization-context"
import { displayNumber } from "../../../core/services/helper-service"

type IProps = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}
export default function MaxProjectModal({ open, setOpen }: IProps): React.JSX.Element {
  const { organization } = useContext(OrganizationContext)

  function handleClose(): void {
    setOpen(false)
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Maximum de projets atteint</DialogTitle>
      <DialogContent>
        <DialogContentText fontSize={12} mb={2}>
          Vous avez atteint le maximum de projets ({displayNumber(organization?.maxProjectCount)}) pour votre organisation.
          <br />
          Pour augmenter ce maximum, vous pouvez nous contacter:
        </DialogContentText>
        <Box display="flex" flexDirection="row" alignItems="center" mb={1}>
          <ContactMail fontSize="small" />
          <Typography ml={2}>
            <a href={`mailto: ${appConstants.contact.mail}`}>
              <i>{appConstants.contact.mail}</i>
            </a>
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" mb={1}>
          <ContactPhone fontSize="small" />
          <Typography ml={2}>{appConstants.contact.phone}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Fermer</Button>
      </DialogActions>
    </Dialog>
  )
}
