import { Typography } from "@mui/material"
import React from "react"

export interface ITextInputProps {
  label: string
}

export function TitleFormTypography({ label }: Readonly<ITextInputProps>): React.JSX.Element {
  return (
    <Typography
      sx={{
        fontFamily: "Poppins",
        fontSize: "24px",
        fontWeight: 600,
        lineHeight: "36px",
        textAlign: "left",
      }}>
      {label}
    </Typography>
  )
}
