import { Group, Plane, Scene, Vector3 } from "three"
import { IfcViewerAPI } from "web-ifc-viewer"
import { MeasurementHelper } from "../../../helpers/MeasurementHelper"
import { UnitsHelper } from "../../../helpers/UnitsHelper"
import { PointLabelGroup } from "../annotations/LabelGroupTypes"
import { LabelStyle } from "../annotations/LabelStyle"
import TextLabels from "../annotations/TextLabelsGenerator"

/**
 * Add point coordinates in 3d space
 */
export class PointCoordinatesCommand {
  //extends Command
  static addedPoints: Array<PointLabelGroup> = []
  ifc: IfcViewerAPI
  scene: THREE.Scene
  point: THREE.Vector3
  textPoint: THREE.Vector3
  coordinatesString?: string
  textPosition?: THREE.Vector3
  group: PointLabelGroup
  text: any
  highLight: any
  expressId: number | undefined
  modelId: number | undefined

  constructor(
    point: THREE.Vector3,
    textPoint: THREE.Vector3,
    scene: THREE.Scene,
    ifc: IfcViewerAPI,
    objectExpressId: number | undefined,
    objectModelId: number | undefined
  ) {
    this.ifc = ifc
    this.scene = scene
    this.point = point
    this.textPoint = textPoint
    this.highLight = TextLabels.createHighLight(this.point)
    this.highLight.canNotBeRayCasted = false
    this.expressId = objectExpressId
    this.modelId = objectModelId
    this.group = new Group()
    this.setUpGroupData()
  }

  /**
   * hide points that are outside all clipping planes
   * @param planes clipping planes
   */
  static hidePointsOutsideOfClippingView(planes?: Array<Plane>, scene?: Scene): void {
    const objs = PointCoordinatesCommand.addedPoints
    if (planes != null && planes.length > 0 && objs.length > 0) {
      const point: Vector3 = new Vector3(0, 0, 0)
      const toBeShown = objs.filter((elem) =>
        planes.every(
          (elem2) =>
            elem.point != null &&
            (elem.point.clone().sub(elem2.coplanarPoint(point)).dot(elem2.normal) >= 0 || elem.expressId === undefined)
        )
      )
      const toBeHidden = objs.filter((o) => !toBeShown.includes(o))
      for (const o of toBeHidden) {
        o.canBeVisible = false
        scene?.remove(o)
      }
      for (const p of toBeShown) {
        p.canBeVisible = true
        scene?.add(p)
      }
    }
  }

  /**
   * hide points that are outside all clipping planes
   * @param planes clipping planes
   */
  static showAllPoints(scene?: Scene): void {
    for (const p of PointCoordinatesCommand.addedPoints) {
      p.canBeVisible = true
      scene?.add(p)
    }
  }

  execute(): void {
    this.scene.add(this.group)
    PointCoordinatesCommand.addedPoints.push(this.group)
  }

  undo(): void {
    this.scene.remove(this.group)
    PointCoordinatesCommand.addedPoints = PointCoordinatesCommand.addedPoints.filter((ar) => ar !== this.group)
  }

  redo(): void {
    this.scene.add(this.text)
    PointCoordinatesCommand.addedPoints.push(this.group)
  }

  dispose(): void {
    this.group.remove(this.text)
    this.group.remove(this.highLight)
    this.undo()
    this.text.material.dispose()
    this.text.clear()
    this.highLight.clear()
    this.group.assosiatedObject = null
    this.group.clear()
  }

  async defineTextLabel(): Promise<void> {
    let modelId = 0
    if (this.modelId) modelId = this.modelId
    const units = await UnitsHelper.getConversionUnits(this.ifc, modelId)
    const spacesNumber = this.getNumberOfSpaces()
    const precision = UnitsHelper.setNumericalPrecision(units.projectUnit)
    this.coordinatesString = `  z: ${this.textPoint.y.toFixed(precision)}\n  y: ${this.textPoint.z.toFixed(
      precision
    )} ${" ".repeat(spacesNumber)}[${units.unitSymbol}]\n  x: ${this.textPoint.x.toFixed(precision)}`
    this.textPosition = this.point.clone().add(new Vector3(0, 0.2, 0))
    this.text = TextLabels.makeTextSprite(
      this.coordinatesString,
      this.textPosition.x,
      this.textPosition.y,
      this.textPosition.z,
      LabelStyle.pointLabelStyle
    )
    this.text.canNotBeRayCasted = false
    this.text.material.depthTest = false
  }

  async updateText(): Promise<void> {
    this.scene.remove(this.text)
    this.group.remove(this.text)
    this.text.material.dispose()
    this.text.clear()
    await this.defineTextLabel()
    this.group.add(this.text)
  }

  updateTextSize(size?: string): void {
    MeasurementHelper.updateLabelSize(this.ifc, this.text, this.textPosition ?? new Vector3(), Number(size))
  }

  /**
   * Set up the instance's geometric 3d object's group and it's properties
   */
  private async setUpGroupData(): Promise<void> {
    await this.defineTextLabel()
    this.group.add(this.highLight)
    this.updateTextSize()
    this.group.add(this.text)
    this.group.expressId = this.expressId
    this.group.modelId = this.modelId
    this.group.point = this.point
    this.group.assosiatedObject = this
    this.group.canBeVisible = true
  }

  /**
   * Calculate number of spaces needed to be added to annotation string for unit placement
   * @returns number of spaces
   */
  private getNumberOfSpaces(): number {
    if (this.point === null) return 1
    const xNum = MeasurementHelper.numDigits(this.point.x)
    const yNum = MeasurementHelper.numDigits(this.point.y)
    const zNum = MeasurementHelper.numDigits(this.point.z)
    const max = Math.max(...[xNum, yNum, zNum])
    return max - yNum + 1
  }
}
