import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Children } from "../../../../components/miscellianous/children"
import { BSMaterialResult } from "../../../dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { TypeMaterialEnum } from "../../../enum/typeMaterialEnum"
import { useBSMaterialResults } from "../../../hooks/beem-shot/useBSMaterialResults"
import { BSProjectContext } from "../BSProject/BSProjectContext"
import { BSVariantContext } from "../BSVariant/BSVariantContext"

export const BSMaterialResultContext = React.createContext<BsMaterialResultStore>({} as BsMaterialResultStore)

export function BSMaterialContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const { getBSMaterialResult, updateBsMaterialQuantities, resetBsMaterialQuantities, addBSOverriddenMaterial } =
    useBSMaterialResults()

  const { bsProject } = useContext(BSProjectContext)
  const { selectedVariant } = useContext(BSVariantContext)

  const [bsMaterialResults, setBsMaterialResults] = useState<BSMaterialResult[]>([])

  useEffect(() => {
    if (selectedVariant?.id) {
      getBSMaterialResult(selectedVariant?.id).then((x) => setBsMaterialResults(x))
    }
  }, [getBSMaterialResult, selectedVariant?.id, bsProject])

  const getAllMaterialResult = useCallback(
    (variantId: string): Promise<void> => getBSMaterialResult(variantId).then((x) => setBsMaterialResults(x)),
    [getBSMaterialResult]
  )

  const resetMaterial = useCallback(
    (variantId: string, materialResultId: string): Promise<void> =>
      resetBsMaterialQuantities(variantId, materialResultId).then(() => {
        if (selectedVariant?.id) {
          getAllMaterialResult(selectedVariant?.id)
        }
      }),
    [getAllMaterialResult, resetBsMaterialQuantities, selectedVariant?.id]
  )

  const updateMaterialQuantities = useCallback(
    (variantId: string, materialResultId: string, quantity: string): Promise<void> =>
      updateBsMaterialQuantities(variantId, materialResultId, quantity).then(() => {
        if (selectedVariant?.id) {
          getBSMaterialResult(selectedVariant?.id).then((x) => setBsMaterialResults(x))
        }
      }),
    [getBSMaterialResult, selectedVariant?.id, updateBsMaterialQuantities]
  )

  const addOverridenMaterial = useCallback(
    (
      variantId: string,
      materialResultId: string,
      ficheConfigureeId: string | undefined,
      iniesId: number | undefined,
      actualQuantities: number,
      typeRecord: TypeMaterialEnum
    ): Promise<void> =>
      addBSOverriddenMaterial(variantId, materialResultId, ficheConfigureeId, iniesId, actualQuantities, typeRecord).then(
        () => {
          getBSMaterialResult(variantId).then((x) => setBsMaterialResults(x))
        }
      ),
    [addBSOverriddenMaterial, getBSMaterialResult]
  )

  const bsMaterialResultStore = useMemo(
    () => ({
      bsMaterialResults,
      setBsMaterialResults,
      updateMaterialQuantities,
      getAllMaterialResult,
      resetMaterial,
      addOverridenMaterial,
    }),
    [addOverridenMaterial, bsMaterialResults, getAllMaterialResult, resetMaterial, updateMaterialQuantities]
  )
  return <BSMaterialResultContext.Provider value={bsMaterialResultStore}>{children}</BSMaterialResultContext.Provider>
}

export type BsMaterialResultStore = {
  bsMaterialResults: BSMaterialResult[]
  setBsMaterialResults: React.Dispatch<React.SetStateAction<BSMaterialResult[]>>
  updateMaterialQuantities(variantId: string, materialResultId: string, quantity: string): Promise<void>
  getAllMaterialResult(variantId: string): Promise<void>
  resetMaterial(variantId: string, materialResultId: string): Promise<void>
  addOverridenMaterial(
    variantId: string,
    materialResultId: string,
    ficheConfigureeId: string | undefined,
    iniesId: number | undefined,
    actualQuantities: number,
    typeRecord: TypeMaterialEnum
  ): Promise<void>
}
