import { Box, Card, CardContent, CircularProgress, Dialog, DialogContent, DialogTitle, Typography } from "@mui/material"
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline"
import { useEffect, useState } from "react"
import { customPalette } from "../../../../../../theme"
import VideoPlayer from "../../../../../components/video-player/video-player"
import { useTutorial } from "../../../../../core/hooks/bim-model/use-tutorial"

type DigitalModelVideoCardProps = {
  title: string
  video: string
  thumbnail: string
}

export default function DigitalModelVideoCard({ title, video, thumbnail }: DigitalModelVideoCardProps): React.JSX.Element {
  const { fetchVideoData, fetchVideoThumbnail } = useTutorial()
  const [open, setOpen] = useState(false)
  const [url, setUrl] = useState<string | undefined>(undefined)
  const [thumbnailUrl, setThumbnailUrl] = useState<string | undefined>(undefined)

  useEffect(() => {
    fetchVideoData(video).then((signedUrl) => setUrl(signedUrl))
  }, [fetchVideoData, video])

  useEffect(() => {
    fetchVideoThumbnail(video).then((signedUrl) => setThumbnailUrl(signedUrl))
  }, [fetchVideoData, video])

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth title={title}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {url ? (
            <Box>
              <VideoPlayer videoUrl={url} thumbnailUrl={thumbnailUrl} />
            </Box>
          ) : (
            <CircularProgress />
          )}
        </DialogContent>
      </Dialog>
      {thumbnailUrl && (
        <Card
          sx={{
            border: `3px solid ${customPalette.lightGray2}`,
            aspectRatio: "16/9",
            backgroundImage: `url(${thumbnailUrl})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => setOpen(true)}>
          <CardContent>
            <PlayCircleOutlineIcon sx={{ color: customPalette.lightGray2 }} />
          </CardContent>
        </Card>
      )}
      <Box sx={{ textTransform: "uppercase", mt: "12px" }}>
        <Typography component="span" sx={{ fontWeight: "bold" }}>
          {title}
        </Typography>
      </Box>
    </>
  )
}
