import { Box } from "@mui/material"
import React, { FormEvent, useCallback, useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router"
import { ErrorContext } from "../../../../components/layout/error-snackbar"
import { pagesUrl } from "../../../../core/appConstants"
import { ProjectContext } from "../../../../core/context/project/project-context"
import { SelectedPhaseContext } from "../../../../core/context/selected-phase-context"
import { ProjectPhase } from "../../../../core/dto/project/project-phase"
import { PreviousPage } from "../../../../core/enum/previousPage"
import { useProjects } from "../../../../core/hooks/projects/use-projects"
import ListProjectPhaseStepperItem from "./list-project-phase-stepper-item"
import ProjectPhaseStepperHeader from "./project-phase-stepper-header"
import "./project-phase-stepper.css"
import { isDateValid } from "../../../../core/services/date-service"
import { PhaseEnum } from "../../../../core/enum/phaseEnum"

export default function ProjectPhaseStepper(): React.JSX.Element {
  const { projectId } = useParams()
  const { project, setProject } = useContext(ProjectContext)
  const navigate = useNavigate()
  const { postPhases } = useProjects()
  const openErrorSnackbar: (err: Error, message?: string) => void = useContext(ErrorContext)
  const { setSelectedPhase } = useContext(SelectedPhaseContext)

  const initPhaseForm = useCallback((): ProjectPhase[] => (project.phases ? project.phases : []), [project])
  const [projectPhasesForm, setProjectPhasesForm] = useState<ProjectPhase[]>(initPhaseForm())

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState<Record<string, string | undefined>>({})

  useEffect(() => {
    const projectPhases = initPhaseForm()
    setProjectPhasesForm(projectPhases)
  }, [initPhaseForm])

  function goToPreviousPage(): void {
    navigate(`/project/${project.id}/control-board`, {
      state: {
        project,
        previousPage: PreviousPage.PHASES,
      },
    })
  }

  function validate(): boolean {
    let isValid = true

    if (projectPhasesForm === undefined || projectPhasesForm === null || projectPhasesForm.length === 0) {
      isValid = false
      openErrorSnackbar(new Error("Veuillez sélectionner au moins une phase !"))
    }

    if (projectPhasesForm.find((element) => element.currentPhase) === undefined) {
      isValid = false
      openErrorSnackbar(new Error("Veuillez sélectionner au moins une phase actuelle"))
    }

    projectPhasesForm.forEach((phase) => {
      if (!validatePhase(phase)) {
        isValid = false
      }
    })

    return isValid
  }

  function validatePhase(projectPhase: ProjectPhase): boolean {
    if (!isDateValid(projectPhase.endingDate)) {
      const errorKey = createErrorKey(projectPhase.phase)
      setError({ ...error, [errorKey]: "La date est invalide" })
      return false
    }
    return true
  }

  function cleanError(errorKey: string): void {
    setError({ ...error, [errorKey]: undefined })
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): Promise<void> {
    event.preventDefault()
    if (validate() && projectId) {
      setIsSubmitting(true)
      const currentProjectPhase: ProjectPhase | undefined = projectPhasesForm.find((e) => e.currentPhase)
      if (currentProjectPhase) {
        setSelectedPhase(currentProjectPhase.phase)
      }
      postPhases(projectId, projectPhasesForm)
        .then(() => {
          setProject({ ...project, phases: project.phases })
          navigate(`${pagesUrl.PROJECT_PAGE}/${project.id}/control-board`, {
            state: {
              project,
            },
          })
        })
        .catch((err: Error) => {
          openErrorSnackbar(err)
        })
        .finally(() => setIsSubmitting(false))
    }

    return Promise.resolve()
  }

  function createErrorKey(phase: PhaseEnum): string {
    return `endingDate${phase}`
  }

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <ProjectPhaseStepperHeader project={project} goToPreviousPage={goToPreviousPage} isSubmitting={isSubmitting} />
      <ListProjectPhaseStepperItem
        phasesForm={projectPhasesForm}
        error={error}
        setProjectPhasesForm={setProjectPhasesForm}
        createErrorKey={createErrorKey}
        cleanError={cleanError}
      />
    </Box>
  )
}
