import { UnitEnum } from "../../enum/unitEnum"
import { Parameters } from "../material/parameters"

export class FicheConfiguree {
  id!: string
  epdcId!: string
  name!: string
  configuratorName!: string
  functionalUnit!: UnitEnum
  referenceLifeTime!: number
  re2020CarbonImpact!: number
  description!: string
  parameters!: Parameters[]

  lastModifiedDate!: string
  lastModifiedUserName!: string
  createdByUser!: string

  static fromDto(iniesRecordDto: any): FicheConfiguree {
    const iniesRecord = new FicheConfiguree()

    iniesRecord.id = iniesRecordDto.id
    iniesRecord.epdcId = iniesRecordDto.epdcId
    iniesRecord.name = iniesRecordDto.name
    iniesRecord.configuratorName = iniesRecordDto.configuratorName
    iniesRecord.functionalUnit = iniesRecordDto.functionalUnit
    iniesRecord.referenceLifeTime = iniesRecordDto.referenceLifeTime
    iniesRecord.re2020CarbonImpact = iniesRecordDto.re2020CarbonImpact
    iniesRecord.description = iniesRecordDto.description
    iniesRecord.parameters = iniesRecordDto.parameters

    iniesRecord.lastModifiedDate = iniesRecordDto.lastModifiedDate
    iniesRecord.lastModifiedUserName = iniesRecordDto.lastModifiedUserName
    iniesRecord.createdByUser = iniesRecordDto.createdByUser

    return iniesRecord
  }
}
