import { Box, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Tooltip } from "@mui/material"
import React, { useEffect, useState } from "react"
import ZoneImg from "../../../../../assets/zone.png"
import { CalculationTypesEnum } from "../../../../core/enum/calculationTypeEnum"
import { usagesLabels } from "../../../../core/enum/usageEnum"
import { CalculationForm } from "../../../../core/hooks/calcul-carbon/use-calcul-carbon-information-project-mutation"
import { useCalculation } from "../../../../core/hooks/use-calculation"
import SelectVariant from "../components/select-variant"
import { NumberInput } from "../../../../components/inputs/number-input/number-input"

const calculationTypeData = Object.keys(CalculationTypesEnum).map((i) => i as keyof typeof CalculationTypesEnum)
type Props = {
  selectedVariant: 1 | 2
  handleChangeVariant: (v: 1 | 2) => void
  handleChangeForm: (field: string, value: string | number) => void
  formValueVariant: CalculationForm
  errors: Record<string, string>
  disabled: boolean
}
export default function GeneralInformationForm({
  selectedVariant,
  handleChangeVariant,
  handleChangeForm,
  formValueVariant,
  errors,
  disabled,
}: Readonly<Props>): React.JSX.Element {
  const [climaticZones, setClimaticZones] = useState<string[]>([])
  const { fetchClimaticZones } = useCalculation()

  useEffect(() => {
    fetchClimaticZones().then((response: string[]) => {
      setClimaticZones(response)
    })
  }, [fetchClimaticZones])

  return (
    <Box
      px={{
        xs: 0,
        sm: 4,
        lg: "388px",
      }}
      mt={2}>
      <Grid container rowSpacing={3}>
        <Grid item xs={12} sm={8} px={2}>
          <FormControl fullWidth>
            <InputLabel sx={{ textTransform: "uppercase", backgroundColor: "white" }}>USAGE PRINCIPAL DE LA ZONE</InputLabel>
            <Select
              fullWidth
              onChange={(e) => handleChangeForm("usage", e?.target?.value)}
              value={formValueVariant.usage?.toString() || ""}
              error={!!errors?.usage}
              aria-describedby="usage"
              disabled={disabled}>
              {usagesLabels.map((lv) => (
                <MenuItem key={`${lv.value}`} value={lv.value.toString()}>
                  {lv.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error id="usage">
              {errors?.usage ? `${errors?.usage}` : ""}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} px={2}>
          <Tooltip
            title={
              <img
                src={ZoneImg}
                alt="ZoneTooltip"
                style={{
                  maxHeight: "300px",
                }}
                width="max-content"
              />
            }
            placement="left-end"
            arrow>
            <FormControl fullWidth>
              <InputLabel sx={{ textTransform: "uppercase", backgroundColor: "white" }}>ZONE CLIMATIQUE</InputLabel>
              {climaticZones.length > 0 && (
                <Select
                  fullWidth
                  onChange={(e) => handleChangeForm("climaticZone", e?.target?.value)}
                  value={formValueVariant.climaticZone || ""}
                  error={!!errors?.climaticZone}
                  aria-describedby="climaticZone"
                  disabled={disabled}>
                  {climaticZones.map((lv) => (
                    <MenuItem key={`${lv}`} value={lv}>
                      {lv}
                    </MenuItem>
                  ))}
                </Select>
              )}
              <FormHelperText error id="climaticZone">
                {errors?.climaticZone ? `${errors?.climaticZone}` : ""}
              </FormHelperText>
            </FormControl>
          </Tooltip>
        </Grid>
        <Grid item xs={12} sm={6} px={2}>
          <NumberInput
            id="altitude"
            label="ALTITUDE"
            value={formValueVariant.altitude}
            handleChange={(value) => handleChangeForm("altitude", !Number.isNaN(+value) ? +value : 0)}
            variant="outlined"
            // error={errors}
            disabled={disabled}
            decimalScale={2}
            unit="m"
            // endAdornment={<InputAdornment position='end'>m</InputAdornment>}
          />
        </Grid>
        <Grid item xs={12} sm={6} px={2}>
          <FormControl fullWidth>
            <InputLabel sx={{ textTransform: "uppercase", backgroundColor: "white" }}>Type de calcul</InputLabel>
            <Select
              fullWidth
              onChange={(e) => handleChangeForm("calculationType", e?.target?.value)}
              value={formValueVariant.calculationType || ""}
              error={!!errors?.calculationType}
              aria-describedby="calculationType"
              disabled={disabled}>
              {calculationTypeData.map((key) => (
                <MenuItem key={`${key}`} value={key} disabled={key.toString() !== CalculationTypesEnum.RE2020.toString()}>
                  {CalculationTypesEnum[key]}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error id="calculationType">
              {errors?.calculationType ? `${errors?.calculationType}` : ""}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} mt={3} display="flex" flexDirection="row" justifyContent="center">
          <SelectVariant selectedVariant={selectedVariant} handleChangeVariant={handleChangeVariant} />
        </Grid>
        {selectedVariant === 1 && (
          <Grid item xs={12} px={2}>
            <TextField
              fullWidth
              label="NOM DE LA BASE"
              onChange={(e) => handleChangeForm("variantName1", e.target.value)}
              variant="outlined"
              value={formValueVariant.variantName1 || ""}
              error={!!errors?.variantName1}
              helperText={errors?.variantName1 && errors.variantName1}
              disabled={disabled}
            />
          </Grid>
        )}
        {selectedVariant === 2 && (
          <Grid item xs={12} px={2}>
            <TextField
              fullWidth
              label="NOM DE LA VARIANTE"
              onChange={(e) => handleChangeForm("variantName2", e.target.value)}
              variant="outlined"
              value={formValueVariant.variantName2 || ""}
              error={!!errors?.variantName2}
              helperText={errors?.variantName2 && errors?.variantName2}
              disabled={disabled}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  )
}
