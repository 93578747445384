import React, { Dispatch, SetStateAction, useMemo, useState } from "react"
import { Children } from "../../../components/miscellianous/children"

export const MaterialLibDeclarationTypeContext = React.createContext<MaterialLibDeclarationTypeStore>(
  {} as MaterialLibDeclarationTypeStore
)

export default function MaterialLibDeclarationTypeContextProvider({ children }: Children): React.JSX.Element {
  const [declarationTypes, setDeclarationTypes] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const store: MaterialLibDeclarationTypeStore = useMemo(
    () => ({ declarationTypes, setDeclarationTypes, loading, setLoading }),
    [loading, declarationTypes]
  )
  return <MaterialLibDeclarationTypeContext.Provider value={store}>{children}</MaterialLibDeclarationTypeContext.Provider>
}

export type MaterialLibDeclarationTypeStore = {
  declarationTypes: string[]
  setDeclarationTypes: Dispatch<SetStateAction<string[]>>
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
}
