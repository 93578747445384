import { Clear } from "@mui/icons-material"
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material"
import React, { useState } from "react"
import { NomenclatureFilterDto } from "../../core/dto/nomenclature-filter"
import { NomenclatureTreeDto } from "../../core/dto/nomenclature-tree"
import "./material-page.css"
import { MaterialLibPage } from "../../core/enum/materialLibPage"
import MaterialRecordCreation from "../../core/dto/material/material-record-creation"
import { DeclarationFilter } from "../../core/enum/declarationFilter"
import { useFetchMaterialLibDeclarationType } from "../../core/hooks/material-lib/use-fetch-material-lib-declaration-type"
import "./material-page-modal/material-page-modal.css"
import { getDeclarationTypeLabel } from "../../core/services/declaration-type-service"
import MaterialPageModal from "./material-page-modal/material-page-modal"

type MaterialPageFormProps = {
  selectedLvl0: DeclarationFilter | undefined
  setSelectedLvl0: (selected: DeclarationFilter | undefined) => void
  nomenclatureFilterData: NomenclatureFilterDto
  selectedLvl1: string | undefined
  selectedLvl2: string | undefined
  selectedLvl3: string | undefined
  selectedLvl4: string | undefined
  selectedTypologieDeclaration: string | undefined
  responsibleOrganism: string | undefined
  setSelectedLvl1: React.Dispatch<React.SetStateAction<string | undefined>>
  setSelectedLvl2: React.Dispatch<React.SetStateAction<string | undefined>>
  setSelectedLvl3: React.Dispatch<React.SetStateAction<string | undefined>>
  setSelectedLvl4: React.Dispatch<React.SetStateAction<string | undefined>>
  setSelectedTypologieDeclaration: (value: React.SetStateAction<string | undefined>) => void
  setResponsibleOrganism: React.Dispatch<React.SetStateAction<string | undefined>>
  typeMaterialLib: MaterialLibPage
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  setMaterialCreation: React.Dispatch<React.SetStateAction<MaterialRecordCreation>> | undefined
  materialCreation: MaterialRecordCreation | undefined
  isWriting: boolean
}

const style = {
  width: "98%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: "0 32px",
  display: "block",
  overflowX: "hidden",
  height: "100%",
}

export function MaterialFilterForm({
  selectedLvl0,
  setSelectedLvl0,
  nomenclatureFilterData,
  selectedLvl1,
  selectedLvl2,
  selectedLvl3,
  selectedLvl4,
  selectedTypologieDeclaration,
  responsibleOrganism,
  setSelectedLvl1,
  setSelectedLvl2,
  setSelectedLvl3,
  setSelectedLvl4,
  setSelectedTypologieDeclaration,
  setResponsibleOrganism,
  typeMaterialLib,
  open,
  setOpen,
  setMaterialCreation,
  materialCreation,
  isWriting,
}: Readonly<MaterialPageFormProps>): React.JSX.Element {
  const { declarationTypes } = useFetchMaterialLibDeclarationType()

  const [materialProvider, setMaterialProvider] = useState<string | undefined>()
  const [materialReference, setMaterialReference] = useState<string | undefined>()

  function handleOpen(): void {
    setOpen(true)
  }

  function handleClose(): void {
    setOpen(false)
  }

  const categories: NomenclatureTreeDto[] =
    selectedLvl0 === DeclarationFilter.FDES ? nomenclatureFilterData.fdes : nomenclatureFilterData.pep
  const category: NomenclatureTreeDto | undefined = categories?.find((c) => c.iniesId === selectedLvl1)
  const subCategory: NomenclatureTreeDto | undefined = category?.children.find((subc) => subc.iniesId === selectedLvl2)
  const material: NomenclatureTreeDto | undefined = subCategory?.children.find((m) => m.iniesId === selectedLvl3)

  function endAdornment(
    value: string | undefined,
    handleChange: (v?: string) => void,
    type: "text" | "select" = "text"
  ): React.JSX.Element | undefined {
    if (value) {
      return (
        <InputAdornment
          position="end"
          sx={{
            mr: type === "text" ? 0 : 2,
          }}>
          <IconButton onClick={() => handleChange(undefined)} size="small">
            <Clear fontSize="small" />
          </IconButton>
        </InputAdornment>
      )
    }
    return undefined
  }

  return (
    <Box className="partial-border" display="flex" flexDirection="column" sx={{ mt: 4 }}>
      {isWriting && (
        <Button
          variant="contained"
          sx={{
            textTransform: "uppercase",
            width: "100%",
            color: "#d0d0d0",
          }}
          onClick={handleOpen}>
          Ajouter une déclaration
        </Button>
      )}
      <Modal
        sx={{
          margin: "40px 0",
        }}
        open={open}
        onClose={handleClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box className="modal-import-inies" sx={style}>
          <MaterialPageModal
            handleClose={handleClose}
            typeMaterialLib={typeMaterialLib}
            setMaterialCreation={setMaterialCreation}
            materialCreation={materialCreation}
          />
        </Box>
      </Modal>
      <FormControl sx={{ m: 1, minWidth: 120, margin: "8px 0", mt: 4 }}>
        <InputLabel sx={{ backgroundColor: "white" }}>TYPE</InputLabel>
        <Select
          label="type"
          value={selectedLvl0 ?? ""}
          onChange={(e) => {
            setSelectedLvl0(e.target.value as DeclarationFilter | undefined)
          }}
          endAdornment={endAdornment(
            selectedLvl0,
            (v) => {
              // It might seem weird to put undefined but v is always undefined. If we refactor someday, the clear method could be simplified. "v" param is useless
              setSelectedLvl0(undefined)
              setSelectedLvl1(v)
              setSelectedLvl2(v)
              setSelectedLvl3(v)
              setSelectedLvl4(v)
            },
            "select"
          )}>
          <MenuItem key={DeclarationFilter.FDES} value={DeclarationFilter.FDES}>
            Produits
          </MenuItem>
          <MenuItem key={DeclarationFilter.PEP} value={DeclarationFilter.PEP}>
            Équipement (PEP)
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 120, margin: "8px 0" }}>
        <InputLabel sx={{ textTransform: "uppercase", backgroundColor: "white" }}>catégorie</InputLabel>
        <Select
          label="catégorie"
          value={selectedLvl1 ?? ""}
          onChange={(e) => setSelectedLvl1(e.target.value)}
          disabled={!selectedLvl0}
          endAdornment={endAdornment(
            selectedLvl1,
            (v) => {
              setSelectedLvl1(v)
              setSelectedLvl2(v)
              setSelectedLvl3(v)
              setSelectedLvl4(v)
            },
            "select"
          )}>
          {categories
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((categoryItem) => (
              <MenuItem key={categoryItem.iniesId} value={categoryItem.iniesId}>
                {categoryItem.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 120, margin: "8px 0" }}>
        <InputLabel sx={{ textTransform: "uppercase", backgroundColor: "white" }}>sous-catégorie</InputLabel>
        <Select
          label="sous-catégorie"
          value={selectedLvl2 ?? ""}
          disabled={!selectedLvl1}
          onChange={(e) => setSelectedLvl2(e.target.value)}
          endAdornment={endAdornment(
            selectedLvl2,
            (v) => {
              setSelectedLvl2(v)
              setSelectedLvl3(v)
              setSelectedLvl4(v)
            },
            "select"
          )}>
          {category?.children
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((subCategoryItem) => (
              <MenuItem key={subCategoryItem.iniesId} value={subCategoryItem.iniesId}>
                {subCategoryItem.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 120, margin: "8px 0" }}>
        <InputLabel sx={{ textTransform: "uppercase", backgroundColor: "white" }}>
          {selectedLvl0 === DeclarationFilter.PEP ? "Type" : "matériaux"}
        </InputLabel>
        <Select
          label={selectedLvl0 === DeclarationFilter.PEP ? "Type" : "matériaux"}
          value={selectedLvl3 ?? ""}
          onChange={(e) => setSelectedLvl3(e.target.value)}
          disabled={!selectedLvl2}
          endAdornment={endAdornment(
            selectedLvl3,
            (v) => {
              setSelectedLvl3(v)
              setSelectedLvl4(v)
            },
            "select"
          )}>
          {subCategory?.children
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((m) => (
              <MenuItem key={m.iniesId} value={m.iniesId}>
                {m.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      {selectedLvl0 === DeclarationFilter.PEP && (
        <FormControl sx={{ m: 1, minWidth: 120, margin: "8px 0" }}>
          <InputLabel sx={{ textTransform: "uppercase", backgroundColor: "white" }}>spécification</InputLabel>
          <Select
            label="spécification"
            value={selectedLvl4 ?? ""}
            onChange={(e) => setSelectedLvl4(e.target.value)}
            disabled={!selectedLvl3}
            endAdornment={endAdornment(selectedLvl4, setSelectedLvl4, "select")}>
            {material?.children
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((m) => (
                <MenuItem key={m.iniesId} value={m.iniesId}>
                  {m.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}

      <TextField
        onChange={(e) => setResponsibleOrganism(e.target.value)}
        value={responsibleOrganism ?? ""}
        sx={{ m: 1, minWidth: 120, margin: "8px 0" }}
        label="ORGANISME DECLARANT"
        variant="outlined"
        InputProps={{ endAdornment: endAdornment(responsibleOrganism, setResponsibleOrganism) }}
      />

      <FormControl sx={{ m: 1, minWidth: 120, margin: "8px 0" }}>
        <InputLabel id="declaration" sx={{ textTransform: "uppercase", backgroundColor: "white" }}>
          typologie de déclaration
        </InputLabel>
        <Select
          labelId="declaration"
          label="typologie de déclaration"
          value={selectedTypologieDeclaration ?? ""}
          onChange={(e) => setSelectedTypologieDeclaration(e.target.value)}
          endAdornment={endAdornment(selectedTypologieDeclaration, setSelectedTypologieDeclaration, "select")}>
          {declarationTypes
            .sort((a, b) => a.localeCompare(b))
            .map((typologie) => (
              <MenuItem key={typologie} value={typologie.toString()}>
                {getDeclarationTypeLabel(typologie)}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <TextField
        InputProps={{ endAdornment: endAdornment(materialProvider, setMaterialProvider) }}
        value={materialProvider ?? ""}
        onChange={(e) => setMaterialProvider(e.target.value)}
        sx={{ m: 1, minWidth: 120, margin: "8px 0" }}
        label="FOURNISSEUR"
        variant="outlined"
      />
      <TextField
        InputProps={{ endAdornment: endAdornment(materialReference, setMaterialReference) }}
        value={materialReference ?? ""}
        onChange={(e) => setMaterialReference(e.target.value)}
        sx={{ m: 1, minWidth: 120, margin: "8px 0" }}
        label="REFERENCE"
        variant="outlined"
      />
    </Box>
  )
}
