import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { Children } from "../../../../components/miscellianous/children"
import { BSProjectCardDto } from "../../../dto/beem-shot/BSProject/BSProjectCardDto"
import { BSProjectCardWithSizeDto } from "../../../dto/beem-shot/BSProject/BSProjectCardWithSizeDto"
import { ProjectStatusEnum } from "../../../enum/projectStatusEnum"
import { useBSProject } from "../../../hooks/beem-shot/useBSProject"
import { UserContext } from "../../user/user-context"

export const BSProjectListContext = React.createContext<BSProjectListStore>({} as BSProjectListStore)

export const BS_PROJECT_ITEMS_PER_PAGE = 6

export function BsProjectListContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const { user } = useContext(UserContext)

  const { getBSProjects } = useBSProject()

  const [bsProjectList, setBsProjectList] = useState<BSProjectCardDto[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [statusFilter, setStatusFilter] = useState<ProjectStatusEnum>(ProjectStatusEnum.ALL)

  const [projectCount, setProjectCount] = useState<number>(0)

  const [lastProjectId, setLastProjectId] = useState<string | undefined>(undefined)

  const [searchState, setSearchState] = useState<string>("")

  const [page, setPage] = useState(1)

  const isOneProjectCreated = useRef<boolean>(false)

  const fetchBsProjectCard = useCallback(
    (actualPage: number, search?: string, limit = BS_PROJECT_ITEMS_PER_PAGE) => {
      if (statusFilter) {
        setIsLoading(true)
        return getBSProjects(statusFilter, actualPage, limit, searchState)
          .then((newBSProjectList: BSProjectCardWithSizeDto) => {
            setBsProjectList(newBSProjectList.projectCardDtoList)
            setProjectCount(newBSProjectList.projectCount)
            if (!isOneProjectCreated.current && newBSProjectList.projectCardDtoList.length > 0) {
              isOneProjectCreated.current = true
            }
            setLastProjectId(newBSProjectList.projectCardDtoList[newBSProjectList.projectCardDtoList.length - 1].id)
          })
          .finally(() => {
            setIsLoading(false)
          })
      }
      return Promise.resolve()
    },
    [getBSProjects, searchState, statusFilter]
  )

  useEffect(() => {
    fetchBsProjectCard(0)
  }, [fetchBsProjectCard])

  const bsProjectListStore = useMemo(
    () => ({
      bsProjectList,
      setBsProjectList,
      isLoading,
      setIsLoading,
      fetchBsProjectCard,
      statusFilter,
      setStatusFilter,
      isOneProjectCreated,
      lastProjectId,
      projectCount,
      searchState,
      setSearchState,
      page,
      setPage,
    }),
    [bsProjectList, isLoading, fetchBsProjectCard, statusFilter, lastProjectId, projectCount, searchState, page]
  )
  return <BSProjectListContext.Provider value={bsProjectListStore}>{children}</BSProjectListContext.Provider>
}

export type BSProjectListStore = {
  bsProjectList: BSProjectCardDto[]
  setBsProjectList: Dispatch<SetStateAction<BSProjectCardDto[]>>
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
  fetchBsProjectCard(actualPage: number, search: string, limit?: number): Promise<void>
  statusFilter: ProjectStatusEnum
  setStatusFilter: Dispatch<SetStateAction<ProjectStatusEnum>>
  isOneProjectCreated: React.MutableRefObject<boolean>
  lastProjectId: string | undefined
  projectCount: number
  searchState: string
  setSearchState: Dispatch<SetStateAction<string>>
  page: number
  setPage: Dispatch<SetStateAction<number>>
}
