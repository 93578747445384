import { useCallback, useContext, useEffect } from "react"
import { appConstants } from "../../appConstants"
import { MaterialLibFilterContext } from "../../context/material/material-lib-filter-context"
import { NomenclatureFilterDto } from "../../dto/nomenclature-filter"
import { useHttp } from "../use-http"

type useFetchMaterialDeclarationFilterReturn = {
  loading: boolean
  iniesData: NomenclatureFilterDto
  error: Error | undefined
}

export const useFetchMaterialDeclarationFilter = (): useFetchMaterialDeclarationFilterReturn => {
  const { get } = useHttp()

  const { materialNomenclatureFilter, setMaterialNomenclatureFilter, loading, setLoading, error, setError } =
    useContext(MaterialLibFilterContext)

  useEffect(() => {
    fetchDeclaration()
  }, [])

  const fetchDeclaration = useCallback((): void => {
    setLoading(true)
    get(appConstants.apiEndpoints.INIES_NOMENCLATURE_FILTERS, [])
      .then((response) => response.json())
      .then((filters: NomenclatureFilterDto) => {
        setMaterialNomenclatureFilter(filters)
      })
      .catch((err) => {
        setError(err?.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [get, setMaterialNomenclatureFilter, setError, setLoading])

  return {
    loading,
    iniesData: materialNomenclatureFilter,
    error,
  }
}
