import { RoleEnum } from "../enum/roleEnum"

export type Roles = {
  [keys: string]: string
}

export const roles: Roles = {
  [RoleEnum.MOA]: "MOA",
  [RoleEnum.BE]: "BE",
  [RoleEnum.ARCHITECT]: "ARCHITECTE",
  [RoleEnum.ADMINISTRATOR]: "ADMINISTRATEUR",
  [RoleEnum.CONSULTANT]: "CONSULTANT",
}

export const roleList = Object.values(RoleEnum)

export function roleOnProjectToLabel(role: RoleEnum | undefined): string {
  if (role === undefined) {
    return ""
  } else {
    return roles[role]
  }
}

export function labelToRole(label: string): RoleEnum | undefined {
  if (label === "") {
    return undefined
  } else {
    return Object.keys(roles).find((key) => roles[key] === label) as RoleEnum
  }
}
