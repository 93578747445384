import { Box, Button, CircularProgress, Container, Grid, Paper, TextField, Typography } from "@mui/material"
import React, { FormEvent, useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { ErrorContext } from "../../../components/layout/error-snackbar"
import PasswordInput from "../../../components/inputs/password-input/password-input"
import { appConstants, pagesUrl } from "../../../core/appConstants"
import { InvitedUser } from "../../../core/dto/invited-user"
import { TestUserUserCreationDto } from "../../../core/dto/user/test-user-user-creation-dto"
import { useInvitation } from "../../../core/hooks/use-invitation"
import { useUser } from "../../../core/hooks/use-user"
import { isPasswordValid, isPhoneValid } from "../../../core/services/user-service"
import { UserContext } from "../../../core/context/user/user-context"
import PhoneInput from "../../../components/inputs/phone-input/phone-input"
import { resolveUrl } from "../../../core/services/http-service"

type TestUserForm = {
  givenName: string
  familyName: string
  phone: string
  email: string
  password: string
}

function initForm(invitedUser: InvitedUser | undefined): TestUserForm {
  if (invitedUser) {
    return {
      givenName: invitedUser?.firstName || "",
      familyName: invitedUser?.lastName || "",
      phone: invitedUser?.phone || "",
      email: invitedUser?.email || "",
      password: "",
    }
  } else {
    return {
      givenName: "",
      familyName: "",
      phone: "",
      email: "",
      password: "",
    }
  }
}

function formToDto(userForm: TestUserForm): TestUserUserCreationDto {
  return {
    givenName: userForm.givenName?.trim(),
    familyName: userForm.familyName?.trim(),
    phone: userForm.phone?.trim(),
    email: userForm.email?.trim(),
    password: userForm.password?.trim(),
  }
}

export default function CreateUserFromInvitationForm(): React.JSX.Element {
  const navigate = useNavigate()
  const { invitationId } = useParams()
  const openErrorSnackbar: (err: Error, message?: string) => void = useContext(ErrorContext)
  const { logout } = useContext(UserContext)
  const { createUserFromInvitation } = useUser()
  const { fetchInvitation } = useInvitation()
  const [error, setError] = useState<any>({})
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [userForm, setUserForm] = useState<TestUserForm>(initForm(undefined))
  const [organizationName, setOrganizationName] = useState<string>("")

  useEffect(() => {
    if (invitationId !== undefined) {
      fetchInvitation(invitationId).then((invitationInfo: InvitedUser | undefined) => {
        const userFormFromInvitation = initForm(invitationInfo)
        setOrganizationName(invitationInfo?.userOrganizationName || "")
        setUserForm(userFormFromInvitation)
      })
    }
  }, [fetchInvitation, invitationId, logout])

  function validate(dto: TestUserUserCreationDto): boolean {
    const newError: Record<string, any> = {}
    let isValid = true

    if (!dto.phone) {
      newError.phone = "Le numéro de téléphone doit être renseigné"
      isValid = false
    } else if (!isPhoneValid(dto.phone)) {
      newError.phone =
        'Le numéro de téléphone doit être composé uniquement de chiffres et faire 10 caractères ou commencer par "+" et faire 12 caractères'
      isValid = false
    }

    if (!appConstants.regex.email.test(dto.email)) {
      newError.email = "L'adresse email ne respecte pas la forme classique : email@complement.fin"
      isValid = false
    }

    if (!isPasswordValid(dto.password)) {
      newError.password = true
      isValid = false
    }

    setError(newError)
    return isValid
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault()

    const dto: TestUserUserCreationDto = formToDto(userForm)

    if (invitationId === undefined) {
      openErrorSnackbar(new Error("Vous n'avez pas d'invitation. L'invitation est obligatoire pour pouvoir s'inscrire"))
    } else if (validate(dto) && !isSubmitting) {
      setIsSubmitting(true)
      createUserFromInvitation(userForm, invitationId)
        .then(() => {
          navigate(resolveUrl(pagesUrl.CONFIRM_PAGE, [], { email: userForm.email }))
        })
        .finally(() => setIsSubmitting(false))
    }
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const target = event.target
    const name = target.id

    setUserForm({
      ...userForm,
      [name]: target.value,
    })

    if (error[name]) {
      setError({ ...error, [name]: undefined })
    }
  }

  return (
    <Container maxWidth="md">
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Box component="form" onSubmit={handleSubmit}>
          <Typography variant="h6" sx={{ pt: "-200px" }}>
            Inscription
          </Typography>
          <Paper variant="outlined" sx={{ my: { xs: 3, md: 3 }, p: { xs: 2, md: 3 } }}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="givenName"
                  variant="standard"
                  label="Prenom"
                  value={userForm.givenName}
                  required
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="familyName"
                  variant="standard"
                  label="Nom"
                  value={userForm.familyName}
                  required
                  fullWidth
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <PhoneInput form={userForm} error={error} handleChange={handleChange} required variant="standard" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField variant="standard" label="Organisation" value={organizationName} fullWidth disabled />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="email"
                  variant="standard"
                  label="Email"
                  value={userForm.email}
                  required
                  fullWidth
                  onChange={handleChange}
                  error={!!error.email}
                  helperText={error.email}
                />
              </Grid>
              <Grid item xs={12} sm={9}>
                <PasswordInput
                  err={error?.password}
                  value={userForm.password}
                  handleChange={handleChange}
                  variant={undefined}
                  label="Mot de passe *"
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
              {isSubmitting ? (
                <CircularProgress />
              ) : (
                <Button variant="contained" type="submit">
                  Créer
                </Button>
              )}
            </Box>
          </Paper>
        </Box>
      </Box>
    </Container>
  )
}
