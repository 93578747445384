import React from "react"
import { useNavigate } from "react-router-dom"
import { HelperCenter } from "./components/HelperCenter"
import { PageCenter } from "../../core/enum/help-center/helpCenterEnum"
import { pagesUrl } from "../../core/appConstants"

export function HelperCenterPage(): React.JSX.Element {
  const navigate = useNavigate()

  function updateActualPage(pageCenter: PageCenter): void {
    switch (pageCenter) {
      case PageCenter.TUTORIAL_EXEMPLE:
        navigate(pagesUrl.BEEM_SHOT_TUTORIAL_HELPER)
        break
      case PageCenter.AIDE_PARAMETRAGE:
        navigate(pagesUrl.BEEM_SHOT_CONFIGURATION_HELPER)
        break
      case PageCenter.CENTRE_AIDE:
        navigate(pagesUrl.BEEM_SHOT_HELP_CENTER)
        break
      default:
        break
    }
  }

  return <HelperCenter updateActualPage={updateActualPage} handleClose={undefined} />
}
