import InfoIcon from "@mui/icons-material/Info"
import { Divider, styled, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material"
import React from "react"

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 1)",
    m: 8,
  },
}))

interface IProps {
  description: string
  title: string
}

export function CustomTooltipDescription({ title, description }: Readonly<IProps>): React.JSX.Element {
  return (
    <HtmlTooltip
      title={
        <>
          <Typography variant="subtitle1" p={1} py={2}>
            {description}
          </Typography>
          <Divider />
          <Typography variant="body1" p={1}>
            {title}
          </Typography>
        </>
      }>
      <InfoIcon sx={{ fontSize: "16px", ml: 1 }} />
    </HtmlTooltip>
  )
}
