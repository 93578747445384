import { Grid, Tab, Tabs } from "@mui/material"
import React from "react"
import { BSMaterialResult } from "../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { TypeMaterialEnum } from "../../../../core/enum/typeMaterialEnum"
import { BSMaterialTab } from "./components/BSMaterialTab"
import "./material-page-modal.css"

type MaterialPageModalAddIniesProps = {
  handleClose: () => void
  selectedBsMaterialResult: BSMaterialResult | undefined
}

export function BSMaterialLib({
  handleClose,
  selectedBsMaterialResult,
}: Readonly<MaterialPageModalAddIniesProps>): React.JSX.Element {
  const [tabNumber, setTabNumber] = React.useState(0)

  function handleChangeTabs(event: React.SyntheticEvent, newValue: number): void {
    setTabNumber(newValue)
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} display="flex" justifyContent="center">
        <Tabs
          value={tabNumber}
          variant="standard"
          TabIndicatorProps={{ style: { display: "none" } }}
          textColor="inherit"
          onChange={handleChangeTabs}
          sx={{ backgroundColor: "white", borderRadius: 2 }}>
          <Tab
            label="Depuis la Base INIES"
            sx={{ backgroundColor: tabNumber === 0 ? "#E6F0FB" : "", borderRadius: "15px", height: "1vh" }}
          />

          <Tab
            label="Mes fiches configurées"
            sx={{
              backgroundColor: tabNumber === 1 ? "#E6F0FB" : "",
              borderRadius: "15px",
              height: "1vh",
            }}
          />
        </Tabs>
      </Grid>

      {tabNumber === 0 && (
        <BSMaterialTab
          handleClose={handleClose}
          selectedBsMaterialResult={selectedBsMaterialResult}
          type={TypeMaterialEnum.INIES_MATERIAL}
        />
      )}

      {tabNumber === 1 && (
        <BSMaterialTab
          handleClose={handleClose}
          selectedBsMaterialResult={selectedBsMaterialResult}
          type={TypeMaterialEnum.FICHE_CONFIGURE}
        />
      )}
    </Grid>
  )
}
