import { useContext, useMemo } from "react"
import { ErrorContext } from "../../components/layout/error-snackbar"
import { appConstants } from "../appConstants"
import { SupportForm } from "../dto/support/support-form"
import { MyUserUpdateDto } from "../dto/user/my-user-update-dto"
import { ProjectInvitation } from "../dto/user/projectInvitation"
import { TestUserCreationDto } from "../dto/user/test-user-creation-dto"
import { TestUserUserCreationDto } from "../dto/user/test-user-user-creation-dto"
import { TestUser } from "../dto/user/test-users"
import { User } from "../dto/user/user"
import { UserForm } from "../dto/user/user-form"
import { UserUpdateDto } from "../dto/user/user-update-dto"
import { RoleEnum } from "../enum/roleEnum"
import { resolveUrl } from "../services/http-service"
import { useHttp } from "./use-http"

type UserHook = {
  getCurrentUser(): Promise<User>
  adminGetUser(userId: string): Promise<User>
  updateMyUser(userUpdateDto: MyUserUpdateDto): Promise<User>
  adminUpdateUser(userUpdateDto: UserUpdateDto): Promise<Response>
  createUserFromInvitation(user: TestUserUserCreationDto, invitationId: string): Promise<void>
  createTestUser(user: TestUserCreationDto): Promise<void>
  sendInvitationOnProject(invitation: ProjectInvitation): Promise<void>
  sendInvitation(invitation: UserForm): Promise<void>
  sendAdminInvitation(organizationId: string, invitation: UserForm): Promise<void>
  sendSupportForm(supportForm: SupportForm): Promise<void>
  sendConfirmationAccountEmail(username: string): Promise<void>
  revokeUserProjectRight(projectId: string, userId: string, role: RoleEnum): Promise<RoleEnum[]>
  getUserRight(projectId: string): Promise<RoleEnum[]>
  getInvitationByProject(projectId: string): Promise<ProjectInvitation[]>
  grantSelfRight(projectId: string, role: string): Promise<Response>
  disableUser(cognitoUserId: string): Promise<Response>
  adminDisableUser(cognitoUserId: string, organizationId: string): Promise<Response>
  getIsBimModelTutorialEnabled(): Promise<any>
  disableBimModelTutorial(): Promise<Response>
  getUserRseeMax(): Promise<number>
  getUserRseeCount(): Promise<number>
  cancelUserInvitation(email: string, projectId: string, organizationProjectId: string): Promise<Response>
  cancelUserOrganisationInvitation(email: string, organizationProjectId: string): Promise<Response>
  sendUserConnexionInformation(): Promise<Response>
  adminGetTestUsers(
    startDate?: string,
    endDate?: string,
    searchString?: string,
    organizationFilter?: string[]
  ): Promise<TestUser[]>
  changePropectOption(userId: string): Promise<void>
}

export function useUser(): UserHook {
  const openErrorSnackbar = useContext(ErrorContext)
  const { get, post, put, deleteRequest } = useHttp()

  return useMemo(
    () => ({
      getCurrentUser(): Promise<User> {
        return get(appConstants.apiEndpoints.USER, []).then((response) => {
          if (response.status !== 404) {
            return response.json()
          } else {
            throw new Error("USER_EXITS_COGNITO_BUT_DOES_NOT_EXISTS_IN_DB")
          }
        })
      },
      adminGetUser(userId: string): Promise<User> {
        return get(`${appConstants.apiEndpoints.USER}/admin/${userId}`, []).then((response) => response.json())
      },
      adminGetTestUsers(
        startDate: string,
        endDate: string,
        searchString: string,
        organizationFilter: string[]
      ): Promise<TestUser[]> {
        let params: any[] = []

        if (startDate) {
          params = [
            ...params,
            {
              key: "startDate",
              value: startDate,
            },
          ]
        }

        if (endDate) {
          params = [
            ...params,
            {
              key: "endDate",
              value: endDate,
            },
          ]
        }

        if (searchString) {
          params = [
            ...params,
            {
              key: "searchString",
              value: searchString,
            },
          ]
        }
        if (organizationFilter) {
          params = [
            ...params,
            {
              key: "organizationFilter",
              value: organizationFilter,
            },
          ]
        }

        return get(`${appConstants.apiEndpoints.USER}/admin/test-users/list`, params).then((response) => response.json())
      },
      updateMyUser(userUpdateDto: MyUserUpdateDto): Promise<User> {
        return put(appConstants.apiEndpoints.USER, userUpdateDto).then((response) => response.json())
      },
      adminUpdateUser(userUpdateDto: UserUpdateDto): Promise<Response> {
        return put(`${appConstants.apiEndpoints.USER}/admin`, userUpdateDto)
      },
      createUserFromInvitation(user: TestUserUserCreationDto, invitationId: string): Promise<void> {
        return post(`${appConstants.apiEndpoints.USER}/create-user`, user, [
          {
            key: "invitationId",
            value: invitationId,
          },
        ]).then(() => undefined)
      },
      createTestUser(user: TestUserCreationDto): Promise<void> {
        return post(`${appConstants.apiEndpoints.USER}/create-test-user`, user).then()
      },
      sendInvitationOnProject(invitation: ProjectInvitation): Promise<void> {
        return post(`${appConstants.apiEndpoints.USER}/send-invitation-on-project`, invitation).then(() => undefined)
      },
      sendInvitation(invitation: UserForm): Promise<void> {
        return post(`${appConstants.apiEndpoints.USER}/send-invitation`, invitation).then(() => undefined)
      },
      sendAdminInvitation(organizationId: string, invitation: UserForm): Promise<void> {
        return post(`${appConstants.apiEndpoints.USER}/send-admin-invitation`, invitation, [
          { key: "organizationId", value: organizationId },
        ]).then(() => undefined)
      },
      sendSupportForm(supportForm: SupportForm): Promise<void> {
        return post(`${appConstants.apiEndpoints.USER}/send-support-form`, supportForm).then(() => undefined)
      },
      sendConfirmationAccountEmail(userEmail: string): Promise<void> {
        return post(`${appConstants.apiEndpoints.USER}/send-confirmation-email-account`, {}, [
          { key: "userEmail", value: userEmail.toLowerCase() },
        ]).then()
      },
      revokeUserProjectRight(projectId: string, userId: string, role: RoleEnum): Promise<RoleEnum[]> {
        return deleteRequest(`${appConstants.apiEndpoints.USER}/rights/revoke`, [
          { key: "projectId", value: projectId },
          { key: "userId", value: userId },
          { key: "role", value: role },
        ]).then((response) => response.json())
      },
      getUserRight(projectId: string): Promise<RoleEnum[]> {
        return get(`${appConstants.apiEndpoints.USER}/rights`, [
          {
            key: "projectId",
            value: projectId,
          },
        ]).then((response) => response.json())
      },
      getInvitationByProject(projectId: string): Promise<ProjectInvitation[]> {
        return get(`${appConstants.apiEndpoints.USER}/invitation/by-project`, [
          {
            key: "projectId",
            value: projectId,
          },
        ]).then((response) => response.json())
      },
      grantSelfRight(projectId: string, role: string): Promise<Response> {
        return post(`${appConstants.apiEndpoints.USER}/rights/grant-self`, {}, [
          {
            key: "projectId",
            value: projectId,
          },
          {
            key: "role",
            value: role,
          },
        ])
      },
      disableUser(cognitoUserId: string): Promise<Response> {
        return deleteRequest(resolveUrl(appConstants.apiEndpoints.USER_WITH_ID, [cognitoUserId]))
      },
      adminDisableUser(cognitoUserId: string, organizationId: string): Promise<Response> {
        return deleteRequest(resolveUrl(appConstants.apiEndpoints.USER_ADMIN, [cognitoUserId]), [
          { key: "organizationId", value: organizationId },
        ])
      },
      getIsBimModelTutorialEnabled(): Promise<any> {
        return get(`${appConstants.apiEndpoints.USER}/tutorials-enabled`).then((response) => {
          if (response.status === 404) {
            openErrorSnackbar(new Error("Impossible de savoir si vous avez demandé de ne plus afficher le tutoriel"))
            return { bimModelTutorialEnabled: true }
          } else {
            return response.json()
          }
        })
      },
      disableBimModelTutorial(): Promise<Response> {
        return put(`${appConstants.apiEndpoints.USER}/disable-bim-model-tutorial`, {}).then((response) => response.json())
      },
      getUserRseeMax(): Promise<number> {
        return get(`${appConstants.apiEndpoints.USER}/rsee-max`).then((response) => response.json())
      },
      getUserRseeCount(): Promise<number> {
        return get(`${appConstants.apiEndpoints.USER}/rsee-count`).then((response) => response.json())
      },
      cancelUserInvitation(email: string, projectId: string, organizationProjectId: string): Promise<Response> {
        return post(`${appConstants.apiEndpoints.USER}/cancel-invitation-on-project`, {}, [
          {
            key: "email",
            value: email.toLowerCase(),
          },
          {
            key: "projectId",
            value: projectId,
          },
          {
            key: "organizationProjectId",
            value: organizationProjectId,
          },
        ])
      },
      cancelUserOrganisationInvitation(email: string, organizationId: string): Promise<Response> {
        return post(`${appConstants.apiEndpoints.USER}/cancel-invitation-on-organization`, {}, [
          {
            key: "email",
            value: email.toLowerCase(),
          },
          {
            key: "organizationId",
            value: organizationId,
          },
        ])
      },
      sendUserConnexionInformation(): Promise<Response> {
        return put(`${appConstants.apiEndpoints.USER}/connexion`, {}, [])
      },
      changePropectOption(userId: string): Promise<void> {
        return post(`${appConstants.apiEndpoints.USER}/prospect-option`, {}, [
          {
            key: "userId",
            value: userId,
          },
        ]).then(() => undefined)
      },
    }),
    [post, openErrorSnackbar, deleteRequest, get, put]
  )
}
