import React from "react"
import BSIfcDisplayerCore from "./bs-ifc-displayer-core"
import { IfcDisplayerProps } from "./ifc-displayer-core"
import IfcDisplayerErrorBoundary from "./ifc-displayer-error-boundary"

export function BSIfcDisplayer(props: Readonly<IfcDisplayerProps>): React.JSX.Element {
  const { height } = props
  return (
    <IfcDisplayerErrorBoundary height={height}>
      <BSIfcDisplayerCore {...props} />
    </IfcDisplayerErrorBoundary>
  )
}
