import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"
import { Children } from "../../../components/miscellianous/children"
import { ProjectTypeEnum } from "../../enum/project/projectTypeEnum"
import { OrganizationRoleEnum } from "../../enum/user/organization-role-enum"
import { useQuery } from "../../hooks/miscellaneous/use-query"
import { UserContext, UserStore } from "../user/user-context"

export const ProjectTypeContext = React.createContext<ProjectTypeStore>({} as ProjectTypeStore)

export default function ProjectTypeContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const query = useQuery()
  const { pathname } = useLocation()
  const { user } = useContext<UserStore>(UserContext)

  const [projectTypeUpToDate, setProjectTypeUpToDate] = useState<boolean>(true)

  const initTypeFromQuery = useCallback(
    (queryParam: string | null): ProjectTypeEnum => {
      const defaultValue = user?.role === OrganizationRoleEnum.TEST_USER ? ProjectTypeEnum.RSEE : ProjectTypeEnum.BIM
      switch (queryParam) {
        case ProjectTypeEnum.RSEE:
          return ProjectTypeEnum.RSEE
        case ProjectTypeEnum.BIM:
          return ProjectTypeEnum.BIM
        case ProjectTypeEnum.BIM_ORGANIZATION:
          return ProjectTypeEnum.BIM_ORGANIZATION
        case ProjectTypeEnum.RSEE_ORGANIZATION:
          return ProjectTypeEnum.RSEE_ORGANIZATION
        default:
          return defaultValue
      }
    },
    [user?.role]
  )

  const initialValue = useMemo(() => initTypeFromQuery(query.get("type")), [initTypeFromQuery, query])

  const [currentProjectType, setCurrentProjectType] = useState<ProjectTypeEnum>(initialValue)

  useEffect(() => {
    if (pathname === "/projects") {
      const newProjectType = initTypeFromQuery(query.get("type"))
      setCurrentProjectType(newProjectType)
      setProjectTypeUpToDate(true)
    } else {
      setProjectTypeUpToDate(false)
    }
  }, [initTypeFromQuery, pathname, query, setCurrentProjectType])

  const projectTypeStore: ProjectTypeStore = useMemo(
    () => ({ currentProjectType, setCurrentProjectType, projectTypeLoaded: projectTypeUpToDate }),
    [currentProjectType, projectTypeUpToDate]
  )

  return <ProjectTypeContext.Provider value={projectTypeStore}>{children}</ProjectTypeContext.Provider>
}

type ProjectTypeStore = {
  currentProjectType: ProjectTypeEnum
  setCurrentProjectType: Dispatch<SetStateAction<ProjectTypeEnum>>
  projectTypeLoaded: boolean
}
