import React from "react"

interface IProps {
  children?: React.ReactNode
  index: number
  selectedTab: number
}

export function TabPanel({ children, selectedTab, index }: IProps): React.JSX.Element {
  return (
    <div
      role="tabpanel"
      hidden={selectedTab !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      style={{ width: "100%" }}>
      {selectedTab === index && children}
    </div>
  )
}
