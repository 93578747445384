import { SvgIcon } from "@mui/material"
import React from "react"

type IProps = {
  style?: any
}

export function IconCheckupList(props: IProps): React.JSX.Element {
  const { style } = props

  return (
    <SvgIcon
      {...props}
      fillOpacity={0}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ width: style?.width ? style?.width : "24", height: style?.height ? style?.height : "24" }}>
      {/*  SVG from https://tablericons.com/ */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-checkup-list"
        strokeWidth="1.5"
        stroke="#2c3e50"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
        <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
        <path d="M9 14h.01" />
        <path d="M9 17h.01" />
        <path d="M12 16l1 1l3 -3" />
      </svg>
    </SvgIcon>
  )
}
