/**
 * The types of the dimemsioning of the ifc model
 */
export enum DimensionMode {
  ONE_POINT,
  TWO_POINTS,
  ONE_EDGE,
  EDGE_AND_POINT,
  AREA,
  OBJECT_EDGES,
  FACE_AREA,
  FACE_SEGMENTS,
}
