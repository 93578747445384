import { SvgIcon } from "@mui/material"
import React from "react"

type IProps = {
  style?: any
}

export function IconFilter(props: IProps): React.JSX.Element {
  const { style } = props

  return (
    <SvgIcon
      {...props}
      fillOpacity={0}
      viewBox="0 0 54 54"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ width: style?.width ? style?.width : 30, height: style?.height ? style?.height : 30 }}>
      <path d="M14.3333 17.5H27" stroke="#0BE2A0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M33.3333 17.5H39.6666" stroke="#0BE2A0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.3333 27H17.5" stroke="#0BE2A0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M23.8333 27H39.6666" stroke="#0BE2A0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.3333 36.5H31.75" stroke="#0BE2A0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M38.0833 36.5H39.6666" stroke="#0BE2A0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M27 17.4997C27 18.3395 27.3336 19.145 27.9275 19.7388C28.5214 20.3327 29.3268 20.6663 30.1667 20.6663C31.0065 20.6663
         31.812 20.3327 32.4058 19.7388C32.9997 19.145 33.3333 18.3395 33.3333 17.4997C33.3333 16.6598 32.9997 15.8544 32.4058 
         15.2605C31.812 14.6666 31.0065 14.333 30.1667 14.333C29.3268 14.333 28.5214 14.6666 27.9275 15.2605C27.3336 15.8544 27 16.6598 27 17.4997Z"
        fill="#0BE2A0"
        stroke="#009069"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 26.9997C17.5 27.8395 17.8336 28.645 18.4275 29.2388C19.0214 29.8327 19.8268 30.1663 20.6667 30.1663C21.5065
         30.1663 22.312 29.8327 22.9058 29.2388C23.4997 28.645 23.8333 27.8395 23.8333 26.9997C23.8333 26.1598 23.4997 25.3544 
         22.9058 24.7605C22.312 24.1666 21.5065 23.833 20.6667 23.833C19.8268 23.833 19.0214 24.1666 18.4275 24.7605C17.8336 25.3544 17.5 26.1598 17.5 26.9997Z"
        fill="#0BE2A0"
        stroke="#009069"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.75 36.4997C31.75 37.3395 32.0836 38.145 32.6775 38.7388C33.2714 39.3327 34.0768 39.6663 34.9167 39.6663C35.7565 
        39.6663 36.562 39.3327 37.1558 38.7388C37.7497 38.145 38.0833 37.3395 38.0833 36.4997C38.0833 35.6598 37.7497 34.8544 
        37.1558 34.2605C36.562 33.6666 35.7565 33.333 34.9167 33.333C34.0768 33.333 33.2714 33.6666 32.6775 34.2605C32.0836 34.8544 31.75 35.6598 31.75 36.4997Z"
        fill="#0BE2A0"
        stroke="#009069"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}
