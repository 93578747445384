export enum FloorCoverEnum {
  PVC = "PVC",
  PARQUET = "PARQUET",
  CARRELAGE = "CARRELAGE",
  MOQUETTE = "MOQUETTE",
}

export const floorCoverEnumLabels = {
  [FloorCoverEnum.PVC]: "PVC",
  [FloorCoverEnum.PARQUET]: "Parquet",
  [FloorCoverEnum.CARRELAGE]: "Carrelage",
  [FloorCoverEnum.MOQUETTE]: "Moquette",
}
