import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Children } from "../../../../components/miscellianous/children"
import { RseeDocument } from "../../../dto/rsee/rsee-document"
import { useRsee } from "../../../hooks/rsee/use-rsee"
import { RseeProjectContext } from "./rsee-project-context"

export const RseeDocumentListContext = React.createContext<RseeDocumentListStore>({} as RseeDocumentListStore)

export function RseeDocumentListContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const { fetchRseeDocumentList } = useRsee()

  const { rseeProject } = useContext(RseeProjectContext)

  const [rseeDocumentList, setRseeDocumentList] = useState<RseeDocument[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const getRseeDocumentList = useCallback(() => {
    if (rseeProject?.id) {
      setIsLoading(true)
      fetchRseeDocumentList(rseeProject?.id)
        .then((newBSProjectList) => {
          setRseeDocumentList(newBSProjectList)
          return newBSProjectList
        })
        .finally(() => setIsLoading(false))
    }
  }, [fetchRseeDocumentList, rseeProject?.id])

  useEffect(() => getRseeDocumentList(), [getRseeDocumentList])

  const rseeProjectListStore = useMemo(
    () => ({
      rseeDocumentList,
      setRseeDocumentList,
      isLoading,
      setIsLoading,
    }),
    [isLoading, rseeDocumentList]
  )
  return <RseeDocumentListContext.Provider value={rseeProjectListStore}>{children}</RseeDocumentListContext.Provider>
}

export type RseeDocumentListStore = {
  rseeDocumentList: RseeDocument[]
  setRseeDocumentList: Dispatch<SetStateAction<RseeDocument[]>>
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
}
