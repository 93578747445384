import { useMemo } from "react"
import { appConstants } from "../../appConstants"
import { BSMaterialResult } from "../../dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { TypeMaterialEnum } from "../../enum/typeMaterialEnum"
import { useHttp } from "../use-http"

type BimModelHook = {
  getBSMaterialResult(bsVariantId: string): Promise<BSMaterialResult[]>
  updateBsMaterialQuantities(bsVariantId: string, bsMaterialResultId: string, overriddenQuantities: string): Promise<void>
  resetBsMaterialQuantities(bsVariantId: string, bsMaterialResultId: string): Promise<void>
  addBSOverriddenMaterial(
    bsVariantId: string,
    bsMaterialResultId: string,
    ficheConfigureeId: string | undefined,
    iniesId: number | undefined,
    actualQuantities: number,
    typeRecord: TypeMaterialEnum
  ): Promise<void>
}

export function useBSMaterialResults(): BimModelHook {
  const { get, post } = useHttp()

  return useMemo(
    () => ({
      getBSMaterialResult(bsVariantId: string): Promise<BSMaterialResult[]> {
        return get(`${appConstants.apiEndpoints.BEEM_SHOT}/material-results`, [
          {
            key: "variantId",
            value: bsVariantId,
          },
        ]).then((response) => response.json())
      },
      updateBsMaterialQuantities(
        bsVariantId: string,
        bsMaterialResultId: string,
        overriddenQuantities: string
      ): Promise<void> {
        return post(`${appConstants.apiEndpoints.BEEM_SHOT}/update-bsmaterial-result-quantities`, {}, [
          {
            key: "bsVariantId",
            value: bsVariantId,
          },
          {
            key: "bsMaterialResultId",
            value: bsMaterialResultId,
          },
          {
            key: "overriddenQuantities",
            value: overriddenQuantities.toString(),
          },
        ]).then(() => undefined)
      },
      resetBsMaterialQuantities(bsVariantId: string, bsMaterialResultId: string): Promise<void> {
        return post(`${appConstants.apiEndpoints.BEEM_SHOT}/reset-bsmaterial-result-quantities`, {}, [
          {
            key: "bsVariantId",
            value: bsVariantId,
          },
          {
            key: "bsMaterialResultId",
            value: bsMaterialResultId,
          },
        ]).then(() => undefined)
      },
      addBSOverriddenMaterial(
        bsVariantId: string,
        bsMaterialResultId: string,
        ficheConfigureeId: string | undefined,
        iniesId: number | undefined,
        actualQuantities: number,
        typeMaterial: TypeMaterialEnum
      ): Promise<void> {
        let params = [
          {
            key: "bsVariantId",
            value: bsVariantId,
          },
          {
            key: "bsMaterialResultId",
            value: bsMaterialResultId,
          },

          {
            key: "actualQuantities",
            value: actualQuantities.toString(),
          },
          {
            key: "typeMaterial",
            value: typeMaterial.toString(),
          },
        ]

        if (ficheConfigureeId) {
          params = [
            ...params,
            {
              key: "ficheConfigureeId",
              value: ficheConfigureeId,
            },
          ]
        }

        if (iniesId) {
          params = [
            ...params,
            {
              key: "iniesId",
              value: iniesId.toString(),
            },
          ]
        }

        return post(`${appConstants.apiEndpoints.BEEM_SHOT}/add-overriden-bsmaterial`, {}, params).then(() => undefined)
      },
    }),
    [get, post]
  )
}
