import CalculateIcon from "@mui/icons-material/CalculateOutlined"
import DatasetLinkedOutlinedIcon from "@mui/icons-material/DatasetLinkedOutlined"
import ShareIcon from "@mui/icons-material/Share"
import TableRowsIcon from "@mui/icons-material/TableRows"
import { Grid, IconButton, Tooltip } from "@mui/material"
import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { TableauDashboard } from "../../../../components/tableau-dashboards/tableau-dashboard"
import { adminPagesUrl, pagesUrl } from "../../../../core/appConstants"
import { RseeDocumentContext } from "../../../../core/context/beem-pilot/rsee/rsee-document-context"
import { RseeProjectContext } from "../../../../core/context/beem-pilot/rsee/rsee-project-context"
import { RseeProjectRoleContext } from "../../../../core/context/user/rsee-project-role-context"
import { UserContext } from "../../../../core/context/user/user-context"
import { RseeSummaryTypeEnum } from "../../../../core/enum/rsee/rseeSummaryTypeEnum"
import { OrganizationRoleEnum } from "../../../../core/enum/user/organization-role-enum"
import { isUltimateUser } from "../../../../core/services/authentication-service"
import { getEnvProperty, PROPERTIES } from "../../../../core/services/environment-service"
import { resolveUrl } from "../../../../core/services/http-service"
import { TableauViz } from "../../../../core/services/tableau-service"
import RseeHeader, { Action } from "../components/rsee-header"
import ShareLinkCreationModal from "../components/share-link-creation-modal"

export default function RseeDocumentDashboard(): React.JSX.Element {
  const navigate = useNavigate()
  const { organizationId, rseeProjectId, rseeDocumentId } = useParams()

  const { rseeProject } = useContext(RseeProjectContext)
  const { hasProject } = useContext(RseeProjectRoleContext)
  const { rseeDocument, dashboardUrl, isDataReady } = useContext(RseeDocumentContext)
  const { user } = useContext(UserContext)

  const [open, setOpen] = useState(false)
  const isWriting = useMemo(() => hasProject, [hasProject])
  const [rseeDocumentIdForTableau, setRseeDocumentIdForTableau] = useState<string | undefined>(undefined)
  const viz = useRef<any>(new TableauViz())

  useEffect(() => {
    if (getEnvProperty(PROPERTIES.REACT_APP_ENVIRONMENT) === "local") {
      setRseeDocumentIdForTableau("65ae97c77fff780724c27188") // Operation_test from QA
    } else {
      setRseeDocumentIdForTableau(rseeDocument?.id)
    }
  }, [rseeDocument?.id])

  function share(): void {
    setOpen(true)
  }

  function handleGroup(): void {
    if (rseeProject?.id && rseeDocument?.id) {
      isUltimateUser(user)
        ? navigate(
            resolveUrl(adminPagesUrl.RSEE_PROJECT_DOCUMENT_GROUP_UPDATE, [organizationId, rseeProjectId, rseeDocumentId])
          )
        : navigate(resolveUrl(pagesUrl.RSEE_PROJECT_DOCUMENT_GROUP_UPDATE, [rseeProjectId, rseeDocumentId]))
    }
  }

  function getMenuButton(): React.JSX.Element {
    return (
      <Grid container>
        {isWriting && (
          <Grid item xs={6}>
            <Tooltip title="Partager" placement="top">
              <IconButton onClick={share}>
                <ShareIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
        <Grid item xs={6}>
          <Tooltip title="Liens de partage" placement="top">
            <IconButton onClick={handleGroup}>
              <DatasetLinkedOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={6}>
          <Tooltip title="Résumé d'import" placement="bottom">
            <IconButton
              onClick={() => {
                navigate(
                  resolveUrl(pagesUrl.RSEE_PROJECT_DOCUMENT_SUMMARY_IMPORT, [rseeProjectId, rseeDocumentId], {
                    from: RseeSummaryTypeEnum.RSEE_DASHBOARD,
                  })
                )
              }}>
              <TableRowsIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    )
  }

  function getAction(): Action | undefined {
    if (user?.role === OrganizationRoleEnum.ULTIMATE_USER) {
      return {
        path: resolveUrl(adminPagesUrl.RSEE_PROJECT_DOCUMENT_UPDATE, [organizationId, rseeProjectId, rseeDocument?.id]),
      }
    } else if (user?.role === OrganizationRoleEnum.TEST_USER) {
      return undefined
    } else {
      return { path: resolveUrl(pagesUrl.RSEE_PROJECT_DOCUMENT_UPDATE, [rseeProjectId, rseeDocument?.id]) }
    }
  }

  return (
    <Grid container rowSpacing={2} columnSpacing={2}>
      <RseeHeader
        title="Dashboard RSEE"
        action={getAction()}
        navigateBackPath={
          user?.role === OrganizationRoleEnum.ULTIMATE_USER
            ? resolveUrl(adminPagesUrl.RSEE_PROJECT, [organizationId, rseeProject?.id])
            : resolveUrl(pagesUrl.RSEE_PROJECT, [rseeProject?.id])
        }
        actionLabel="Actualiser le RSEE"
        icon={<CalculateIcon sx={{ fontSize: "40px" }} />}
        isWriting={isWriting}
        menu={getMenuButton()}
      />
      <Grid item xs={9} />
      {rseeDocumentIdForTableau && dashboardUrl !== "" && (
        <Grid item xs={12}>
          <TableauDashboard
            dashboardUrl={dashboardUrl}
            isDataReady={isDataReady}
            tableauParameter={[{ name: "0P_Document_Id", value: [rseeDocumentIdForTableau] }]}
            filterToAdd={[]}
            vizRef={viz}
          />
        </Grid>
      )}
      <ShareLinkCreationModal open={open} setOpen={setOpen} rseeDocumentId={rseeDocument?.id} />
    </Grid>
  )
}
