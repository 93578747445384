import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"
import UserFormComponent from "../../../components/forms/user-invitation-form/user-form-component"
import { UserForm } from "../../../core/dto/user/user-form"
import { useUser } from "../../../core/hooks/use-user"
import { OrganizationContext } from "../../../core/context/organization/organization-context"
import { SuccessContext } from "../../../components/layout/success-snackbar"
import { pagesUrl } from "../../../core/appConstants"
import { OrganizationRoleEnum } from "../../../core/enum/user/organization-role-enum"
import { UserContext } from "../../../core/context/user/user-context"
import { isMegaUser } from "../../../core/services/authentication-service"
import { roleSuperUserSelectOptions, roleUpdateSelectOptions } from "../../../core/services/user-service"

export default function UserInvitationPage(): React.JSX.Element {
  const navigate = useNavigate()
  const { user } = useContext(UserContext)
  const { sendInvitation } = useUser()
  const { refreshOrganization } = useContext(OrganizationContext)
  const openSuccessSnackbar = useContext(SuccessContext)

  function sendInvitationFromUser(userInvitationDto: UserForm): Promise<void> {
    return sendInvitation(userInvitationDto)
      .then(() => refreshOrganization())
      .then(() => {
        openSuccessSnackbar("Invitation envoyée à l'utilisateur")
        navigate(pagesUrl.MY_ORGANIZATION_PAGE)
      })
  }

  return (
    <UserFormComponent
      onSubmit={sendInvitationFromUser}
      selectOptions={isMegaUser(user) ? roleUpdateSelectOptions : roleSuperUserSelectOptions}
      defaultRole={OrganizationRoleEnum.NORMAL_USER}
    />
  )
}
