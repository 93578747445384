import { useMemo } from "react"
import { appConstants } from "../appConstants"
import { VariantICChantier } from "../data-structures/variant-worksite-ci"
import { AdditionalCodeForVariant } from "../dto/additional-code/additional-code-for-variant"
import { Calculation } from "../dto/calculation/calculation"
import { CalculationCreateOrUpdate } from "../dto/calculation/calculation-create-or-update"
import { Variant } from "../dto/calculation/variant"
import { Results } from "../dto/results"
import { PhaseEnum } from "../enum/phaseEnum"
import { useHttp } from "./use-http"
import { ProjectToCopyOption } from "../dto/project/project-to-copy-option"
import { resolveUrl } from "../services/http-service"

type CalculationHook = {
  fetchCalculation(projectId: string, phase?: PhaseEnum): Promise<Response>
  sendCalculationAndVariants(
    calculation: Calculation,
    variant1: Variant,
    variant2: Variant
  ): Promise<CalculationCreateOrUpdate>
  startCalculation(calculationId: string): Promise<Response>
  fetchVariant(variantId: string): Promise<Variant>
  fetchClimaticZones(): Promise<string[]>
  getIcTotal(projectId: string, phase: PhaseEnum): Promise<Results>
  updateSameFlatRatesV1V2(calculationId: string, sameFlatRatesV1V2: boolean): Promise<void>
  updateSameWorksitesV1V2(calculationId: string, sameWorkSitesV1V2: boolean): Promise<void>
  updateSameAdditionalV1V2(calculationId: string, sameAdditionaV1V2: boolean): Promise<void>
  copyAdditionalCodeListV1ToV2(calculationId: string): Promise<AdditionalCodeForVariant>
  updateWorksiteCI(variables: VariantICChantier): Promise<Response[]>
  getAllCopyOptions(currentProjectId: string): Promise<ProjectToCopyOption[]>
  affectMaterialFromOtherProject(
    sourceProjectId: string,
    targetProjectId: string,
    sourcePhase: PhaseEnum,
    targetPhase: PhaseEnum
  ): Promise<void>
}

export function useCalculation(): CalculationHook {
  const { get, post, put } = useHttp()

  return useMemo(
    () => ({
      fetchCalculation(projectId: string, phase: PhaseEnum): Promise<Response> {
        return get(appConstants.apiEndpoints.CALCULATION, [
          { key: "projectId", value: projectId },
          { key: "phase", value: phase },
        ])
      },
      sendCalculationAndVariants(
        calculation: Calculation,
        variant1: Variant,
        variant2: Variant
      ): Promise<CalculationCreateOrUpdate> {
        return post(appConstants.apiEndpoints.CALCULATION, {
          calculation,
          variant1,
          variant2,
        }).then((response) => response.json())
      },
      startCalculation(calculationId: string): Promise<Response> {
        return post(`${appConstants.apiEndpoints.CALCULATION}/${calculationId}/start-calculation`, calculationId)
      },
      fetchVariant(variantId: string): Promise<Variant> {
        return get(`${appConstants.apiEndpoints.CALCULATION}/variant/${variantId}`).then((response) => response.json())
      },
      fetchClimaticZones: async (): Promise<string[]> =>
        get(`${appConstants.apiEndpoints.CALCULATION}/climatic-zones`).then(async (response) => {
          const climaticZone: string[] = await response.json()
          return climaticZone
        }),
      getIcTotal(projectId: string, phase): Promise<Results> {
        return get(`${appConstants.apiEndpoints.CALCULATION}/ic-total`, [
          { key: "projectId", value: projectId },
          { key: "phase", value: phase },
        ])
          .then((response) => response.json())
          .catch(() => undefined)
      },
      updateSameFlatRatesV1V2(calculationId: string, sameFlatRatesV1V2: boolean): Promise<void> {
        const isSame = sameFlatRatesV1V2 === undefined ? false : sameFlatRatesV1V2
        return put(`${appConstants.apiEndpoints.CALCULATION}/${calculationId}/sameFlatRatesV1V2`, {}, [
          { key: "isSame", value: `${isSame}` },
        ]).then(() => undefined)
      },
      updateSameWorksitesV1V2(calculationId: string, sameWorkSitesV1V2: boolean): Promise<void> {
        const isSame = sameWorkSitesV1V2 === undefined ? false : sameWorkSitesV1V2
        return put(`${appConstants.apiEndpoints.CALCULATION}/${calculationId}/sameWorkSitesV1V2`, {}, [
          { key: "isSame", value: `${isSame}` },
        ]).then(() => undefined)
      },
      updateSameAdditionalV1V2(calculationId: string, sameAdditionaV1V2: boolean): Promise<void> {
        const isSame = sameAdditionaV1V2 === undefined ? false : sameAdditionaV1V2
        return put(`${appConstants.apiEndpoints.CALCULATION}/${calculationId}/sameAdditionaV1V2`, {}, [
          { key: "isSame", value: `${isSame}` },
        ]).then(() => undefined)
      },
      copyAdditionalCodeListV1ToV2(calculationId: string): Promise<AdditionalCodeForVariant> {
        return get(`${appConstants.apiEndpoints.CALCULATION}/${calculationId}/copy-additional-code-list-v1-to-v2`).then(
          (response) => response.json()
        )
      },
      async updateWorksiteCI(variantICChantier: VariantICChantier): Promise<Response[]> {
        return Promise.all(
          Object.keys(variantICChantier).map((variant) => {
            const variantKey = variant === "1" ? 1 : 2
            return put(`${appConstants.apiEndpoints.CALCULATION}/variant/${variantICChantier[variantKey].id}/ic-chantier`, {
              ...variantICChantier[variantKey].data,
            })
          })
        ).then((response) => Promise.all(response.map((i) => i.json())))
      },
      getAllCopyOptions(currentProjectId: string): Promise<ProjectToCopyOption[]> {
        return get(
          resolveUrl(`${appConstants.apiEndpoints.CALCULATION}/get-project-to-copy-material`, [], { currentProjectId })
        ).then((response) => response.json())
      },
      affectMaterialFromOtherProject(
        originProjectId: string,
        targetProjectId: string,
        originPhase: PhaseEnum,
        targetPhase: PhaseEnum
      ): Promise<void> {
        return get(`${appConstants.apiEndpoints.CALCULATION}/affect-code`, [
          { key: "originProjectId", value: originProjectId },
          { key: "targetProjectId", value: targetProjectId },
          { key: "originPhase", value: originPhase },
          { key: "targetPhase", value: targetPhase },
        ]).then(() => undefined)
      },
    }),
    [get, post, put]
  )
}
