import React, { useCallback, useMemo, useState } from "react"
import { Children } from "../../../components/miscellianous/children"
import { DeclarationFilter } from "../../enum/declarationFilter"

export type FilterType = {
  selectedLvl0?: DeclarationFilter
  selectedLvl1?: string
  selectedLvl2?: string
  selectedLvl3?: string
  selectedLvl4?: string
  selectedTypologieDeclaration?: string
  responsibleOrganism?: string
  last?: string
  lastPrev?: string[]
}
type DataFilterType = {
  inie: FilterType
  project: FilterType
}
type CalculationCarbonMaterialFilterStore = {
  filterByMaterialId: Record<string, DataFilterType>
  updateFilter(key: string, data: DataFilterType): void
}
export const CalculationCarbonMaterialFilterContext = React.createContext<CalculationCarbonMaterialFilterStore>({
  filterByMaterialId: {},
  updateFilter: () => undefined,
})

export default function CalculationCarbonMaterialFilterContextProvider({ children }: Children): React.JSX.Element {
  const [filterByMaterialId, setFilterByMaterialId] = useState<Record<string, DataFilterType>>({})

  const updateFilter = useCallback((key: string, d: DataFilterType) => {
    setFilterByMaterialId((prev) => ({
      ...prev,
      [key]: {
        inie: {
          ...prev[key]?.inie,
          ...d?.inie,
        },
        project: {
          ...prev[key]?.project,
          ...d?.project,
        },
      },
    }))
  }, [])

  const value: CalculationCarbonMaterialFilterStore = useMemo(
    () => ({ filterByMaterialId, updateFilter }),
    [filterByMaterialId, updateFilter]
  )
  return (
    <CalculationCarbonMaterialFilterContext.Provider value={value}>
      {children}
    </CalculationCarbonMaterialFilterContext.Provider>
  )
}
