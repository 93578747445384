import { DeclarationTypeEnum } from "../../enum/declarationTypeEnum"
import { UnitEnum } from "../../enum/unitEnum"
import { stringToDate } from "../../services/date-service"
import { Parameters } from "./parameters"
import { OriginRecordStatus } from "../../enum/materialLib/originRecordStatus"

export class MaterialRecord {
  id!: string
  projectId!: string
  iniesId!: number
  organizationId!: string
  fdesName!: string
  responsibleOrganism!: string
  provider!: string
  productReference!: string
  functionalUnit!: UnitEnum
  referenceLifeTime!: number
  moduleD!: number
  totalLifeCycleCarbonImpact!: number
  carbonBiogenic = 0
  lastIniesUpdate?: Date
  lastModifiedDate?: Date
  lastModifiedUserName!: string
  declarationType!: DeclarationTypeEnum
  description!: string
  re2020CarbonImpact: number | undefined
  expirationDate?: Date
  configuratorId!: string
  parameters!: Parameters[]
  originRecordStatus: OriginRecordStatus | undefined

  static fromDto(materialDto: any): MaterialRecord {
    const material = new MaterialRecord()
    material.id = materialDto.id
    material.projectId = materialDto.projectId
    material.iniesId = materialDto.iniesId
    material.organizationId = materialDto.organizationId
    material.fdesName = materialDto.fdesName
    material.responsibleOrganism = materialDto.responsibleOrganism
    material.provider = materialDto.provider
    material.productReference = materialDto.productReference
    material.functionalUnit = materialDto.functionalUnit
    material.referenceLifeTime = materialDto.referenceLifeTime
    material.moduleD = materialDto.moduleD
    material.totalLifeCycleCarbonImpact = materialDto.totalLifeCycleCarbonImpact
    material.carbonBiogenic = materialDto.carbonBiogenic
    material.lastIniesUpdate = stringToDate(materialDto.lastIniesUpdate)
    material.lastModifiedDate = stringToDate(materialDto.lastModifiedDate)
    material.lastModifiedUserName = materialDto.lastModifiedUserName
    material.declarationType = materialDto.declarationType
    material.description = materialDto.description
    material.re2020CarbonImpact = materialDto.re2020CarbonImpact
    material.expirationDate = stringToDate(materialDto.expirationDate)
    material.configuratorId = materialDto.configuratorId
    material.parameters = materialDto.parameters
    material.originRecordStatus = materialDto.originRecordStatus
    return material
  }
}
