import { Grid } from "@mui/material"
import React, { useContext } from "react"
import FilterButton from "../../../../components/buttons/filter-button/filter-button"
import { UserContext, UserStore } from "../../../../core/context/user/user-context"
import { ProjectTypeEnum } from "../../../../core/enum/project/projectTypeEnum"
import { isMegaUser } from "../../../../core/services/authentication-service"

type IProps = {
  currentType: ProjectTypeEnum
  updateCurrentType(newProjectType: ProjectTypeEnum): void
}

export default function ProjectTypeSelectButtons({ currentType, updateCurrentType }: IProps): React.JSX.Element {
  const { user } = useContext<UserStore>(UserContext)

  return (
    <Grid container sx={{ width: "90%" }} columnSpacing={3} rowSpacing={3}>
      <Grid item xs={12} sm={2}>
        <FilterButton
          value={ProjectTypeEnum.BIM}
          currentValue={currentType}
          label="Mes Projets BIM"
          onClick={() => updateCurrentType(ProjectTypeEnum.BIM)}
        />
      </Grid>

      {isMegaUser(user) && (
        <Grid item xs={12} sm={2}>
          <FilterButton
            value={ProjectTypeEnum.BIM_ORGANIZATION}
            currentValue={currentType}
            label="Projets BIM de mon organisation"
            onClick={() => updateCurrentType(ProjectTypeEnum.BIM_ORGANIZATION)}
          />
        </Grid>
      )}
    </Grid>
  )
}
