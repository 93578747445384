export function getAwsErrorMessage(message: string): string {
  switch (message) {
    case "LimitExceededException":
      return "Tentative de réinitialisation dépassée, veuillez réessayer ultérieurement.."
    case "InvalidParameterException":
      return "Votre compte n'est pas verifié"
    case "UserNotFoundException":
      return "Utilisateur non trouvé"
    case "ExpiredCodeException":
      return "Code expiré"
    case "CodeMismatchException":
      return "Code invalide"
    default:
      return message
  }
}
