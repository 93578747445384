import { Typography } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { SearchField } from "../../../components/inputs/search-field"
import { SimpleContact } from "../../../core/dto/SimpleContact"
import { useOrganization } from "../../../core/hooks/use-organization"
import { Collaborator } from "../../../core/dto/user/collaborator"
import { UserContext } from "../../../core/context/user/user-context"

interface IProps {
  selectedCognitoUserId: string | undefined
  errors: Record<string, string | undefined>

  handleChange(emailSelected: string | undefined): void
}

export function BSBimModelInviter({ selectedCognitoUserId, handleChange, errors }: Readonly<IProps>): React.JSX.Element {
  const { fetchCollaborators } = useOrganization()
  const { user } = useContext(UserContext)

  const [collaborators, setCollaborators] = useState<SimpleContact[]>()

  useEffect(() => {
    fetchCollaborators().then((newCollaboratorList: Collaborator[]) => {
      const simpleContactList: SimpleContact[] = newCollaboratorList
        .filter((collaborator) => collaborator.cognitoUserId !== user?.cognitoUserId)
        .map((collaborator) => ({
          cognitoUserId: collaborator.cognitoUserId,
          lastName: collaborator.familyName,
          email: collaborator.email,
          firstName: collaborator.givenName,
          userOrganizationId: collaborator.organizationId,
          userOrganizationName: collaborator.organizationName,
        }))
      setCollaborators(simpleContactList)
    })
  }, [fetchCollaborators, user?.cognitoUserId])

  function completeFillContact(event: any, value: SimpleContact | null): void {
    if (value) {
      handleChange(value?.cognitoUserId)
    }
  }

  function getOptionLabel(option: SimpleContact | null): string {
    return option ? `${option?.firstName} ${option?.lastName}` : ""
  }

  function getCurrentSelection(): SimpleContact | null {
    if (selectedCognitoUserId) {
      const collaboratorFound = collaborators?.find((simpleCollab) => simpleCollab.cognitoUserId === selectedCognitoUserId)
      return collaboratorFound || null
    } else {
      return null
    }
  }

  return (
    <>
      <Typography variant="h5" fontWeight={600}>
        Inviter un acteur à paramétrer et importer la maquette numérique
      </Typography>
      <Typography variant="body1">
        Vous souhaitez inviter un acteur à paramétrer et importer la maquette numérique sur ce projet, sélectionnez un
        collaborateur de votre organisation.
      </Typography>
      <Typography variant="subtitle1">Depuis mon organisation</Typography>
      <SearchField
        label="Sélectionner un collaborateur"
        value={getCurrentSelection()}
        options={collaborators}
        onChange={completeFillContact}
        getOptionLabel={getOptionLabel}
        errors={errors}
      />
    </>
  )
}
