import React, { Dispatch, SetStateAction, useMemo, useState } from "react"
import { Children } from "../../../components/miscellianous/children"
import { MaterialLibIndicator } from "../../dto/organization/organization-indicator"

export const MaterialLibProjectIndicatorContext = React.createContext<ProjectMaterialIndicatorStore>(
  {} as ProjectMaterialIndicatorStore
)

export default function MaterialLibProjectIndicatorContextProvider({ children }: Children): React.JSX.Element {
  const [projectMaterialIndicator, setProjectMaterialIndicator] = useState<MaterialLibIndicator>({
    total: 0,
    fdes: 0,
    pep: 0,
  })
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | undefined>(undefined)

  const MaterialLibProjectIndicatorStore = useMemo(
    () =>
      new ProjectMaterialIndicatorStore(
        projectMaterialIndicator,
        setProjectMaterialIndicator,
        loading,
        setLoading,
        error,
        setError
      ),
    [error, loading, projectMaterialIndicator]
  )
  return (
    <MaterialLibProjectIndicatorContext.Provider value={MaterialLibProjectIndicatorStore}>
      {children}
    </MaterialLibProjectIndicatorContext.Provider>
  )
}

export class ProjectMaterialIndicatorStore {
  projectMaterialIndicator: MaterialLibIndicator
  setProjectMaterialIndicator: Dispatch<SetStateAction<MaterialLibIndicator>>
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  error: Error | undefined
  setError: Dispatch<SetStateAction<Error | undefined>>

  constructor(
    projectMaterialIndicator: MaterialLibIndicator,
    setProjectMaterialIndicator: Dispatch<SetStateAction<MaterialLibIndicator>>,
    loading: boolean,
    setLoading: Dispatch<SetStateAction<boolean>>,
    error: Error | undefined,
    setError: Dispatch<SetStateAction<Error | undefined>>
  ) {
    this.projectMaterialIndicator = projectMaterialIndicator
    this.setProjectMaterialIndicator = setProjectMaterialIndicator
    this.loading = loading
    this.setLoading = setLoading
    this.error = error
    this.setError = setError
  }
}
