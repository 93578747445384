import { PhaseEnum } from "../enum/phaseEnum"

export class SelectedPhaseStore {
  selectedPhase: PhaseEnum | undefined
  setSelectedPhase: (phase: PhaseEnum | undefined) => void

  constructor(selectedPhase: PhaseEnum | undefined, setSelectedPhase: (phase: PhaseEnum | undefined) => void) {
    this.selectedPhase = selectedPhase
    this.setSelectedPhase = setSelectedPhase
  }
}
