import React, { ChangeEvent } from "react"
import BasicField from "./basic-field"
import { formatPhone } from "../../../../core/services/user-service"

type IProps = {
  form: any
  error: Record<string, any>
  handleChange(event: ChangeEvent<HTMLInputElement>): void
}

export default function PhoneField({ form, error, handleChange }: IProps): React.JSX.Element {
  function handleChangePhone(event: ChangeEvent<HTMLInputElement>): void {
    const value: string = event.target.value

    event.target.value = formatPhone(value)
    handleChange(event)
  }

  return (
    <BasicField
      fieldName="phone"
      label="Téléphone"
      type="phone"
      form={form}
      error={error}
      required
      handleChange={handleChangePhone}
    />
  )
}
