import { BufferGeometry, Line, Points, Vector3 } from "three"
import { IfcViewerAPI } from "web-ifc-viewer"
import { viewerConstants } from "../../../constants/ViewerConstants"
import { DimensionMode } from "../../../enums/DimensionMode"
import { MeasurementHelper } from "../../../helpers/MeasurementHelper"
import { PointData } from "../annotations/PointData"
import { DimensionCommand } from "../base/DimensionCommand"

export class TwoPointsDimensionCommand extends DimensionCommand {
  startPoint: PointData
  endPoint: PointData
  points: Array<Vector3> = []

  constructor(startPoint: PointData, endPoint: PointData, scene: THREE.Scene, ifc: IfcViewerAPI) {
    super(scene, ifc)
    this.startPoint = startPoint
    this.endPoint = endPoint
    this.points = [this.startPoint.point, this.endPoint.point]

    this.lineGeom = new BufferGeometry().setFromPoints(this.points)
    this.line = new Line(this.lineGeom, viewerConstants.measurement.lineDimension.lineMaterial)
    this.line.renderOrder = 1
    this.line.name = DimensionMode.TWO_POINTS.toString()

    this.distance = this.startPoint.point.distanceTo(this.endPoint.point)
    this.textPosition = MeasurementHelper.getTextPosition(this.startPoint.point, this.endPoint.point)
    this.defineTextLabel(this.endPoint.modelId ?? 0)

    this.pointsGeometry = new BufferGeometry().setFromPoints(this.points)
    this.labelpoints = new Points(this.pointsGeometry, viewerConstants.measurement.lineDimension.pointMaterial)

    this.setUpGroupData()
  }

  async updateText(): Promise<void> {
    if (this.group && this.label) {
      this.scene.remove(this.label)
      this.group.remove(this.label)
      this.label.material.dispose()
      this.label.clear()
      await this.defineTextLabel(this.endPoint.modelId ?? 0)
      this.group.add(this.label)
    }
  }

  private async setUpGroupData(): Promise<void> {
    await this.defineTextLabel(this.endPoint.modelId ?? 0)
    if (this.group && this.label && this.line && this.labelpoints) {
      this.group.add(this.line)
      this.updateTextSize()
      this.group.add(this.label)
      this.group.add(this.labelpoints)
      this.group.assosiatedObject = this
      this.group.startPoint = this.startPoint.point
      this.group.endPoint = this.endPoint.point
      this.group.startPointExpressId = this.startPoint.expressId
      this.group.endPointExpressId = this.endPoint.expressId
      this.group.startPointModelId = this.startPoint.modelId
      this.group.endPointModelId = this.endPoint.modelId
    }
  }
}
