import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined"
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined"
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined"
import { Box, CircularProgress, Fade, Grid, IconButton, Tooltip, Typography } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import DefaultBsProjectCard from "../../../../assets/DefaultBsProjectCard.png"
import { CustomBottomNavigation } from "../../../components/buttons/navigate-button/CustomBottomNavigation"
import { DefaultTypography } from "../../../components/typography/default-typography"
import { TextInputTypography } from "../../../components/typography/textInputTypography"
import { BSInputContext } from "../../../core/context/beem-shot/BSInputContext/BSInputContext"
import { BSProjectContext } from "../../../core/context/beem-shot/BSProject/BSProjectContext"
import { BSVariantContext } from "../../../core/context/beem-shot/BSVariant/BSVariantContext"
import { OrganizationContext } from "../../../core/context/organization/organization-context"
import { CalculStatusEnum } from "../../../core/enum/calculStatusEnum"
import { ProjectStatusEnum } from "../../../core/enum/projectStatusEnum"
import { formatToFrench } from "../../../core/services/date-service"
import { BSBimModelCard } from "./BSBimModelCard/BSBimModelCard"
import { BSCalculCard } from "./BSCalculCard/BSCalculCard"
import { BSProjectForm } from "./BSProjectForm"
import { BSSelectVariantDialog } from "./BSVariant/BSSelectVariantDialog"
import { BSVariantForm } from "./BSVariant/BSVariantForm"
import { DeleteBSProjectDialog } from "./Dialog/DeleteBSProjectDialog"
import { UpdateBSProjectDialog } from "./Dialog/UpdateBSProjectDialog"

type IProps = {
  handleChange(event: React.SyntheticEvent, newValue: string): void
  isWritingMode: boolean
}

export function BSProjectDetail({ handleChange, isWritingMode }: Readonly<IProps>): React.JSX.Element {
  const { bsProjectId, bsVariantId } = useParams()
  const { bsProject, updateProject } = useContext(BSProjectContext)
  const { organization } = useContext(OrganizationContext)
  const { bsInput } = useContext(BSInputContext)
  const { selectedVariant, fetchAllBsVariants } = useContext(BSVariantContext)

  const [openSelectedVariants, setOpenSelectedVariants] = useState<boolean>(false)

  const [openManageProjectStatus, setOpenManageProjectStatus] = useState<boolean>(false)
  const [openUpdateProjectInfo, setOpenUpdateProjectInfo] = useState<boolean>(false)
  const [openCreateVariantForm, setOpenCreateVariantForm] = useState<boolean>(false)

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)

  useEffect(() => {
    if (bsProjectId) {
      fetchAllBsVariants(bsProjectId)
    }
  }, [bsProjectId, fetchAllBsVariants, bsVariantId])

  const handleClose = (): void => {
    setOpenUpdateProjectInfo(false)
    setOpenManageProjectStatus(false)
    setOpenSelectedVariants(false)
  }

  function handleCloseVariant(): void {
    setOpenCreateVariantForm(false)
  }

  return (
    <>
      {!bsProject ? (
        <Box height="60vh" width="100%" display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <Fade in timeout={600}>
          <Box>
            <Box
              display="flex"
              flexDirection="row"
              py={2}
              alignItems="center"
              alignContent="center"
              columnGap={0.5}
              justifyItems="center">
              <DefaultTypography label="Crée par " color="#070F27" fontWeight={400} fontSize="11px" lineHeight="16.5px" />
              <DefaultTypography
                label={bsProject?.createdByUser ?? ""}
                color="#070F27"
                fontWeight={500}
                fontSize="11px"
                lineHeight="16.5px"
              />
              <Typography variant="subtitle1" fontSize={11}>
                {`· ${organization?.businessName} · `}
              </Typography>

              <Typography variant="subtitle1" fontSize={11} fontWeight={400} sx={{ color: "#8F8F8F" }}>
                {` Mise à jour le ${formatToFrench(bsProject?.lastModifiedDate)}`}
              </Typography>
            </Box>

            <Grid container display="flex" flexDirection="row" borderRadius={3} sx={{ background: "#fff" }}>
              <Grid item xs={1} />
              <Grid
                container
                item
                xs={10}
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                rowGap={2}
                p={3}>
                <Box>
                  <img
                    src={DefaultBsProjectCard}
                    alt="Default Bs ProjectCard Img"
                    style={{
                      minHeight: 120,
                      minWidth: 120,
                      objectFit: "contain",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                </Box>

                <Grid
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                  flexDirection="row"
                  gap={1}>
                  {selectedVariant?.name}

                  <IconButton onClick={() => setOpenSelectedVariants(true)}>
                    <KeyboardArrowDownIcon />
                  </IconButton>
                </Grid>

                <Grid display="flex" justifyContent="center" alignItems="center" flexDirection="row">
                  <RoomOutlinedIcon />
                  <Typography>
                    {`${bsProject?.address?.street} · ${bsProject?.address?.additional} · ${bsProject?.address?.zipCode} ${bsProject?.address?.city}`}
                  </Typography>
                </Grid>

                <Grid container display="flex" justifyContent="center" columnGap={3}>
                  <Grid
                    item
                    xs={2}
                    display="flex"
                    flexDirection="column"
                    sx={{ border: 2, borderColor: "#DDE6E8", borderRadius: 2, p: 1 }}>
                    <TextInputTypography label="N° d'affaire" />
                    <DefaultTypography
                      label={bsProject?.businessCode ?? ""}
                      fontSize="12px"
                      lineHeight="18px"
                      fontWeight={400}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    display="flex"
                    flexDirection="column"
                    sx={{ border: 2, borderColor: "#DDE6E8", borderRadius: 2, p: 1 }}>
                    <TextInputTypography label="Surface de référence" />
                    <DefaultTypography
                      label={bsInput?.surfaceRef.toString() || "0"}
                      fontSize="12px"
                      lineHeight="18px"
                      fontWeight={400}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    display="flex"
                    flexDirection="column"
                    sx={{ border: 2, borderColor: "#DDE6E8", borderRadius: 2, p: 1 }}>
                    <TextInputTypography label="Date du dépôt de permis" />
                    <DefaultTypography
                      label={bsProject?.licenceDate ? formatToFrench(bsProject?.licenceDate) : "Pas de date dépôt..."}
                      fontSize="12px"
                      lineHeight="18px"
                      fontWeight={400}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {isWritingMode ? (
                <Grid container item xs={1} display="flex" alignItems="flex-start" flexDirection="row" p={1}>
                  {bsProject?.status === ProjectStatusEnum.IN_PROGRESS && (
                    <Tooltip title="Modifier les informations" placement="bottom" arrow>
                      <IconButton
                        onClick={() => setOpenUpdateProjectInfo(true)}
                        sx={{ background: "#F5F8F8", borderRadius: 2, mr: 1, mt: 1 }}>
                        <CreateOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  )}

                  <Tooltip title="Supprimer le projet" placement="bottom" arrow>
                    <IconButton
                      onClick={() => setOpenDeleteDialog(true)}
                      sx={{ background: "#F5F8F8", borderRadius: 2, mr: 1, mt: 1 }}>
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Gérer l'état du projet" placement="bottom" arrow>
                    <IconButton
                      onClick={() => setOpenManageProjectStatus(true)}
                      sx={{ background: "#F5F8F8", borderRadius: 2, mr: 1, mt: 1 }}>
                      <SettingsOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              ) : (
                <Grid item xs={1} />
              )}
            </Grid>

            <Grid container pt={2} display="flex" justifyContent="space-between">
              <Grid
                container
                item
                xs={5.9}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                boxShadow={2}
                borderRadius={2}
                sx={{
                  background: selectedVariant?.calculStatus === CalculStatusEnum.WAITING_FOR_MODEL ? "#fff" : "#E6F0FB",
                  px: 4,
                  pt: 4,
                }}>
                {selectedVariant && (
                  <BSBimModelCard isWritingMode={isWritingMode && bsProject?.status === ProjectStatusEnum.IN_PROGRESS} />
                )}
              </Grid>

              <Grid
                container
                item
                xs={5.9}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                boxShadow={2}
                borderRadius={2}
                sx={{
                  background: "#fff",
                  px: 4,
                  pt: 4,
                }}>
                <BSCalculCard
                  handleChange={handleChange}
                  isWritingMode={isWritingMode && bsProject?.status === ProjectStatusEnum.IN_PROGRESS}
                />
              </Grid>
            </Grid>
          </Box>
        </Fade>
      )}

      <UpdateBSProjectDialog open={openManageProjectStatus} setOpen={setOpenManageProjectStatus} />

      <DeleteBSProjectDialog open={openDeleteDialog} setOpen={setOpenDeleteDialog} />

      <BSProjectForm open={openUpdateProjectInfo} handleClose={handleClose} />

      <BSVariantForm open={openCreateVariantForm} handleClose={handleCloseVariant} bsVariant={selectedVariant} />

      <BSSelectVariantDialog
        open={openSelectedVariants}
        handleClose={handleClose}
        projectName={bsProject?.name}
        bsProjectId={bsProject?.id}
        isVariantUpdateButton
        isWriting={bsProject?.status === ProjectStatusEnum.IN_PROGRESS}
      />

      {isWritingMode && bsProject?.status === ProjectStatusEnum.IN_PROGRESS && (
        <CustomBottomNavigation actionLabel="Créer une variante" actionButton={() => setOpenCreateVariantForm(true)} />
      )}
    </>
  )
}
