import { useMemo } from "react"
import { appConstants } from "../../appConstants"
import { BSVariant } from "../../dto/beem-shot/BSVariant/BSVariant"
import { BSVariantCreationDto } from "../../dto/beem-shot/BSVariant/BSVariantCreateDto"
import { BSVariantUpdateDto } from "../../dto/beem-shot/BSVariant/BSVariantUpdateDto"
import { useHttp } from "../use-http"

type BsVariantHook = {
  createBSVariant(beemShotProjectCreationDto: BSVariantCreationDto, sourceVariantId: string | undefined): Promise<BSVariant>
  getBSVariants(projectId: string): Promise<BSVariant[]>
  updateBSVariant(bsVariantUpdateDto: BSVariantUpdateDto): Promise<BSVariant>
}

export function useBSVariant(): BsVariantHook {
  const { get, put, post } = useHttp()

  return useMemo(
    () => ({
      createBSVariant(bsVariantCreationDto: BSVariantCreationDto, sourceVariantId: string | undefined): Promise<BSVariant> {
        const params = sourceVariantId
          ? [
              {
                key: "sourceVariantId",
                value: sourceVariantId,
              },
            ]
          : []

        return post(appConstants.apiEndpoints.BEEM_SHOT_VARIANT, bsVariantCreationDto, params).then((response) =>
          response.json()
        )
      },
      getBSVariants(projectId: string): Promise<BSVariant[]> {
        return get(`${appConstants.apiEndpoints.BEEM_SHOT_VARIANT}`, [
          {
            key: "projectId",
            value: projectId,
          },
        ]).then((response) => response.json())
      },
      updateBSVariant(bsVariantUpdateDto: BSVariantUpdateDto): Promise<BSVariant> {
        return put(appConstants.apiEndpoints.BEEM_SHOT_VARIANT_UPDATE, bsVariantUpdateDto).then((response) =>
          response.json()
        )
      },
    }),
    [get, post, put]
  )
}
