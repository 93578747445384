import { useMemo } from "react"
import { appConstants } from "../../appConstants"
import { streamToBlob } from "../../services/file-service"
import { useHttp } from "../use-http"

export type TutorialHook = {
  fetchTutorialModel: () => Promise<File | undefined>
  fetchVideoData(video: string): Promise<string>
  fetchVideoThumbnail(video: string): Promise<string>
  fetchConventionBeem(): Promise<File | undefined>
  fetchCGCBeem(): Promise<File | undefined>
  fetchRevitPlugin(): Promise<File | undefined>
  fetchSoftwareDoc(software: string, version: string): Promise<File | undefined>
}

export function useTutorial(): TutorialHook {
  const { get } = useHttp()
  return useMemo(
    () => ({
      fetchTutorialModel(): Promise<File | undefined> {
        return get(`${appConstants.apiEndpoints.BIM_MODEL}/tutorial-file`)
          .then((response) => streamToBlob(response))
          .then((blob: Blob | undefined) => {
            if (blob) {
              return new File([blob], "Tutoriel", { type: "application/ifc" })
            } else {
              return undefined
            }
          })
      },
      fetchVideoData(videoName: string): Promise<string> {
        return get(`${appConstants.apiEndpoints.BIM_MODEL}/signedUrl/video`, [
          {
            key: "video",
            value: videoName,
          },
        ]).then((response) => response.text())
      },
      fetchVideoThumbnail(videoName: string): Promise<string> {
        return get(`${appConstants.apiEndpoints.BIM_MODEL}/signedUrl/thumbnail`, [
          {
            key: "video",
            value: videoName,
          },
        ]).then((response) => response.text())
      },
      fetchConventionBeem() {
        return get(`${appConstants.apiEndpoints.UTILS}/convention-beem`).then(async (res) => {
          const blob = await streamToBlob(res)
          if (blob) {
            return new File([blob], "Convention_Beem", { type: "application/pdf" })
          }
          return undefined
        })
      },
      /**
       * @deprecated Since 2.7.1
       */
      fetchCGCBeem() {
        return get(`${appConstants.apiEndpoints.UTILS}/cahier-des-charges-beem`).then(async (res) => {
          const blob = await streamToBlob(res)
          if (blob) {
            return new File([blob], "Cahier_Des_Charges_Beem", { type: "application/pdf" })
          }
          return undefined
        })
      },
      fetchRevitPlugin() {
        return get(`${appConstants.apiEndpoints.UTILS}/revit-plugin`).then(async (res) => {
          const blob = await streamToBlob(res)
          if (blob) {
            return new File([blob], "Revit_Plugin.exe", { type: "application/octet-stream" })
          }
          return undefined
        })
      },
      fetchSoftwareDoc(software, version) {
        return get(`${appConstants.apiEndpoints.UTILS}/software-doc`, [
          {
            key: "software",
            value: software,
          },
          {
            key: "version",
            value: version,
          },
        ]).then(async (res) => {
          const blob = await streamToBlob(res)
          if (blob) {
            return new File([blob], `${software}_${version}`, { type: "application/zip" })
          }
          return undefined
        })
      },
    }),
    [get]
  )
}
