import { Button, CircularProgress, Grid, TextField } from "@mui/material"
import React, { ChangeEvent, Dispatch, FormEvent, SetStateAction, useContext, useState } from "react"
import { DateInput } from "../../../../components/inputs/date-input/date-input"
import NumberLicenseInput from "../../../../components/inputs/number-license-input/number-license-input"
import { ErrorContext } from "../../../../components/layout/error-snackbar"
import { ShareLink } from "../../../../core/dto/rsee/share-link"
import { ShareLinkCreationDto } from "../../../../core/dto/rsee/share-link-creation-dto"
import { ShareLinkUpdateDto } from "../../../../core/dto/rsee/share-link-update-dto"
import { useProjects } from "../../../../core/hooks/projects/use-projects"
import { licenseNumberToString, licenseStringValueToNumber } from "../../../../core/services/helper-service"

type SharingFormType = {
  maxUsageCount: string
  validityDate: Date | undefined
  comment: string | undefined
}

function initForm(shareLink: ShareLink | undefined): SharingFormType {
  if (shareLink === undefined) {
    return {
      maxUsageCount: "",
      validityDate: undefined,
      comment: "",
    }
  } else {
    return {
      maxUsageCount: licenseNumberToString(shareLink.maxUsageCount),
      validityDate: shareLink.validityDate,
      comment: shareLink.comment,
    }
  }
}

function formToCreationDto(form: SharingFormType): ShareLinkCreationDto {
  return {
    maxUsageCount: licenseStringValueToNumber(form.maxUsageCount, -1),
    validityDate: form.validityDate,
    comment: form.comment,
  }
}

function formToUpdateDto(form: SharingFormType, shareLinkId: string): ShareLinkUpdateDto {
  return {
    id: shareLinkId,
    maxUsageCount: licenseStringValueToNumber(form.maxUsageCount, -1),
    validityDate: form.validityDate,
    comment: form.comment,
  }
}

type IProps = {
  errors: Record<string, string>
  setErrors: Dispatch<SetStateAction<Record<string, string>>>
  rseeDocumentId?: string
  shareLink?: ShareLink
  setIsSubmitting: Dispatch<SetStateAction<boolean>>
  onSubmitSuccess(newSharingInformation: ShareLink): void
}

export function ShareForm({
  errors,
  setErrors,
  rseeDocumentId,
  shareLink,
  setIsSubmitting,
  onSubmitSuccess,
}: Readonly<IProps>): React.JSX.Element {
  const [form, setForm] = useState(initForm(shareLink))
  const openErrorSnackbar = useContext(ErrorContext)
  const { generateShareLink, updateShareLink } = useProjects()

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.id

    setErrors({ ...errors, [name]: "" })

    setForm({ ...form, [name]: value })
  }

  function handleChangeDate(date: Date | undefined): void {
    setForm({ ...form, validityDate: date })
  }

  function handleChangeMaxUsageCount(fieldId: string, newValue: string): void {
    setForm({
      ...form,
      [fieldId]: newValue,
    })
  }

  function validate(): boolean {
    return true
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault()
    const isValid = validate()
    if (rseeDocumentId && isValid) {
      setIsSubmitting(true)
      generateShareLink(rseeDocumentId)
        .then((newSharingLink: ShareLink) => {
          onSubmitSuccess(newSharingLink)
        })
        .catch((error: Error) => {
          openErrorSnackbar(error)
        })
        .finally(() => {
          setIsSubmitting(false)
        })
    } else if (shareLink?.id && isValid) {
      setIsSubmitting(true)
      updateShareLink(formToUpdateDto(form, shareLink.id))
        .then((newSharingLink) => {
          onSubmitSuccess(newSharingLink)
        })
        .finally(() => {
          setIsSubmitting(false)
        })
    }
  }

  return (
    <Grid container component="form" id="sharing-form-id" onSubmit={handleSubmit} spacing={2} sx={{ mt: 1 }}>
      <Grid item xs={12}>
        <NumberLicenseInput
          id="maxUsageCount"
          label="Nombre d'utilisations permises"
          variant="outlined"
          value={form.maxUsageCount}
          onChange={handleChangeMaxUsageCount}
          fullWidth
          error={errors}
        />
      </Grid>
      <Grid item xs={12}>
        <DateInput label={"Valide jusqu'au"} value={form.validityDate} handleChange={handleChangeDate} />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="comment"
          label="Commentaire"
          variant="outlined"
          value={form.comment}
          onChange={handleChange}
          fullWidth
          error={!!errors.comment}
        />
      </Grid>
    </Grid>
  )
}

type IButtonsProps = {
  cancel(): void
  isSubmitting: boolean
}

export function SharingFormButtons({ cancel, isSubmitting }: IButtonsProps): React.JSX.Element {
  return (
    <>
      <Button variant="outlined" color="primary" onClick={cancel}>
        Annuler
      </Button>
      {isSubmitting ? (
        <CircularProgress />
      ) : (
        <Button variant="contained" color="primary" form="sharing-form-id" type="submit">
          Valider
        </Button>
      )}
    </>
  )
}
