export enum RoofEnum {
  TOITURE_PLATE_BETON = "TOITURE_PLATE_BETON",
  TOITURE_PLATE_BOIS_CLT = "TOITURE_PLATE_BOIS_CLT",
  TOITURE_PLATE_BOIS = "TOITURE_PLATE_BOIS",
  TOITURE_PLATE_BOIS_BETON = "TOITURE_PLATE_BOIS_BETON",
  TOITURE_PLATE_ACIER_BETON = "TOITURE_PLATE_ACIER_BETON",
  TOITURE_PENTE_BOIS = "TOITURE_PENTE_BOIS",
  TOITURE_PENTE_METAL = "TOITURE_PENTE_METAL",
}

export const roofEnumLabels = {
  [RoofEnum.TOITURE_PLATE_BETON]: "Toiture plate béton",
  [RoofEnum.TOITURE_PLATE_BOIS]: "Toiture plate bois",
  [RoofEnum.TOITURE_PLATE_BOIS_CLT]: "Toiture plate bois CLT",
  [RoofEnum.TOITURE_PLATE_BOIS_BETON]: "Toiture plate bois-béton",
  [RoofEnum.TOITURE_PLATE_ACIER_BETON]: "Toiture plate mixte acier-béton",
  [RoofEnum.TOITURE_PENTE_BOIS]: "Toiture en pente-charpente bois",
  [RoofEnum.TOITURE_PENTE_METAL]: "Toiture en pente-charpente acier",
}

export function isToiturePente(toiture: RoofEnum): boolean {
  return toiture === RoofEnum.TOITURE_PENTE_BOIS || toiture === RoofEnum.TOITURE_PENTE_METAL
}
