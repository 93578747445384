import React, { useContext, useEffect, useMemo, useState } from "react"
import { Children } from "../../../components/miscellianous/children"
import { useBimModel } from "../../../core/hooks/bim-model/use-bim-model"
import { DashboardExtensionContext } from "./DashboardExtensionContext"

export const BSModelPublicContext = React.createContext<BimModelDashboardStore>({} as BimModelDashboardStore)

export function BSModelPublicContextProvider({ children }: Children): React.JSX.Element {
  const { bimModelToken, dashboardType } = useContext(DashboardExtensionContext)

  const [isInitialized, setIsInitialized] = useState<boolean>(false)
  const [bimModelFile, setBimModelFile] = useState<File | undefined>(undefined)

  const { fetchBimModelFromAuthToken } = useBimModel()

  useEffect(() => {
    if (bimModelToken) {
      fetchBimModelFromAuthToken(bimModelToken, dashboardType)
        .then((blob) => {
          if (blob) {
            const newFile = new File([blob], "bimModel.ifc", { type: "application/ifc" })
            setBimModelFile(newFile)
          }
        })
        .finally(() => {
          setIsInitialized(true)
        })
    } else {
      setIsInitialized(true)
    }
  }, [bimModelToken, dashboardType, fetchBimModelFromAuthToken])

  const bimModelDashboardStore = useMemo(
    () => ({
      bimModelFile,
    }),
    [bimModelFile]
  )
  return <BSModelPublicContext.Provider value={bimModelDashboardStore}>{children}</BSModelPublicContext.Provider>
}

export type BimModelDashboardStore = {
  bimModelFile: File | undefined
}
