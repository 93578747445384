import { Button, Dialog, DialogContent, DialogTitle, FormHelperText, TextField, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import { Auth } from "aws-amplify"
import React, { useRef, useState } from "react"
import { useForm } from "react-hook-form"

import { Check } from "@mui/icons-material"
import { ErrorContext } from "../../components/layout/error-snackbar"
import { SuccessContext } from "../../components/layout/success-snackbar"
import { TitleFormTypography } from "../../components/typography/title-form-typography"
import { isPasswordValid } from "../../core/services/user-service"

type IProps = {
  openResetPassword: boolean
  handleClose(): void
}

export default function AccountPasswordFormField({ openResetPassword, handleClose }: IProps): React.JSX.Element {
  const openErrorSnackbar: (err: Error, message?: string) => void = React.useContext(ErrorContext)
  const openSuccessSnackbar: (message: string) => void = React.useContext(SuccessContext)

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm()
  const password = useRef({})
  password.current = watch("newPassword", "")

  const [isSubmiting, setIsSubmiting] = useState<boolean>(false)

  const onSubmit = (data: any): void => {
    setIsSubmiting(true)

    Auth.currentAuthenticatedUser()
      .then((user) => Auth.changePassword(user, data.oldPassword, data.newPassword))
      .then(() => {
        openSuccessSnackbar("Nouveau mot de passe enregistré !")
        reset()
      })
      .catch((err) => {
        console.error(err)

        let message = err.message
        switch (err.message) {
          case "Incorrect username or password.":
            message = "Mot de passe incorrecte."
            break
          default:
            break
        }
        openErrorSnackbar(err, message)
      })
      .finally(() => {
        setIsSubmiting(false)
      })
  }
  const newPassword = watch("newPassword")

  return (
    <Dialog open={openResetPassword} onClose={handleClose}>
      <DialogTitle>
        <TitleFormTypography label="Réinitialiser" />
      </DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} display="flex" flexDirection="column" sx={{ flex: 1 }}>
          <TextField
            {...register("oldPassword", {
              required: "Veuillez insérez un mot de passe",
            })}
            sx={{ mt: 2, mb: 2 }}
            label="Mot de passe actuel"
            variant="outlined"
            type="password"
            error={Boolean(errors?.oldPassword)}
            helperText={Boolean(errors?.oldPassword?.message) && `${errors.oldPassword?.message}`}
          />
          <TextField
            {...register("newPassword", {
              required: "Veuillez insérez un nouveau mot de passe",
              minLength: {
                value: 7,
                message: "Le mot de passe doit avoir au moins 7 caractères.",
              },
              validate: (value: any) =>
                isPasswordValid(value) ||
                "Le mot de passe doit contenir au moins une lettre minuscule, une lettre majuscule et un caractère spécial",
            })}
            sx={{ mt: 2, mb: 2 }}
            label="Nouveau mot de passe"
            variant="outlined"
            type="password"
            error={Boolean(errors?.newPassword)}
            helperText={Boolean(errors?.newPassword?.message) && `${errors.newPassword?.message}`}
          />
          <FormHelperText component="span" id="password-helper" sx={{ color: "black" }}>
            Votre mot de passe doit comprendre au moins
            <Box display="flex" alignItems="center" gap={1}>
              <Check fontSize="small" sx={{ color: newPassword?.length >= 7 ? "#0BE2A0" : "#8F8F8F" }} />
              <Typography fontSize={12} fontWeight={500} sx={{ color: newPassword?.length >= 7 ? "black" : "#8F8F8F" }}>
                8 caractères
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <Check fontSize="small" sx={{ color: /\d/.test(newPassword) ? "#0BE2A0" : "#8F8F8F" }} />
              <Typography fontSize={12} fontWeight={500} sx={{ color: /\d/.test(newPassword) ? "black" : "#8F8F8F" }}>
                1 chiffre
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <Check fontSize="small" sx={{ color: /[!@#$%^&*(),.?":{}|<>]/.test(newPassword) ? "#0BE2A0" : "#8F8F8F" }} />
              <Typography
                fontSize={12}
                fontWeight={500}
                sx={{ color: /[!@#$%^&*(),.?":{}|<>]/.test(newPassword) ? "black" : "#8F8F8F" }}>
                1 caractère spécial
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <Check fontSize="small" sx={{ color: /[a-z]/.test(newPassword) ? "#0BE2A0" : "#8F8F8F" }} />
              <Typography fontSize={12} fontWeight={500} sx={{ color: /[a-z]/.test(newPassword) ? "black" : "#8F8F8F" }}>
                1 lettre minuscule
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <Check fontSize="small" sx={{ color: /[A-Z]/.test(newPassword) ? "#0BE2A0" : "#8F8F8F" }} />
              <Typography fontSize={12} fontWeight={500} sx={{ color: /[A-Z]/.test(newPassword) ? "black" : "#8F8F8F" }}>
                1 lettre majuscule
              </Typography>
            </Box>
          </FormHelperText>

          <TextField
            {...register("confirmationNewPassword", {
              validate: (value: any) => value === password.current || "Les mot de passes ne correspondent pas.",
            })}
            error={Boolean(errors?.confirmationNewPassword)}
            helperText={Boolean(errors?.confirmationNewPassword?.message) && `${errors.confirmationNewPassword?.message}`}
            sx={{ mt: 2 }}
            label="Confirmer votre nouveau mot de passe"
            variant="outlined"
            type="password"
          />

          <Button
            disabled={isSubmiting}
            sx={{ mt: 3, height: 40, alignSelf: "center", width: 300 }}
            variant="contained"
            type="submit">
            {isSubmiting ? "Enregistrement..." : "Enregistrer"}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
