import {
  Box,
  Button,
  Container,
  FormHelperText,
  Grid,
  Link as MaterialLink,
  Paper,
  TextField,
  Typography,
} from "@mui/material"
import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { SuccessContext } from "../../../components/layout/success-snackbar"
import { pagesUrl } from "../../../core/appConstants"
import { useQuery } from "../../../core/hooks/miscellaneous/use-query"
import { useUser } from "../../../core/hooks/use-user"
import { confirm, resendCode } from "../../../core/services/authentication-service"

interface FormValues {
  code: string
  username: string
}

export default function ConfirmPage(): React.JSX.Element {
  const navigate = useNavigate()
  const location = useLocation()
  const query = useQuery()
  const openSuccessSnackbar = useContext(SuccessContext)
  const [formValues, setFormValues] = useState<FormValues>({
    code: "",
    username: "",
  })
  const [errorConfirmation, setErrorConfirmation] = useState<string>("")
  const [errorResend, setErrorResend] = useState<string>("")

  const { sendConfirmationAccountEmail } = useUser()

  useEffect(
    () => {
      setFormValues({ ...formValues, username: query.get("email") ?? "" })
    },
    // formValues
    [query]
  )

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.id

    setErrorConfirmation("")
    setErrorResend("")

    setFormValues({ ...formValues, [name]: value })
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault()

    confirm(formValues.username.toLowerCase(), formValues.code)
      .then(() => {
        sendConfirmationAccountEmail(formValues.username)
        openSuccessSnackbar("Confirmation réussi")
        navigate(location.state?.from ?? pagesUrl.PROJECTS_PAGE)
      })
      .catch((e) => {
        switch (e.code) {
          case "AliasExistsException":
            setErrorConfirmation("Ce profil est déjà confirmé")
            break
          case "CodeMismatchException":
            setErrorConfirmation("Le code fourni est incorrect")
            break
          case "ExpiredCodeException":
            setErrorConfirmation("Le code fourni a expiré ou n'existe pas.")
            break
          case "ResourceNotFoundException":
            setErrorConfirmation("La ressource n'existe pas")
            break
          case "TooManyFailedAttemptsException":
            setErrorConfirmation("Trop de tentatives échouées. Redemandez un code ou contactez le support")
            break
          case "LimitExceededException":
            setErrorConfirmation("Limite de tentatives dépassée, veuillez réessayer après un certain temps")
            break
          case "TooManyRequestsException":
            setErrorConfirmation(
              "Au moins une requête de confirmation est déjà en cours de traitement. Votre utilisateur sera bientôt confirmé."
            )
            break
          case "UserNotFoundException":
            setErrorConfirmation("L'utilisateur n'existe pas")
            break
          case "InvalidParameterException":
            if (e.message === "User cannot be confirmed. Current status is CONFIRMED") {
              setErrorConfirmation("L'utilisateur ne peut pas être confirmé, car il est déjà confirmé")
            } else {
              setErrorConfirmation(`Erreur lors de la confirmation du code. Plus d'informations : ${e.message}`)
            }
            break
          case "InternalErrorException":
          case "InvalidLambdaResponseException":
          case "NotAuthorizedException":
          case "UnexpectedLambdaException":
          case "UserLambdaValidationException":
            setErrorConfirmation("Erreur lors de la confirmation du code")
            break
          default:
            setErrorConfirmation("Erreur lors de la confirmation du code")
            console.error("Code inconnu:", e)
        }
      })
  }

  function usernameField(): React.JSX.Element | null {
    return (
      <TextField
        id="username"
        variant="standard"
        label="Email"
        value={formValues.username}
        required
        fullWidth
        onChange={handleChange}
        sx={{ mb: 3 }}
      />
    )
  }

  function resendCodeForm(): void {
    if (!formValues.username) {
      setErrorResend("Vous devez renseigner une adresse email.")
    }

    resendCode(formValues.username)
      .then(() => {
        openSuccessSnackbar(`Le code a été renvoyé pour l'adresse '${formValues.username}'`)
      })
      .catch((e: any) => {
        setErrorResend("Erreur lors du renvoi du code.")
        console.error("Erreur lors du renvoi: ", e)
      })
  }

  return (
    <Container maxWidth="md">
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Box component="form" onSubmit={handleSubmit}>
          <Paper variant="outlined" sx={{ my: { xs: 3, md: 3 }, p: { xs: 2, md: 3 } }}>
            <Typography variant="h6" sx={{ pt: "-200px" }}>
              Confirmation de l'inscription
            </Typography>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12} sm={12}>
                {usernameField()}
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="code"
                  variant="standard"
                  label="Code"
                  error={errorConfirmation !== ""}
                  value={formValues.code}
                  required
                  onChange={handleChange}
                />
                {errorConfirmation !== "" && <FormHelperText error>{errorConfirmation}</FormHelperText>}
                <FormHelperText>Veuillez rentrer le code reçu par email. Pensez à vérifier vos spams.</FormHelperText>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormHelperText>
                  {" Vous devriez recevoir le code dans moins de 5min. Sinon, vous pouvez "}
                  <MaterialLink sx={{ cursor: "pointer" }} variant="body2" onClick={resendCodeForm}>
                    redemander un code en cliquant ici
                  </MaterialLink>
                </FormHelperText>
                {errorResend !== "" && <FormHelperText error>{errorResend}</FormHelperText>}
              </Grid>
            </Grid>
            <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
              <Button variant="contained" type="submit">
                Valider
              </Button>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Container>
  )
}
