import { Delete, Edit } from "@mui/icons-material"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import TreeItem from "@mui/lab/TreeItem"
import TreeView from "@mui/lab/TreeView"
import { Box, CircularProgress, Grid, IconButton, Table, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import React, { useContext, useEffect, useMemo, useRef } from "react"
import { LotContext } from "../../../../core/context/lot-context"
import { CodeCompletion } from "../../../../core/dto/code-completion/code-completion"
import Lot from "../../../../core/dto/lots/lot"
import SousLot from "../../../../core/dto/lots/sous-lot"
import { groupBy } from "../../../../core/services/code-service"
import "./additional-code-list.css"

type IProps = {
  additionalCodeList: CodeCompletion[]
  isListLoading: boolean
  selectedAdditionalCode: CodeCompletion | undefined
  handleClickAcv(additionalCode: CodeCompletion): void
  handleEditAdditionalCode(additionalCode: CodeCompletion): void
  handleDeleteAdditionalCode(additionalCode: CodeCompletion): void
  disabled: boolean
}

export default function AdditionalCodeList({
  selectedAdditionalCode,
  isListLoading,
  additionalCodeList,
  handleClickAcv,
  handleEditAdditionalCode,
  handleDeleteAdditionalCode,
  disabled,
}: IProps): React.JSX.Element {
  const tableRef = useRef<HTMLTableElement>(null)
  const { lots } = useContext(LotContext)
  const [selected, setSelected] = React.useState<string | undefined>()

  const newSelected = selectedAdditionalCode
    ? `${selectedAdditionalCode.name}${selectedAdditionalCode.descriptionFromCodeAcv}`
    : undefined

  useEffect(() => {
    if (tableRef) {
      const rowsList = tableRef.current?.querySelectorAll("tr")
      rowsList?.forEach((row) => {
        if (row.id === selectedAdditionalCode?.id) {
          row.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" })
        }
      })
    }
  }, [selectedAdditionalCode])

  useEffect(() => {
    setSelected(selectedAdditionalCode?.id)
  }, [newSelected])

  const groupBySousLot = useMemo(() => groupBy(additionalCodeList, "sousLot"), [additionalCodeList])

  const filteredLot = useMemo(
    () =>
      (lots || [])
        .map((lot: Lot) => {
          if (lot.children.length) {
            const newChildren = lot.children.filter((code) => code.name in groupBySousLot && [code.name].length)
            return {
              ...lot,
              children: newChildren,
            }
          }
          return lot
        })
        .filter((lot: Lot) => lot.children.length),
    [lots, groupBySousLot]
  )

  function renderLabel(additionalCode: CodeCompletion, index: number): React.JSX.Element {
    return (
      <Grid
        container
        alignItems="center"
        sx={{
          borderTop: index === 0 ? `0.2px solid #e0e0e0` : undefined,
          borderBottom: `0.2px solid #e0e0e0`,
          minHeight: 40,
        }}>
        <Grid item xs={4} zeroMinWidth>
          <Typography
            noWrap
            variant="subtitle1"
            sx={{ p: 0, textAlign: "center" }}
            fontSize={14}>{`${additionalCode.name}`}</Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography
            noWrap
            variant="subtitle1"
            sx={{ p: 0, textAlign: "center" }}
            fontSize={14}>{`${additionalCode.descriptionFromCodeAcv}`}</Typography>
        </Grid>
        <Grid item xs={1} alignItems="right" sx={{ p: 0 }}>
          {!disabled && (
            <>
              <IconButton onClick={() => handleDeleteAdditionalCode(additionalCode)} disabled={disabled}>
                <Delete />
              </IconButton>
              <IconButton
                onClick={() => {
                  handleEditAdditionalCode(additionalCode)
                }}
                disabled={disabled}>
                <Edit />
              </IconButton>
            </>
          )}
        </Grid>
      </Grid>
    )
  }

  return (
    <Box className="table-digital-model" sx={{ overflow: "auto", minHeight: "100px", maxHeight: "491px" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ p: 0, textAlign: "center" }}>Nom</TableCell>
            <TableCell sx={{ p: 0, textAlign: "center" }}>Description</TableCell>
          </TableRow>
        </TableHead>
      </Table>
      <TreeView
        aria-label="controlled"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        selected={selected}>
        {isListLoading && <CircularProgress />}
        {filteredLot.map((lot: Lot) => (
          <TreeItem key={lot.name} nodeId={lot.name} label={lot.label}>
            {lot.children.map((sousLot: SousLot) => (
              <TreeItem key={sousLot.name} nodeId={sousLot.name} label={sousLot.label}>
                {groupBySousLot[sousLot.name].map((additionalCode, index) => (
                  <TreeItem
                    key={additionalCode?.id}
                    nodeId={additionalCode?.id || ""}
                    onClick={() => {
                      if (handleClickAcv) {
                        handleClickAcv(additionalCode)
                      }
                    }}
                    label={renderLabel(additionalCode, index)}
                  />
                ))}
              </TreeItem>
            ))}
          </TreeItem>
        ))}
      </TreeView>
    </Box>
  )
}
