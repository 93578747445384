import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Typography,
} from "@mui/material"
import React, { useState } from "react"
import CloseIcon from "@mui/icons-material/Close"
import city from "../../../../assets/img/city.png"
import { useTutorial } from "../../../core/hooks/bim-model/use-tutorial"

interface IProps {
  openModal: boolean

  handleClose(): void
}

export function DownloadMethodAcceptDialog({ openModal, handleClose }: IProps): React.JSX.Element {
  const { fetchConventionBeem } = useTutorial()

  const [acceptConditions, setAcceptConditions] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  async function handleDownloadFile(): Promise<void> {
    if (acceptConditions) {
      setError(false)
      const file = await fetchConventionBeem()
      if (file) {
        const element = document.createElement("a")
        element.href = URL.createObjectURL(file)
        element.download = file.name
        element.click()
      }
      handleClose()
    } else {
      setError(true)
    }
  }

  function handleCheckbox(event: React.ChangeEvent<HTMLInputElement>): void {
    setError(false)
    setAcceptConditions(event.target.checked)
  }

  return (
    <Dialog open={openModal} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle>Appliquer la méthode Time To Beem</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}>
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid
          container
          display="flex"
          justifyContent="space-between"
          alignContent="center"
          alignItems="center"
          borderRadius={4}
          sx={{ background: "#C9FFE8" }}>
          <Grid item xs={8} display="flex" flexDirection="column" justifyContent="space-between" gap={3} sx={{ p: 5 }}>
            <Typography variant="body1">
              Consultez la documentation Time To Beem pour renseigner correctement les codes ACV dans les objets modélisés de
              la maquette numérique.
            </Typography>
            <FormControl error={error}>
              <FormControlLabel
                control={<Checkbox checked={acceptConditions} onChange={handleCheckbox} />}
                label="J'accepte les conditions d'utilisation Time To Beem"
              />
              {error && <FormHelperText>Vous devez accepter les conditions d'utilisation</FormHelperText>}
            </FormControl>

            <Grid container item xs={6}>
              <Button
                variant="contained"
                sx={{ borderRadius: 3, color: "white", backgroundColor: "#009069" }}
                onClick={() => handleDownloadFile()}>
                Télécharger le fichier ( .PDF )
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={4} p={3}>
            <img
              src={city}
              alt={city}
              height="200px"
              width="max-content"
              style={{
                marginLeft: 15,
                marginTop: -8,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
