import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded"
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material"
import { Box } from "@mui/system"
import React, { ChangeEvent, FormEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { theme } from "../../../../../../theme"
import SelectInput, { SelectOption } from "../../../../../components/inputs/select-input/select-input"
import { ProjectContext } from "../../../../../core/context/project/project-context"
import { SelectedPhaseContext } from "../../../../../core/context/selected-phase-context"
import { ProjectPhase } from "../../../../../core/dto/project/project-phase"
import { ProjectToCopyOption } from "../../../../../core/dto/project/project-to-copy-option"
import { PhaseEnum, phaseToLabel } from "../../../../../core/enum/phaseEnum"
import { useForm } from "../../../../../core/hooks/form/use-form"
import { required } from "../../../../../core/hooks/form/validation"
import {
  IniesRecordFilter,
  useIniesRecordsFiltered,
} from "../../../../../core/hooks/material-lib/use-inies-records-filtered"
import {
  ProjectRecordFilter,
  useProjectRecordsFiltered,
} from "../../../../../core/hooks/material-lib/use-project-records-filtered"
import { useCalculation } from "../../../../../core/hooks/use-calculation"

type Form = {
  projectId: string | undefined
  phase: PhaseEnum | undefined
}

type IProps = {
  open: boolean
  handleClose(): void
  refreshCode(): Promise<void>
}

export function CopyMaterialDialog({ open, handleClose, refreshCode }: Readonly<IProps>): React.JSX.Element {
  const { project } = useContext(ProjectContext)
  const { selectedPhase } = useContext(SelectedPhaseContext)
  const { getAllCopyOptions, affectMaterialFromOtherProject } = useCalculation()
  const { search: searchInies, reset: resetIniesRecordsFiltered } = useIniesRecordsFiltered()
  const { search: searchMaterial, reset: resetMaterialProjects } = useProjectRecordsFiltered()

  const [projectOptions, setProjectOptions] = useState<SelectOption<string>[]>([])
  const [phaseOptions, setPhaseOptions] = useState<SelectOption<PhaseEnum>[] | undefined>(undefined)
  const [isWaiting, setIsWaiting] = useState<boolean>(false)
  const [openAreYouSure, setOpenAreYouSure] = useState<boolean>(false)
  const [formInitialized, setFormInitialized] = useState(false)
  const projectIdToPhasesRef = useRef<Map<string, ProjectPhase[]>>(new Map<string, ProjectPhase[]>())

  function submit(formValue: Form): Promise<any> {
    if (project?.id && selectedPhase && formValue.projectId && formValue.phase) {
      return affectMaterialFromOtherProject(formValue.projectId, project.id, formValue.phase, selectedPhase)
    }
    return Promise.resolve()
  }

  const dto = useMemo(
    (): Form => ({
      projectId: undefined,
      phase: undefined,
    }),
    []
  )

  const dtoToForm = useCallback((form: Form): Form => form, [])

  const { form, errors, handleChange, handleSubmit, resetForm } = useForm(dto, dtoToForm, [required("phase")], submit)

  useEffect(() => {
    if (project.id) {
      getAllCopyOptions(project.id).then(initForm)
    }
  }, [getAllCopyOptions, project?.id])

  function initForm(projectsToCopyFrom: ProjectToCopyOption[]): void {
    const projectIdToPhases = new Map<string, ProjectPhase[]>()
    const projectSelectOptions: SelectOption<string>[] = []

    projectsToCopyFrom.forEach((option) => {
      projectIdToPhases.set(option.id, option.phases)
      projectSelectOptions.push({
        value: option.id,
        label: option.name,
      })
    })

    projectSelectOptions.sort((a, b) => {
      if (b.label && a?.label) {
        return a.label.localeCompare(b.label)
      }
      return 0
    })
    projectIdToPhasesRef.current = projectIdToPhases
    setProjectOptions(projectSelectOptions)
    setFormInitialized(true)
  }

  function handleChangeProject(event: ChangeEvent<HTMLInputElement>): void {
    handleChange(event)
    const projectId: string = event.target.value
    updatePhaseList(projectId)
  }

  function refreshMaterialLib(): Promise<void> {
    resetIniesRecordsFiltered()
    resetMaterialProjects()

    searchMaterial({ filter: new ProjectRecordFilter(), lastFdesName: undefined })
    searchInies({ filter: new IniesRecordFilter(), lastFdesName: undefined })
    return Promise.resolve()
  }

  function internalHandleSubmit(event: FormEvent<HTMLFormElement>): void {
    setIsWaiting(true)
    handleSubmit(event)
      .then(() => {
        const promise1 = refreshCode()
        const promise2 = refreshMaterialLib()
        return Promise.all([promise1, promise2])
      })
      .then(() => {
        handleClose()
        setOpenAreYouSure(false)
        resetForm()
      })
      .finally(() => {
        setIsWaiting(false)
      })
  }

  function updatePhaseList(projectId: string): void {
    const phases = projectIdToPhasesRef.current.get(projectId)
    const newPhaseOptions: SelectOption<PhaseEnum>[] = []
    phases?.forEach((phase) => {
      if (phase) {
        newPhaseOptions.push({
          value: phase.phase,
          label: phaseToLabel(phase.phase),
        })
      }
    })
    setPhaseOptions(newPhaseOptions)
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Copier les matériaux d'un autre projet</DialogTitle>
        <DialogContent sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 2 }}>
            <WarningAmberRoundedIcon sx={{ color: theme.palette.error.main }} />
            <DialogContentText fontWeight="bold" sx={{ color: theme.palette.error.main }}>
              Attention
              <br />
              Cette action va remplacer les matériaux déjà affectés ainsi que supprimer les codes complétions et les données
              supplémentaires actuellement affectés pour les remplacer par celle du projet sélectionné.
            </DialogContentText>
          </Box>
          <DialogContentText>Choisissez le projet et la phase dont les matériaux seront copiés:</DialogContentText>
          {formInitialized && (
            <Grid container component="form" id="copy-form" rowGap={2} onSubmit={internalHandleSubmit} sx={{ mt: 2 }}>
              <Grid item xs={6}>
                <SelectInput
                  id="projectId"
                  label="Projet"
                  selectedOption={form.projectId}
                  options={projectOptions}
                  mode="event"
                  handleEventChange={handleChangeProject}
                />
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={6}>
                {phaseOptions && (
                  <SelectInput
                    id="phase"
                    label="Phase"
                    selectedOption={form.phase}
                    options={phaseOptions}
                    mode="event"
                    handleEventChange={handleChange}
                    errors={errors}
                  />
                )}
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Annuler
          </Button>
          <Button variant="contained" form="copy-form" onClick={() => setOpenAreYouSure(true)}>
            Valider
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openAreYouSure}>
        <DialogContent sx={{ display: "flex", justifyContent: "center", rowGap: 2 }}>
          <DialogContentText>Êtes-vous sûr ?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpenAreYouSure(false)}>
            Annuler
          </Button>
          {isWaiting ? (
            <CircularProgress sx={{ ml: 2, mr: 2 }} />
          ) : (
            <Button variant="contained" type="submit" form="copy-form">
              Valider
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}
