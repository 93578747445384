export enum CotesEnum {
  Epaisseur = "epaisseur",
  HauteurMaximum = "hauteur maximum",
  Largeur = "largeur",
  Longueur = "longueur",
  Hauteur = "hauteur",
  HauteurUtile = "hauteur utile",
  LargeurUtile = "largeur utile",
  LongueurUtile = "longueur utile",
  HauteurReelle = "hauteur réelle",
  LargeurReelle = "largeur réelle",
  LongueurReelle = "longueur réelle",
  Inclinaison = "inclinaison",
  Surface = "surface",
  Volume = "volume",
}
