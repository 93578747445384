import { Box } from "@mui/material"
import React, { useEffect, useMemo } from "react"
import FlatRates from "../../../../../core/dto/calculation/flat-rates"
import { UsageEnum } from "../../../../../core/enum/usageEnum"
import ItemRateDataForm from "./item-rate-data-form"

type FlateRateKey =
  | "lot10EnergyNetwork"
  | "lot11CommunicationNetwork"
  | "lot8Dot1Production"
  | "lot8Dot3Emission"
  | "lot8Dot4AirTreatment"
  | "lot8Dot5PipeNetwork"
const formFields: {
  id: number
  labelToCheck: string
  title: string
  name: FlateRateKey
}[] = [
  {
    id: 1,
    labelToCheck: "Calcul détaillé",
    title: "8.1 Equipement de production (chaud/froid) + hors cogénération",
    name: "lot8Dot1Production",
  },
  {
    id: 2,
    labelToCheck: "Calcul détaillé",
    title: "8.3 Systèmes d'émission",
    name: "lot8Dot3Emission",
  },
  {
    id: 3,
    labelToCheck: "Calcul détaillé",
    title: '8.4 Traitement de l"air et éléments de désenfumage',
    name: "lot8Dot4AirTreatment",
  },
  {
    id: 4,
    labelToCheck: "Calcul détaillé",
    title: "8.5 Réseaux et conduits",
    name: "lot8Dot5PipeNetwork",
  },
  {
    id: 5,
    labelToCheck: "Calcul détaillé",
    title: '10 Réseaux d"énergie (CFO)',
    name: "lot10EnergyNetwork",
  },
  {
    id: 6,
    labelToCheck: "Calcul détaillé",
    title: "11 Réseaux de com (CFA)",
    name: "lot11CommunicationNetwork",
  },
]

const legislationRules: Record<
  UsageEnum,
  Record<
    FlateRateKey,
    {
      textFieldValue: string
      textFieldReadOnly?: boolean
      checkBoxValue?: boolean
      checkBoxReadOnly?: boolean
    }
  >
> = {
  [UsageEnum.MAISONS_INDIVIDUELLES_OU_ACCOLEES]: {
    lot8Dot1Production: {
      textFieldValue: "170",
    },
    lot8Dot3Emission: {
      checkBoxReadOnly: true,
      textFieldReadOnly: true,
      checkBoxValue: true,
      textFieldValue: "",
    },
    lot8Dot4AirTreatment: {
      checkBoxReadOnly: true,
      textFieldReadOnly: true,
      checkBoxValue: true,
      textFieldValue: "",
    },
    lot8Dot5PipeNetwork: {
      checkBoxReadOnly: true,
      textFieldReadOnly: true,
      checkBoxValue: true,
      textFieldValue: "",
    },
    lot10EnergyNetwork: {
      textFieldValue: "98",
    },
    lot11CommunicationNetwork: {
      textFieldValue: "2",
    },
  },
  [UsageEnum.LOGEMENTS_COLLECTIFS]: {
    lot8Dot1Production: {
      textFieldValue: "74",
    },
    lot8Dot3Emission: {
      checkBoxReadOnly: true,
      textFieldReadOnly: true,
      checkBoxValue: true,
      textFieldValue: "",
    },
    lot8Dot4AirTreatment: {
      checkBoxReadOnly: true,
      textFieldReadOnly: true,
      checkBoxValue: true,
      textFieldValue: "",
    },
    lot8Dot5PipeNetwork: {
      checkBoxReadOnly: true,
      textFieldReadOnly: true,
      checkBoxValue: true,
      textFieldValue: "",
    },
    lot10EnergyNetwork: {
      textFieldValue: "48",
    },
    lot11CommunicationNetwork: {
      textFieldValue: "2",
    },
  },
  [UsageEnum.BUREAUX]: {
    lot8Dot1Production: {
      textFieldValue: "60",
    },
    lot8Dot3Emission: {
      textFieldValue: "60",
    },
    lot8Dot4AirTreatment: {
      textFieldValue: "50",
    },
    lot8Dot5PipeNetwork: {
      textFieldValue: "30",
    },
    lot10EnergyNetwork: {
      textFieldValue: "115",
    },
    lot11CommunicationNetwork: {
      textFieldValue: "15",
    },
  },
  [UsageEnum.ENSEIGNEMENT_PRIMAIRE_OU_SECONDAIRE]: {
    lot8Dot1Production: {
      textFieldValue: "40",
    },
    lot8Dot3Emission: {
      textFieldValue: "20",
    },
    lot8Dot4AirTreatment: {
      textFieldValue: "40",
    },
    lot8Dot5PipeNetwork: {
      textFieldValue: "50",
    },
    lot10EnergyNetwork: {
      textFieldValue: "115",
    },
    lot11CommunicationNetwork: {
      textFieldValue: "15",
    },
  },
}

type ListRateDataFormType = {
  onChange: (field: keyof FlatRates, value: string | boolean, isCheckbox: boolean) => void
  onUserChange: (field: keyof FlatRates, value: string | boolean, isCheckbox: boolean) => void
  rate: FlatRates
  usage: UsageEnum
  variant: 1 | 2
  disabled: boolean
}

export default function ListRateDataForm({
  onChange,
  onUserChange,
  rate,
  usage,
  variant,
  disabled,
}: ListRateDataFormType): React.JSX.Element {
  const legislationRule = useMemo(() => legislationRules[usage], [usage])

  useEffect(() => {
    formFields.forEach((field) => {
      const legislationRuleField = legislationRule[field.name]
      onChange(field.name, legislationRuleField.checkBoxValue || false, true)
      onChange(field.name, legislationRuleField.textFieldValue || "", false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legislationRule, variant])

  function handleChange(field: keyof FlatRates, value: string | boolean, isCheckbox: boolean): void {
    if (isCheckbox && typeof value === "boolean") {
      const legislationRuleField = legislationRule[field as FlateRateKey]
      if (value) {
        onUserChange(field, "", false)
      } else {
        onUserChange(field, legislationRuleField.textFieldValue || "", false)
      }
    }
    onUserChange(field, value, isCheckbox)
  }

  return (
    <Box sx={{ margin: "40px 0" }}>
      {formFields.map(({ id, labelToCheck, title, name }) => (
        <ItemRateDataForm
          key={id}
          fieldName={name}
          rank=""
          disabled={disabled}
          checkBoxReadOnly={legislationRule[name].checkBoxReadOnly}
          isChecked={(legislationRule[name].checkBoxReadOnly && legislationRule[name].checkBoxValue) || rate[name].active}
          labelToCheck={labelToCheck}
          title={title}
          value={legislationRule[name].textFieldValue}
          onChange={handleChange}
        />
      ))}
    </Box>
  )
}
