import { Vector3 } from "three"
import { IfcCurve } from "./IfcCurve"
import { IfcPoint } from "./IfcPoint"
import { MathHelper } from "../helpers/MathHelper"

/**
 * represents a face points and lines in 3D space extracted from a geometry
 */
export class IfcSurface {
  curveLoop: IfcCurve[]
  polygon: IfcPoint[]
  isSelfIntersecting: boolean

  /**
   * creates a new IfcSurface from a list of points as IfcPoint objects
   */
  constructor(pts: IfcPoint[]) {
    this.polygon = []
    for (const point of pts) {
      const index = this.polygon.findIndex((x) => x.id === point.id)
      if (index === -1) {
        this.polygon.push(point)
      } else {
        this.polygon.splice(index, 1)
        this.polygon.push(point)
      }
    }
    this.curveLoop = []
    const points = [...this.polygon]
    for (let i = 0; i < points.length; i += 1) {
      if (i + 1 < points.length) {
        const vectors = [
          new Vector3(points[i].x, points[i].y, points[i].z),
          new Vector3(points[i + 1].x, points[i + 1].y, points[i + 1].z),
        ]
        this.curveLoop.push(new IfcCurve(vectors))
      } else if (points[0].id !== points[i].id && i + 1 === points.length) {
        const vectors = [
          new Vector3(points[i].x, points[i].y, points[i].z),
          new Vector3(points[0].x, points[0].y, points[0].z),
        ]
        this.curveLoop.push(new IfcCurve(vectors))
        this.polygon.push(points[0])
      }
    }

    this.isSelfIntersecting = this.hasIntersectingLines()
  }

  /**
   * Having an intersection from ray casting, this method gets the first little triangle represented
   * by three points the intersection point landed on
   */
  static getIntersectionFacePoints(intersection: any): Vector3[] {
    const face = intersection.face
    const geometry = intersection.object.geometry
    const positionAttribute = geometry.attributes.position
    const faceVertexA = new Vector3().fromBufferAttribute(positionAttribute, face.a)
    const faceVertexB = new Vector3().fromBufferAttribute(positionAttribute, face.b)
    const faceVertexC = new Vector3().fromBufferAttribute(positionAttribute, face.c)
    return [faceVertexA, faceVertexB, faceVertexC]
  }

  /**
   * Having an intersection from ray casting, this method gets the surface that the intersection landed on,
   * the algorithm do the following:
   * 1. get the little triangle represented by three points where the intersection point landed on
   * 2. get all the object lines that are coplanar with the triangle using the 3 points of the triangle + each point of the line
   * 3. connect all lines that are coplanar to form polygon loops
   * 4. return the only polygon or the polygon that contains the intersection point
   */
  static getSurface(lines: Array<Array<Vector3>>, intersection: any): IfcSurface | undefined {
    const point = intersection.point as Vector3
    const linesClone: Array<Array<Vector3>> = [...lines]
    const hitTrianglefacePoints = this.getIntersectionFacePoints(intersection)
    const faceLines = Array<Array<Vector3>>()
    if (hitTrianglefacePoints.length > 2) {
      for (const line of linesClone) {
        if (
          line.every((p) =>
            MathHelper.arePointsCoplanar(
              p,
              hitTrianglefacePoints[0],
              hitTrianglefacePoints[1],
              hitTrianglefacePoints[2],
              1e-10
            )
          )
        ) {
          faceLines.push(line)
        }
      }
    }
    const curves = IfcCurve.toIfcCurves(faceLines).filter((curve) => curve.points.length > 1)
    let groups: IfcPoint[][] = []

    for (const curve of curves) {
      const points: IfcPoint[] = IfcSurface.processCurvePointGroup(curve, curves)
      if (points.length > 0) {
        groups.push(points)
      }
    }

    groups = groups.filter((group) =>
      curves.some(
        (c) => c.points.some((p) => p.id === group[0].id) && c.points.some((p) => p.id === group[group.length - 1].id)
      )
    )
    const surfaces: IfcSurface[] = []
    for (const group of groups) {
      const s = new IfcSurface(group)
      surfaces.push(s)
    }

    if (surfaces.length === 1) return surfaces[0]

    const finalSurfaces = surfaces.filter((s) =>
      MathHelper.isPointOnPolygon(
        point,
        s.polygon.map((x) => new Vector3(x.x, x.y, x.z))
      )
    )

    const surface = finalSurfaces[0]

    return surface
  }

  /**
   * this recursive method is used to connect all the lines connected to form a polygon loop based on the first line
   */
  static processCurvePointGroup(curve: IfcCurve, allCurves: IfcCurve[]): IfcPoint[] {
    const tolerance = 1e-6
    const points: IfcPoint[] = []
    curve.processed = true

    const connectedCurves = allCurves.filter(
      (c) => !c.processed && (c.points.some((p) => p.id === curve.start.id) || c.points.some((p) => p.id === curve.end.id))
    )

    if (connectedCurves.length > 0) {
      // Sort curves by angle
      connectedCurves.sort((a, b) => {
        // If angles are equal, compare distances
        const aDistance = Math.min(
          curve.start.toVector3().distanceTo(a.start.toVector3()),
          curve.start.toVector3().distanceTo(a.end.toVector3())
        )
        const bDistance = Math.min(
          curve.start.toVector3().distanceTo(b.start.toVector3()),
          curve.start.toVector3().distanceTo(b.end.toVector3())
        )
        if (aDistance !== bDistance) {
          return aDistance - bDistance
        }

        const aAngle = curve.angleTo(a)
        const bAngle = curve.angleTo(b)
        return aAngle - bAngle
      })

      // Connect to closest curve
      const nextCurve = connectedCurves.find(
        (c) => !c.points.every((p) => curve.points.some((p2) => IfcPoint.isSamePoint(p, p2, tolerance)))
      )
      if (!nextCurve) return points
      let oppositePoint
      if (nextCurve.points.some((p) => IfcPoint.isSamePoint(p, curve.start, tolerance))) {
        points.push(curve.start)
        oppositePoint = nextCurve.points.find((p) => !IfcPoint.isSamePoint(p, curve.start, tolerance))
      } else {
        points.push(curve.end)
        oppositePoint = nextCurve.points.find((p) => !IfcPoint.isSamePoint(p, curve.end, tolerance))
      }

      if (oppositePoint) {
        points.push(oppositePoint)
        points.push(...IfcSurface.processCurvePointGroup(nextCurve, allCurves))
      }
    }

    return points
  }

  hasIntersectingLines(): boolean {
    const curves = this.curveLoop
    for (let i = 0; i < curves.length; i += 1) {
      for (let j = i + 1; j < curves.length; j += 1) {
        const firstCurve = curves[i]
        const secondCurve = curves[j]
        if (
          MathHelper.linesIntersect(
            firstCurve.points.map((x) => x.toVector3()),
            secondCurve.points.map((x) => x.toVector3())
          )
        ) {
          return true
        }
      }
    }
    return false
  }
}
