import { useMemo } from "react"
import { useHttp } from "./use-http"
import { TableauJwt } from "../dto/tableau-jwt"

export type TableauHook = {
  fetchTableauJwt(): Promise<TableauJwt>
}

export function useTableau(): TableauHook {
  const { get } = useHttp()

  return useMemo(
    () => ({
      fetchTableauJwt(): Promise<TableauJwt> {
        return get("/tableau/jwt").then((response) => response.json())
      },
    }),
    [get]
  )
}
