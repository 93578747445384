import React, { useMemo, useState } from "react"
import { Children } from "../../../components/miscellianous/children"
import { CalculationCarbonInformationProjectStore } from "../../store/calculation-carbon-information-project-store"

export const CalculationCarbonInformationProjectContext = React.createContext<CalculationCarbonInformationProjectStore>(
  {} as CalculationCarbonInformationProjectStore
)

export default function CalculationCarbonInformationProjectContextProvider({ children }: Children): React.JSX.Element {
  const [data, setData] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>(undefined)

  const CalculationCarbonInformationProjectsStore = useMemo(
    () => new CalculationCarbonInformationProjectStore(data, setData, loading, setLoading, error, setError),
    [error, loading, data]
  )
  return (
    <CalculationCarbonInformationProjectContext.Provider value={CalculationCarbonInformationProjectsStore}>
      {children}
    </CalculationCarbonInformationProjectContext.Provider>
  )
}
