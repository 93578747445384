import { Grid } from "@mui/material"
import React from "react"
import { ProjectCardList } from "../../../components/project-card-list/project-card-list"
import { ProjectTypeEnum } from "../../../core/enum/project/projectTypeEnum"
import { ProjectStatusEnum } from "../../../core/enum/projectStatusEnum"

export default function BimProjectOrganizationPage(): React.JSX.Element {
  return (
    <Grid container rowGap={2} sx={{ pr: 4, pl: 4 }} alignItems="center">
      <Grid item xs={12}>
        <Grid container columnSpacing={3}>
          <ProjectCardList
            currentProjectType={ProjectTypeEnum.BIM_ORGANIZATION}
            projectTypeUpToDate
            currentStatus={ProjectStatusEnum.IN_PROGRESS}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
