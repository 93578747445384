import React, { useMemo, useState } from "react"
import { Children } from "../../../components/miscellianous/children"
import { CodeCompletion } from "../../dto/code-completion/code-completion"
import { CalculationCarbonExtractedDataStore } from "../../store/calculation-carbon-extracted-data-store"

export const CalculationCarbonExtractedDataContext = React.createContext<CalculationCarbonExtractedDataStore>(
  {} as CalculationCarbonExtractedDataStore
)

export default function CalculationCarbonExtractedDataProvider({ children }: Children): React.JSX.Element {
  const [data, setData] = useState<any>({})
  const [codeCompletion, setCodeCompletion] = useState<CodeCompletion[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | undefined>(undefined)

  const CalculationCarbonExtractedDatatStore = useMemo(
    () =>
      new CalculationCarbonExtractedDataStore(
        data,
        setData,
        codeCompletion,
        setCodeCompletion,
        loading,
        setLoading,
        error,
        setError
      ),
    [data, codeCompletion, loading, error]
  )
  return (
    <CalculationCarbonExtractedDataContext.Provider value={CalculationCarbonExtractedDatatStore}>
      {children}
    </CalculationCarbonExtractedDataContext.Provider>
  )
}
