import { Line3, Vector2, Vector3 } from "three"
import { viewerConstants } from "../constants/ViewerConstants"

/**
 * Helper class for math operations and geometry calculations
 */
export class MathHelper {
  /**
   * decide if two numbers are equal within a tolerance
   */
  static equal(a: number, b: number, tolerance: number = viewerConstants.mathTolerance): boolean {
    return Math.abs(a - b) < tolerance
  }

  /**
   * decide if two vectors are equal within a tolerance
   */
  static pointsEqual(a: Vector3, b: Vector3, tolerance: number = viewerConstants.mathTolerance): boolean {
    return this.equal(a.x, b.x, tolerance) && this.equal(a.y, b.y, tolerance) && this.equal(a.z, b.z, tolerance)
  }

  /**
   * Project a 3D point onto a 2D plane using a normal
   * @param point The point to project
   * @param normal The normal of the plane to project onto
   * @returns The 2D point
   */
  static projectPointTo2D(point: Vector3, normal: Vector3): Vector2 {
    const maxCoord = Math.max(Math.abs(normal.x), Math.abs(normal.y), Math.abs(normal.z))

    if (maxCoord === Math.abs(normal.x)) {
      return new Vector2(point.y, point.z)
    } else if (maxCoord === Math.abs(normal.y)) {
      return new Vector2(point.x, point.z)
    } else {
      return new Vector2(point.x, point.y)
    }
  }

  /**
   * Check if a given point is inside a given polygon
   * @param poimt The point to check if the polygon is inside
   * @param polygon The polygon
   * @returns The area of the polygon
   */
  static isPointOnPolygon(point: Vector3, polygon: Vector3[]): boolean {
    const n = polygon.length
    if (n < 3) {
      return false
    }

    // Calculate the normal vector of the plane containing the polygon
    const edge1 = polygon[1].sub(polygon[0])
    const edge2 = polygon[2].sub(polygon[0])
    const normal = edge1.cross(edge2).normalize()

    // Project the 3D polygon points onto a 2D plane using the normal vector
    const projectedPolygon = polygon.map((p) => this.projectPointTo2D(p, normal))
    const projectedPoint = this.projectPointTo2D(point, normal)

    // check if the point is inside the polygon
    return this.isPointIn2DPolygon(projectedPoint, projectedPolygon)
  }

  /**
   * remove duplicate and collinear points from a polygon
   * @param points
   * @returns
   */
  static removeDuplicateAndCollinearPoints(points: Vector3[]): Vector3[] {
    const cleanedPoints = []

    for (let i = 0; i < points.length; i += 1) {
      const prev = points[(i - 1 + points.length) % points.length]
      const curr = points[i]
      const next = points[(i + 1) % points.length]

      // Remove duplicate points
      if (!curr.equals(prev)) {
        // Remove collinear points
        const ab = new Vector3().subVectors(curr, prev)
        const bc = new Vector3().subVectors(next, curr)
        const cross = new Vector3().crossVectors(ab, bc)

        if (cross.lengthSq() > Number.EPSILON) {
          cleanedPoints.push(curr)
        }
      }
    }

    return cleanedPoints
  }

  /**
   * Calculate the area of a 3d polygon using three.js for creating the faces from the points,
   * then we calculate the area of each triangle and sum the areas to find the total area of the polygon
   * also before calulation we remove duplicate and collinear points without deforming the polygon
   * Note: dont pass a closed polygon to this function so the last point doesn't need to be the first point
   * @param polygon The polygon to calculate the perimeter of
   * @returns The perimeter of the polygon
   */
  static calculateArea(polygon: Vector3[]): number {
    const points = this.removeDuplicateAndCollinearPoints([...polygon])
    const numVertices = points.length

    // Calculate the normal of the polygon (assuming it's non-self-intersecting)
    const ab = new Vector3().subVectors(points[1], points[0])
    const ac = new Vector3().subVectors(points[2], points[0])
    const normal = new Vector3().crossVectors(ab, ac).normalize()

    // Calculate the area of individual triangles and sum their areas
    let area = 0
    for (let i = 1; i <= numVertices - 2; i += 1) {
      const a = points[0]
      const b = points[i]
      const c = points[i + 1]

      const abx = new Vector3().subVectors(b, a)
      const acx = new Vector3().subVectors(c, a)

      const cross = new Vector3().crossVectors(abx, acx)
      const triangleArea = cross.dot(normal) / 2
      area += triangleArea
    }

    return Math.abs(area)
  }

  /**
   * calculates the cross product of two vectors formed by these points, and then computes
   * the dot product with the last vector formed by the first and fourth points.
   * If the dot product is close to zero, the points are coplanar.
   * @param p1 first point
   * @param p2 second point
   * @param p3 third point
   * @param p4 fourth point
   * @returns true if points are coplanar, else false
   */
  static arePointsCoplanar(
    p1: Vector3,
    p2: Vector3,
    p3: Vector3,
    p4: Vector3,
    tolerance: number = viewerConstants.mathTolerance
  ): boolean {
    const vector1 = new Vector3().subVectors(p2, p1)
    const vector2 = new Vector3().subVectors(p3, p1)
    const vector3 = new Vector3().subVectors(p4, p1)

    const crossProduct = new Vector3().crossVectors(vector1, vector2)
    const dotProduct = crossProduct.dot(vector3)

    return Math.abs(dotProduct) < tolerance
  }

  static isPointIn2DPolygon(point: Vector2, polygon: Vector2[]): boolean {
    let inside = false
    const x = point.x
    const y = point.y

    for (let i = 0, j = polygon.length - 1; i < polygon.length; i += 1) {
      const xi = polygon[i].x
      const yi = polygon[i].y
      const xj = polygon[j].x
      const yj = polygon[j].y

      const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi
      if (intersect) {
        inside = !inside
      }
      j = i
    }

    return inside
  }

  static isPointOnLine(point: Vector3, linePoints: Vector3[], tolerance: number = viewerConstants.mathTolerance): boolean {
    for (let i = 0; i < linePoints.length - 1; i += 1) {
      const lineSegment = new Line3(linePoints[i], linePoints[i + 1])
      const closestPoint = new Vector3() // Create a Vector3 for storing the result
      lineSegment.closestPointToPoint(point, true, closestPoint)
      const distance = point.distanceTo(closestPoint)

      if (distance <= tolerance) {
        return true
      }
    }

    return false
  }

  /**
   * wether 2 lines intersects
   */
  static linesIntersect(line1: Vector3[], line2: Vector3[]) {
    const x1 = line1[0].x
    const y1 = line1[0].y
    const z1 = line1[0].z
    const x2 = line1[1].x
    const y2 = line1[1].y
    const z2 = line1[1].z
    const x3 = line2[0].x
    const y3 = line2[0].y
    const z3 = line2[0].z
    const x4 = line2[1].x
    const y4 = line2[1].y
    const z4 = line2[1].z

    const denominator = (y4 - y3) * (z2 - z1) - (z4 - z3) * (y2 - y1)
    if (denominator === 0) return false

    const t1 = (x4 - x3) * (z2 - z1) - ((z4 - z3) * (x2 - x1)) / denominator
    const t2 = (x2 - x1) * (y4 - y3) - ((y2 - y1) * (x4 - x3)) / denominator

    return t1 >= 0 && t1 <= 1 && t2 >= 0 && t2 <= 1
  }
}
