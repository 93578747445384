import { Alert, Box, Button, Grid } from "@mui/material"
import React, { useCallback, useContext, useEffect, useState } from "react"
import NavigationDialog from "../../../../../components/dialog/navigation-dialog"
import ViewerIFC from "../../../../../components/ifc-displayer/ViewerIFC"
import { ViewerTypes } from "../../../../../components/ifc-displayer/enums/ViewerTypes"
import IfcDisplayer from "../../../../../components/ifc-displayer/ifc-displayer"
import { SubsetInfo } from "../../../../../components/ifc-displayer/models/SubsetInfo"
import { CodeReferenceContext } from "../../../../../core/context/code-acv/CodeReferenceContext"
import { CodeExtrait } from "../../../../../core/dto/code-extrait/code-extrait"
import { codeStateEnum } from "../../../../../core/enum/codeStateEnum"
import BimModelManquantList from "../../bim-model-upload-page/components/project-bim-model-manquant-list"
import BimModelList from "./project-bim-model-list/project-bim-model-list"
import { CodeExtraitDisplay } from "../../../../../core/dto/code-extrait/CodeExtraitDisplay"

type IProps = {
  bimModelFile: File
  reset: () => void
  isTree?: boolean
  analyzeModel: (modelId: number, ifc: ViewerIFC) => void
  codesExtraits: CodeExtrait[]
  setProgress(progress: number): void
  handleClickAcv: (codeExtrait: CodeExtrait) => void
  handleClickCodeManquant: (codeManquant: SubsetInfo) => void
  handleCodeVariantChange: (codeVariant: codeStateEnum, unhilight?: boolean) => void
  selectedCodeExtrait?: CodeExtrait
  cancelUpload?: () => void
  isTutorialPage?: boolean
}

/**
 * This component analyze the bim model using the analyze function.
 *
 * @param bimModelFile
 * @param reset
 * @param analyzeModel
 * @param codesExtraits
 * @param setProgress
 * @param handleClickAcv
 * @param handleClickCodeManquant
 * @param handleCodeVariantChange
 * @param selectedCodeExtract
 * @constructor
 */

export function BimModelAnalyzer({
  bimModelFile,
  reset,
  analyzeModel,
  codesExtraits,
  setProgress,
  handleClickAcv,
  handleClickCodeManquant,
  handleCodeVariantChange,
  selectedCodeExtrait,
  isTree,
  cancelUpload,
  isTutorialPage,
}: Readonly<IProps>): React.JSX.Element {
  const { isCodeAcvLoading } = useContext(CodeReferenceContext)
  const [codeState, setCodeState] = useState<codeStateEnum>(codeStateEnum.CODE_ACV)
  const [displayedCodeExtrait, setDisplayedCodeExtrait] = useState<CodeExtrait[]>([])
  const [codeManquantElements, setCodeManquantElements] = useState<SubsetInfo[]>([])
  const [selectedCodeManquantElement, setSelectedCodeManquantElement] = useState<SubsetInfo>()
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const getCodeExtrait = useCallback((): CodeExtrait[] => {
    if (codeState === codeStateEnum.CODE_ACV) {
      return codesExtraits.filter((codeExtrait) => codeExtrait.errors.length === 0)
    } else if (codeState === codeStateEnum.CODE_INVALIDE) {
      return codesExtraits.filter((codeExtrait) => codeExtrait.errors.length > 0)
    } else {
      return []
    }
  }, [codeState, codesExtraits])

  const updateCodeState = useCallback(
    (newState: codeStateEnum, unhighlight?: boolean): void => {
      if (newState === codeStateEnum.CODE_ACV || newState === codeStateEnum.CODE_INVALIDE) {
        setSelectedCodeManquantElement(undefined)
      }
      handleCodeVariantChange(newState, unhighlight)
      setCodeState(newState)
    },
    [handleCodeVariantChange]
  )

  function updateCodeManquantElements(subsets: SubsetInfo[]): void {
    setCodeManquantElements(subsets)
  }

  function updateSelectedCodeManquantElement(subset: SubsetInfo): void {
    handleClickCodeManquant(subset)
    setSelectedCodeManquantElement(subset)
  }

  function handleClickedCodeManquant(subset: SubsetInfo): void {
    if (selectedCodeManquantElement?.id === subset.id) {
      handleClickCodeManquant(subset)
      setSelectedCodeManquantElement(undefined)
    } else {
      updateSelectedCodeManquantElement(subset)
    }
  }

  const updateVariantForCodeExtrait = useCallback(
    (codeExtrait: CodeExtrait): void => {
      if (codeExtrait.errors.length > 0) {
        updateCodeState(codeStateEnum.CODE_INVALIDE)
      } else {
        updateCodeState(codeStateEnum.CODE_ACV)
      }
    },
    [updateCodeState]
  )

  useEffect(() => {
    if (selectedCodeExtrait) {
      updateVariantForCodeExtrait(selectedCodeExtrait)
    }
  }, [selectedCodeExtrait, updateVariantForCodeExtrait])

  useEffect(() => {
    if (selectedCodeManquantElement) {
      updateCodeState(codeStateEnum.CODE_MANQUANT)
    }
  }, [selectedCodeManquantElement, updateCodeState])

  useEffect(() => {
    setDisplayedCodeExtrait(getCodeExtrait())
  }, [codesExtraits, codeState, getCodeExtrait])

  function handleOpen(): void {
    setIsDialogOpen(true)
  }

  function clickAcv(codeExtraitDisplay: CodeExtraitDisplay): void {
    const newCodeExtrait = codesExtraits.find((codeExtrait) => codeExtrait.id === codeExtraitDisplay.id)
    if (newCodeExtrait) {
      handleClickAcv(newCodeExtrait)
    } else {
      console.error("L'utilisateur a essayé de sélectionné un code qui n'est pas dans la liste")
    }
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "500px" }}>
      <Grid container sx={{ mb: 5 }}>
        <Grid item sm={5}>
          <Grid container>
            <Grid item sm={4} sx={{ pr: 2, pl: 2 }}>
              <Button
                fullWidth
                variant={codeState === codeStateEnum.CODE_ACV ? "contained" : "outlined"}
                size="large"
                sx={{ mr: 5 }}
                onClick={() => updateCodeState(codeStateEnum.CODE_ACV, true)}>
                code acv
              </Button>
            </Grid>

            <Grid item sm={4} sx={{ pr: 2, pl: 2 }}>
              <Button
                fullWidth
                variant={codeState === codeStateEnum.CODE_INVALIDE ? "contained" : "outlined"}
                size="large"
                sx={{ mr: 5 }}
                onClick={() => updateCodeState(codeStateEnum.CODE_INVALIDE, true)}>
                code invalide
              </Button>
            </Grid>

            <Grid item sm={4} sx={{ pr: 2, pl: 2 }}>
              <Button
                fullWidth
                variant={codeState === codeStateEnum.CODE_MANQUANT ? "contained" : "outlined"}
                size="large"
                onClick={() => updateCodeState(codeStateEnum.CODE_MANQUANT, true)}
                sx={{ mr: 5 }}>
                code manquant
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={1.5} />
      </Grid>

      <Grid container rowSpacing={1} columnSpacing={2}>
        <Grid item xs={5} sm={5}>
          <Grid item sm={12}>
            {!isTutorialPage && codesExtraits?.length === 0 && bimModelFile && (
              <>
                <Alert
                  severity="error"
                  action={
                    <Button variant="outlined" color="inherit" size="small" onClick={handleOpen}>
                      AIDE
                    </Button>
                  }>
                  Erreur, aucun code renseigné dans la maquette
                </Alert>
                <NavigationDialog handleClose={() => setIsDialogOpen(false)} open={isDialogOpen} />
              </>
            )}
          </Grid>
          <BimModelList
            isTree={isTree}
            variant={codeState}
            actualPage="UPLOAD_BIM_MODEL"
            codesExtraits={displayedCodeExtrait}
            handleClickAcv={handleClickAcv}
            selectedCodeExtrait={selectedCodeExtrait}
          />
          <BimModelManquantList
            variant={codeState}
            codeManquantElements={codeManquantElements}
            selectedManquantElement={selectedCodeManquantElement}
            setSelectedManquantElement={handleClickedCodeManquant}
          />
        </Grid>
        <Grid item xs={7} sm={7}>
          {!isCodeAcvLoading && bimModelFile && (
            <IfcDisplayer
              file={bimModelFile}
              reset={reset}
              onChangeModel={analyzeModel}
              setExternalProgress={setProgress}
              type={ViewerTypes.CODE_VERIFICATION}
              codesExtraits={codesExtraits}
              showFileName
              setSelectedCodeExtrait={clickAcv}
              setCodeManquantElements={updateCodeManquantElements}
              setSelectedCodeManquantElement={updateSelectedCodeManquantElement}
              cancelUpload={cancelUpload}
              height="500px"
            />
          )}
        </Grid>
      </Grid>
    </Box>
  )
}
