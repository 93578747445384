import { Grid, Link, Typography } from "@mui/material"
import React from "react"
import { Link as RouterLink } from "react-router-dom"
import imgDashboardVariante from "../../../../assets/img/Tableau_de_bord_BIM_Variantes.png"
import IconChevronLeft from "../../../components/custom-icons/icon-chevron-left"
import { pagesUrl } from "../../../core/appConstants"
import TestUserForm from "./components/test-user-form"

const textStyle1 = {
  color: "#44738C",
  fontWeight: "bold",
  fontSize: "2rem",
  textAlign: "center",
}

const textStyle2 = {
  color: "#44738C",
  textAlign: "center",
}

const signUpStyle = {
  backgroundColor: "#002D4F",
  borderTopLeftRadius: "100px",
  display: "flex",
  justifyContent: "center",
}

export default function SignUpTestUserPage(): React.JSX.Element {
  return (
    <Grid container sx={{ backgroundColor: "#F5FBFB" }} minHeight="100vh">
      <Grid item xs={6} sm={6} sx={{ display: { xs: "none", sm: "block" } }}>
        <Grid container rowGap={2} sx={{ pt: "3rem" }}>
          <Grid item xs={1} sm={1} />
          <Grid item xs={10} sm={10} display="flex" alignItems="center" sx={{ pr: "20px", pl: "20px" }}>
            <IconChevronLeft style={{ fontSize: "24px" }} />
            <Link component={RouterLink} to={pagesUrl.SHOWCASE_WEBSITE} sx={{ color: "#44738C" }}>
              Accueil
            </Link>
          </Grid>
          <Grid item xs={1} sm={1} />
          <Grid item xs={1} sm={1} />
          <Grid item xs={10} sm={10} justifyContent="center">
            <Typography sx={textStyle1} variant="h1">
              Ravi de vous rencontrer !
            </Typography>
            <Typography sx={textStyle2} variant="h6">
              Avec Time To Beem, pilotez votre impact carbone en temps réel
            </Typography>
          </Grid>
          <Grid item xs={1} sm={1} />
          <Grid item xs={1} sm={1} />
          <Grid item xs={10} sm={10} sx={{ display: "flex", justifyContent: "center" }}>
            <img
              src={imgDashboardVariante}
              alt="Example of dashbord for BIM project"
              style={{ width: "90%", objectFit: "contain" }}
            />
          </Grid>
          <Grid item xs={1} sm={1} />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} sx={signUpStyle}>
        <TestUserForm />
      </Grid>
    </Grid>
  )
}
