import { Box, Toolbar } from "@mui/material"
import React, { useEffect } from "react"
import { Outlet, useNavigate, useParams } from "react-router-dom"
import { adminPagesUrl } from "../../core/appConstants"
import { resolveUrl } from "../../core/services/http-service"
import AdminOrganizationPanel from "../../pages/admin-page/organization/components/admin-organization-panel"

export default function LayoutAdmin(): React.JSX.Element {
  const navigate = useNavigate()
  const { organizationId } = useParams()

  useEffect(() => {
    navigate(resolveUrl(adminPagesUrl.ADMIN_ORGANIZATION_PAGE, [organizationId], {}))
  }, [navigate, organizationId])

  return (
    <Box>
      <AdminOrganizationPanel />

      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box component="main" sx={{ flexGrow: 1, p: 0, border: 0 }}>
          <Toolbar />
          <Outlet />
        </Box>
      </Box>
    </Box>
  )
}
