import { useCallback } from "react"
import { appConstants } from "../appConstants"
import { ProjectUser } from "../dto/project/project-user"
import { Team } from "../dto/team"
import { useHttp } from "./use-http"
import { Collaborator } from "../dto/user/collaborator"
import { resolveUrl } from "../services/http-service"

type ProjectsHook = {
  fetchTeam(projectId: string): Promise<Team>
  fetchContact(): Promise<ProjectUser[]>
  fetchRseeTeam(projectId: string): Promise<Collaborator[]>
  addTeamMember(cognitoUserId: string, rseeProjectId: string): Promise<Collaborator>
  revokeUserFromRseeProject(cognitoUserId: string, projectId: string): Promise<void>
  hasRseeProject(rseeProjectId: string): Promise<boolean>
}

export const useTeams = (): ProjectsHook => {
  const { get, deleteRequest, post } = useHttp()

  const fetchTeam = useCallback(
    (projectId: string): Promise<Team> =>
      get(`${appConstants.apiEndpoints.PROJECTS}/team`, [
        {
          key: "projectId",
          value: projectId,
        },
      ]).then((response) => response.json()),
    [get]
  )

  const fetchContact = useCallback(
    (): Promise<ProjectUser[]> =>
      get(`${appConstants.apiEndpoints.USER}/by-organization`).then((response) => response.json()),
    [get]
  )

  const fetchRseeTeam = useCallback(
    (projectId: string): Promise<Collaborator[]> =>
      get(resolveUrl(appConstants.apiEndpoints.RSEE_TEAM, [projectId])).then((response) => response.json()),
    [get]
  )

  const addTeamMember = useCallback(
    (cognitoUserId: string, rseeProjectId: string): Promise<Collaborator> =>
      post(resolveUrl(appConstants.apiEndpoints.RSEE_TEAM, [rseeProjectId]), undefined, [
        { key: "cognitoUserId", value: cognitoUserId },
      ]).then((response) => response.json()),
    [post]
  )

  const revokeUserFromRseeProject = useCallback(
    (cognitoUserId: string, rseeProjectId: string): Promise<void> =>
      deleteRequest(resolveUrl(appConstants.apiEndpoints.RSEE_TEAM, [rseeProjectId]), [
        { key: "cognitoUserId", value: cognitoUserId },
      ]).then(() => undefined),
    [deleteRequest]
  )

  const hasRseeProject = useCallback(
    (rseeProjectId: string): Promise<boolean> =>
      get(resolveUrl(appConstants.apiEndpoints.RSEE_TEAM_ROLE, [rseeProjectId]), [])
        .then((response) => response.text())
        .then((data) => data === "true"),
    [get]
  )

  return {
    fetchTeam,
    fetchContact,
    fetchRseeTeam,
    addTeamMember,
    revokeUserFromRseeProject,
    hasRseeProject,
  }
}
