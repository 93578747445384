import { CodesVersion } from "./enum/codesVersionEnum"

export const currentCodeVersion = CodesVersion.VERSION_1_8

export const ICADE_SIRET = "58207494401211"
export const TTB_SIRET = "92170493800015"

export const pagesUrl = {
  SHOWCASE_WEBSITE: "https://timetobeem.com/",
  SIGN_IN_PAGE: "/signin",
  SIGN_UP_INVITATION_PAGE: "/signup/:invitationId/user",
  SIGN_UP_PAGE: "/signup",
  ASK_DEMO: "https://timetobeem.com/reservez-votre-demo/",
  FORGOT_PWD_PAGE: "/password",
  CONFIRM_PAGE: "/confirm",
  ACCOUNT_PAGE: "/my-account",
  MY_OFFER_PAGE: "/my-offer",
  ACCOUNT_PAGE_UPDATE: "/my-account/update",
  SUPPORT_PAGE: "/support",
  MY_ORGANIZATION_PAGE: "/my-organization",
  MY_ORGANIZATION_INVITE_USER_PAGE: "/my-organization/invite-user",
  MY_ORGANIZATION_EDIT_USER_PAGE: "/my-organization/user/:userId",
  GLOBAL_CONTROL_PANEL_DASHBOARD: "/global-control-panel/dashboard",
  MY_MATERIAL_PAGE: "/material-lib",
  HOME: "/home",
  PROJECTS_PAGE: "/projects",
  PROJECT_PAGE: "/project",
  PROJECT_INFO: "/info",
  PROJECT_CONTROL_BOARD: "/project/:projectId/control-board",
  PROJECT_PHASE_STEPPER_CREATION: "/project/:projectId/project-phase-stepper/new",
  PROJECT_PHASE_STEPPER: "/project/:projectId/control-board/project-phase-stepper",
  PROJECT_TEAM_MANAGEMENT: "/project/:projectId/team",
  PROJECT_TEAM_CREATION: "/project/:projectId/team/new",
  PROJECT_MATERIALS: "materials",
  PROJECT_MATERIAL_PAGE: "/project/:projectId/control-board/project-material-lib",
  PROJECT_DIGITAL_MODEL: "digital-model",
  PROJECT_CALCULATION: "calculation",
  ADD_MATERIALS_PAGE: "/add-materials",
  CALCULATION_PAGE: "/calculation",
  PROJECT_BIM_MODEL: "/project/bim-model",
  PROJECT_BIM_MODEL_INTRO: "/project/bim-model/intro",
  PROJECT_BIM_MODEL_MODELIZE: "/project/bim-model/modelize",
  PROJECT_BIM_MODEL_CODIFY: "/project/bim-model/codify",
  PROJECT_BIM_MODEL_IMPORT: "/project/:projectId/bim-model/import",
  PROJECT_BIM_MODEL_IMPORT_TUTO: "/project/:projectId/bim-model",
  PROJECT_CALCULATION_CORE_DATA: "/project/:projectId/calculation",
  PROJECT_CALCULATION_FLAT_RATES: "/project/:projectId/calculation/flate-rate-data",
  PROJECT_CALCULATION_EXTRACTED: "/project/calculation/extracted",
  PROJECT_CALCULATION_EXTRACTED_DATA: "/project/:projectId/calculation/extracted-data",
  PROJECT_CALCULATION_ADDITIONAL_DATA: "/project/:projectId/calculation/additional-data",
  PROJECT_CALCULATION_WORKSITE: "/project/:projectId/calculation/worksite",
  PROJECT_CALCULATION_VARIANTS_CREATION: "/project/calculation/variants-creation",
  PROJECT_CALCULATION_SUMMARY: "/project/calculation/summary",
  PROJECT_CALCULATION_QUANTITY: "/project/calculation/quantity",
  PROJECT_CALCULATION_GENERATE_DASHBOARD: "/project/calculation/generate-dashboard",
  PROJECT_DASHBOARD: "/project/:projectId/control-board/dashboard",
  PROJECT_DASHBOARD_TABLEAU: "/project/admin/:projectId/control-board/dashboard/tableau",
  PROJECT_DASHBOARD_POWERBI: "/project/:projectId/control-board/dashboard/:powerBiId",
  PROJECT_DASHBOARD_ADMINISTRATION: "/project/dashboard/administration",
  BEEM_GUIDE_EXCEL: "/beem-guide-excel",
  BEEM_GUIDE_EXCEL_ACCEPT: "/beem-guide-excel-accept",
  ICADE_REPORT_SIGNIN: "/icade-report-signin",
  ICADE_REPORT: "/icade-report",
  SEQUENS_REPORT: "/sequens-report",
  ILOT_LABRO_R4_REPORT: "/ilot-labro-R4-report",
  ILOT_LABRO_R7_REPORT: "/ilot-labro-R7-report",
  CONTROL_BOARD_HOME: "/control-board-home",
  CONTROL_BOARD_HOME_LAND_MARK: "/control-board-home/land-mark",
  RSEE: "/rsee",
  RSEE_PROJECT_NEW: "/rsee/new",
  RSEE_PROJECT: "/rsee/project/:rseeProjectId",
  RSEE_PROJECT_GROUP_UPDATE: "/rsee/project/:rseeProjectId/group/update",
  RSEE_PROJECT_DOCUMENT: "/rsee/project/:rseeProjectId/document/:rseeDocumentId",
  RSEE_PROJECT_DOCUMENT_SUMMARY_IMPORT: "/rsee/project/:rseeProjectId/document/:rseeDocumentId/summary-import",
  RSEE_PROJECT_DOCUMENT_NEW: "/rsee/project/:rseeProjectId/document/new",
  RSEE_PROJECT_DOCUMENT_UPDATE: "/rsee/project/:rseeProjectId/document/:rseeDocumentId/update",
  RSEE_PROJECT_DOCUMENT_GROUP_UPDATE: "/rsee/project/:rseeProjectId/document/:rseeDocumentId/group/update",
  BEEM_SHOT_HOME: "/beem-shot",
  BEEM_SHOT_CREATE_PROJECT: "/beem-shot/create-project",
  BEEM_SHOT_PROJECT: "/beem-shot/project/:bsProjectId/variant/:bsVariantId",
  BEEM_SHOT_UPLOAD_PAGE: "/beem-shot/project/:bsProjectId/variant/:bsVariantId/bim-model/import",
  BEEM_SHOT_INVITATION_CONFIRMATION: "/beem-shot/project/:bsProjectId/variant/:bsVariantId/bim-model/invitation",
  BEEM_SHOT_BIM_MODEL_CONTROL: "/beem-shot/project/:bsProjectId/variant/:bsVariantId/bim-model/control",
  BEEM_SHOT_CREATE_CALCUL: "/beem-shot/project/:bsProjectId/variant/:bsVariantId/bim-model/:bimModelId/calcul/create",
  BEEM_SHOT_CALCUL: "/beem-shot/project/:bsProjectId/variant/:bsVariantId/bim-model/:bimModelId/calcul",
  BEEM_SHOT_HELP_CENTER: "/beem-shot/help-center",
  BEEM_SHOT_CONFIGURATION_HELPER: "/beem-shot/configuration-helper",
  BEEM_SHOT_TUTORIAL_HELPER: "/beem-shot/tutorial-helper",
  EXTENSION_BIM_VIEWER: "/tableau-extensions/bim-viewer",
  KAIRNIAL_DASHBOARD: "/kairnial-dashboard",
  BEEM_PILOT_HOME: "/beem-pilot",
  BEEM_PILOT_UPLOAD_PAGE: "/beem-pilot/rsee/project/:rseeProjectId/document/:rseeDocumentId/upload-file",
  BEEM_PILOT_DOCUMENT_SUMMARY_IMPORT: "/beem-pilot/rsee/project/:rseeProjectId/document/:rseeDocumentId/summary-import",
  BEEM_PILOT_DOCUMENT_DASHBOARD: "/beem-pilot/rsee/project/:rseeProjectId/document/:rseeDocumentId/dashboard",
  BEEM_PILOT_PROJECT: "/beem-pilot/rsee/project/:rseeProjectId",
  BEEM_PILOT_DASHBOARD_EXTERNAL: "/beem-pilot/rsee/external",
}

export const adminPagesUrl = {
  PROJECT_PAGE: "/admin/project",
  PROJECT_RSEE_PAGE: "/admin/organization/:organizationId/rsee",
  RSEE_PROJECT: "/admin/rsee/project/organization/:organizationId/:rseeProjectId",
  RSEE_PROJECT_DOCUMENT: "/admin/rsee/project/organization/:organizationId/:rseeProjectId/document/:rseeDocumentId",
  RSEE_PROJECT_DOCUMENT_UPDATE:
    "/admin/rsee/project/organization/:organizationId/:rseeProjectId/document/:rseeDocumentId/update",
  RSEE_PROJECT_GROUP_UPDATE: "/admin/rsee/project/organization/:organizationId/:rseeProjectId/group/update",
  RSEE_PROJECT_DOCUMENT_GROUP_UPDATE:
    "/admin/rsee/project/organization/:organizationId/:rseeProjectId/document/:rseeDocumentId/group/update",
  ADMIN_USER_ORGANIZATION_PAGE: "/admin/organization/:organizationId/user",
  BIM_PROJECT_PAGE: "/admin/organization/:organizationId/project",
  PROJECT_INFO: "/admin/organization/:organizationId/project/:projectId/control-board/info",
  PROJECT_TEAM_MANAGEMENT: "/admin/organization/:organizationId/project/:projectId/team",
  PROJECT_MATERIAL_PAGE: "/admin/organization/:organizationId/project/:projectId/control-board/project-material-lib",
  PROJECT_CONTROL_BOARD: "/admin/organization/:organizationId/project/:projectId/control-board",
  PROJECT_CALCULATION_CORE_DATA: "/admin/organization/:organizationId/project/:projectId/calculation",
  PROJECT_CALCULATION_FLAT_RATES: "/admin/organization/:organizationId/project/:projectId/calculation/flate-rate-data",
  PROJECT_CALCULATION_EXTRACTED_DATA: "/admin/organization/:organizationId/project/:projectId/calculation/extracted-data",
  PROJECT_CALCULATION_ADDITIONAL_DATA: "/admin/organization/:organizationId/project/:projectId/calculation/additional-data",
  PROJECT_CALCULATION_WORKSITE: "/admin/organization/:organizationId/project/:projectId/calculation/worksite",
  ADMIN_PROJECT_BIM_MODEL_IMPORT: "/admin/organization/:organizationId/project/:projectId/bim-model/import",
  ADMIN_PROJECT_DASHBOARD: "/admin/organization/:organizationId/project/:projectId/control-board/dashboard",
  ADMIN_PROJECT_DASHBOARD_TABLEAU: "/admin/organization/:organizationId/project/:projectId/control-board/dashboard/tableau",
  ADMIN_PROJECT_DASHBOARD_POWERBI:
    "/admin/organization/:organizationId/project/:projectId/control-board/dashboard/:powerBiId",
  MY_MATERIAL_PAGE: "/admin/material-lib/:organizationId/material-lib/admin",
  ADMIN: "/admin",
  ADMIN_ORGANIZATION_LIST_PAGE: "/admin/organization-list",
  ADMIN_INVITED_USERS_LIST_PAGE: "/admin/invited-users-list",
  ADMIN_SUMMARY: "/admin/summary",
  ADMIN_ORGANIZATION_NEW_PAGE: "/admin/organization/new",
  ADMIN_ORGANIZATION_PAGE: "/admin/organization/:organizationId",
  ADMIN_ORGANIZATION_USER_PAGE: "/admin/organization/:organizationId/user/:userId",
  ADMIN_ORGANIZATION_INVITE_USER_PAGE: "/admin/organization/:organizationId/admin-invite-user",
  ADMIN_ORGANIZATION_INVITE_FIRST_MEGA_USER: "/admin/organization/:organizationId/admin-invite-user",
  ORGANIZATION_LIST_PAGE: "/admin/organization-list",
}

export const appConstants = {
  apiEndpoints: {
    ACCOUNT: "/my-account",
    EVENT: "/event",
    PROJECTS: "/projects",
    RSEE: "/rsee",
    RSEE_PROJECT_LIST_GLOBAL_DASHBOARD: "/rsee/project-list-global-dashboard",
    RSEE_DOCUMENT_LIST_GLOBAL_DASHBOARD: "/rsee/document-list-global-dashboard",
    PROJECTS_DELETE: "/projects/delete/:projectId",
    PROJECTS_STATUS: "/projects/status",
    PROJECTS_LIST_CARD: "/projects/project-card-information",
    ORGANIZATION_PROJECT_CARDS_LIST: "/projects/organisation-project-card-information",
    ADMIN_ORGANIZATION_PROJECT_CARDS_LIST: "/projects/admin-organisation-project-card-information",
    ADMIN_RSEE_ORGANIZATION_PROJECT_CARDS_LIST: "/rsee/admin-organisation-project-card-information",
    RSEE_ORGANIZATION_PROJECT_CARDS_LIST: "/rsee/organisation-project-card-information",
    RSEE_PROJECT_CARDS_LIST: "/rsee/project-cards",
    RSEE_PROJECT: "/rsee/project/:projectId",
    RSEE_DOCUMENT_CARDS_LIST: "/rsee/project/:projectId/document-card-information",
    RSEE_DOCUMENT_URL: "/rsee/dashboard-url",
    RSEE_DOCUMENT_IS_DASHBOARD_READY: "/tableau/is-data-ready",
    RSEE_DOCUMENT: "/rsee/document/:documentId",
    RSEE_DOCUMENT_LIST: "/rsee/document/list",
    RSEE_DOCUMENT_SHARE_LINK: "/rsee/document/:documentId/share-link",
    RSEE_SHARE_LINK: "/rsee/share-link",
    RSEE_SHARE_LINK_ID: "/rsee/share-link/:shareLinkId",
    RSEE_SHARE_INFORMATION: "/rsee/dashboard-shared-information",
    RSEE_PROJECT_LINK_LIST: "/rsee/project/:projectId/share-link/list",
    RSEE_DOCUMENT_LINK_LIST: "/rsee/document/:documentId/share-link/list",
    RSEE_TEAM: "/rsee/project/:projectId/team",
    RSEE_TEAM_ROLE: "/rsee/project/:projectId/role",
    RSEE_ADD_DOCUMENT: "/rsee/project/add-document",
    RSEE_UPDATE_CURRENT_DOCUMENT: "/rsee/update-current-document",
    RSEE_SUMMARY_IMPORT_LIST: "/rsee/project/:rseeProjectId/document/:rseeDocumentId/summary-import",
    CALCULATION: "/calculation",
    CALCULATION_VARIANT: "/calculation/variant",
    CODE_ACV: "/code-acv",
    BEEM_GUIDE_EXCEL: "/require-beem-guide-access",
    MATERIAL: "/materiallib",
    MATERIAL_ORGANIZATION: "/materiallib/organization-material",
    PROJECT_ORGANIZATION: "/materiallib/project-material",
    MATERIAL_ORGANIZATION_INDICATOR: "/materiallib/organization-indicator",
    MATERIAL_ORGANIZATION_INDICATOR_ADMIN: "/materiallib/organization-indicator-admin",
    MATERIAL_ORGANIZATION_FILTERED_PAGINATED: "/materiallib/by-organization-filtered-paginated",
    ORGANIZATION_RECORDS_FILTERS_PAGINATED_ADMIN: "/materiallib/by-organization-filtered-paginated-admin",
    MATERIAL_PROJECT_FILTERED_PAGINATED: "/materiallib/by-project-filtered-paginated",
    ORGANIZATION: "/organization",
    INIES: "/inies",
    INIES_NOMENCLATURE_FILTERS: "/inies/get-nomenclature-filters",
    INIES_RECORDS_FILTERS_PAGINATED: "/inies/inies-records-filtered-paginated",
    ORGANIZATION_RECORDS_FILTERS_PAGINATED: "/materiallib/by-organization-filtered-paginated",
    PROJECT_RECORDS_FILTERS_PAGINATED: "/materiallib/by-project-filtered-paginated",
    INIES_DECLARATION_TYPE: "/inies/declaration-types",
    PROJECT_MATERIAL_INDICATOR: "/materiallib/project-indicator",
    USER: "/user",
    USER_WITH_ID: "/user/:userId",
    USER_ADMIN: "/user/admin/:userId",
    FORBIDDEN_DOMAIN_NAMES: "/user/forbidden-domain-names",
    UTILS: "/utils",
    UTILS_STATISTICS_CREATE: "/utils/statistics/create",
    UTILS_STATISTIC: "/utils/statistics/:statisticId",
    UTILS_STATISTICS_LIST: "/utils/statistics/list",
    BEEM_SHOT: "/beem-shot",
    BEEM_SHOT_PROJECT: "/beem-shot/project",
    BEEM_SHOT_PROJECTS: "/beem-shot/projects",
    BEEM_SHOT_PROJECT_ID: "/beem-shot/project/:bsProjectId",
    BEEM_SHOT_PROJECT_ID_START_ESTIMATE: "/beem-shot/bsVariantId/:bsVariantId/start-estimating",
    BEEM_SHOT_INPUT: "/beem-shot/input",
    BEEM_SHOT_UPDATE_BS_ITEMS: "/beem-shot/:bsVariantId/update-bs-items",
    BEEM_SHOT_INPUT_ID: "/beem-shot/input/:inputId",
    BEEM_SHOT_BIM_MODEL: "/beem-shot/bs-bim-model",
    BEEM_SHOT_BIM_MODEL_ID: "/beem-shot/:bsVariantId/bs-bim-model",
    BEEM_SHOT_BIM_FILE: "/beem-shot/:bsVariantId/file",
    BEEM_SHOT_ITEM: "/beem-shot/:bsVariantId/bs-item",
    BEEM_SHOT_ITEM_UPDATE: "/beem-shot/update-bs-item",
    BEEM_SHOT_BIM_MODEL_INVITATION: "/beem-shot/invitation",
    BEEM_SHOT_BIM_MODEL_SEND_BACK_INVITATION: "/beem-shot/:bsProjectId/send-back-invitation",
    BEEM_SHOT_BIM_MODEL_CANCEL_INVITATION: "/beem-shot/:invitationId/cancel-invitation",
    BEEM_SHOT_CATEGORIES: "/beem-shot/categories",
    BEEM_SHOT_MACRO_COMPONENT_TYPE: "/beem-shot/bs-macro-component-type",
    BEEM_SHOT_UPDATE_CALCUL_STATUS_ENUM: "/beem-shot/:bsVariantId/calcul-status",
    BEEM_SHOT_MACRO_COMPONENT: "/beem-shot/:bsProjectId/bs-macro-component",
    BEEM_SHOT_COVER_IMAGE: "/beem-shot/coverImage",
    BEEM_SHOT_CALCULATION_READY: "/beem-shot/calculation-ready",
    BEEM_SHOT_MACRO_COMPONENT_OPTIONS: "/beem-shot/:bsVariantId/bs-item/:bsItemId/bs-macro-components-options",
    BEEM_SHOT_DASHBOARD_VARIANTE_URL: "/beem-shot/variantes-dashboard-url",
    BIM_MODEL: "/bim-models",
    BIM_MODEL_PUBLIC_CODES_EXTRAITS: "/bim-models/codes-extraits-from-auth-token",
    BIM_MODEL_PUBLIC_CODES_EXTRAITS_FOR_BEEM_SHOT: "/bim-models/bs-codes-extraits-from-auth-token",
    BIM_MODEL_AUTH_TOKEN: "/bim-models/bim-model-from-auth-token",
    BIM_MODEL_ID: "/bim-models/:bimModelId",
    BIM_MODEL_ID_AUTH_TOKEN: "/bim-models/:bimModelId/bim-model-auth-token",
    KAIRNIAL_JWT: "/kairnial/public-jwt",
    KAIRNIAL_DASHBOARD_INFO: "/kairnial/dashboard-info",
    KAIRNIAL_PROJECTS: "/kairnial/projects",
    KAIRNIAL_REFRESH: "/kairnial/refresh",
    KAIRNIAL_GLOBAL_DASHBOARD_URL: "/kairnial/global-dashboard-url",
    BEEM_SHOT_VARIANT: "/beem-shot/variant",
    BEEM_SHOT_VARIANT_UPDATE: "/beem-shot/update-bs-variant",
    HOME_PAGE_INDICATORS: "/home-page/indicators",
    HOME_LAST_PROJECTS: "/home-page/last-projects",
  },

  regex: {
    email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    onlyDigits: /\d+/,
    notDigits: /\D+/,
    phone: /^\+\d{9,11}$/,
    passwordContainsLowerCase: /.*[a-z].*/,
    passwordContainsUpperCase: /.*[A-Z].*/,
    passwordContainsSpecialChar: /.*[\^$*.[\]()?\-"!@#%&/\\,><':;|_~`+=].*/,
    number: /\d*[,.]?\d*/,
    zipCode: /^((0[1-9])|([1-8][0-9])|(9[0-8])|(2A)|(2B))[0-9]{3}$/,
    ifcUnicodeRegEx: /\\X2\\(.*?)\\X0\\/giu,
    ifcUnicodeAccentMatcherRegEx: /\\X\\([0-9A-Fa-f]{2})/g,
    ifcUnicodeAccentReplacerRegEx: /\\x([0-9a-f]{2})/gi,
  },

  // localStorageKey
  localStorage: {
    USER: "user",
  },

  miscellaneous: {
    DRAWER_WIDTH: 240,
    PROJECT_IMG_WIDTH: 270,
    PROJECT_IMG_HEIGTH: 270,
    PROJECT_IMG_MAX_WIDTH: 1080,
    PROJECT_IMG_MAX_HEIGTH: 1080,
    PROJECT_IMG_MAX_SIZE: 3, // MB
    LABEL_UNLIMITED: "Illimité",
  },

  /** Cache API keys */
  cacheApi: {
    /** caching key for the ifc file for the caching and file size feature */
    IFC_CACH: "ifcCach",
    /** caching key for the fullscreen in new tab feature */
    FULLSCREEN_CACH: "fullScreenCach",
  },

  contact: {
    mail: "contact@time-to-beem.com",
    phone: "+33 7 61 89 58 03",
  },

  web: {
    baseInie: "https://www.base-inies.fr/iniesV4/dist/consultation.html",
  },

  utils: {
    space: " ",
  },
}
