import { BufferGeometry, Line, Points, Scene, Vector3 } from "three"
import { IfcViewerAPI } from "web-ifc-viewer"
import { DimensionMode } from "../../../enums/DimensionMode"
import { MeasurementHelper } from "../../../helpers/MeasurementHelper"
import { UnitsHelper } from "../../../helpers/UnitsHelper"
import { LineGroup } from "../annotations/LabelGroupTypes"
import { LabelStyle } from "../annotations/LabelStyle"
import { PointData } from "../annotations/PointData"
import TextLabels from "../annotations/TextLabelsGenerator"

export class DimensionCommand {
  static addedLines: Array<LineGroup> = []
  ifc: IfcViewerAPI
  scene: Scene
  endPoint?: PointData
  linePoints?: Array<PointData>
  points?: Array<Vector3>
  lineGeom?: BufferGeometry
  line?: Line
  distance?: number
  distanceString?: string
  textPosition?: Vector3
  label: any
  labelpoints?: Points
  pointsGeometry?: BufferGeometry
  group?: LineGroup
  subLine?: Line
  dimensionMode?: DimensionMode = DimensionMode.ONE_POINT

  constructor(scene: Scene, ifc: IfcViewerAPI) {
    this.ifc = ifc
    this.scene = scene
    this.group = new LineGroup()
    this.group.assosiatedObject = this
  }

  /**
   * Execute the command
   */
  execute(): void {
    if (this.group) {
      this.scene.add(this.group)
      DimensionCommand.addedLines.push(this.group)
    }
  }

  /**
   * Undo the command
   */
  undo(): void {
    if (this.group != null) {
      this.scene.remove(this.group)
      DimensionCommand.addedLines = DimensionCommand.addedLines.filter((ar) => ar !== this.group)
    }
  }

  /**
   * Redo the command (if it was undone)
   */
  redo(): void {
    this.execute()
  }

  /**
   * Dispose all instance's geometric data
   */
  dispose(): void {
    if (this.group && this.line && this.label && this.labelpoints) {
      this.group.remove(this.line)
      this.group.remove(this.label)
      this.group.remove(this.labelpoints)
      if (this.subLine) this.group.remove(this.subLine)
      this.undo()
      this.line.geometry.dispose()
      this.labelpoints.geometry.dispose()
      if (this.subLine) this.subLine.geometry.dispose()
      this.label.material.dispose()
      this.label.clear()
      this.group.assosiatedObject = null
      this.group.clear()
    }
  }

  async defineTextLabel(modelId: number): Promise<void> {
    const units = await UnitsHelper.getConversionUnits(this.ifc, modelId)
    const precision = UnitsHelper.setNumericalPrecision(units.projectUnit)
    if (this.distance && this.textPosition) {
      this.distanceString = ` ${this.distance?.toFixed(precision)} [${
        this.dimensionMode === DimensionMode.AREA ? "m²" : units.unitSymbol
      }]`
      this.label = TextLabels.makeTextSprite(
        this.distanceString,
        this.textPosition.x,
        this.textPosition.y,
        this.textPosition.z,
        LabelStyle.defaultStyle
      )
    }
    this.label.material.depthTest = false
  }

  updateTextSize(size?: string): void {
    MeasurementHelper.updateLabelSize(this.ifc, this.label, this.textPosition ?? new Vector3(), Number(size))
  }
}
