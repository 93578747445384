import { Box, CircularProgress, Grid, TablePagination, Typography } from "@mui/material"
import React from "react"
import { FicheConfiguree } from "../../../../../core/dto/fiche-configuree/fiche-configuree"
import { originMaterialRecordToLabel } from "../../../../../core/dto/material/material"
import { DeclarationTypeEnum } from "../../../../../core/enum/declarationTypeEnum"
import { getDeclarationTypeLabel } from "../../../../../core/services/declaration-type-service"
import { IniesRecord } from "../../../../../core/dto/material/IniesRecord"
import { MaterialRecord } from "../../../../../core/dto/material/MaterialRecord"

type MaterialPageTableProps = {
  handleSetSelectedRow(row: IniesRecord | MaterialRecord | FicheConfiguree | undefined): void
  getTypologyBackground(typologie: DeclarationTypeEnum): string
  getTypologyTextColor(typologie: DeclarationTypeEnum): string
  records: IniesRecord[] | MaterialRecord[] | FicheConfiguree[] | []
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  rowsPerPage: number
  setRowsPerPage(newlimit: number): void
  handleSearchPaginated(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void
  isLoading: boolean
}

export function BSMaterialLibTable({
  handleSetSelectedRow,
  getTypologyBackground,
  getTypologyTextColor,
  records,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  handleSearchPaginated,
  isLoading,
}: Readonly<MaterialPageTableProps>): React.JSX.Element {
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  function retirerDonneeEnvironnementale(str: string): string {
    const texteASupprimer = " - DONNEE ENVIRONNEMENTALE PAR DEFAUT"
    if (str.endsWith(texteASupprimer)) {
      return str.slice(0, -texteASupprimer.length)
    }
    return str
  }

  return (
    <>
      <Grid
        container
        minHeight={200}
        display="flex"
        flexDirection="row"
        rowGap={1}
        justifyContent="center"
        alignContent="flex-start"
        alignItems="flex-start"
        width="97%">
        <Box display="flex" alignItems="center" p={1} gap={1} width="97%">
          <Grid item xs={2}>
            <Typography variant="subtitle2" fontSize={12} sx={{ color: "#8F8F8F" }}>
              Typologie de déclaration
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="subtitle2" fontSize={12} sx={{ color: "#8F8F8F" }}>
              Nom du produit
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle2" fontSize={12} sx={{ color: "#8F8F8F" }}>
              Organisme déclarant
            </Typography>
          </Grid>
        </Box>

        {records.length === 0 && (isLoading ? <CircularProgress /> : <Box>Pas de résultats disponible</Box>)}
        {records.length > 0 &&
          records.map((record: IniesRecord | MaterialRecord | FicheConfiguree) => (
            <Box
              display="flex"
              alignItems="center"
              borderRadius={3}
              p={1.5}
              minHeight={5}
              gap={1}
              key={record.id}
              boxShadow={5}
              width="97%"
              onClick={() => handleSetSelectedRow(record)}
              sx={{ ":hover": { width: "100%", cursor: "pointer" } }}>
              <Grid item xs={2}>
                <Typography
                  fontWeight={600}
                  component="span"
                  sx={{
                    backgroundColor: getTypologyBackground(
                      record instanceof FicheConfiguree ? DeclarationTypeEnum.FICHE_CONFIGUREE : record.declarationType
                    ),
                    color: getTypologyTextColor(
                      record instanceof FicheConfiguree ? DeclarationTypeEnum.FICHE_CONFIGUREE : record.declarationType
                    ),
                    padding: "8px",
                    borderRadius: "28px",
                    fontSize: 12,
                    whiteSpace: "nowrap",
                  }}>
                  {getDeclarationTypeLabel(
                    record instanceof FicheConfiguree ? DeclarationTypeEnum.FICHE_CONFIGUREE : record.declarationType
                  )}
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="subtitle2" fontSize={12}>
                  {record instanceof IniesRecord && retirerDonneeEnvironnementale(record.fdesName)}

                  {record instanceof FicheConfiguree && retirerDonneeEnvironnementale(record.name)}

                  {record instanceof MaterialRecord && (
                    <Typography
                      component="span"
                      sx={{
                        fontWeight: "bold",

                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        color: record instanceof MaterialRecord ? originMaterialRecordToLabel(record).color : "",
                      }}>
                      {` (${record instanceof MaterialRecord ? originMaterialRecordToLabel(record).text : ""})`}
                    </Typography>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                {(record instanceof IniesRecord || record instanceof MaterialRecord) && (
                  <Typography sx={{ fontSize: 12 }}>{`${record?.responsibleOrganism}`}</Typography>
                )}

                {record instanceof FicheConfiguree && (
                  <Typography sx={{ fontSize: 12 }}>{`${record?.configuratorName}`}</Typography>
                )}
              </Grid>
            </Box>
          ))}
      </Grid>

      <TablePagination
        sx={{
          mt: 1,
          display: "flex",
          justifyContent: "center",
        }}
        component="div"
        count={records.length === rowsPerPage ? -1 : records.length}
        page={page}
        onPageChange={handleSearchPaginated}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50]}
        labelDisplayedRows={({ from, to }) =>
          `${from < rowsPerPage ? from : `... ${from}`}-${records.length === rowsPerPage ? ` ${to} ...` : from + to} `
        }
        labelRowsPerPage="Ligne par page:"
      />
    </>
  )
}
