import { useMemo } from "react"
import { appConstants } from "../appConstants"
import { Organization } from "../dto/organization/organization"
import { OrganizationAdminCreateOrUpdate } from "../dto/organization/organization-admin-create-or-update"
import { OrganizationUpdate } from "../dto/organization/organization-update"
import { SimpleContact } from "../dto/SimpleContact"
import { Collaborator } from "../dto/user/collaborator"
import { Contact } from "../dto/user/contact"
import { useHttp } from "./use-http"

type OrganizationHook = {
  fetchOrganizationsList(): Promise<Organization[]>
  fetchMyOrganization(): Promise<Organization | undefined>
  adminFetchOrganization(organizationId: string): Promise<Organization>
  updateOrganization(organization: OrganizationUpdate): Promise<Organization>
  fetchCollaborators(): Promise<Collaborator[]>
  fetchContacts(): Promise<Contact[]>
  createOrUpdateOrganizationAsUltimate(organization: OrganizationAdminCreateOrUpdate): Promise<Organization>
  getContactAndCollabs(projectId: string): Promise<SimpleContact[]>
  getCollaboratorsForOrganization(organizationId: string): Promise<Collaborator[]>
  fetchOrganizationDashboardUrl(): Promise<string>
}

export function useOrganization(): OrganizationHook {
  const { get, post, put } = useHttp()

  return useMemo(
    () => ({
      fetchOrganizationsList(): Promise<Organization[]> {
        return get(`${appConstants.apiEndpoints.ORGANIZATION}/list`).then((response) => response.json())
      },
      fetchMyOrganization(): Promise<Organization | undefined> {
        return get(`${appConstants.apiEndpoints.ORGANIZATION}/my-organization`).then((response) => {
          if (response.status === 401) {
            return undefined
          } else {
            return response.json()
          }
        })
      },
      adminFetchOrganization(organizationId: string): Promise<Organization> {
        return get(`${appConstants.apiEndpoints.ORGANIZATION}/${organizationId}`).then((response) => response.json())
      },
      updateOrganization(organization): Promise<Organization> {
        return put(`${appConstants.apiEndpoints.ORGANIZATION}/my-organization`, organization).then((response) =>
          response.json()
        )
      },
      fetchCollaborators(): Promise<Collaborator[]> {
        return get(`${appConstants.apiEndpoints.ORGANIZATION}/collaborator/list`).then((response) => response.json())
      },
      fetchContacts(): Promise<Contact[]> {
        return get(`${appConstants.apiEndpoints.ORGANIZATION}/contact/list`).then((response) => response.json())
      },
      createOrUpdateOrganizationAsUltimate(organization: OrganizationAdminCreateOrUpdate): Promise<Organization> {
        if (organization.id) {
          return put(`${appConstants.apiEndpoints.ORGANIZATION}`, organization).then((response) => response.json())
        } else {
          return post(`${appConstants.apiEndpoints.ORGANIZATION}`, organization).then((response) => response.json())
        }
      },
      getContactAndCollabs(projectId: string): Promise<SimpleContact[]> {
        return get(`${appConstants.apiEndpoints.ORGANIZATION}/contacts-and-collabs`, [
          { key: "projectId", value: projectId },
        ]).then((response) => response.json())
      },
      getCollaboratorsForOrganization(organizationId: string): Promise<Collaborator[]> {
        return get(`${appConstants.apiEndpoints.ORGANIZATION}/admin/collaborator/list`, [
          {
            key: "organizationId",
            value: organizationId,
          },
        ])
          .then((response) => response.json())
          .catch((err) => {
            throw err
          })
      },
      fetchOrganizationDashboardUrl(): Promise<string> {
        return get(`${appConstants.apiEndpoints.ORGANIZATION}/dashboard-url`, []).then((response) => response.text())
      },
    }),
    [get, post, put]
  )
}
