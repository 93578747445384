export enum RoofFlatCoverEnum {
  GRAVILLONNEE = "GRAVILLONNEE",
  DALLES_SUR_PLOTS = "DALLES_SUR_PLOTS",
  VEGETALISEE = "VEGETALISEE",
  PLATELAGE = "PLATELAGE",
}

export const roofFlatCoverEnumLabels = {
  [RoofFlatCoverEnum.GRAVILLONNEE]: "Toiture gravillonnée",
  [RoofFlatCoverEnum.DALLES_SUR_PLOTS]: "Dalles sur plots",
  [RoofFlatCoverEnum.VEGETALISEE]: "Toiture végétalisée",
  [RoofFlatCoverEnum.PLATELAGE]: "Platelage bois",
}
