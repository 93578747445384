import React from "react"
import { Paper, Typography } from "@mui/material"

type IProps = {
  height: string
}
export default function EmptyDisplayer({ height }: IProps): React.JSX.Element {
  return (
    <Paper
      sx={{
        height: { height },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        textAlign: "center",
        width: "100%",
      }}>
      <Typography>Erreur de chargement du viewer. Actualiser la page peut régler le problème.</Typography>
    </Paper>
  )
}
