import ViewInArIcon from "@mui/icons-material/ViewInAr"
import { Box, Button } from "@mui/material"
import React, { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import TitleH1 from "../../../components/miscellianous/title-h1"
import { ProjectContext, ProjectStore } from "../../../core/context/project/project-context"
import { TutorialEnabledContext } from "../../../core/context/tutoriel-enabled-context"
import { useUser } from "../../../core/hooks/use-user"
import { pagesUrl } from "../../../core/appConstants"

export default function ProjectBimModelHeader(): React.JSX.Element {
  const { project } = useContext<ProjectStore>(ProjectContext)
  const navigate = useNavigate()

  const [check, setCheck] = useState<boolean>(false)
  const { disableBimModelTutorial } = useUser()
  const { setTutorialEnabled } = useContext(TutorialEnabledContext)

  function handleBack(): void {
    navigate(`/project/${project?.id}/control-board`, {
      state: {
        project,
      },
    })
  }

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <Box display="flex" flexDirection="row">
        <ViewInArIcon fontSize="large" sx={{ mt: 2, mr: 4 }} />
        <TitleH1 text="Workflow Beem" />
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
        {/*  Reactivate someday if needed */}
        {/*<FormGroup aria-label='position' row>*/}
        {/*  <FormControlLabel*/}
        {/*    value='end'*/}
        {/*    control={*/}
        {/*      <Checkbox*/}
        {/*        checked={check}*/}
        {/*        onChange={() => {*/}
        {/*          setCheck(!check)*/}
        {/*        }}*/}
        {/*      />*/}
        {/*    }*/}
        {/*    label="Ne plus m'afficher cette étape"*/}
        {/*    labelPlacement='end'*/}
        {/*  />*/}
        {/*</FormGroup>*/}
        <Box display="flex">
          <Button
            variant="outlined"
            onClick={() => {
              handleBack()
            }}
            sx={{ mr: 4 }}>
            Retour
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              if (check) {
                setTutorialEnabled(false)
                disableBimModelTutorial()
              }
              navigate(`${pagesUrl.PROJECT_PAGE}/${project?.id}/bim-model/import`)
            }}>
            Suivant
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
