/* eslint-disable jsx-a11y/anchor-is-valid */
import { Avatar, Box, Link, Typography } from "@mui/material"
import React, { useState } from "react"
import { ProjectInvitation } from "../../../core/dto/user/projectInvitation"

type IProps = {
  selectedUser: ProjectInvitation
  cancelButton: boolean
  openCancelInvitationDialog(user: ProjectInvitation | undefined): void
}

export default function TeamInvitationDetails({
  selectedUser,
  cancelButton,
  openCancelInvitationDialog,
}: IProps): React.JSX.Element {
  const espace = " "
  const initName = selectedUser.firstName === undefined ? null : selectedUser.firstName[0].toUpperCase()
  const fullName = selectedUser.lastName + espace + selectedUser.firstName

  const [isCancelling, setIsCancelling] = useState(false)

  function onCancelInvitation(): void {
    openCancelInvitationDialog(selectedUser)
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "row", padding: "2% 5%" }}>
      <Avatar sx={{ bgcolor: "", size: "large" }} variant="rounded">
        {initName}
      </Avatar>
      <Box sx={{ display: "block", padding: "0% 5%" }}>
        <Typography component="div" sx={{ fontWeight: "bold" }}>
          {fullName}
        </Typography>
        <Typography component="div" sx={{ fontSize: "10px" }}>
          <Typography component="div" color="#FF4C0A" sx={{ fontSize: "10px" }}>
            En attente d'acceptation ...
          </Typography>
          {cancelButton && (
            <Link onClick={onCancelInvitation} sx={{ cursor: "pointer" }} underline="always">
              Annuler l'invitation
            </Link>
          )}
        </Typography>
      </Box>
    </Box>
  )
}
