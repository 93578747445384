export enum BuildingFacadeEnum {
  BETON_DE_CHANVRE = "BETON_DE_CHANVRE",
  BRIQUE = "BRIQUE",
  OSSATURE_BOIS_BETON_DE_CHANVRE = "OSSATURE_BOIS_BETON_DE_CHANVRE",
  PIERRE_NATURELLE = "PIERRE_NATURELLE",
  PISE = "PISE",
  TERRE_CRUE_PORTEUSE = "TERRE_CRUE_PORTEUSE",
  OSSATURE_BOIS = "OSSATURE_BOIS",
  PARPAING = "PARPAING",
  BETON_ARMEE_PREFABRIQUE = "BETON_ARMEE_PREFABRIQUE",
  BETON = "BETON",
}

export const buildingFacadeEnumLabels = {
  [BuildingFacadeEnum.BETON_DE_CHANVRE]: "Béton de chanvre",
  [BuildingFacadeEnum.BRIQUE]: "Brique de terre cuite",
  [BuildingFacadeEnum.OSSATURE_BOIS_BETON_DE_CHANVRE]: "Ossature bois béton de chanvre",
  [BuildingFacadeEnum.PIERRE_NATURELLE]: "Pierre naturelle",
  [BuildingFacadeEnum.PISE]: "Pisé",
  [BuildingFacadeEnum.TERRE_CRUE_PORTEUSE]: "Terre crue porteuse",
  [BuildingFacadeEnum.OSSATURE_BOIS]: "Ossature bois",
  [BuildingFacadeEnum.PARPAING]: "Parpaing",
  [BuildingFacadeEnum.BETON_ARMEE_PREFABRIQUE]: "Béton armé préfabriqué",
  [BuildingFacadeEnum.BETON]: "Béton armé",
}
