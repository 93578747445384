import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from "react"
import { ErrorContext } from "../../../../components/layout/error-snackbar"
import { Children } from "../../../../components/miscellianous/children"
import { BSBimModel } from "../../../dto/beem-shot/BSBimModel/BSBimModel"
import { useBSBimModel } from "../../../hooks/beem-shot/useBSBimModel"
import { BSProjectContext } from "../BSProject/BSProjectContext"
import { BSVariantContext } from "../BSVariant/BSVariantContext"

export const BsModelContext = React.createContext<BsModelStore>({} as BsModelStore)

export default function BsModelContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const { bsProject } = useContext(BSProjectContext)
  const openErrorSnackbar = useContext(ErrorContext)
  const { selectedVariant } = useContext(BSVariantContext)

  const { fetchBimModelByVariantId } = useBSBimModel()

  const [bsBimModel, setBsBimModel] = useState<BSBimModel>(new BSBimModel())

  useEffect(() => {
    if (bsProject?.id && selectedVariant?.id) {
      fetchBimModelByVariantId(selectedVariant.id)
        .then((bimModelResponse) => {
          if (bimModelResponse) {
            setBsBimModel(bimModelResponse)
          } else {
            setBsBimModel(new BSBimModel())
          }
        })
        .catch((e) => {
          openErrorSnackbar(e)
        })
    }
  }, [bsProject, fetchBimModelByVariantId, openErrorSnackbar, selectedVariant?.id])

  const bsModelStore: BsModelStore = useMemo(() => ({ bsBimModel, setBsBimModel }), [bsBimModel])
  return <BsModelContext.Provider value={bsModelStore}>{children}</BsModelContext.Provider>
}

export type BsModelStore = {
  bsBimModel: BSBimModel
  setBsBimModel: Dispatch<SetStateAction<BSBimModel>>
}
