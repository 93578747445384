import { isArray } from "mathjs"
import { Dispatch, SetStateAction } from "react"
import { Scene } from "three"
import { IFCModel } from "web-ifc-three/IFC/components/IFCModel"
import { IfcViewerAPI } from "web-ifc-viewer"
import { CodeExtraitDisplay } from "../../../core/dto/code-extrait/CodeExtraitDisplay"
import { CodeExtrait } from "../../../core/dto/code-extrait/code-extrait"
import { codeStateEnum } from "../../../core/enum/codeStateEnum"
import { codeToKey } from "../../../core/services/code-service"
import { viewerConstants } from "../constants/ViewerConstants"
import { IfcTypes } from "../enums/IfcTypes"
import { SubsetTypes } from "../enums/SubsetTypes"
import { ViewerTypes } from "../enums/ViewerTypes"
import { MaterialHelper } from "../helpers/MaterialHelper"
import { IfcSelection } from "../models/IfcSelection"
import { SubsetInfo } from "../models/SubsetInfo"
import { SectionsManager } from "./SectionsManager"

export interface SubsetManagerProps {
  type: ViewerTypes
  codesExtraits: CodeExtraitDisplay[]
  setSelectedCodeExtrait: (codeExtrait: CodeExtraitDisplay, disableViewerHilight?: boolean) => void
  viewer: IfcViewerAPI
  container: HTMLElement | null
  showMessage: (message: string) => void
  setCodeManquantElements?: (codeManquantElements: SubsetInfo[]) => void
  setSelectedCodeManquantElement?: (codeManquant: SubsetInfo) => void
  updateIsInHidingIrellevantCodesState?: (isHide: boolean) => void
  updateIsInHideState?: (isHide: boolean) => void
  setViewerBarProgress?: Dispatch<SetStateAction<number>>
  setProgress?: Dispatch<SetStateAction<number>>
  sectionsManager: SectionsManager
}

export class SubsetsManager {
  type: ViewerTypes
  viewer: IfcViewerAPI
  container: HTMLElement | null
  allModelExpressIds: number[] = []
  subsets: SubsetInfo[] = []
  colorInvalidCode = "#faa107"
  colorWithoutCode = "red"
  colorSelected = "blue"
  transparentOpacity = 0.1
  selectedCodeExtrait?: CodeExtraitDisplay
  currentCodeVariant: codeStateEnum | undefined
  isActive = true
  sectionsManager: SectionsManager
  isInHideMode = false
  isInHideIrellevantCodeMode = false
  lastSelection: IfcSelection | undefined
  codesExtraits: CodeExtraitDisplay[]
  setSelectedCodeExtrait: (codeExtrait: CodeExtraitDisplay, disableViewerHilight?: boolean) => void
  setCodeManquantElements?: (codeManquantElements: SubsetInfo[]) => void
  setSelectedCodeManquantElement?: (codeManquant: SubsetInfo) => void
  openMessage?: (message: string) => void
  updateIsInHidingIrellevantCodesState?: (isHide: boolean) => void
  updateIsInHideState?: (isHide: boolean) => void
  setViewerBarProgress?: Dispatch<SetStateAction<number>>
  setProgress?: Dispatch<SetStateAction<number>>

  constructor(props: SubsetManagerProps) {
    this.viewer = props.viewer
    this.container = props.container
    this.type = props.type
    this.sectionsManager = props.sectionsManager
    this.codesExtraits = props.codesExtraits
    this.setSelectedCodeExtrait = props.setSelectedCodeExtrait
    this.setCodeManquantElements = props.setCodeManquantElements
    this.setSelectedCodeManquantElement = props.setSelectedCodeManquantElement
    this.updateIsInHideState = props.updateIsInHideState
    this.updateIsInHidingIrellevantCodesState = props.updateIsInHidingIrellevantCodesState
    this.openMessage = props.showMessage
    this.setViewerBarProgress = props.setViewerBarProgress
    this.setProgress = props.setProgress
  }

  static deleteSubset(subset: SubsetInfo): void {
    subset.ifcSubset?.removeFromParent()
    subset.ifcSubset?.geometry.dispose()
    if (isArray(subset.ifcSubset?.material)) {
      subset.ifcSubset?.material.forEach((x) => x.dispose())
    } else {
      subset.ifcSubset?.material?.dispose()
    }
    subset.ifcSubset = undefined
  }

  setCodesExtraits(newCodesExtraits: (CodeExtrait | CodeExtraitDisplay)[]): void {
    this.codesExtraits = newCodesExtraits
  }

  /**
   * Set the code colors based on the codes extrait
   * This initialize 'this.subsets' which is a list containing all code extrait to highlight
   *
   * @param  {number} modelId the model id
   * @param  {CodeExtrait[]} codesExtraits the code extrait list
   * @param  {Map<number, number[]>} typesToElementIdsMap
   */
  async setCodeColors(
    modelId: number,
    codesExtraits: (CodeExtrait | CodeExtraitDisplay)[],
    typesToElementIdsMap?: Map<number, number[]>
  ): Promise<void> {
    if (this.subsets.length > 0) {
      this.deleteSubsets()
    }
    this.hideModel()
    this.subsets = []
    if (this.allModelExpressIds.length === 0) {
      this.allModelExpressIds = await this.getAllModelExpressIds()
    }

    const scene = this.viewer.context.getScene()
    const processedElementIds: number[] = []

    codesExtraits
      .filter(
        (codeExtrait: CodeExtrait | CodeExtraitDisplay) =>
          codeExtrait.color && codeExtrait.color !== "" && codeExtrait.elementIds.length > 0
      )
      .forEach((codeExtrait: CodeExtrait | CodeExtraitDisplay) => {
        const isValid = codeExtrait.errors.length === 0
        const color = isValid ? MaterialHelper.fixColor(codeExtrait.color) : this.colorInvalidCode
        const info: SubsetInfo = {
          id: `${codeExtrait.code + codeExtrait.occurence}`,
          modelId: modelId ?? 0,
          elementIds: codeExtrait.elementIds,
          type: isValid ? SubsetTypes.VALID_CODE : SubsetTypes.INVALID_CODE,
          codeColor: color,
          name: codeExtrait.code,
          isHighlighted: false,
        }
        this.createSubset(info, scene)
        this.subsets.push(info)
        processedElementIds.push(...codeExtrait.elementIds)
      })

    if (typesToElementIdsMap && typesToElementIdsMap.size > 0) {
      typesToElementIdsMap.forEach((elementIds, typeId) => {
        const ids = elementIds.filter((x) => !processedElementIds.includes(x))
        if (ids.length > 0) {
          const typeString = IfcTypes[typeId]
          const info: SubsetInfo = {
            id: typeId.toString(),
            modelId: modelId ?? 0,
            elementIds: ids,
            type: SubsetTypes.WITHOUT_CODE,
            codeColor: this.colorWithoutCode,
            name: typeString,
            isHighlighted: false,
          }
          this.createSubset(info, scene)
          this.subsets.push(info)
          processedElementIds.push(...ids)
        }
      })
    }

    const orphanIds = this.allModelExpressIds.filter((x) => !processedElementIds.includes(x))
    if (orphanIds.length > 0) {
      const info: SubsetInfo = {
        id: `'orphans'${modelId.toString()}`,
        modelId: modelId ?? 0,
        elementIds: orphanIds,
        type: SubsetTypes.ORPHAN,
        codeColor: this.colorWithoutCode,
        name: "Autres",
        isHighlighted: false,
      }
      this.createSubset(info, scene)
      this.subsets.push(info)
    }

    this.updateCodeState(this.currentCodeVariant ?? codeStateEnum.CODE_ACV, false).then(() => {
      if (this.setCodeManquantElements) {
        const codeManquantElements = this.subsets.filter(
          (x) => x.type === SubsetTypes.WITHOUT_CODE || x.type === SubsetTypes.ORPHAN
        )
        this.setCodeManquantElements(codeManquantElements)
      }

      this.setPickableObjects(this.subsets)
      if (this.setViewerBarProgress) this.setViewerBarProgress(viewerConstants.endFileLoadingProgress)
      if (this.setProgress)
        this.setProgress(viewerConstants.startFileLoadingProgress + viewerConstants.endFileLoadingProgress)
    })
  }

  async updateCodeState(codeVariant: codeStateEnum, unhilight: boolean): Promise<void> {
    if (this.currentCodeVariant !== codeVariant) {
      this.currentCodeVariant = codeVariant
      this.subsets.forEach((x) => {
        this.restoreSubsetMaterial(x)
      })
      const hilighted = this.subsets.find((x) => x.isHighlighted)
      if (hilighted) {
        if (unhilight) {
          hilighted.isHighlighted = false
        } else this.highlightOneSubset(hilighted)
      }
      this.rerender()
    }
  }

  restoreAllSubsetsMaterial(): void {
    this.subsets.forEach((x) => {
      this.restoreSubsetMaterial(x)
    })
  }

  restoreSubsetMaterial(subset: SubsetInfo): void {
    const material = MaterialHelper.getSubsetMaterial(subset.codeColor)
    if (!subset.ifcSubset) {
      console.error("ifcSubset is undefined for subset:", subset)
      // You might want to handle this case appropriately, e.g., initializing ifcSubset or skipping the operation
      return // Skip setting material if ifcSubset is undefined
    }
    if (subset.type === SubsetTypes.VALID_CODE) {
      subset.ifcSubset.material = material
    } else if (subset.type === SubsetTypes.WITHOUT_CODE || subset.type === SubsetTypes.ORPHAN) {
      if (this.currentCodeVariant === codeStateEnum.CODE_MANQUANT) {
        subset.ifcSubset.material = material || undefined
      } else if (subset.originalMaterial !== undefined) {
        subset.ifcSubset.material = subset.originalMaterial
      } else {
        console.error("ifcSubset.material is undefined for subset:", subset)
      }
    } else if (subset.type === SubsetTypes.INVALID_CODE) {
      if (this.currentCodeVariant === codeStateEnum.CODE_INVALIDE) {
        subset.ifcSubset.material = material
      } else if (subset.originalMaterial !== undefined) {
        subset.ifcSubset.material = subset.originalMaterial
      } else {
        console.error("ifcSubset.material is undefined for subset:", subset)
      }
    }
  }

  /**
   * Create an IFC subset based on the subset info
   * @param subset the subset info
   * @param scene the scene to add the subset to
   */
  createSubset(subset: SubsetInfo, scene: Scene): void {
    const ifcSubset = this.viewer.IFC.loader.ifcManager.createSubset({
      modelID: subset.modelId,
      ids: subset.elementIds,
      customID: subset.id,
      removePrevious: true,
      scene,
      applyBVH: true,
    })

    if (ifcSubset) {
      subset.ifcSubset = ifcSubset
      subset.originalMaterial = MaterialHelper.cloneMaterial(ifcSubset.material)
    }
  }

  /**
   * Hide the original model mesh
   */
  hideModel(): void {
    const scene = this.viewer.context.getScene()
    if (this?.viewer?.context?.items) {
      this.viewer.context.items.ifcModels.forEach((x) => {
        x.visible = false
        scene.remove(x)
      })
    }
  }

  /**
   * if the user wants to select a code acv and it is hidden then revert the hide effect if needed
   * for the user to be able to select
   * @param subset
   * @returns
   */
  revertHiddenElementsIfNeeded(subset?: SubsetInfo): void {
    if (!subset) return
    const prevIsInHideMode = this.isInHideMode
    const prevIsInHideIrellevantCodeMode = this.isInHideIrellevantCodeMode
    if (subset && subset.type !== SubsetTypes.VALID_CODE && this.isInHideIrellevantCodeMode) {
      this.hideUnrelevantCodeSubsets(false)
    }
    if (subset && !subset.isHighlighted && this.isInHideMode) {
      this.hideUnselectedSubsets(false)
    }

    if (prevIsInHideMode !== this.isInHideMode) {
      if (this.updateIsInHideState) {
        this.updateIsInHideState(this.isInHideMode)
      }
    }
    if (prevIsInHideIrellevantCodeMode !== this.isInHideIrellevantCodeMode) {
      if (this.updateIsInHidingIrellevantCodesState) {
        this.updateIsInHidingIrellevantCodesState(this.isInHideIrellevantCodeMode)
      }
    }
  }

  /**
   * Highlight the code extrait element in the viewer by setting the code elements to
   * selected color and other elements to be transparent
   * @param codeExtrait the code extrait to highlight
   */
  highlightCodeExtrait(codeExtrait: CodeExtraitDisplay): void {
    const subset = this.subsets.find((x) => x.id === codeToKey(codeExtrait))
    this.revertHiddenElementsIfNeeded(subset)
    if (subset && this.isSubsetValidForHighlight(subset)) {
      this.highlightOneSubset(subset)
      this.selectedCodeExtrait = codeExtrait
      this.lastSelection = { modelID: subset.modelId, expressID: subset.elementIds[0] } as IfcSelection
    }
  }

  /**
   * Highlight multiple codes extraits elements in the viewer by setting the code elements to
   * its color and other elements to be transparent
   * @param codeExtraitKeys the keys, like "SUP_VER_MUR1", for codes extraits to highlight
   */
  highlightCodesExtraitsList(codeExtraitKeys: string[]): void {
    const subsetList: SubsetInfo[] = []
    codeExtraitKeys.forEach((key) => {
      const subset = this.subsets.find((x) => x.id === key)
      if (subset && this.isSubsetValidForHighlight(subset)) {
        subsetList.push(subset)
        this.revertHiddenElementsIfNeeded(subset)
        // I don't know what is this  line for
        this.lastSelection = { modelID: subset.modelId, expressID: subset.elementIds[0] } as IfcSelection
      }
    })

    this.highlightSubsetList(subsetList)
  }

  /**
   * unHighlight the code extrait element in the viewer by setting the code elements to
   * original color and other elements to be not transparent
   * @param codeExtrait the code extrait to unhighlight
   */
  unHighlightCodeExtrait(codeExtrait: CodeExtraitDisplay): void {
    const subset = this.subsets.find((x) => x.id === `${codeExtrait.code + codeExtrait.occurence}`)
    if (subset && this.isSubsetValidForHighlight(subset)) {
      this.unHighlightSubset(subset)
      this.selectedCodeExtrait = undefined
    }
  }

  /**
   * highlight in the viewer by setting the code elements to
   * selected color and other elements to be transparent
   * @param subset the subset to highlight
   */
  highlightOneSubset(subset: SubsetInfo): void {
    const wasSectionsActive = this.viewer.clipper.active
    if (wasSectionsActive) {
      this.viewer.clipper.active = false
    }

    if (subset) {
      this.restoreSubsetMaterial(subset)
      subset.isHighlighted = true
    }

    const allOtherSubsets = this.subsets.filter((x) => x.id !== subset.id)
    allOtherSubsets.forEach((x) => {
      MaterialHelper.updateSubsetOpacity(x, this.transparentOpacity)
      x.isHighlighted = false
    })

    this.rerender()
    if (wasSectionsActive) {
      this.viewer.clipper.active = true
    }

    this.sectionsManager.deleteAllHelperPlanes()
  }

  highlightSubsetList(subsetList: SubsetInfo[]): void {
    const wasSectionsActive = this.viewer.clipper.active
    if (wasSectionsActive) {
      this.viewer.clipper.active = false
    }
    if (!subsetList) {
      return
    }

    subsetList.forEach((subset) => {
      this.restoreSubsetMaterial(subset)
      subset.isHighlighted = true
    })

    const allOtherSubsets = this.subsets.filter(
      (anySubset) => !subsetList.some((highlightedSubset) => anySubset.id === highlightedSubset.id)
    )

    allOtherSubsets.forEach((x) => {
      MaterialHelper.updateSubsetOpacity(x, this.transparentOpacity)
      x.isHighlighted = false
    })

    this.rerender()
    if (wasSectionsActive) {
      this.viewer.clipper.active = true
    }

    this.sectionsManager.deleteAllHelperPlanes()
  }

  /**
   * hide or unhide the unselected elements in the viewer
   * @param hide true to hide the unselected elements
   * @returns true if the operation was applied
   */
  hideUnselectedSubsets(hide: boolean): boolean {
    if (!this.prepareHideUnselectedSubsets(hide)) {
      return false
    }
    let applied = false
    const subsetsVisible: SubsetInfo[] = []

    this.subsets.forEach((x) => {
      if (!x.isHighlighted && x.ifcSubset) {
        if (!hide && x.type !== SubsetTypes.VALID_CODE && this.isInHideIrellevantCodeMode) {
          x.ifcSubset.visible = false
        } else {
          x.ifcSubset.visible = !hide
        }
        applied = true
      }
      if (x.ifcSubset?.visible) {
        subsetsVisible.push(x)
      }
    })
    if (applied) {
      this.isInHideMode = hide
      this.setPickableObjects(subsetsVisible)
    }

    return applied
  }

  /**
   * hide or unhide the elements with invalid or missing code in the viewer
   * @param hide true to hide the elements with invalid or missing code otherwise will unhide them
   */
  hideUnrelevantCodeSubsets(hide: boolean): boolean {
    if (!this.prepareHideUnrelevantCodeSubsets(hide)) return false
    let applied = false
    const subsetsVisible: SubsetInfo[] = []
    this.subsets.forEach((x) => {
      if (x.type !== SubsetTypes.VALID_CODE && x.ifcSubset) {
        x.ifcSubset.visible = !hide
        applied = true
      }
      if (x.ifcSubset && x.ifcSubset.visible) subsetsVisible.push(x)
    })

    if (applied) {
      this.isInHideIrellevantCodeMode = hide
      this.setPickableObjects(subsetsVisible)
    }
    return applied
  }

  /**
   * prepare the viewer to hide or unhide the unselected elements
   * @param hide true to hide the unselected elements
   * @returns true if the operation was applied
   */
  prepareHideUnselectedSubsets(hide: boolean): boolean {
    if (hide) {
      if (this.subsets.filter((x) => x.isHighlighted).length === 0) {
        this.openMessage!("Il n'y a aucun élément sélectionné dans le visualiseur.")
        return false
      }
      this.setPickableObjects(this.subsets.filter((x) => x.isHighlighted))
    }
    return true
  }

  /**
   * prepare the viewer to hide or unhide the elements with invalid or missing code
   * @param hide true to hide the elements with invalid or missing code otherwise will unhide them
   * @returns true if the operation was applied
   */
  prepareHideUnrelevantCodeSubsets(hide: boolean): boolean {
    if (this.isInHideMode) {
      this.openMessage!("Vous devez d'abord désactiver le mode masqué pour pouvoir utiliser cette fonctionnalité.")
      return false
    }
    if (hide) {
      if (this.subsets.filter((x) => x.type === SubsetTypes.VALID_CODE).length === 0) {
        this.openMessage!("Il n'y a aucun élément avec un code valide dans le visualiseur.")
        return false
      }
      this.setPickableObjects(this.subsets.filter((x) => x.type === SubsetTypes.VALID_CODE))
    }
    return true
  }

  /**
   * set the pickable objects to become the wanted subsets
   * @param subsets the subsets to set
   */
  setPickableObjects(subsets: SubsetInfo[]): void {
    this.viewer.context.items.pickableIfcModels = []
    if (subsets.length > 0) {
      for (const subset of subsets) {
        if (subset.ifcSubset) {
          this.viewer.context.items.pickableIfcModels.push(subset.ifcSubset as IFCModel)
        }
      }
    }
  }

  /**
   * unHilight in the viewer by setting the code elements to
   * original color and other elements to be not transparent
   * @param subset the subset to unhilight
   */
  unHighlightSubset(subset: SubsetInfo): void {
    const wasSectionsActive = this.viewer.clipper.active
    if (wasSectionsActive) {
      this.viewer.clipper.active = false
    }

    if (subset) {
      this.restoreSubsetMaterial(subset)
      subset.isHighlighted = false
    }
    const allOtherSubsets = this.subsets.filter((x) => x.id !== subset.id)
    allOtherSubsets.forEach((x) => {
      this.restoreSubsetMaterial(x)
      x.isHighlighted = false
    })

    this.rerender()

    this.rerender()
    if (wasSectionsActive) {
      this.viewer.clipper.active = true
    }

    this.sectionsManager.deleteAllHelperPlanes()
  }

  /**
   * is the subset valid for hilight based on the current code variant
   * @param subset the subset to check if it is valid for hilight
   * @returns true if the subset is valid for hilight, false otherwise
   */
  isSubsetValidForHighlight(subset: SubsetInfo): boolean {
    if (!this.isActive || this.isInHideMode) {
      return false
    }
    if (this.isInHideIrellevantCodeMode && subset.type !== SubsetTypes.VALID_CODE) {
      return false
    }
    if (this.type === ViewerTypes.NO_HIGHLIGHT_CODE) {
      return false
    }

    switch (subset.type) {
      case SubsetTypes.VALID_CODE:
        return true
      case SubsetTypes.INVALID_CODE:
        if (this.type === ViewerTypes.CODE_VERIFICATION) {
          return true
        }
        return this.currentCodeVariant === codeStateEnum.CODE_INVALIDE
      case SubsetTypes.WITHOUT_CODE:
      case SubsetTypes.ORPHAN:
        if (this.type === ViewerTypes.CODE_VERIFICATION) {
          return true
        }
        return this.currentCodeVariant === codeStateEnum.CODE_MANQUANT
      default:
        return false
    }
  }

  changeType(type: ViewerTypes): void {
    this.type = type
  }

  /**
   * toggle the highlight of a subset
   * @param subset the subset to toggle highlight
   */
  toggleSubsetHighlight(subset: SubsetInfo): void {
    if (!subset.isHighlighted) this.revertHiddenElementsIfNeeded(subset)
    if (!this.isSubsetValidForHighlight(subset)) return
    if (subset.isHighlighted) {
      this.unHighlightSubset(subset)
    } else {
      this.highlightOneSubset(subset)
    }
  }

  /**
   * get all the expressIDs from the model
   * @returns the list of all the expressIDs from the model
   */
  async getAllModelExpressIds(): Promise<number[]> {
    if (this?.viewer?.context?.items?.ifcModels.length > 0) {
      const model = this.viewer.context.items.ifcModels[0]
      const node = await this.viewer.IFC.loader.ifcManager.getSpatialStructure(model.modelID, false)
      return this.getIdsFromSpacialNode(node)
    }
    return new Array<number>()
  }

  /**
   * get all the expressIDs from a spacial node from the model
   * @param node the spacial node
   * @returns the list of expressIDs
   */
  getIdsFromSpacialNode(node: any): number[] {
    const ids = [node.expressID] // Add the current node's id to the list

    if (Array.isArray(node.children)) {
      node.children.forEach((child: any) => {
        ids.push(...this.getIdsFromSpacialNode(child))
      })
    }

    return ids as number[]
  }

  /**
   * Handle the new selection on the model elements so we can find which subset and code extrait is being clicked
   */
  handleModelSelection(expressId: number | undefined): void {
    if (this.type !== ViewerTypes.FOR_DASHBOARD) {
      let subset: SubsetInfo | undefined
      let highlight = true
      if (expressId) {
        subset = this.subsets.find((x) => x.elementIds.includes(expressId) && !x.isHighlighted)
      } else {
        highlight = false
        subset = this.subsets.find((x) => x.isHighlighted)
      }

      if (subset && this.isSubsetValidForHighlight(subset)) {
        if (highlight) {
          this.highlightOneSubset(subset)
        } else {
          this.unHighlightSubset(subset)
        }

        this.notifySelectionChanged(subset)
      }
    }
  }

  /**
   * Notify the parent component that the selection has changed
   */
  notifySelectionChanged(subset: SubsetInfo): void {
    if (this.codesExtraits && (subset.type === SubsetTypes.VALID_CODE || subset.type === SubsetTypes.INVALID_CODE)) {
      const selected = this.codesExtraits.find((codeExtrait) => codeToKey(codeExtrait) === subset.id)
      if (selected) {
        this.setSelectedCodeExtrait(selected, true)
      }
    } else if (
      (this.setSelectedCodeManquantElement && subset.type === SubsetTypes.WITHOUT_CODE) ||
      subset.type === SubsetTypes.ORPHAN
    ) {
      this.setSelectedCodeManquantElement?.(subset)
    }
  }

  /**
   * Rerender the scene after material update
   */
  rerender(): void {
    const renderer = this.viewer.context.getRenderer()
    const camera = this.viewer.context.getIfcCamera().cameraControls.camera
    const scene = this.viewer.context.getScene()
    renderer.render(scene, camera)
  }

  deleteSubsets(): void {
    this.subsets.forEach((x) => {
      SubsetsManager.deleteSubset(x)
    })
    this.subsets = new Array<SubsetInfo>()
  }

  dispose(): void {
    this.deleteSubsets()
  }
}
