import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { Children } from "../../../../components/miscellianous/children"
import { BSItemCategory } from "../../../dto/beem-shot/BSItem/BSItemCategory"
import { useBSItem } from "../../../hooks/beem-shot/useBSItem"

export const BSCategoriesContext = React.createContext<BSCategoriesStore>({} as BSCategoriesStore)

export function BSCategoriesContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const [bsCategories, setBsCategories] = useState<BSItemCategory[]>([])

  const { bSCategoryId } = useParams()
  const { getBSCategories } = useBSItem()

  useEffect(() => {
    getBSCategories().then((newBSCategory) => {
      setBsCategories(newBSCategory)
    })
  }, [bSCategoryId, getBSCategories])

  const bsCategoryStore = useMemo(
    () => ({
      bsCategories,
      setBsCategories,
    }),
    [bsCategories]
  )
  return <BSCategoriesContext.Provider value={bsCategoryStore}>{children}</BSCategoriesContext.Provider>
}

export type BSCategoriesStore = {
  bsCategories: BSItemCategory[]
  setBsCategories: Dispatch<SetStateAction<BSItemCategory[]>>
}
