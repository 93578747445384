import { BottomNavigation, Paper } from "@mui/material"
import React, { useContext, useState } from "react"
import { ValidateButton } from "../../components/buttons/validate-button/validate-button"
import { useKairnial } from "./useKairnial"
import { SuccessContext } from "../../components/layout/success-snackbar"

type IProps = {
  secureToken: string
  clientId: string
}

export function KairnialRefreshNavigation({ secureToken, clientId }: Readonly<IProps>): React.JSX.Element {
  const { refreshData } = useKairnial()
  const openSuccessSnackbar = useContext(SuccessContext)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  return (
    <Paper>
      <BottomNavigation
        sx={{
          position: "fixed",
          bottom: 20,
          right: 20,
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          alignContent: "center",
        }}>
        <ValidateButton
          action={() => {
            openSuccessSnackbar(
              "Le rafraîchissement des données est en cours. Les données devraient être à jour dans une dizaine de minutes."
            )
            setIsSubmitting(true)
            refreshData(secureToken, clientId)
              .then(() => {
                openSuccessSnackbar(
                  "Il faudra rafraîchir la page dans environ 2min. Les données ont été récupérées, il faut attendre que le tableau de bord soit régénéré."
                )
              })
              .finally(() => {
                setIsSubmitting(false)
              })
          }}
          label="Rafraîchir"
          isSubmiting={isSubmitting}
        />
      </BottomNavigation>
    </Paper>
  )
}
