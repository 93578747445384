import { useEffect, useState } from "react"
import { styled } from "@mui/system"
import { Paper, Typography } from "@mui/material"

const VideoContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

interface IProps {
  videoUrl: string
  thumbnailUrl?: string
  captionUrl?: string
}

export function VideoPlayer({ videoUrl, captionUrl, thumbnailUrl }: IProps): React.JSX.Element {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false)

  useEffect(() => {
    if (videoUrl) {
      setIsVideoLoaded(true)
    } else {
      setIsVideoLoaded(false)
    }
  }, [videoUrl])

  return (
    <VideoContainer>
      {isVideoLoaded ? (
        // TODO: Generate subtitles for the videos
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video src={videoUrl} poster={thumbnailUrl} controls width="100%">
          {captionUrl && <track src={captionUrl} kind="captions" srcLang="fr" label="English captions" />}
        </video>
      ) : (
        <Typography variant="body1">No video to display</Typography>
      )}
    </VideoContainer>
  )
}

export default VideoPlayer
