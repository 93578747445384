import React, { Dispatch, SetStateAction, useMemo, useState } from "react"
import { Children } from "../../../components/miscellianous/children"
import { IniesRecord } from "../../dto/material/IniesRecord"

export const MaterialLibSearchContext = React.createContext<MaterialLibSearchStore>({} as MaterialLibSearchStore)

export default function MaterialLibSearchContextProvider({ children }: Children): React.JSX.Element {
  const [loading, setLoading] = useState<boolean>(true)
  const [searchContext, setSearchContext] = useState<IniesRecord[] | undefined>()

  const store: MaterialLibSearchStore = useMemo(
    () => ({ searchContext, setSearchContext, loading, setLoading }),
    [loading, searchContext]
  )
  return <MaterialLibSearchContext.Provider value={store}>{children}</MaterialLibSearchContext.Provider>
}

export type MaterialLibSearchStore = {
  searchContext: IniesRecord[] | undefined
  setSearchContext: Dispatch<SetStateAction<IniesRecord[] | undefined>>
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
}
