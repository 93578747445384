import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone"

import { Badge, Box, Card, CardContent, Divider, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import EventSource, { EventSourceListener } from "react-native-sse"
import { appConstants } from "../../core/appConstants"
import { TokenContext } from "../../core/context/user/token-context"
import { EventNotification } from "../../core/dto/event/event"
import { useEvents } from "../../core/hooks/use-events"
import { formatToFrenchWithHours, stringToDate } from "../../core/services/date-service"
import { getEnvProperty, PROPERTIES } from "../../core/services/environment-service"

type MyCustomEvents = "EVENT"

export default function MenuNotification(): React.JSX.Element {
  const { tokenRef } = useContext(TokenContext)

  const { fetchUserNotifications, markEventAsRead, markAllEventAsRead } = useEvents()

  const [eventList, setEventList] = useState<EventNotification[]>([])

  useEffect(() => {
    fetchUserNotifications().then((list: EventNotification[]) => {
      const sortedList: EventNotification[] = [...list]
      sortedList.sort((a, b) => {
        if (a.createdDate && b.createdDate) {
          return b.createdDate.localeCompare(a.createdDate)
        }
        return 0
      })

      setEventList(sortedList)
    })
  }, [fetchUserNotifications])

  useEffect(() => {
    const url = `${getEnvProperty(PROPERTIES.REACT_APP_API_URL)}${appConstants.apiEndpoints.EVENT}/subscribe-event`

    const es = new EventSource<MyCustomEvents>(url, {
      debug: true,
      timeoutBeforeConnection: 10000,
      pollingInterval: 300,
      headers: {
        Authorization: {
          toString() {
            return `Bearer ${tokenRef.current}`
          },
        },
      },
    })

    const listener: EventSourceListener<MyCustomEvents> = (event) => {
      if (event.type === "open") {
        console.info("Open SSE connection.")
      } else if (event.type === "EVENT" && event.data) {
        const eventUpdate = JSON.parse(event.data) as EventNotification
        setEventList((array) => [eventUpdate, ...array])
      } else if (event.type === "error") {
        console.error("Connection error:", event.message)
      } else if (event.type === "exception") {
        console.error("Error:", event.message, event.error)
      }
    }

    es.addEventListener("open", listener)
    es.addEventListener("message", listener)
    es.addEventListener("error", listener)
    es.addEventListener("EVENT", listener)

    return () => {
      es.close()
    }
  }, [tokenRef])

  /* Declaration account menu */
  const [notifAnchorEl, setNotifAnchorEl] = React.useState(null)
  const notifOpen = Boolean(notifAnchorEl)

  function handleClose(): void {
    setNotifAnchorEl(null)
  }

  function handleMarkEventButton(eventId: string): void {
    markEventAsRead(eventId).then(() => {
      const indexToRemove = eventList.findIndex((item) => item.id === eventId)
      const newItems = eventList.filter((item, index) => index !== indexToRemove)
      setEventList(newItems)
    })
  }

  function handleMarkAllEventButton(): void {
    markAllEventAsRead().then(() => {
      setEventList([])
    })
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <Tooltip title="Notifications">
        <IconButton id="basic-button" color="inherit" onClick={(event: any) => setNotifAnchorEl(event.currentTarget)}>
          <Badge color="error" badgeContent={eventList.filter((item) => !item.read).length}>
            <NotificationsNoneIcon />
          </Badge>
        </IconButton>
      </Tooltip>

      <Menu
        id="account-menu"
        anchorEl={notifAnchorEl}
        open={notifOpen}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "account-menu",
        }}
        sx={{ maxHeight: 700 }}>
        <Box
          component="li"
          sx={{
            height: 90,
            width: 600,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: "6px 16px",
          }}>
          <Typography className="notranslate" variant="h5">
            Notifications
          </Typography>
        </Box>

        <Divider />

        {eventList.length !== 0 && (
          <Typography
            display="flex"
            justifyContent="right"
            onClick={handleMarkAllEventButton}
            sx={{
              p: 1,
              pr: 2,
              cursor: "pointer",
              "&:hover": {
                textDecoration: "underline",
              },
            }}>
            Effacer les notifications
          </Typography>
        )}

        {eventList.length !== 0 ? (
          eventList.map((event) => (
            <MenuItem key={event.id} sx={{ display: "flex", minWidth: "100%" }}>
              <Card sx={{ minWidth: "100%" }}>
                <CardContent
                  sx={{ minWidth: "100%" }}
                  onClick={() => {
                    if (event.id) {
                      handleMarkEventButton(event.id)
                    }
                  }}>
                  <Box display="flex" sx={{ justifyContent: "flex-end", height: 10 }} fontSize={1}>
                    <Tooltip title="Effacer">
                      <IconButton
                        onClick={() => {
                          if (event.id) {
                            handleMarkEventButton(event.id)
                          }
                        }}>
                        <CloseOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box>
                    <Typography variant="h5" component="div">
                      {event.title.toUpperCase()}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {event.description}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyItems="center" justifyContent="right">
                    <Typography variant="body2" color="text.secondary" fontSize={11}>
                      {formatToFrenchWithHours(stringToDate(event.createdDate))}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </MenuItem>
          ))
        ) : (
          <MenuItem sx={{ display: "flex", flexDirection: "column", justifyContent: "center", minHeight: 500, height: 500 }}>
            <Box> Aucune notification pour le moment</Box>
          </MenuItem>
        )}
      </Menu>
    </Box>
  )
}
