import { Box, Button } from "@mui/material"
import React from "react"
import { customPalette } from "../../../../../theme"

type Props = {
  selectedVariant: 1 | 2
  handleChangeVariant: (variant: 1 | 2) => void
}

export default function SelectVariant({ selectedVariant, handleChangeVariant }: Props): React.JSX.Element {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}>
      <Button
        onClick={() => {
          handleChangeVariant(1)
        }}
        variant="contained"
        color="info"
        sx={{
          backgroundColor: selectedVariant === 1 ? customPalette.lightGray2 : "transparent",
        }}>
        BASE
      </Button>
      <Button
        onClick={() => {
          handleChangeVariant(2)
        }}
        variant="contained"
        color="info"
        sx={{ ml: 5, backgroundColor: selectedVariant === 2 ? customPalette.lightGray2 : "transparent" }}>
        VARIANTE
      </Button>
    </Box>
  )
}
