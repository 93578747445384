import { Box, Modal, Typography } from "@mui/material"
import React from "react"
import { BSMaterialResult } from "../../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { FicheConfiguree } from "../../../../../core/dto/fiche-configuree/fiche-configuree"
import { IniesRecord } from "../../../../../core/dto/material/IniesRecord"
import { MaterialRecord } from "../../../../../core/dto/material/MaterialRecord"
import { DeclarationTypeEnum } from "../../../../../core/enum/declarationTypeEnum"
import { BSIniesRecordCardInfo } from "./BSIniesRecordCardInfo"

type IProps = {
  openCard: boolean
  selectedRow: IniesRecord | MaterialRecord | FicheConfiguree | undefined
  setSelectedRow: React.Dispatch<React.SetStateAction<IniesRecord | MaterialRecord | FicheConfiguree | undefined>>
  getTypologyBackground(typologie: DeclarationTypeEnum): string
  getTypologyTextColor(typologie: DeclarationTypeEnum): string
  handleCloseMaterialCardModal(): void
  handleClose(): void
  selectedBsMaterialResult: BSMaterialResult | undefined
}

export function BSMaterialCardModal(props: Readonly<IProps>): React.JSX.Element {
  const { openCard, handleCloseMaterialCardModal, selectedRow, selectedBsMaterialResult, handleClose, ...otherProps } = props

  return (
    <Modal
      open={openCard}
      onClose={handleCloseMaterialCardModal}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <Box>
        {selectedRow ? (
          <BSIniesRecordCardInfo
            handleCloseMaterialCardModal={handleCloseMaterialCardModal}
            selectedRow={selectedRow}
            selectedBsMaterialResult={selectedBsMaterialResult}
            handleClose={handleClose}
            {...otherProps}
          />
        ) : (
          <Typography>Pas de matériau sélectionné</Typography>
        )}
      </Box>
    </Modal>
  )
}
