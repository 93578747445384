import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { LotContext } from "../../../../core/context/lot-context"
import AdditionalCodeCreation from "../../../../core/dto/additional-code/additional-code-creation"
import AdditionalCodeUpdate from "../../../../core/dto/additional-code/additional-code-update"
import CodeCustomCreation from "../../../../core/dto/code-acv-custom/code-custom-creation"
import { CodeCustomUpdate } from "../../../../core/dto/code-acv-custom/code-custom-update"

type Iprops = {
  isOpen: boolean
  handleCloseModal: () => void
  handleChange: (field: string, value: string | undefined) => void
  formValues: AdditionalCodeCreation | AdditionalCodeUpdate | CodeCustomCreation | CodeCustomUpdate
  handleCreateOrUpdate: () => void
  isUpdate?: boolean
}

export default function AdditionalAndCompletionModal({
  isOpen,
  handleCloseModal,
  handleChange,
  formValues,
  handleCreateOrUpdate,
  isUpdate,
}: Readonly<Iprops>): React.JSX.Element {
  const { lots, enumToLot, sousLots, setSousLots } = useContext(LotContext)
  const fieldName: "name" | "code" = "name" in formValues ? "name" : "code"
  const fieldDescription: "description" | "descriptionFromCodeAcv" =
    "description" in formValues ? "description" : "descriptionFromCodeAcv"

  const [errors, setErrors] = useState<Record<string, string | undefined> | undefined>(undefined)

  const handleChangeValue = (field: string, value: string | undefined): void => {
    setErrors(undefined)
    handleChange(field, value)
  }

  useEffect(() => {
    const newSousLotList = enumToLot[formValues.lot]?.children.map((child) => child)
    if (newSousLotList) {
      setSousLots(newSousLotList)
    }
  }, [enumToLot, enumToLot.children, formValues.lot, setSousLots])

  const handleCheckError = (): Record<string, string> => {
    const allKey = [fieldName, fieldDescription, "lot", "sousLot"]
    let errorsResponse: Record<string, string> = {}
    allKey.forEach((key) => {
      errorsResponse = {
        ...errorsResponse,
        [key]: !`${formValues[key as keyof typeof formValues] || ""}`.toString().length ? "Ce champ est obligatoire" : "",
      }
    })
    setErrors(errorsResponse)
    return errorsResponse
  }

  const handleSubmit = (): void => {
    const errorsResponse = handleCheckError()
    const haveError = Object.keys(errorsResponse).some((key) => Boolean(errorsResponse[key].length !== 0))
    if (haveError) {
      return
    }
    handleCreateOrUpdate()
  }

  return (
    <Dialog open={isOpen} onClose={handleCloseModal}>
      <DialogTitle>{isUpdate ? "Éditer l'élément" : "Nouvel élément"}</DialogTitle>
      <DialogContent>
        <TextField
          label="Nom"
          value={formValues.name}
          onChange={(e) => handleChangeValue(fieldName, e.target.value)}
          sx={{
            mt: 1,
          }}
          fullWidth
          error={Boolean(errors && fieldName in errors && errors[fieldName])}
          helperText={errors && fieldName in errors && errors[fieldName]}
        />
        <TextField
          label="Description"
          value={"description" in formValues ? formValues.description : formValues.descriptionFromCodeAcv}
          onChange={(e) => handleChangeValue(fieldDescription, e.target.value)}
          sx={{
            mt: 1,
          }}
          fullWidth
          error={Boolean(errors && fieldDescription in errors && errors[fieldDescription])}
          helperText={errors && fieldDescription in errors && errors[fieldDescription]}
        />

        <FormControl
          fullWidth
          sx={{
            mt: 2,
          }}>
          <InputLabel sx={{ backgroundColor: "white" }}>Lot</InputLabel>
          <Select
            onChange={(e) => handleChangeValue("lot", e.target.value)}
            value={formValues.lot}
            error={Boolean(errors && "lot" in errors && errors.lot)}
            aria-describedby="lot">
            {lots.map((lv) => (
              <MenuItem key={`${lv.name}`} value={lv.name}>
                {lv.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error id="lot">
            {errors?.lot ? `${errors?.lot}` : ""}
          </FormHelperText>
        </FormControl>

        <FormControl
          fullWidth
          sx={{
            mt: 2,
          }}>
          <InputLabel sx={{ backgroundColor: "white" }}>Sous lot</InputLabel>
          <Select
            disabled={enumToLot[formValues.lot] === undefined}
            value={formValues.sousLot}
            onChange={(e) => handleChangeValue("sousLot", e.target.value)}
            error={Boolean(errors && "sousLot" in errors && errors.sousLot)}
            aria-describedby="sousLot">
            {sousLots.map((lv) => (
              <MenuItem key={`${lv.name}`} value={lv.name}>
                {lv.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error id="sousLot">
            {errors?.sousLot ? `${errors?.sousLot}` : ""}
          </FormHelperText>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setErrors(undefined)
            handleCloseModal()
          }}>
          Annuler
        </Button>
        <Button variant="contained" color="primary" onClick={() => handleSubmit()}>
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  )
}
