import { Logout, Settings } from "@mui/icons-material"
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined"
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined"
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined"
import { Avatar, Box, Divider, IconButton, Menu, Tooltip } from "@mui/material"
import React from "react"
import { appConstants, pagesUrl } from "../../core/appConstants"
import { UserContext, UserStore } from "../../core/context/user/user-context"
import { hasSuperUserRights } from "../../core/services/authentication-service"
import { DefaultTypography } from "../typography/default-typography"
import { SettingsMenuItem } from "./setting-menu-item"

export function SettingsMenu(): React.JSX.Element {
  const { user, logout, setIsFromLogoutButton } = React.useContext<UserStore>(UserContext)

  /* Definition initial utilisateur */
  const initName = `${user?.givenName?.[0]?.toUpperCase()}${user?.familyName?.[0]?.toUpperCase()}` ?? ""
  const fullName = user ? user.givenName + appConstants.utils.space + user.familyName : ""
  const superUser = hasSuperUserRights(user)

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  function handleClick(event: any): void {
    setAnchorEl(event.currentTarget)
  }

  function handleClose(): void {
    setAnchorEl(null)
  }

  function onLogout(): Promise<void> {
    handleClose()
    setIsFromLogoutButton(true)
    return logout().then(() => {
      setIsFromLogoutButton(true)
    })
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <Tooltip title="Paramètre du compte">
        <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}>
          <Avatar sx={{ width: 45, height: 45, backgroundColor: "#C9FFE8" }}>
            <DefaultTypography label={initName} color="#0D6E58" fontWeight={700} lineHeight="20px" />
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            bgcolor: "#070F27",
            color: "white",
            boxShadow: "0 4px 20px rgba(0,0,0,0.5)",
            borderRadius: 8,
            p: 2,
          },
          "& .MuiMenuItem-root": {
            p: 1,
            "&:hover": {
              bgcolor: "#0BE2A0",
              color: "black",
              borderRadius: 2,
            },
          },
        }}>
        <SettingsMenuItem
          action={handleClose}
          label="Mon profil"
          link={pagesUrl.ACCOUNT_PAGE}
          Icone={<AccountBoxOutlinedIcon />}
        />

        {false && (
          <SettingsMenuItem action={handleClose} label="Mes paramètres" link={pagesUrl.ACCOUNT_PAGE} Icone={<Settings />} />
        )}

        <Divider sx={{ mt: "8px", mb: "8px", color: "#BDBDBD", backgroundColor: "#BDBDBD ", width: "100%" }} />

        {superUser
          ? [
              <SettingsMenuItem
                key="my-organization"
                action={handleClose}
                label="Mon organisation"
                link={pagesUrl.MY_ORGANIZATION_PAGE}
                Icone={<HomeWorkOutlinedIcon />}
              />,
            ]
          : null}

        <SettingsMenuItem
          action={handleClose}
          label="Mon offre"
          link={pagesUrl.MY_OFFER_PAGE}
          Icone={<WidgetsOutlinedIcon />}
        />

        <Divider sx={{ mt: "8px", mb: "8px", color: "#BDBDBD", backgroundColor: "#BDBDBD ", width: "100%" }} />

        <SettingsMenuItem action={onLogout} label="Se déconnecter" Icone={<Logout />} />

        <Box sx={{ mt: 1 }}>
          <DefaultTypography
            label={`Version: ${process.env.REACT_APP_VERSION}`}
            color="#D0D0D0"
            fontSize="11px"
            lineHeight="16.5px"
          />
        </Box>
      </Menu>
    </Box>
  )
}
