export default class WorksiteIc {
  moisEteAvecGrue = 0
  moisEteSansGrue = 0
  moisHiverAvecGrue = 0
  moisHiverSansGrue = 0
  terresExcavees = 0
  terresEvacuees = 0
  distanceDechetInerte = 0
  terresImportees = 0
  distanceImportDesTerres = 0
  eauPotableConsommee = 0
  eauUseeRejetee = 0
  electriciteConsommee = 0
  gazConsomme = 0
  carburantConsomme = 0
  simplified = true
}
