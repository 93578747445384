import React, { useContext, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Children } from "../../components/miscellianous/children"
import { PhaseEnum, stringToPhase } from "../enum/phaseEnum"
import { SelectedPhaseStore } from "../store/selected-phase-store"
import { ProjectContext } from "./project/project-context"
import { ErrorContext } from "../../components/layout/error-snackbar"
import { pagesUrl } from "../appConstants"
import { resolveUrl } from "../services/http-service"

export const SelectedPhaseContext = React.createContext<SelectedPhaseStore>({} as SelectedPhaseStore)
export default function SelectedPhaseProvider({ children }: Children): React.JSX.Element {
  const openErrorSnackbar = useContext(ErrorContext)
  const navigate = useNavigate()
  const { project } = useContext(ProjectContext)
  const [selectedPhase, setSelectedPhase] = useState<PhaseEnum | undefined>(undefined)

  function phaseKey(projectId: string): string {
    return `phase${projectId}`
  }

  function setSelectedPhaseAndSaveIt(phase: PhaseEnum | undefined): void {
    if (project?.id) {
      setSelectedPhase(phase)
      if (phase) {
        sessionStorage.setItem(phaseKey(project.id), phase)
      } else {
        sessionStorage.removeItem(phaseKey(project.id))
      }
    }
  }

  useEffect(() => {
    if (project?.id) {
      let phase: PhaseEnum | undefined
      const item: string | null = sessionStorage.getItem(phaseKey(project.id))
      if (item == null) {
        const index = project.phases.findIndex((e) => e.currentPhase)
        if (index !== -1) {
          phase = project.phases[index].phase
        } else {
          openErrorSnackbar(new Error("Veuillez renseigner une phase active, sinon ce projet ne va pas fonctionner"))
          phase = undefined
          setSelectedPhase(phase)
          navigate(resolveUrl(pagesUrl.PROJECT_PHASE_STEPPER, [project.id]))
        }
      } else {
        phase = stringToPhase(item)
      }
      setSelectedPhase(phase)
    }
  }, [project])

  const selectedPhaseStore: SelectedPhaseStore = useMemo(
    () => new SelectedPhaseStore(selectedPhase, setSelectedPhaseAndSaveIt),
    [selectedPhase]
  )
  return <SelectedPhaseContext.Provider value={selectedPhaseStore}>{children}</SelectedPhaseContext.Provider>
}
