import { TextField } from "@mui/material"
import { TextFieldVariants } from "@mui/material/TextField/TextField"
import React, { ChangeEvent } from "react"
import { formatPhone } from "../../../core/services/user-service"

type IProps = {
  form: any
  error: Record<string, any>
  handleChange(event: ChangeEvent<HTMLInputElement>): void
  required: boolean
  disabled?: boolean
  variant?: TextFieldVariants
  fullWidth?: boolean
  backgroundColor?: string
  margin?: "normal" | "dense" | "none"
  borderRadius?: number
  hideBorder?: boolean
}

export default function PhoneInput({
  form,
  error,
  handleChange,
  required,
  disabled = false,
  variant = "standard",
  fullWidth = false,
  backgroundColor,
  margin,
  borderRadius = 0,
  hideBorder = false,
}: Readonly<IProps>): React.JSX.Element {
  function handleChangePhone(event: ChangeEvent<HTMLInputElement>): void {
    const value: string = event.target.value

    event.target.value = formatPhone(value)
    handleChange(event)
  }

  return (
    <TextField
      id="phone"
      variant={variant}
      margin={margin}
      label={required ? "Téléphone *" : "Téléphone"}
      value={form.phone}
      onChange={handleChangePhone}
      error={!!error.phone}
      helperText={error.phone}
      disabled={disabled}
      fullWidth={fullWidth}
      sx={{
        backgroundColor,
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            border: hideBorder ? "none" : undefined,
            borderRadius: borderRadius || 0,
          },
          "&:hover fieldset": {
            border: hideBorder ? "none" : undefined,
            borderRadius: borderRadius || 0,
          },
          "&.Mui-focused fieldset": {
            border: hideBorder ? "none" : undefined,
            borderRadius: borderRadius || 0,
          },
        },
      }}
    />
  )
}
