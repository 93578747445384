import { Info } from "@mui/icons-material"
import { Box, Drawer, List, ListItem, ListItemText, Typography } from "@mui/material"
import React, { ReactElement, useEffect, useState } from "react"
import { IfcPropertyHelper } from "./helpers/IfcPropertyHelper"

function CreatePropertiesMenu({ contentDrawerJSON }: IProps): ReactElement {
  const properties: React.JSX.Element[] = []

  delete contentDrawerJSON.psets
  delete contentDrawerJSON.mats
  delete contentDrawerJSON.type

  Object.keys(contentDrawerJSON).forEach((key) => {
    let value: any = contentDrawerJSON[key]
    if (value === null || value === undefined) value = ""
    while (value.value) value = value.value

    if (Array.isArray(value) || typeof value === "object") value = JSON.stringify(value)

    value = IfcPropertyHelper.decodeIfcProperty(value.toString())

    properties.push(
      <ListItem key={key}>
        <ListItemText primary={key} secondary={value} />
      </ListItem>
    )
  })

  return <List dense>{properties.map((item) => item)}</List>
}

type IProps = {
  contentDrawerJSON: any
}
export default function DisplayPropertiesIFC({ contentDrawerJSON }: IProps): ReactElement {
  const [stateOpenDrawer, setStateOpenDrawer] = useState<boolean>(false)

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return
    }

    setStateOpenDrawer(open)
  }

  useEffect(() => {
    if (Object.entries(contentDrawerJSON).length > 0) {
      setStateOpenDrawer(true)
    }
  }, [contentDrawerJSON])

  return (
    <Drawer anchor="left" open={stateOpenDrawer} onClose={toggleDrawer(false)} style={{ zIndex: "99999" }}>
      <Box sx={{ padding: "15px 5px", width: "250px" }}>
        <Typography variant="subtitle1" sx={{ display: "flex", justify: "center", paddingLeft: 2 }}>
          <Info sx={{ marginRight: 1 }} /> Propriété de l'élément
        </Typography>
        <Box sx={{ padding: "5px", display: "flex" }}>
          <CreatePropertiesMenu contentDrawerJSON={contentDrawerJSON} />
        </Box>
      </Box>
    </Drawer>
  )
}
