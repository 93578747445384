/**
 * Units for the ifc files
 */
export enum IfcUnit {
  UNKNOWN = 0,
  METER = 1,
  CENTIMETER = 2,
  MILLIMETER = 3,
  INCH = 4,
  FOOT = 5,
  SQUARE_METER = 6,
  SQUARE_CENTIMETER = 7,
  SQUARE_MILLIMETER = 8,
  SQUARE_INCH = 9,
  SQUARE_FOOT = 10,
  CUBIC_METER = 11,
  CUBIC_CENTIMETER = 12,
  CUBIC_MILLIMETER = 13,
  CUBIC_INCH = 14,
  CUBIC_FOOT = 15,
  CUBIC_DECIMETER = 16,
}
