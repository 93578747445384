import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { Box, debounce, Grid, IconButton, Modal, SelectChangeEvent } from "@mui/material"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { SuccessContext } from "../../../../../components/layout/success-snackbar"
import ImportFicheConfiguree, {
  successfullAddFicheConfiguree,
} from "../../../../../components/material-lib/material-page-modal/import-fiche-configuree"
import {
  CalculationCarbonMaterialFilterContext,
  FilterType,
} from "../../../../../core/context/calculation/calculation-carbon-material-filter-context"
import MaterialLibDeclarationTypeContextProvider from "../../../../../core/context/material/material-lib-declaration-type-context"
import { IniesRecord } from "../../../../../core/dto/material/IniesRecord"
import { MaterialRecord } from "../../../../../core/dto/material/MaterialRecord"
import { DeclarationFilter } from "../../../../../core/enum/declarationFilter"
import { useFetchMaterialDeclarationFilter } from "../../../../../core/hooks/material-lib/use-fetch-material-lib-declaration-filter"
import { useIniesRecordsFiltered } from "../../../../../core/hooks/material-lib/use-inies-records-filtered"
import { useProjectRecordsFiltered } from "../../../../../core/hooks/material-lib/use-project-records-filtered"
import { useMaterial } from "../../../../../core/hooks/use-material"
import { materialModalstyle } from "../material-modal-style"
import ImportIniesSection from "./import-inies-section"
import { MaterialPageCard } from "./material-page-card"
import { MaterialPageFilterFormCalculation } from "./material-page-filter-form-calculation"
import MaterialPageSearch from "./material-page-search"
import MaterialPageTable from "./material-page-table"

const style = {
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 7,
  pt: 3,
  pb: 1,
  display: "block",
  overflowX: "hidden",
}

type IProps = {
  handleAssignMaterial: (newValue: IniesRecord | MaterialRecord, isMaterial?: boolean) => void
  isAssignMaterialModalOpen: boolean
  closeAssignMaterialModal: () => void
  materialId: string
}

export default function AssignMaterialCalculationModalFormModal({
  handleAssignMaterial,
  isAssignMaterialModalOpen,
  closeAssignMaterialModal,
  materialId,
}: Readonly<IProps>): React.JSX.Element {
  const { projectId } = useParams()

  const { filterByMaterialId, updateFilter } = useContext(CalculationCarbonMaterialFilterContext)
  const openSuccessSnackbar: (message: string) => void = useContext(SuccessContext)

  const { iniesData: nomenclatureFilterData } = useFetchMaterialDeclarationFilter()
  const {
    search: searchInies,
    iniesRecords,
    reset: resetIniesRecordsFiltered,
    limit: iniesLimit,
    setLimit: setIniesLimit,
  } = useIniesRecordsFiltered()
  const {
    search: searchMaterial,
    materialRecords,
    reset: resetMaterialProjects,
    limit: projectLimit,
    setLimit: setProjectLimit,
  } = useProjectRecordsFiltered()
  const { sendFicheConfiguree } = useMaterial()

  const [file, setFile] = useState<File | undefined>()
  const [isSending, setIsSending] = useState<boolean>(false)

  const [openCard, setOpenCard] = useState(false)

  const [selectedLvl1, setSelectedLvl1] = useState<string>()
  const [selectedLvl2, setSelectedLvl2] = useState<string>()
  const [selectedLvl3, setSelectedLvl3] = useState<string>()
  const [selectedLvl4, setSelectedLvl4] = useState<string>()

  const [selectedTypologyDeclaration, setSelectedTypologyDeclaration] = useState<string>()
  const [selectedRow, setSelectedRow] = useState<IniesRecord | MaterialRecord | undefined>(undefined)

  const [lastInie, setLastInie] = useState<string | undefined>(undefined)

  const [lastPrevInie, setLastPrevInie] = useState<string[]>([])

  const [responsibleOrganism, setResponsibleOrganism] = useState<string>()

  const [tab, setTab] = useState<1 | 2>(2) // 1: inies, 2: projectMaterialLib

  const [searchState, setSearchState] = useState<string | undefined>(undefined)

  const [selectedLvl0, setSelectedLvl0] = useState<DeclarationFilter | undefined>(undefined)
  const [pageMaterial, setPageMaterial] = useState(0)

  const [openFicheeConfigureeModal, setOpenFicheeConfigureeModal] = useState(false)

  const getRowsPerPage = useCallback(() => (tab === 1 ? iniesLimit : projectLimit), [iniesLimit, projectLimit, tab])
  const setRowsPerPage = useCallback(
    (newRowsPerPage: number): void => (tab === 1 ? setIniesLimit(newRowsPerPage) : setProjectLimit(newRowsPerPage)),
    [setIniesLimit, setProjectLimit, tab]
  )

  useEffect(() => {
    const key = tab === 1 ? "inie" : "project"
    if (materialId && filterByMaterialId?.[materialId]?.[key]) {
      const filterData: FilterType = filterByMaterialId[materialId][key]
      if (filterData.selectedLvl0) {
        setSelectedLvl0(filterData.selectedLvl0)
      }
      if (filterData.selectedLvl1) {
        setSelectedLvl1(filterData.selectedLvl1)
      }
      if (filterData.selectedLvl2) {
        setSelectedLvl2(filterData.selectedLvl2)
      }
      if (filterData.selectedLvl3) {
        setSelectedLvl3(filterData.selectedLvl3)
      }
      if (filterData.selectedLvl4) {
        setSelectedLvl4(filterData.selectedLvl4)
      }
      if (filterData.selectedTypologieDeclaration) setSelectedTypologyDeclaration(filterData.selectedTypologieDeclaration)
      if (filterData.last) setLastInie(filterData.last)
      if (filterData.lastPrev?.length) setLastPrevInie(filterData.lastPrev)
      if (filterData.responsibleOrganism) setResponsibleOrganism(filterData.responsibleOrganism)
    }
  }, [filterByMaterialId, materialId, tab, isAssignMaterialModalOpen])

  useEffect(() => {
    if (isAssignMaterialModalOpen && materialId) {
      const filter = {
        selectedLvl0,
        selectedLvl1,
        selectedLvl2,
        selectedLvl3,
        selectedLvl4,
        last: lastInie,
        lastPrev: lastPrevInie,
        selectedTypologieDeclaration: selectedTypologyDeclaration,
        responsibleOrganism,
      }
      updateFilter(materialId, {
        inie: tab === 1 ? filter : {},
        project: tab !== 1 ? filter : {},
      })
    }
  }, [
    isAssignMaterialModalOpen,
    tab,
    selectedLvl1,
    selectedLvl2,
    selectedLvl3,
    selectedLvl4,
    lastInie,
    lastPrevInie,
    selectedTypologyDeclaration,
    responsibleOrganism,
    updateFilter,
    materialId,
    selectedLvl0,
  ])

  function resetFilters(): void {
    setSelectedLvl1(undefined)
    setSelectedLvl2(undefined)
    setSelectedLvl3(undefined)
    setSelectedLvl4(undefined)
    setResponsibleOrganism(undefined)
    setSelectedTypologyDeclaration(undefined)
  }

  const searchIniesMaterial = useCallback(
    (newSelectedLvl0: DeclarationFilter | undefined, containedInName?: string, lastFdesName?: string) =>
      searchInies({
        filter: {
          containedInName,
          classificationLvl0: newSelectedLvl0,
          classificationLvl1: Number(selectedLvl1) || undefined,
          classificationLvl2: Number(selectedLvl2) || undefined,
          classificationLvl3: Number(selectedLvl3) || undefined,
          classificationLvl4: Number(selectedLvl4) || undefined,
          responsibleOrganism: responsibleOrganism ?? undefined,
          declarationType: selectedTypologyDeclaration ?? undefined,
        },
        lastFdesName,
      }).then((record: IniesRecord[]) => {
        if (record?.length) {
          setLastInie(record[record.length - 1].fdesName)
        }
      }),
    [responsibleOrganism, searchInies, selectedLvl1, selectedLvl2, selectedLvl3, selectedLvl4, selectedTypologyDeclaration]
  )

  const searchProjectMaterial = useCallback(
    (newSelectedLvl0: DeclarationFilter | undefined, containedInName?: string, lastFdesName?: string) =>
      searchMaterial({
        filter: {
          containedInName,
          classificationLvl0: newSelectedLvl0,
          classificationLvl1: Number(selectedLvl1) || undefined,
          classificationLvl2: Number(selectedLvl2) || undefined,
          classificationLvl3: Number(selectedLvl3) || undefined,
          classificationLvl4: Number(selectedLvl4) || undefined,
          responsibleOrganism: responsibleOrganism ?? undefined,
          declarationType: selectedTypologyDeclaration ?? undefined,
        },
        lastFdesName,
      }).then((record: MaterialRecord[]) => {
        if (record?.length) {
          setLastInie(record[record.length - 1].fdesName)
        }
      }),
    [
      responsibleOrganism,
      searchMaterial,
      selectedLvl1,
      selectedLvl2,
      selectedLvl3,
      selectedLvl4,
      selectedTypologyDeclaration,
    ]
  )

  useEffect(() => {
    // It is this useEffect that refresh data when needed
    if (tab === 1) {
      searchIniesMaterial(selectedLvl0, searchState)
    } else {
      searchProjectMaterial(selectedLvl0, searchState)
    }
  }, [
    isAssignMaterialModalOpen,
    selectedLvl0,
    responsibleOrganism,
    searchIniesMaterial,
    searchMaterial,
    searchProjectMaterial,
    searchState,
    selectedLvl1,
    selectedLvl2,
    selectedLvl3,
    selectedLvl4,
    selectedTypologyDeclaration,
    tab,
    iniesLimit, // this limit must trigger the useEffect
    projectLimit,
  ])

  function handleChangeSelectedLvl0(selected: DeclarationFilter | undefined): void {
    setSelectedLvl0(selected)
    setPageMaterial(0)
    if (tab === 1) {
      searchIniesMaterial(selected)
    } else {
      searchProjectMaterial(selected)
    }
  }

  function handleChangeLvl1(event: SelectChangeEvent): void {
    setSelectedLvl1(event.target.value)
  }

  function handleChangeLvl2(event: SelectChangeEvent): void {
    setSelectedLvl2(event.target.value)
  }

  function handleChangeLvl3(event: SelectChangeEvent): void {
    setSelectedLvl3(event.target.value)
  }

  function handleChangeLvl4(event: SelectChangeEvent): void {
    setSelectedLvl4(event.target.value)
  }

  function handleChangeResponsibleOrganism(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    setResponsibleOrganism(event.target.value)
  }

  function handleTypologieDeclaration(event: SelectChangeEvent): void {
    setSelectedTypologyDeclaration(event.target.value)
  }

  function handleSelectedRow(row: IniesRecord | MaterialRecord | undefined): void {
    setSelectedRow(row)
    setOpenCard(true)
  }

  function importFicheConfiguree(): void {
    setOpenFicheeConfigureeModal(true)
  }

  function handleCloseImportFicheConfigureeModal(): void {
    setOpenFicheeConfigureeModal(false)
    if (tab === 1) {
      searchIniesMaterial(selectedLvl0, "")
    } else {
      searchProjectMaterial(selectedLvl0, "")
    }
  }

  const handleSearchDelayed = useMemo(
    () =>
      debounce((e: string) => {
        setSearchState(e)
        setPageMaterial(0)

        if (tab === 1) {
          searchIniesMaterial(selectedLvl0, e)
        } else {
          searchProjectMaterial(selectedLvl0, e)
        }
      }, 700),
    [selectedLvl0, searchIniesMaterial, searchProjectMaterial, tab]
  )

  const handleClickInieOrMaterial = useCallback(
    (v: 1 | 2): void => {
      setTab(v)
      setSelectedRow(undefined)
      setLastInie(undefined)
      setPageMaterial(0)
      resetFilters()

      if (v === 1) {
        searchIniesMaterial(selectedLvl0)
      } else {
        searchProjectMaterial(selectedLvl0)
      }
    },
    [selectedLvl0, searchIniesMaterial, searchProjectMaterial]
  )

  const handleSearchProjectPaginated = useCallback(
    (newPage: number) => {
      if (newPage > pageMaterial) {
        searchProjectMaterial(selectedLvl0, searchState, lastInie).then(() => {
          if (lastInie) setLastPrevInie([...lastPrevInie, lastInie])
        })
      } else if (newPage <= pageMaterial) {
        lastPrevInie.pop()
        const newLastPrevInie = [...lastPrevInie]
        const lastFdesName = newPage === 0 ? undefined : newLastPrevInie[newLastPrevInie.length - 1]
        searchProjectMaterial(selectedLvl0, searchState, lastFdesName)
      }
    },
    [selectedLvl0, lastInie, lastPrevInie, pageMaterial, searchProjectMaterial, searchState]
  )

  const handleSearchModalIniesPaginated = useCallback(
    (newPage: number) => {
      if (newPage > pageMaterial) {
        searchIniesMaterial(selectedLvl0, searchState, lastInie).then(() => {
          if (lastInie) setLastPrevInie([...lastPrevInie, lastInie])
        })
      } else if (newPage <= pageMaterial) {
        lastPrevInie.pop()
        const newLastPrevInie = [...lastPrevInie]
        const lastFdesName = newPage === 0 ? undefined : newLastPrevInie[newLastPrevInie.length - 1]
        searchIniesMaterial(selectedLvl0, searchState, lastFdesName)
      }
    },
    [selectedLvl0, lastInie, lastPrevInie, pageMaterial, searchIniesMaterial, searchState]
  )

  const handleSearchPaginated = useCallback(
    (event: React.MouseEvent | null, newPage: number) => {
      resetIniesRecordsFiltered()
      resetMaterialProjects()

      if (tab === 1) {
        handleSearchModalIniesPaginated(newPage)
      } else if (tab === 2) {
        handleSearchProjectPaginated(newPage)
      }
      setPageMaterial(newPage)
    },
    [handleSearchModalIniesPaginated, handleSearchProjectPaginated, resetIniesRecordsFiltered, resetMaterialProjects, tab]
  )

  const dataTableSearched = useMemo(() => {
    if (tab === 1) {
      return iniesRecords
    } else if (tab === 2) {
      return materialRecords
    } else {
      return []
    }
  }, [materialRecords, iniesRecords, tab])

  function handleAssignMaterialToCode(newValue: IniesRecord | MaterialRecord): void {
    handleAssignMaterial(newValue, tab === 2)
    setOpenCard(false)
    closeAssignMaterialModal()
  }

  function sendFile(): void {
    if (file && projectId) {
      setIsSending(true)
      sendFicheConfiguree(file, projectId)
        .then(() => {
          handleCloseImportFicheConfigureeModal()
          openSuccessSnackbar(successfullAddFicheConfiguree)
        })
        .finally(() => {
          setIsSending(false)
        })
    }
  }

  return (
    <MaterialLibDeclarationTypeContextProvider>
      <Modal
        open={isAssignMaterialModalOpen}
        onClose={closeAssignMaterialModal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box
          sx={{
            ...materialModalstyle,
            width: "90%",
            height: "90%",
          }}>
          <Box>
            <Box
              sx={{
                margin: "8px 0",
              }}>
              <Box display="flex">
                <IconButton
                  sx={{ marginLeft: "auto" }}
                  onClick={() => {
                    closeAssignMaterialModal()
                  }}>
                  <CloseOutlinedIcon />
                </IconButton>
              </Box>
              <Grid container columnSpacing={12}>
                <Grid item xs={4}>
                  <ImportIniesSection
                    tab={tab}
                    handleClick={handleClickInieOrMaterial}
                    importFicheConfiguree={importFicheConfiguree}
                  />
                  <MaterialPageFilterFormCalculation
                    selectedLvl0={selectedLvl0}
                    setSelectedLvl0={handleChangeSelectedLvl0}
                    nomenclatureFilterData={nomenclatureFilterData}
                    selectedLvl1={selectedLvl1}
                    setSelectedLvl1={handleChangeLvl1}
                    selectedLvl2={selectedLvl2}
                    setSelectedLvl2={handleChangeLvl2}
                    selectedLvl3={selectedLvl3}
                    setSelectedLvl3={handleChangeLvl3}
                    selectedLvl4={selectedLvl4}
                    setSelectedLvl4={handleChangeLvl4}
                    handleChangeResponsibleOrganism={handleChangeResponsibleOrganism}
                    selectedTypologieDeclaration={selectedTypologyDeclaration}
                    handleTypologieDeclaration={handleTypologieDeclaration}
                    responsibleOrganism={responsibleOrganism}
                    setResponsibleOrganism={setResponsibleOrganism}
                  />
                </Grid>

                <Grid item xs={8}>
                  <MaterialPageSearch
                    handleSearchDelayed={handleSearchDelayed}
                    search={searchState}
                    setSearch={setSearchState}
                  />
                  <MaterialPageTable
                    onSelected={handleSelectedRow}
                    selectedRow={selectedRow}
                    records={dataTableSearched}
                    page={pageMaterial}
                    handleSearchPaginated={handleSearchPaginated}
                    tab={tab}
                    rowsPerPage={getRowsPerPage()}
                    setRowsPerPage={setRowsPerPage}
                    setPage={setPageMaterial}
                  />

                  <Modal
                    open={openCard}
                    onClose={() => setOpenCard(false)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    aria-labelledby="add-material-modal"
                    aria-describedby="modal-modal-description">
                    <Box>
                      <MaterialPageCard
                        selectedRow={selectedRow}
                        handleAddEnvironmentalStatement={handleAssignMaterialToCode}
                        isMaterial={false}
                        setDisplayMaterialModalOpen={setOpenCard}
                      />
                    </Box>
                  </Modal>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal
        sx={{
          margin: "40px 0",
        }}
        open={openFicheeConfigureeModal}
        onClose={() => handleCloseImportFicheConfigureeModal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Box className="modal-import-inies" sx={style}>
          <ImportFicheConfiguree
            handleClose={handleCloseImportFicheConfigureeModal}
            sendFile={sendFile}
            isSending={isSending}
            file={file}
            setFile={setFile}
          />
        </Box>
      </Modal>
    </MaterialLibDeclarationTypeContextProvider>
  )
}
