import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { Children } from "../../../components/miscellianous/children"
import { Project } from "../../dto/project/project"
import { useProjects } from "../../hooks/projects/use-projects"
import { CodeReferenceContextProvider } from "../code-acv/CodeReferenceContext"

export const ProjectContext = React.createContext<ProjectStore>({} as ProjectStore)

export function ProjectContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  // TODO: Changer le selectedProject par defaut pour le récupérer du localStorage. Actuellement, si tu es sur le projet2
  // et que tu refresh la page, ça fetch le projet et ça bascule sur le projet1
  const [selectedProject, setSelectedProject] = useState<Project>(new Project())

  const { projectId } = useParams()
  const { getProject } = useProjects()

  useEffect(() => {
    if (projectId && projectId !== "new") {
      getProject(projectId).then((newProject) => {
        setSelectedProject(newProject)
      })
    }
  }, [projectId, getProject])

  const projectStore = useMemo(
    () => ({
      project: selectedProject,
      setProject: setSelectedProject,
    }),
    [selectedProject]
  )
  return (
    <ProjectContext.Provider value={projectStore}>
      <CodeReferenceContextProvider projectCodeVersion={selectedProject?.codesVersion}>
        {children}
      </CodeReferenceContextProvider>
    </ProjectContext.Provider>
  )
}

export type ProjectStore = {
  project: Project
  setProject: Dispatch<SetStateAction<Project>>
}
