import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"
import { ShareForm, SharingFormButtons } from "./share-form"
import { ShareLink } from "../../../../core/dto/rsee/share-link"
import { CopyLinkButton } from "../../../../components/buttons/CopyLinkButton/CopyLinkButton"

type IProps = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  rseeDocumentId: string | undefined
}
export default function ShareLinkCreationModal({ open, setOpen, rseeDocumentId }: IProps): React.JSX.Element {
  const [errors, setErrors] = useState<Record<string, string>>({})
  const [shareLink, setShareLink] = useState<string | undefined>(undefined)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  useEffect(() => {
    if (open) {
      setErrors({})
      setShareLink(undefined)
    }
  }, [open])

  function handleClose(): void {
    setOpen(false)
  }

  function onSubmitSuccess(newSharingInformation: ShareLink): void {
    setShareLink(newSharingInformation.sharePageUrl)
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Lien du partage</DialogTitle>
      {shareLink === undefined ? (
        <>
          <DialogContent>
            <ShareForm
              errors={errors}
              setErrors={setErrors}
              rseeDocumentId={rseeDocumentId}
              setIsSubmitting={setIsSubmitting}
              onSubmitSuccess={onSubmitSuccess}
            />
          </DialogContent>
          <DialogActions>
            <SharingFormButtons cancel={handleClose} isSubmitting={isSubmitting} />
          </DialogActions>
        </>
      ) : (
        <>
          <DialogContent sx={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
            <Typography sx={{ textAlign: "center" }}>
              Toute personne avec ce lien et un compte "Time to Beem" peut visualiser ce tableau de bord.
            </Typography>
          </DialogContent>
          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <CopyLinkButton link={shareLink} />
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}
