import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Children } from "../../../components/miscellianous/children"
import { Organization } from "../../dto/organization/organization"
import { useOrganization } from "../../hooks/use-organization"

export const OrganizationsListContext = React.createContext<OrganizationsListStore>({} as OrganizationsListStore)

export default function OrganizationsListContextProvider({ children }: Children): React.JSX.Element {
  const [organizationsList, setOrganizationsList] = useState<Organization[]>([])
  const { fetchOrganizationsList } = useOrganization()

  const refreshOrganizationList = useCallback(() => {
    fetchOrganizationsList().then((organizations) => setOrganizationsList(organizations))
  }, [fetchOrganizationsList])

  useEffect(() => {
    refreshOrganizationList()
  }, [refreshOrganizationList])

  const organizationsListStore: OrganizationsListStore = useMemo(
    () => ({ organizationsList, setOrganizationsList, refreshOrganizationList }),
    [organizationsList, refreshOrganizationList]
  )
  return <OrganizationsListContext.Provider value={organizationsListStore}>{children}</OrganizationsListContext.Provider>
}

type OrganizationsListStore = {
  organizationsList: Organization[]
  setOrganizationsList(organizationsList: Organization[]): void
  refreshOrganizationList(): void
}
