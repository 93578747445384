import { Box, Modal, Typography } from "@mui/material"
import React from "react"
import MaterialRecordCreation from "../../../core/dto/material/material-record-creation"
import { DeclarationTypeEnum } from "../../../core/enum/declarationTypeEnum"
import { MaterialLibPage } from "../../../core/enum/materialLibPage"
import MaterialCard from "./material-card"
import { IniesRecord } from "../../../core/dto/material/IniesRecord"
import { MaterialRecord } from "../../../core/dto/material/MaterialRecord"

type IProps = {
  openCard: boolean
  selectedRow: IniesRecord | MaterialRecord | undefined
  reset: () => void
  setSelectedRow: React.Dispatch<React.SetStateAction<IniesRecord | MaterialRecord | undefined>>
  getTypologyBackground: (typologie: DeclarationTypeEnum) => string
  isEditable: boolean
  isDeletable: boolean
  isModalCard: boolean
  isProjectMaterial?: boolean
  typeMaterialLib: MaterialLibPage
  tab: 1 | 2
  setMaterialCreation: React.Dispatch<React.SetStateAction<MaterialRecordCreation>> | undefined
  materialCreation: MaterialRecordCreation | undefined
  handleCloseMaterialCardModal: () => void
}

export function MaterialCardModal(props: Readonly<IProps>): React.JSX.Element {
  const { openCard, handleCloseMaterialCardModal, selectedRow, ...otherProps } = props
  return (
    <Modal
      open={openCard}
      onClose={handleCloseMaterialCardModal}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box>
        {selectedRow ? (
          <MaterialCard
            handleCloseMaterialCardModal={handleCloseMaterialCardModal}
            selectedRow={selectedRow}
            {...otherProps}
          />
        ) : (
          <Typography>Pas de matériau sélectionné</Typography>
        )}
      </Box>
    </Modal>
  )
}
