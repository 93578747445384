import { LockOutlined } from "@mui/icons-material"
import { Avatar, Box, Button, Container, Grid, Link as MaterialLink, TextField, Typography } from "@mui/material"
import { ChangeEvent, FormEvent, useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ErrorContext } from "../../../components/layout/error-snackbar"
import { SuccessContext } from "../../../components/layout/success-snackbar"
import PasswordInput from "../../../components/inputs/password-input/password-input"
import { forgotPassword, forgotPasswordSubmit } from "../../../core/services/authentication-service"
import { getEnvProperty, PROPERTIES } from "../../../core/services/environment-service"
import { isPasswordValid } from "../../../core/services/user-service"
import { pagesUrl } from "../../../core/appConstants"

export default function ForgotPasswordPage(): React.JSX.Element {
  const openErrorSnackbar = useContext(ErrorContext)
  const openSuccessSnackbar = useContext(SuccessContext)
  const navigate = useNavigate()
  const [credentials, setCredentials] = useState({ username: "", code: "", password: "" })
  const [step, setStep] = useState(0)
  const [error, setError] = useState<any>({})

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    const target = event.target
    const value = target.value
    const name = target.id
    setCredentials({
      ...credentials,
      [name]: value,
    })

    if (error[name]) {
      setError({ ...error, [name]: undefined })
    }
  }

  function isValid(): boolean {
    const newError: Record<string, any> = {}
    let valid = true

    if (!isPasswordValid(credentials.password)) {
      newError.password = true
      valid = false
    }

    if (credentials.code === undefined || credentials.code.length !== 6) {
      newError.code = "Code invalide"
      valid = false
    }

    setError(newError)
    return valid
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>): Promise<void> {
    event.preventDefault()
    if (step === 0 && credentials.username) {
      forgotPassword(credentials.username)
        .then(() => {
          openSuccessSnackbar("Verifier votre email")
          setStep((s) => s + 1)
        })
        .catch((e: Error) => {
          openErrorSnackbar(e)
        })
    } else if (step === 1 && credentials.username) {
      if (!isValid()) {
        return
      }

      forgotPasswordSubmit(credentials.username, credentials.code, credentials.password)
        .then(() => {
          openSuccessSnackbar("Mot de passe réinitialisé")
          navigate(pagesUrl.SIGN_IN_PAGE)
        })
        .catch((e: Error) => {
          openErrorSnackbar(e)
        })
    }
  }

  return (
    <Container maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          Réinitialisez votre mot de passe
        </Typography>
        <Typography component="span" variant="h6" mt={2}>
          {step === 0 ? `Un code va être envoyé à votre adresse email` : `Un code a été envoyé à votre adresse email`}
        </Typography>
        <Box component="form" width="100%" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            id="username"
            margin="normal"
            required
            fullWidth
            label="Email"
            disabled={step === 1}
            value={credentials.username}
            onChange={handleChange}
          />
          {step === 1 && (
            <>
              <TextField
                id="code"
                margin="normal"
                fullWidth
                name="code"
                label="Code"
                type="text"
                error={!!error?.code}
                helperText={error?.code}
                value={credentials.code}
                onChange={handleChange}
              />
              <PasswordInput
                err={error?.password}
                value={credentials.password}
                handleChange={handleChange}
                variant={undefined}
                label="Nouveau mot de passe"
              />
            </>
          )}
          <Grid container display="flex" flexDirection="row" columnSpacing={1} sx={{ mt: 3, mb: 2 }}>
            {step === 1 && (
              <Grid item xs={6}>
                <Button fullWidth variant="outlined" onClick={() => setStep(0)}>
                  Retour
                </Button>
              </Grid>
            )}
            <Grid item xs={step === 1 ? 6 : 12}>
              <Button fullWidth type="submit" variant="contained">
                {step === 0 ? `Réinitialiser` : `Valider`}
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <MaterialLink variant="body2" href={pagesUrl.SIGN_IN_PAGE}>
                Se Connecter ?
              </MaterialLink>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Typography variant="caption">
            Version: {process.env.REACT_APP_VERSION}-{getEnvProperty(PROPERTIES.REACT_APP_ENVIRONMENT)}
          </Typography>
        </Box>
      </Box>
    </Container>
  )
}
