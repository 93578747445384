import { Box, Button, CircularProgress, Divider, Grid, Link, Typography } from "@mui/material"
import { FormEvent, useContext, useState } from "react"
import { DomainNameValidatorContext } from "../../core/context/organization/domain-name-validator-context"
import { UserContext } from "../../core/context/user/user-context"
import { SupportForm } from "../../core/dto/support/support-form"
import { OrganizationRoleEnum } from "../../core/enum/user/organization-role-enum"
import { isEmailValid, isPhoneValid } from "../../core/services/user-service"
import CancelButton from "../buttons/cancel-button/cancel-button"
import PhoneInput from "../inputs/phone-input/phone-input"
import { TextInput } from "../inputs/text-input/text-input"

type IProps = {
  onSubmit(userForm: SupportForm): Promise<void>
  handleClose(): void
}

export function SupportFormComponent({ onSubmit, handleClose }: Readonly<IProps>): React.JSX.Element {
  const { isDomainNameInvalid } = useContext(DomainNameValidatorContext)
  const { user } = useContext(UserContext)
  const [supportForm, setSupportForm] = useState<SupportForm>({
    firstName: user?.givenName ?? "",
    lastName: user?.familyName ?? "",
    phone: user?.phone ?? "",
    email: user?.email ?? "",
    organizationName: user?.organizationName ?? "",
    userOrganizationRole: user?.role ?? OrganizationRoleEnum.NORMAL_USER,
    message: "",
  })
  const [error, setError] = useState<any>({})
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  function validate(): boolean {
    const newError: Record<string, any> = {}
    let isValid = true

    if (!supportForm.firstName) {
      newError.firstName = "Le prénom doit être renseigné"
      isValid = false
    }
    if (!supportForm.lastName) {
      newError.lastName = "Le nom doit être renseigné"
      isValid = false
    }
    if (!supportForm.phone) {
      newError.phone = "Le numéro de téléphone doit être renseigné"
      isValid = false
    } else if (!isPhoneValid(supportForm.phone)) {
      newError.phone =
        'Le numéro de téléphone doit être composé uniquement de chiffres et faire 10 caractères ou commencer par "+" et faire 12 caractères'
      isValid = false
    }
    if (!supportForm.email) {
      newError.email = "L'email doit être renseigné"
      isValid = false
    } else if (!isEmailValid(supportForm.email)) {
      newError.email = "L'adresse email ne respecte pas la forme classique : email@complement.fin"
      isValid = false
    } else if (isDomainNameInvalid(supportForm.email)) {
      newError.email = "Time To Beem est un service B2B. Merci d'utiliser une adresse email professionnelle"
      isValid = false
    }
    if (!supportForm.message) {
      newError.message = "Le message doit être renseigné"
      isValid = false
    }

    setError(newError)
    return isValid
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const target = event.target

    setSupportForm({
      ...supportForm,
      [target.id]: target.value,
    })
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault()

    if (!validate()) {
      return
    }
    supportForm.email = supportForm.email.toLowerCase()
    setIsSubmitting(true)
    onSubmit(supportForm).finally(() => setIsSubmitting(false))
  }

  return (
    <Box component="form" onSubmit={handleSubmit} p={3} color="black" sx={{ backgroundColor: "white" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography variant="body1" sx={{ color: "black" }}>
            Vous rencontrez un problème ? Renseignez le formulaire ci-dessous, notre équipe reviendra vers vous dans les plus
            brefs délais.
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextInput
            fieldName="firstName"
            label="Prénom"
            handleChange={handleChange}
            form={supportForm}
            errors={error}
            backgroundColor="#F5F5F5"
            hideBorder
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput
            fieldName="lastName"
            label="Nom"
            handleChange={handleChange}
            form={supportForm}
            errors={error}
            backgroundColor="#F5F5F5"
            hideBorder
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextInput
            fieldName="email"
            label="Email"
            handleChange={handleChange}
            form={supportForm}
            errors={error}
            backgroundColor="#F5F5F5"
            hideBorder
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <PhoneInput
            form={supportForm}
            error={error}
            handleChange={handleChange}
            required
            variant="outlined"
            fullWidth
            hideBorder
            backgroundColor="#F5F5F5"
            margin="normal"
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            fieldName="message"
            label="Ecrire un message"
            handleChange={handleChange}
            form={supportForm}
            errors={error}
            backgroundColor="#F5F5F5"
            rows={4}
            hideBorder
          />
        </Grid>
      </Grid>

      <Typography variant="body2" fontSize={12} sx={{ color: "#8F8F8F", pb: 1 }}>
        Les champs précédés d’une astérisque sont obligatoires.
      </Typography>

      <Box display="flex" flexDirection="column" p={2} gap={1} borderRadius={3} sx={{ backgroundColor: "#FFF3E0" }}>
        <Typography variant="body2">
          Si le problème persiste, notre équipe est là pour vous aider. Planifiez une session de support pour une assistance
          personnalisée.
        </Typography>

        <Typography variant="body2" sx={{ color: "black" }}>
          <Link
            href="https://calendly.com/g-lescoulier/15min"
            underline="always"
            sx={{ color: "black", textDecorationColor: "black" }}>
            Contacter une assistance personnalisée
          </Link>
        </Typography>
      </Box>
      <Divider sx={{ pt: 2 }} />

      <Box sx={{ mt: 3, display: "flex", justifyContent: "center", gap: 2 }}>
        {isSubmitting ? (
          <CircularProgress />
        ) : (
          <>
            <CancelButton action={handleClose} label="Annuler" />
            <Button variant="contained" type="submit">
              Envoyer ma demande
            </Button>
          </>
        )}
      </Box>
    </Box>
  )
}
