import { BottomNavigation, Paper, useTheme } from "@mui/material"
import React, { useContext } from "react"
import { AppSideBarContext } from "../../../core/context/side-bar/side-bar-context"
import { SubmitButton } from "../SubmitButton/SubmitButton"
import CancelButton from "../cancel-button/cancel-button"
import { ValidateButton } from "../validate-button/validate-button"

interface IProps {
  actionLabel: string
  cancelLabel?: string
  formId?: string
  isSubmiting?: boolean
  actionButton?(): void | undefined
  cancelAction?(): void
}

export function CustomBottomNavigation({
  actionLabel,
  actionButton,
  cancelAction,
  cancelLabel,
  formId,
  isSubmiting,
}: Readonly<IProps>): React.JSX.Element {
  const theme = useTheme()

  const { drawerWidth } = useContext(AppSideBarContext)

  return (
    <Paper elevation={24}>
      <BottomNavigation
        sx={{
          position: "fixed",
          bottom: 20,
          transition: theme.transitions.create("left", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          left: drawerWidth + 20,
          right: 20,
          pr: 2,
          borderRadius: 3,
          display: "flex",
          alignItems: "center",
          height: "8vh",
          justifyContent: "flex-end",
          alignContent: "center",
          gap: 2,
        }}>
        {cancelAction && <CancelButton action={cancelAction} label={cancelLabel} />}
        {actionButton && <ValidateButton action={actionButton} label={actionLabel} isSubmiting={isSubmiting} />}
        {formId && <SubmitButton formId={formId} label={actionLabel} isSubmiting={Boolean(isSubmiting)} />}
      </BottomNavigation>
    </Paper>
  )
}
