import CircleIcon from "@mui/icons-material/Circle"
import { Box, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import React, { useEffect, useMemo, useRef } from "react"
import { CodeExtrait } from "../../../../../../core/dto/code-extrait/code-extrait"
import { codeStateEnum } from "../../../../../../core/enum/codeStateEnum"
import { MaterialLibPage } from "../../../../../../core/enum/materialLibPage"
import "./project-bim-model-list.css"
import TreeList from "../../../../../../components/tree-list/tree-list"
import { sortCodeExtrait } from "../../../../../../core/services/code-service"

const rows: CodeExtrait[] = []

type iProps = {
  variant?: codeStateEnum
  isTree?: boolean
  actualPage: string
  codesExtraits: CodeExtrait[]
  handleClickAcv?: (codeExtract: CodeExtrait) => void
  selectedCodeExtrait?: CodeExtrait
}

export default function BimModelList({
  variant,
  actualPage,
  isTree,
  codesExtraits,
  handleClickAcv,
  selectedCodeExtrait,
}: Readonly<iProps>): React.JSX.Element {
  const tableRef = useRef<HTMLTableElement>(null)

  const codeExtraitsSorted: CodeExtrait[] = useMemo(() => {
    const list = actualPage === MaterialLibPage.EXEMPLE_PAGE ? rows : codesExtraits
    return list.sort(sortCodeExtrait)
  }, [actualPage, codesExtraits])

  useEffect(() => {
    if (tableRef) {
      const rowsList = tableRef.current?.querySelectorAll("tr")
      rowsList?.forEach((row) => {
        if (row.id === `${selectedCodeExtrait ? selectedCodeExtrait.code + selectedCodeExtrait.occurence : ""}`) {
          row.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" })
        }
      })
    }
  }, [selectedCodeExtrait, variant, codesExtraits])

  if (isTree && variant === codeStateEnum.CODE_ACV) {
    return (
      <Box sx={{ maxHeight: "calc(100vh - 350px)", flexGrow: 1, overflowY: "auto" }}>
        <TreeList
          codesExtraits={codeExtraitsSorted}
          handleClickAcv={handleClickAcv}
          selectedCodeExtrait={selectedCodeExtrait}
          renderCodeElement={(code) => (
            <Stack display="flex" flexDirection="row" alignItems="center" flex={1}>
              <Typography minWidth="140px" variant="subtitle1" fontSize={14}>{`${code.code}${code.occurence}`}</Typography>
              <CircleIcon sx={{ fontSize: "40px", color: code.color, mx: "5px" }} />
              <Typography variant="caption">{code.descriptionFromBimModel}</Typography>
            </Stack>
          )}
        />
      </Box>
    )
  }
  return (
    <Box
      component="div"
      sx={{
        overflow: "hidden",
        height: "calc(100vh - 350px)",
        display: variant === codeStateEnum.CODE_ACV || variant === codeStateEnum.CODE_INVALIDE ? "block" : "none",
      }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                p: 0,
                width: "30%",
              }}>
              Code ACV
            </TableCell>
            <TableCell
              sx={{
                p: 0,
                width: "15%",
              }}>
              Etiquette
            </TableCell>
            <TableCell
              align="left"
              sx={{
                p: 0,
                width: "60%",
              }}>
              Description
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
      <Box className="table-bim-model" component="div" sx={{ flex: 1, overflow: "auto", maxHeight: "calc(100vh - 380px)" }}>
        <Table style={{ tableLayout: "fixed" }} ref={tableRef}>
          {codeExtraitsSorted !== undefined && codeExtraitsSorted.length > 0 ? (
            <TableBody>
              {codeExtraitsSorted.map((row) => {
                const keyCode = row.code + row.occurence
                return (
                  <TableRow
                    id={keyCode}
                    key={keyCode}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      backgroundColor:
                        `${row.code + row.occurence}` ===
                        `${selectedCodeExtrait ? selectedCodeExtrait.code + selectedCodeExtrait.occurence : ""}`
                          ? "#ebebeb"
                          : "",
                    }}
                    onClick={() => {
                      if (handleClickAcv) handleClickAcv(row)
                    }}>
                    <TableCell
                      sx={{
                        p: 0,
                        width: "30%",
                      }}>
                      {keyCode}
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        width: "15%",
                      }}>
                      <CircleIcon sx={{ fontSize: "48px", color: row.color }} />
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                      }}
                      align="left">
                      {row.descriptionFromBimModel}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    p: 0,
                    width: "100%",
                  }}>
                  Pas de données..
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </Box>
    </Box>
  )
}
