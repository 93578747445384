import { Typography } from "@mui/material"
import React from "react"

export interface ITextInputProps {
  label: string
}

export function TextInputTypography({ label }: Readonly<ITextInputProps>): React.JSX.Element {
  return (
    <Typography
      sx={{
        fontFamily: "Poppins",
        fontSize: "11px",
        fontWeight: 400,
        lineHeight: "26.5px",
        textAlign: "left",
        color: "#8398A6",
      }}>
      {label}
    </Typography>
  )
}
