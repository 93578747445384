import CalculateIcon from "@mui/icons-material/CalculateOutlined"
import DeleteIcon from "@mui/icons-material/Delete"
import GroupsIcon from "@mui/icons-material/Groups"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import { Grid, IconButton, Tooltip } from "@mui/material"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { SuccessContext } from "../../../../components/layout/success-snackbar"
import { adminPagesUrl, pagesUrl } from "../../../../core/appConstants"
import { RseeProjectContext } from "../../../../core/context/beem-pilot/rsee/rsee-project-context"
import { ProjectTypeContext } from "../../../../core/context/project/project-type-context"
import { RseeProjectRoleContext } from "../../../../core/context/user/rsee-project-role-context"
import { UserContext } from "../../../../core/context/user/user-context"
import { RseeDocument } from "../../../../core/dto/rsee/rsee-document"
import { ProjectTypeEnum } from "../../../../core/enum/project/projectTypeEnum"
import { OrganizationRoleEnum } from "../../../../core/enum/user/organization-role-enum"
import { useRsee } from "../../../../core/hooks/rsee/use-rsee"
import { isMegaUser, isUltimateUser } from "../../../../core/services/authentication-service"
import { resolveUrl } from "../../../../core/services/http-service"
import RseeDocumentCard from "../components/rsee-document-card"
import RseeHeader from "../components/rsee-header"
import { RseeProjectFormDialog } from "../components/RseeProjectFormDialog "
import { DeleteRseeProjectDialog } from "./components/DeleteRseeProjectDialog"

export default function RseeDocumentListPage(): React.JSX.Element {
  const navigate = useNavigate()
  const { organizationId, rseeProjectId } = useParams()

  const { rseeProject } = useContext(RseeProjectContext)
  const openSuccessSnackbar = useContext(SuccessContext)
  const { hasProject } = useContext(RseeProjectRoleContext)
  const { user } = useContext(UserContext)
  const { currentProjectType } = useContext(ProjectTypeContext)

  const { fetchRseeDocumentList, deleteRseeDocument, updateCurrentRseeDocument } = useRsee()

  const [rseeDocumentList, setRseeDocumentList] = useState<RseeDocument[]>([])
  const [openRseeForm, setOpenRseeForm] = useState<boolean>(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
  const isWriting = useMemo(() => hasProject, [hasProject])
  const isReading = useMemo(() => isMegaUser(user) || isUltimateUser(user), [user])

  useEffect(() => {
    if (rseeProject?.id) {
      fetchRseeDocumentList(rseeProject.id).then((documentList) => {
        setRseeDocumentList(documentList)
      })
    }
  }, [fetchRseeDocumentList, rseeProject?.id])

  function handleDeleteDocument(documentId: string): void {
    deleteRseeDocument(documentId).then(() => {
      const rseeDocuments = rseeDocumentList.filter((document) => document.id !== documentId)
      setRseeDocumentList(rseeDocuments)
      openSuccessSnackbar("Suppression du document RSEE réalisé avec succès")

      if (rseeDocuments.length === 0) {
        navigate(resolveUrl(pagesUrl.PROJECTS_PAGE, [], { type: ProjectTypeEnum.RSEE }))
      }
    })
  }

  function handleUpdateCurrentDocument(documentId: string, toUpdate: boolean): void {
    updateCurrentRseeDocument(documentId, toUpdate).then(() => {
      const rseeDocuments = rseeDocumentList.map((document) => {
        if (document.id === documentId) {
          return { ...document, currentDocument: !document.currentDocument }
        }
        return document
      })
      setRseeDocumentList(rseeDocuments)
      if (rseeDocuments.length === 0) {
        navigate(resolveUrl(pagesUrl.PROJECTS_PAGE, [], { type: ProjectTypeEnum.RSEE }))
      }
    })
  }

  function handleEdit(): void {
    setOpenRseeForm(true)
  }

  function handleGroup(): void {
    if (rseeProject?.id) {
      isUltimateUser(user)
        ? navigate(resolveUrl(adminPagesUrl.RSEE_PROJECT_GROUP_UPDATE, [organizationId, rseeProjectId]))
        : navigate(resolveUrl(pagesUrl.RSEE_PROJECT_GROUP_UPDATE, [rseeProjectId]))
    }
  }

  function handleEditDeleteDialog(): void {
    setOpenDeleteDialog(true)
  }

  function getMenuButton(): React.JSX.Element {
    return (
      <Grid container columnSpacing={2} sx={{ alignItems: "center" }}>
        <Grid item>
          {isWriting && (
            <Tooltip title="Mettre à jour" placement="right">
              <IconButton onClick={handleEdit}>
                <ModeEditIcon />
              </IconButton>
            </Tooltip>
          )}
          {(isWriting || isReading) && (
            <Tooltip title="Equipe" placement="right">
              <IconButton onClick={handleGroup}>
                <GroupsIcon />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
        <Grid item>
          {isWriting && (
            <Tooltip title="Supprimer" placement="right">
              <IconButton onClick={handleEditDeleteDialog}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>
    )
  }

  function navigateBackUrl(): string {
    if (user?.role === OrganizationRoleEnum.ULTIMATE_USER) {
      return resolveUrl(adminPagesUrl.PROJECT_RSEE_PAGE, [organizationId], { type: ProjectTypeEnum.RSEE })
    } else if (currentProjectType) {
      return resolveUrl(pagesUrl.PROJECTS_PAGE, [], { type: currentProjectType })
    } else {
      return resolveUrl(pagesUrl.PROJECTS_PAGE, [], { type: ProjectTypeEnum.RSEE })
    }
  }

  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={2}>
        <RseeHeader
          title="Projet"
          action={{ path: resolveUrl(pagesUrl.RSEE_PROJECT_DOCUMENT_NEW, [rseeProject?.id]) }}
          navigateBackPath={navigateBackUrl()}
          actionLabel="Importer RSEE"
          icon={<CalculateIcon sx={{ fontSize: "40px" }} />}
          isWriting={isWriting}
          menu={getMenuButton()}
        />

        {rseeDocumentList.map((rseeDocument) => (
          <Grid key={rseeDocument.id} item xs={12} md={4} lg={3}>
            <RseeDocumentCard
              rseeDocument={rseeDocument}
              deleteDocument={handleDeleteDocument}
              isWriting={isWriting}
              updateCurrentDocument={handleUpdateCurrentDocument}
            />
          </Grid>
        ))}
      </Grid>
      <RseeProjectFormDialog open={openRseeForm} setOpen={setOpenRseeForm} />
      <DeleteRseeProjectDialog open={openDeleteDialog} setOpen={setOpenDeleteDialog} />
    </>
  )
}
