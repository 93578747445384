// Inspired from little lib "react-google-captcha-v3" from https://github.com/t49tran/react-google-recaptcha-v3

import React, { createContext, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { cleanGoogleRecaptcha, injectGoogleReCaptchaScript } from "./utils"

interface IProps {
  reCaptchaKey: string
  container?: {
    element?: string | HTMLElement
    parameters: {
      badge?: "inline" | "bottomleft" | "bottomright"
      theme?: "dark" | "light"
      tabindex?: number
      callback?: () => void
      expiredCallback?: () => void
      errorCallback?: () => void
    }
  }
  children: ReactNode
}

type MapToFunction = { [key: string]: () => void }

// I created this interface to be clear that the google captcha function does not contain a ".finally()". Which is different from a normal promise (and which is very annoying)
export interface Thenable<T = any> {
  then(onFulfilled: (value: T) => any, onRejected?: (reason: any) => any): any

  catch(onRejected: (reason: any) => any): any
}

export const GoogleReCaptchaContext = createContext<IGoogleReCaptchaStore>({} as IGoogleReCaptchaStore)

export function GoogleReCaptchaContextProvider({ reCaptchaKey, container, children }: IProps): React.JSX.Element {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const [greCaptchaInstance, setGreCaptchaInstance] = useState<{ execute: Function } | undefined>(undefined)
  const clientId = useRef<string>(reCaptchaKey)

  useEffect(() => {
    if (!reCaptchaKey) {
      console.warn("Recaptcha key not provided")
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {}
    } else {
      const windowMapToFunction: MapToFunction = window as unknown as MapToFunction
      windowMapToFunction.onRecaptchaLoadCallback = onRecaptchaLoadCallback

      injectGoogleReCaptchaScript(clientId.current, onLoad)

      return () => {
        cleanGoogleRecaptcha("google-recaptcha-v3", container?.element)
      }
    }
  }, [container?.element, container?.parameters, reCaptchaKey])

  function onRecaptchaLoadCallback(): void {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const grecaptcha = (window as any).grecaptcha

    const params = {
      badge: "inline",
      size: "invisible",
      sitekey: clientId.current,
      ...(container?.parameters ?? {}),
    }
    clientId.current = grecaptcha.render(container?.element, params)
  }

  function onLoad(): void {
    if (!window || !(window as any).grecaptcha) {
      console.warn("No script available")
    } else {
      const grecaptcha = (window as any).grecaptcha
      grecaptcha.ready(() => {
        setGreCaptchaInstance(grecaptcha)
      })
    }
  }

  const executeRecaptcha: (action?: string) => Thenable = useCallback(
    (action?: string) => {
      if (greCaptchaInstance?.execute) {
        return greCaptchaInstance.execute(clientId.current, { action })
      } else {
        throw new Error("Google Recaptcha has not been loaded")
      }
    },
    [greCaptchaInstance, clientId]
  )

  const googleReCaptchaContextValue: IGoogleReCaptchaStore = useMemo(
    (): IGoogleReCaptchaStore => ({
      executeRecaptcha: greCaptchaInstance ? executeRecaptcha : undefined,
      container: container?.element,
    }),
    [executeRecaptcha, greCaptchaInstance, container?.element]
  )

  return <GoogleReCaptchaContext.Provider value={googleReCaptchaContextValue}>{children}</GoogleReCaptchaContext.Provider>
}

export interface IGoogleReCaptchaStore {
  executeRecaptcha?: (action?: string) => Thenable<string>
  container?: string | HTMLElement
}
