export function streamToBlob(response: Response): Promise<Blob | undefined> {
  if (response?.body) {
    const reader = response.body.getReader()
    const stream = new ReadableStream({
      start(controller) {
        const pump = (): Promise<void> =>
          reader.read().then(({ done, value }) => {
            if (done) {
              controller.close()
              return
            }
            controller.enqueue(value)
            // eslint-disable-next-line consistent-return
            return pump()
          })
        return pump()
      },
    })
    const r = new Response(stream)
    return r.blob()
  }
  return Promise.resolve(undefined)
}
