import { adminPagesUrl } from "../../core/appConstants"
import { MenuItemType } from "../../core/type/MenuItemType"

export const adminMenu: MenuItemType[] = [
  {
    id: "summary",
    label: "Statistiques",
    link: adminPagesUrl.ADMIN_SUMMARY,
    isForUltimateUser: true,
    isForMegaUser: false,
    isForSuperUser: false,
    isForNormalUser: false,
    isForTestUser: false,
    tooltip: "Récapitulatif",
    isActive: true,
  },
  {
    id: "testUserInformation",
    label: "Testeurs",
    link: adminPagesUrl.ADMIN_INVITED_USERS_LIST_PAGE,
    isForUltimateUser: true,
    isForMegaUser: false,
    isForSuperUser: false,
    isForNormalUser: false,
    isForTestUser: false,
    tooltip: "Liste des testeurs",
    isActive: true,
  },
  {
    id: "organization",
    label: "Organisations",
    link: adminPagesUrl.ADMIN_ORGANIZATION_LIST_PAGE,
    isForUltimateUser: true,
    isForMegaUser: false,
    isForSuperUser: false,
    isForNormalUser: false,
    isForTestUser: false,
    tooltip: "Liste des Organisations",
    isActive: true,
  },
]
