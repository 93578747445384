import { Typography } from "@mui/material"
import React from "react"

export interface ITextInputProps {
  label: string
  fontWeight?: number
  color?: string
  fontSize?: string
  lineHeight?: string
  textDecoration?: "none" | "underline"
  textTransfrom?: "none"
  textAlign?: "left" | "center" | "right"
}

/**
 * Default component to display text
 *
 * @param  {label} text to display
 * @param  {fontWeight} default wheight = 600
 * @param  {color} default color = '#000'
 * @param  {fontSize} default fontSize = '16px'
 * @param  {lineHeight} default lineHeight = '24px'
 * @param  {textDecoration} default lineHeight = 'none'
 */

export function DefaultTypography({
  label,
  fontWeight = 600,
  color = "#000",
  fontSize = "16px",
  lineHeight = "24px",
  textDecoration = "none",
  textTransfrom = "none",
  textAlign = "left",
}: Readonly<ITextInputProps>): React.JSX.Element {
  return (
    <Typography
      textTransform={textTransfrom}
      sx={{
        fontFamily: "Poppins",
        fontSize,
        fontWeight,
        lineHeight,
        textAlign,
        color,
        textDecoration,
      }}>
      {label}
    </Typography>
  )
}
