import { Avatar, Box, Button, CircularProgress, Grid, Typography } from "@mui/material"

import React, { useContext, useState } from "react"
import { Link, useNavigate } from "react-router-dom"

import { ProjectCardList } from "../../../components/project-card-list/project-card-list"
import { pagesUrl } from "../../../core/appConstants"
import MaterialLibOrganizationIndicatorContextProvider from "../../../core/context/material/material-lib-organization-indicator-context"
import { OrganizationContext } from "../../../core/context/organization/organization-context"
import { ProjectContext, ProjectStore } from "../../../core/context/project/project-context"
import { UserContext, UserStore } from "../../../core/context/user/user-context"
import { ProjectTypeEnum } from "../../../core/enum/project/projectTypeEnum"
import { ProjectStatusEnum } from "../../../core/enum/projectStatusEnum"

import { MegaUserIndicatorProjectHome } from "../../../components/indicators/mega-user-indicators-project-home/mega-user-indicator-project-home"
import { TestUserIndicatorProjectHome } from "../../../components/indicators/test-user-indicators-project-home/test-user-indicators-project-home"
import { ProjectTypeContext } from "../../../core/context/project/project-type-context"
import { hasSuperUserRights, isMegaUser, isTestUser } from "../../../core/services/authentication-service"
import { isNumber } from "../../../core/services/helper-service"
import { resolveUrl } from "../../../core/services/http-service"
import ProjectStatusSelectButtons from "./components/project-status-select-buttons"
import ProjectTypeSelectButtons from "./components/project-type-select-buttons"
import MaxProjectModal from "./max-project-modal"

export function ProjectHomePage(): React.JSX.Element {
  const navigate = useNavigate()

  const { user } = useContext<UserStore>(UserContext)
  const { organization } = useContext(OrganizationContext)
  const { setProject } = useContext<ProjectStore>(ProjectContext)
  const { currentProjectType, projectTypeLoaded, setCurrentProjectType } = useContext(ProjectTypeContext)
  const [projectStatus, setProjectStatus] = useState<ProjectStatusEnum>(ProjectStatusEnum.IN_PROGRESS)
  const [open, setOpen] = useState<boolean>(false)

  function handleCreateProject(): void {
    if (
      !organization ||
      !isNumber(organization.maxProjectCount) ||
      !isNumber(organization.projectCounter) ||
      organization.maxProjectCount === undefined ||
      organization.projectCounter === undefined
    ) {
      setOpen(true)
    } else if (organization.maxProjectCount === -1 || organization.projectCounter < organization.maxProjectCount) {
      navigate(`${pagesUrl.PROJECT_PAGE}/new/info`)
    } else {
      setOpen(true)
    }
  }

  function updateProjectType(newProjectType: ProjectTypeEnum): void {
    setCurrentProjectType(newProjectType)
    setProjectStatus(ProjectStatusEnum.IN_PROGRESS)
    navigate(resolveUrl(pagesUrl.PROJECTS_PAGE, [], { type: newProjectType }))
  }

  return (
    <Grid container rowGap={2} sx={{ pr: 4, pl: 4 }} alignItems="center">
      <Grid item xs={10} display="flex" flexDirection="row">
        <Avatar sx={{ backgroundColor: "#f09c9c", mr: 2, mt: 0.5 }} alt={`${user?.givenName} ${user?.familyName}`}>
          {`${user?.givenName} ${user?.familyName}`.charAt(0).toLocaleUpperCase()}
        </Avatar>
        <Box>
          <Typography>Bonjour,</Typography>
          <Typography fontWeight="bold">{`${user?.givenName} ${user?.familyName}`}</Typography>
        </Box>
      </Grid>

      <Grid item xs={2} display="flex" justifyContent="flex-end">
        {hasSuperUserRights(user) &&
          (currentProjectType === ProjectTypeEnum.BIM || currentProjectType === ProjectTypeEnum.BIM_ORGANIZATION) && (
            <Button variant="contained" onClick={handleCreateProject}>
              Créer un projet
            </Button>
          )}
        {(currentProjectType === ProjectTypeEnum.RSEE || currentProjectType === ProjectTypeEnum.RSEE_ORGANIZATION) && (
          <Link to={pagesUrl.RSEE_PROJECT_NEW}>
            <Button variant="contained">Importer RSEE</Button>
          </Link>
        )}
      </Grid>
      <Grid item xs={12}>
        {isMegaUser(user) && (
          <MaterialLibOrganizationIndicatorContextProvider>
            {currentProjectType ? <MegaUserIndicatorProjectHome projectType={currentProjectType} /> : <CircularProgress />}
          </MaterialLibOrganizationIndicatorContextProvider>
        )}
        {isTestUser(user) && <TestUserIndicatorProjectHome />}
      </Grid>
      <Grid item xs={12}>
        {currentProjectType ? (
          <ProjectTypeSelectButtons currentType={currentProjectType} updateCurrentType={updateProjectType} />
        ) : (
          <CircularProgress />
        )}
      </Grid>
      <Grid item xs={12}>
        <ProjectStatusSelectButtons currentStatus={projectStatus} setCurrentStatus={setProjectStatus} />
      </Grid>
      <Grid item xs={12}>
        <Grid container columnSpacing={3}>
          {currentProjectType && (
            <ProjectCardList
              currentProjectType={currentProjectType}
              projectTypeUpToDate={projectTypeLoaded}
              currentStatus={projectStatus}
            />
          )}
        </Grid>
      </Grid>
      <MaxProjectModal open={open} setOpen={setOpen} />
    </Grid>
  )
}
