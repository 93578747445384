import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { Box, debounce, Grid, IconButton, Modal } from "@mui/material"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { DeclarationTypeEnum } from "../../../core/enum/declarationTypeEnum"
import { MaterialLibPage } from "../../../core/enum/materialLibPage"
import { useFetchMaterialDeclarationFilter } from "../../../core/hooks/material-lib/use-fetch-material-lib-declaration-filter"
import { useIniesRecordsFiltered } from "../../../core/hooks/material-lib/use-inies-records-filtered"
import MaterialPageTable from "../material-page-table"
import MaterialPageSearch from "./material-page-modal-search"
// eslint-disable-next-line import/no-cycle
import ImportIniesSection from "./import-inies-section"
// eslint-disable-next-line import/no-cycle
import MaterialPageFilterFormModal from "./material-page-filter-form-modal"

import MaterialRecordCreation from "../../../core/dto/material/material-record-creation"
import { DeclarationFilter } from "../../../core/enum/declarationFilter"
import { useOrganizationRecordsFiltered } from "../../../core/hooks/material-lib/use-organisation-records-filtered"
import { useMaterial } from "../../../core/hooks/use-material"
import { SuccessContext } from "../../layout/success-snackbar"
import { MaterialCardModal } from "../components/material-card-modal"
import ImportFicheConfiguree, { successfullAddFicheConfiguree } from "./import-fiche-configuree"
import "./material-page-modal.css"
import { IniesRecord } from "../../../core/dto/material/IniesRecord"
import { MaterialRecord } from "../../../core/dto/material/MaterialRecord"

type MaterialPageModalAddIniesProps = {
  handleClose: () => void
  typeMaterialLib: MaterialLibPage
  setMaterialCreation: React.Dispatch<React.SetStateAction<MaterialRecordCreation>> | undefined
  materialCreation: MaterialRecordCreation | undefined
}

const style = {
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 7,
  pt: 3,
  pb: 1,
  display: "block",
  overflowX: "hidden",
}

export default function MaterialPageModal({
  handleClose,
  typeMaterialLib,
  setMaterialCreation,
  materialCreation,
}: Readonly<MaterialPageModalAddIniesProps>): React.JSX.Element {
  const { sendFicheConfiguree } = useMaterial()

  const { projectId } = useParams()

  const openSuccessSnackbar: (message: string) => void = useContext(SuccessContext)

  const [selectedLvl0, setSelectedLvl0] = useState<DeclarationFilter | undefined>(undefined)

  const { iniesData: nomenclatureFilterData } = useFetchMaterialDeclarationFilter()

  const {
    search: searchInies,
    iniesRecords,
    reset: resetInies,
    limit: limitInies,
    setLimit: setLimitInies,
  } = useIniesRecordsFiltered()
  const {
    search: searchOrganization,
    materialRecords: organizationsModalRecords,
    reset: resetMaterial,
    limit: limitOrganization,
    setLimit: setLimitOrganization,
  } = useOrganizationRecordsFiltered()

  const [file, setFile] = useState<File | undefined>()
  const [isSending, setIsSending] = useState<boolean>(false)

  const [selectedCategory, setSelectedCategory] = useState<string>()
  const [selectedSubCategory, setSelectedSubCategory] = useState<string>()
  const [selectedSubSubCategory, setSelectedSubSubCategory] = useState<string>()
  const [selectedMaterial, setSelectedMaterial] = useState<string>()
  const [selectedOrganism, setSelectedOrganism] = useState<string>()
  const [selectedTypologieDeclaration, setSelectedTypologieDeclaration] = useState<string>()
  const [selectedRow, setSelectedRow] = useState<IniesRecord | MaterialRecord | undefined>(undefined)

  const [searchState, setSearchState] = useState<string | undefined>(undefined)
  const [tab, setTab] = useState<1 | 2>(1) // tab 1=inies or 2=organization

  const [openCardModal, setOpenCardModal] = useState(false)
  const [openFicheeConfigureeModal, setOpenFicheeConfigureeModal] = useState(false)

  const [lastModalMaterial, setLastModalMaterial] = useState<string | undefined>(undefined)
  const [lastPrevModalMaterial, setLastPrevModalMaterial] = useState<string[]>([])

  const [modalPageMaterial, setModalPageMaterial] = useState(0)

  const [isEditable, setIsEditable] = useState<boolean>(true)

  const location = useLocation()

  const getRowPerPage = useCallback(
    (): number => (tab === 1 ? limitInies : limitOrganization),
    [limitInies, limitOrganization, tab]
  )

  const setRowsPerPage = useCallback(
    (newLimit: number): void => {
      tab === 1 ? setLimitInies(newLimit) : setLimitOrganization(newLimit)
    },
    [setLimitInies, setLimitOrganization, tab]
  )

  useEffect(() => {
    if (location.pathname.endsWith("project-material-lib")) {
      setIsEditable(false)
    }
  }, [location.pathname])

  const handleSetSelectedRow = useCallback((row: IniesRecord | MaterialRecord | undefined) => {
    setSelectedRow(row)
    setOpenCardModal(true)
  }, [])

  const searchOrganizationNomenclatures = useCallback(
    (newSelectedLvl0: DeclarationFilter | undefined, containedInName?: string, lastFdesName?: string) =>
      searchOrganization({
        filter: {
          responsibleOrganism: selectedOrganism ?? undefined,
          declarationType: selectedTypologieDeclaration ?? undefined,
          classificationLvl0: newSelectedLvl0,
          classificationLvl1: Number(selectedCategory) || undefined,
          classificationLvl2: Number(selectedSubCategory) || undefined,
          classificationLvl3: Number(selectedMaterial) || undefined,
          classificationLvl4: Number(selectedMaterial) || undefined,
          containedInName,
        },
        lastFdesName,
      }).then((record: MaterialRecord[]) => {
        if (record?.length) {
          setLastModalMaterial(record[record.length - 1].fdesName)
        }
      }),
    [
      searchOrganization,
      selectedOrganism,
      selectedTypologieDeclaration,
      selectedCategory,
      selectedSubCategory,
      selectedMaterial,
    ]
  )

  const searchIniesNomenclatures = useCallback(
    (newSelectedLvl0: DeclarationFilter | undefined, containedInName?: string, lastFdesName?: string) =>
      searchInies({
        filter: {
          responsibleOrganism: selectedOrganism ?? undefined,
          declarationType: selectedTypologieDeclaration ?? undefined,
          classificationLvl0: newSelectedLvl0,
          classificationLvl1: Number(selectedCategory ?? undefined),
          classificationLvl2: Number(selectedSubCategory ?? undefined),
          classificationLvl3: Number(selectedMaterial ?? undefined),
          classificationLvl4: Number(selectedSubSubCategory ?? undefined),
          containedInName,
        },
        lastFdesName,
      }).then((record: IniesRecord[]) => {
        if (record?.length) {
          setLastModalMaterial(record[record.length - 1].fdesName)
        }
      }),
    [
      searchInies,
      selectedOrganism,
      selectedTypologieDeclaration,
      selectedCategory,
      selectedSubCategory,
      selectedMaterial,
      selectedSubSubCategory,
      // limitInies,
      // limitOrganization,
    ]
  )

  useEffect(() => {
    // It's these use effect that triggers a search when changing a filter or rowsPerPage
    resetMaterial()
    resetInies()
    setSearchState(undefined)

    if (tab === 1) {
      searchIniesNomenclatures(selectedLvl0)
    } else if (tab === 2) {
      searchOrganizationNomenclatures(selectedLvl0)
    }
  }, [selectedLvl0, resetInies, resetMaterial, searchIniesNomenclatures, searchOrganizationNomenclatures, tab])

  const handleSearchModalOrganizationPaginated = useCallback(
    (newPage: number) => {
      if (newPage > modalPageMaterial) {
        searchOrganizationNomenclatures(selectedLvl0, searchState, lastModalMaterial).then(() => {
          if (lastModalMaterial) setLastPrevModalMaterial([...lastPrevModalMaterial, lastModalMaterial])
        })
      } else if (newPage <= modalPageMaterial) {
        lastPrevModalMaterial.pop()
        const newLastPrevInie = [...lastPrevModalMaterial]
        const lastFdesName = newPage === 0 ? undefined : newLastPrevInie[newLastPrevInie.length - 1]
        searchOrganizationNomenclatures(selectedLvl0, searchState, lastFdesName)
      }
    },
    [selectedLvl0, lastModalMaterial, lastPrevModalMaterial, modalPageMaterial, searchOrganizationNomenclatures, searchState]
  )
  const handleSearchModalIniesPaginated = useCallback(
    (newPage: number) => {
      if (newPage > modalPageMaterial) {
        searchIniesNomenclatures(selectedLvl0, searchState, lastModalMaterial).then(() => {
          if (lastModalMaterial) setLastPrevModalMaterial([...lastPrevModalMaterial, lastModalMaterial])
        })
      } else if (newPage <= modalPageMaterial) {
        lastPrevModalMaterial.pop()
        const newLastPrevInie = [...lastPrevModalMaterial]
        const lastFdesName = newPage === 0 ? undefined : newLastPrevInie[newLastPrevInie.length - 1]
        searchIniesNomenclatures(selectedLvl0, searchState, lastFdesName)
      }
    },
    [selectedLvl0, lastModalMaterial, lastPrevModalMaterial, modalPageMaterial, searchIniesNomenclatures, searchState]
  )

  const handleSearchModalPaginated = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      resetInies()
      resetMaterial()

      if (tab === 1) {
        handleSearchModalIniesPaginated(newPage)
      } else if (tab === 2) {
        handleSearchModalOrganizationPaginated(newPage)
      }
      setModalPageMaterial(newPage)
    },
    [resetInies, resetMaterial, tab, handleSearchModalIniesPaginated, handleSearchModalOrganizationPaginated]
  )

  const handleSearchDelayed = useMemo(
    () =>
      debounce((e: string) => {
        setSearchState((prev) => e || prev)
        setModalPageMaterial(0)

        if (tab === 1) {
          searchIniesNomenclatures(selectedLvl0, e)
        } else {
          searchOrganizationNomenclatures(selectedLvl0, e)
        }
      }, 700),
    [selectedLvl0, searchIniesNomenclatures, searchOrganizationNomenclatures, tab]
  )

  const handleClickInieOrMaterial = useCallback(
    (v: 1 | 2): void => {
      setTab(v)
      setModalPageMaterial(0)
      setSelectedRow(undefined)
      setSearchState(undefined)
      if (v === 1) {
        searchIniesNomenclatures(selectedLvl0)
      } else {
        searchOrganizationNomenclatures(selectedLvl0)
      }
    },
    [selectedLvl0, searchIniesNomenclatures, searchOrganizationNomenclatures]
  )

  const handleChangeSelectedLvl0 = (selected: DeclarationFilter | undefined): void => {
    setSelectedLvl0(selected)
    resetInies()
    resetMaterial()
    if (tab === 1) {
      searchIniesNomenclatures(selected)
    } else if (tab === 2) {
      searchOrganizationNomenclatures(selected)
    }
  }

  const getTypologyBackground = useCallback((typologie: DeclarationTypeEnum): string => {
    switch (typologie) {
      case DeclarationTypeEnum.COLLECTIVE:
        return "#D2691E"
      case DeclarationTypeEnum.FORFAITAIRE:
        return "#20B2AA"
      case DeclarationTypeEnum.INDIVIDUELLE:
        return "#FF7F50"
      case DeclarationTypeEnum.FICHE_CONFIGUREE:
        return "#92D050"
      default:
        return "#4169E1"
    }
  }, [])

  function reset(): void {
    resetMaterial()
    resetInies()
  }

  function getRecordsForModal(): IniesRecord[] | MaterialRecord[] {
    if (tab === 1) {
      return iniesRecords
    } else if (tab === 2) {
      return organizationsModalRecords
    }
    return []
  }

  function importFicheConfiguree(): void {
    setOpenFicheeConfigureeModal(true)
  }

  function handleCloseImportFicheConfigureeModal(): void {
    setOpenFicheeConfigureeModal(false)
  }

  function handleCloseMaterialCardModal(): void {
    setOpenCardModal(false)
  }

  function sendFile(): void {
    if (file && projectId) {
      setIsSending(true)
      sendFicheConfiguree(file, projectId)
        .then(() => {
          handleCloseImportFicheConfigureeModal()
          openSuccessSnackbar(successfullAddFicheConfiguree)
        })
        .finally(() => {
          setIsSending(false)
        })
    }
  }

  return (
    <Box>
      <Box
        sx={{
          margin: "8px 0",
        }}>
        <Box display="flex">
          <IconButton sx={{ marginLeft: "auto" }} onClick={handleClose}>
            <CloseOutlinedIcon />
          </IconButton>
        </Box>
        <Grid container columnSpacing={12}>
          <Grid item xs={4} position="relative">
            <ImportIniesSection
              tab={tab}
              handleClick={handleClickInieOrMaterial}
              typeMaterialLib={typeMaterialLib}
              importFicheConfiguree={importFicheConfiguree}
            />
            <MaterialPageFilterFormModal
              selectedLvl0={selectedLvl0}
              setSelectedLvl0={handleChangeSelectedLvl0}
              nomenclatureFilterData={nomenclatureFilterData}
              selectedLvl1={selectedCategory}
              setSelectedLvl1={setSelectedCategory}
              selectedLvl2={selectedSubCategory}
              setSelectedLvl2={setSelectedSubCategory}
              selectedLvl3={selectedSubSubCategory}
              setSelectedLvl3={setSelectedSubSubCategory}
              selectedLvl4={selectedMaterial}
              setSelectedLvl4={setSelectedMaterial}
              selectedOrganism={selectedOrganism}
              setSelectedOrganism={setSelectedOrganism}
              selectedTypologieDeclaration={selectedTypologieDeclaration}
              setSelectedSelectedTypologieDeclaration={setSelectedTypologieDeclaration}
              tab={tab}
            />
          </Grid>
          <Grid item xs={8}>
            <MaterialPageSearch handleSearchDelayed={handleSearchDelayed} search={searchState} setSearch={setSearchState} />
            <MaterialPageTable
              handleSetSelectedRow={handleSetSelectedRow}
              getTypologyBackground={getTypologyBackground}
              records={getRecordsForModal()}
              page={modalPageMaterial}
              handleSearchPaginated={handleSearchModalPaginated}
              setPage={setModalPageMaterial}
              rowsPerPage={getRowPerPage()}
              setRowsPerPage={setRowsPerPage}
            />
            <MaterialCardModal
              openCard={openCardModal}
              selectedRow={selectedRow}
              reset={reset}
              setSelectedRow={setSelectedRow}
              getTypologyBackground={getTypologyBackground}
              isEditable={isEditable}
              isDeletable={false}
              setMaterialCreation={setMaterialCreation}
              materialCreation={materialCreation}
              isModalCard
              handleCloseMaterialCardModal={handleCloseMaterialCardModal}
              typeMaterialLib={typeMaterialLib}
              tab={tab}
            />
          </Grid>
        </Grid>
      </Box>

      <Modal
        sx={{
          margin: "40px 0",
        }}
        open={openFicheeConfigureeModal}
        onClose={() => handleCloseImportFicheConfigureeModal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box className="modal-import-inies" sx={style}>
          <ImportFicheConfiguree
            handleClose={handleCloseImportFicheConfigureeModal}
            sendFile={sendFile}
            isSending={isSending}
            file={file}
            setFile={setFile}
          />
        </Box>
      </Modal>
    </Box>
  )
}
