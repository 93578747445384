import { DeclarationTypeEnum } from "../../enum/declarationTypeEnum"
import { UnitEnum } from "../../enum/unitEnum"
import { stringToDate } from "../../services/date-service"

export class IniesRecord {
  id!: string
  iniesId!: number
  pep = false
  fdesName!: string
  description!: string
  responsibleOrganism!: string
  functionalUnit!: UnitEnum
  referenceLifeTime!: number
  moduleD!: number
  totalLifeCycleCarbonImpact!: number
  lastIniesUpdate?: Date
  declarationType!: DeclarationTypeEnum
  carbonBiogenic = 0
  re2020CarbonImpact: number | undefined
  expirationDate?: Date
  ttbStatus?: string

  static fromDto(iniesRecordDto: any): IniesRecord {
    const iniesRecord = new IniesRecord()
    iniesRecord.id = iniesRecordDto.id
    iniesRecord.iniesId = iniesRecordDto.iniesId
    iniesRecord.pep = iniesRecordDto.pep
    iniesRecord.fdesName = iniesRecordDto.fdesName
    iniesRecord.description = iniesRecordDto.description
    iniesRecord.responsibleOrganism = iniesRecordDto.responsibleOrganism
    iniesRecord.functionalUnit = iniesRecordDto.functionalUnit
    iniesRecord.referenceLifeTime = iniesRecordDto.referenceLifeTime
    iniesRecord.moduleD = iniesRecordDto.moduleD
    iniesRecord.totalLifeCycleCarbonImpact = iniesRecordDto.totalLifeCycleCarbonImpact
    iniesRecord.lastIniesUpdate = stringToDate(iniesRecordDto.lastIniesUpdate)
    iniesRecord.declarationType = iniesRecordDto.declarationType
    iniesRecord.carbonBiogenic = iniesRecordDto.carbonBiogenic
    iniesRecord.re2020CarbonImpact = iniesRecordDto.re2020CarbonImpact
    iniesRecord.expirationDate = stringToDate(iniesRecordDto.expirationDate)
    iniesRecord.ttbStatus = iniesRecordDto.ttbStatus

    return iniesRecord
  }
}
