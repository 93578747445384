import React, { Dispatch, SetStateAction, useMemo, useState } from "react"
import { Children } from "../../components/miscellianous/children"
import { IniesRecord } from "../dto/material/IniesRecord"

export const IniesRecordContext = React.createContext<IniesRecordStore>({} as IniesRecordStore)

export default function IniesRecordContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const [loading, setLoading] = useState<boolean>(true)
  const [iniesRecords, setIniesRecords] = useState<IniesRecord[]>([])
  const [limit, setLimit] = useState(5)

  const store: IniesRecordStore = useMemo(
    () => ({ iniesRecords, setIniesRecords, loading, setLoading, limit, setLimit }),
    [loading, iniesRecords, limit]
  )
  return <IniesRecordContext.Provider value={store}>{children}</IniesRecordContext.Provider>
}

export type IniesRecordStore = {
  iniesRecords: IniesRecord[]
  setIniesRecords: Dispatch<SetStateAction<IniesRecord[]>>
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  limit: number
  setLimit: Dispatch<SetStateAction<number>>
}
