import { Quantity } from "../quantity"
import { CodeExtrait } from "./code-extrait"

export class CodeExtraitUpdate {
  id = ""
  color = ""
  overiddenQuantities: Quantity = new Quantity()
  amountVariant1 = 0
  amountVariant2 = 0
  materialIdVariant1: string | undefined
  materialIdVariant2: string | undefined
  reuseVariant1 = false
  reuseVariant2 = false
  descriptionBe: string | undefined
  filled = false
  sameValuesV1V2 = true
  hypothesisVariant1: string | undefined
  hypothesisVariant2: string | undefined
  awsFileKey1: string | undefined
  awsFileKey2: string | undefined
  filename1: string | undefined
  filename2: string | undefined

  static fromCodeExtrait(codeExtrait: CodeExtrait): CodeExtraitUpdate {
    const codeExtraitUpdate = new CodeExtraitUpdate()
    if (codeExtrait.id) {
      codeExtraitUpdate.id = codeExtrait.id
    }
    codeExtraitUpdate.color = codeExtrait.color
    codeExtraitUpdate.overiddenQuantities = codeExtrait.overiddenQuantities
    codeExtraitUpdate.sameValuesV1V2 = codeExtrait.sameValuesV1V2
    if (codeExtrait.amountVariant1 !== undefined) {
      codeExtraitUpdate.amountVariant1 = codeExtrait.amountVariant1
    }
    if (codeExtrait.amountVariant2 !== undefined) {
      codeExtraitUpdate.amountVariant2 = codeExtrait.amountVariant2
    }
    codeExtraitUpdate.materialIdVariant1 = codeExtrait.materialIdVariant1
    codeExtraitUpdate.materialIdVariant2 = codeExtrait.materialIdVariant2
    codeExtraitUpdate.reuseVariant1 = codeExtrait.reuseVariant1
    codeExtraitUpdate.reuseVariant2 = codeExtrait.reuseVariant2
    codeExtraitUpdate.descriptionBe = codeExtrait.descriptionBe
    codeExtraitUpdate.filled = codeExtrait.filled
    codeExtraitUpdate.hypothesisVariant1 = codeExtrait.hypothesisVariant1 ?? ""
    codeExtraitUpdate.hypothesisVariant2 = codeExtrait.hypothesisVariant2 ?? ""
    codeExtraitUpdate.filename1 = codeExtrait.filename1
    codeExtraitUpdate.filename2 = codeExtrait.filename2
    codeExtraitUpdate.awsFileKey1 = codeExtrait.awsFileKey1
    codeExtraitUpdate.awsFileKey2 = codeExtrait.awsFileKey2

    return codeExtraitUpdate
  }
}
