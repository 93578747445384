import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react"
import ViewerIFC from "../../../components/ifc-displayer/ViewerIFC"
import { Children } from "../../../components/miscellianous/children"
import { CodeExtrait } from "../../dto/code-extrait/code-extrait"

export const BimModelImportContext = React.createContext<BimModelImportContextStore>({} as BimModelImportContextStore)

export function BimModelImportContextProvider({ children }: Children): React.JSX.Element {
  /** all types required by the app with all their element ids */
  const [typesToElementIdsMap, setTypesToElementIdsMap] = useState<Map<number, number[]>>(new Map())
  const [viewer, setViewer] = useState<ViewerIFC | undefined>(undefined)
  const [codesExtraits, setCodesExtraits] = useState<CodeExtrait[]>([])

  const store: BimModelImportContextStore = useMemo(
    () => ({
      typesToElementIdsMap,
      setTypesToElementIdsMap,
      viewer,
      setViewer,
      codesExtraits,
      setCodesExtraits,
    }),
    [codesExtraits, typesToElementIdsMap, viewer]
  )

  useEffect(() => {
    if (codesExtraits.length && viewer) {
      viewer?.manager.subsetsManager.setCodeColors(viewer.modelId ?? 0, codesExtraits, typesToElementIdsMap)
    }
  }, [codesExtraits, typesToElementIdsMap, viewer])

  return <BimModelImportContext.Provider value={store}>{children}</BimModelImportContext.Provider>
}

export type BimModelImportContextStore = {
  typesToElementIdsMap: Map<number, number[]>
  setTypesToElementIdsMap: Dispatch<SetStateAction<Map<number, number[]>>>
  viewer: ViewerIFC | undefined
  setViewer: Dispatch<SetStateAction<ViewerIFC | undefined>>
  codesExtraits: CodeExtrait[]
  setCodesExtraits: Dispatch<SetStateAction<CodeExtrait[]>>
}
