import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export function IconRseeWarningImport(props: SvgIconProps): React.JSX.Element {
  return (
    <SvgIcon {...props}>
      {/*  SVG from https://tablericons.com/ */}
      <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
          fill="#FFF4E5"
        />
        <g clipPath="url(#clip0_900_107481)">
          <path d="M16 13.5V16.8333" stroke="#FF2E2E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M14.6357 8.99298L7.88074 20.2713C7.74149 20.5125 7.6678 20.7859 
            7.667 21.0644C7.6662 21.3428 7.73832 21.6166 7.87619 21.8586C8.01407
             22.1005 8.21288 22.3022 8.45286 22.4434C8.69284 22.5847 8.96562 22.6607
              9.24407 22.6638H22.7557C23.0341 22.6606 23.3067 22.5846 23.5466 22.4434C23.7864
               22.3022 23.9852 22.1006 24.123 21.8588C24.2609 21.617 24.333 21.3433 24.3323
                21.065C24.3316 20.7866 24.2581 20.5133 24.1191 20.2721L17.3641 8.99215C17.222
                 8.75757 17.0217 8.5636 16.7828 8.42898C16.5438 8.29436 16.2742 8.22363 15.9999
                  8.22363C15.7256 8.22363 15.456 8.29436 15.217 8.42898C14.9781 8.5636 14.7779 8.75757 14.6357 8.99215V8.99298Z"
            stroke="#FF2E2E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M16 19.333H16.0087" stroke="#FF2E2E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_900_107481">
            <rect width="24" height="24" fill="white" transform="translate(6 6)" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}
