import { useCallback, useContext, useMemo } from "react"
import ViewerIFC from "../../../components/ifc-displayer/ViewerIFC"
import { ErrorContext } from "../../../components/layout/error-snackbar"
import { BimModelImportContext, BimModelImportContextStore } from "../../context/bim-model/bim-model-import-context"
import { CodeReferenceContext, CodeReferenceStore } from "../../context/code-acv/CodeReferenceContext"
import CodeReference from "../../dto/code-acv/code-reference"
import { CodeExtrait } from "../../dto/code-extrait/code-extrait"
import PsetAcv from "../../dto/pset-acv"
import { CodesVersion } from "../../enum/codesVersionEnum"
import { parseBim, ParsedValues } from "../../services/bim-parser-service"
import { sortCodeAcv } from "../../services/code-service"

type BimModelImportHook = {
  analyzeModel(modelId: number, ifc: ViewerIFC): Promise<void>
}

type IProps = {
  version: CodesVersion
  setProgress(progress: number): void
}

export function useParseBim({ version, setProgress }: IProps): BimModelImportHook {
  const { codeAcvExtraitPerVersion } = useContext<CodeReferenceStore>(CodeReferenceContext)
  const { setTypesToElementIdsMap, setViewer, setCodesExtraits } =
    useContext<BimModelImportContextStore>(BimModelImportContext)
  const openErrorSnackbar = useContext(ErrorContext)

  function mapToArray(hashMap: Map<string, CodeExtrait>): CodeExtrait[] {
    return Array.from(hashMap.values()).sort(sortCodeAcv)
  }

  const getCodeAcvExtrait = useCallback(
    (codeExtrait: string): CodeReference | undefined => {
      const codeAcvMap: Map<string, CodeReference> | undefined = codeAcvExtraitPerVersion.get(version)
      if (codeAcvMap === undefined) {
        return undefined
      } else {
        return codeAcvMap.get(codeExtrait)
      }
    },
    [codeAcvExtraitPerVersion, version]
  )

  const addPsetToCodeExtraits = useCallback(
    (psetAcv: PsetAcv, hashMap: Map<string, CodeExtrait>): void => {
      const key = psetAcv.codeAcv + psetAcv.occurrence
      const existingCodeExtrait: CodeExtrait | undefined = hashMap.get(key)

      if (existingCodeExtrait === undefined) {
        const codeAcv: CodeReference | undefined = getCodeAcvExtrait(psetAcv.codeAcv)
        let codeExtrait
        if (codeAcv === undefined || psetAcv.occurrence === undefined || psetAcv.occurrence === "") {
          codeExtrait = CodeExtrait.newInvalidCodeExtrait(psetAcv)
          codeExtrait.errors.push(`Code ACV incorrect: [${psetAcv.codeAcv}]`)
        } else {
          codeExtrait = CodeExtrait.from(codeAcv, psetAcv)
        }
        hashMap.set(key, codeExtrait)
      } else {
        CodeExtrait.addPsetInformationInformation(existingCodeExtrait, psetAcv)
      }
    },
    [getCodeAcvExtrait]
  )

  const groupPsetAcv = useCallback(
    (extractedPsetAcvs: PsetAcv[]): CodeExtrait[] => {
      console.info("groupACV")
      const hashMap = new Map<string, CodeExtrait>()

      extractedPsetAcvs.forEach((psetAcv) => {
        addPsetToCodeExtraits(psetAcv, hashMap)
      })
      return mapToArray(hashMap)
    },
    [addPsetToCodeExtraits]
  )

  const average = useCallback((total: number | undefined, itemNumber: number | undefined): number | undefined => {
    if (total === undefined) {
      return undefined
    } else if (itemNumber === undefined) {
      return undefined
    } else if (itemNumber === 0) {
      return total
    } else {
      return total / itemNumber
    }
  }, [])

  const averageQuantitiesWhenNeeded = useCallback(
    (list: CodeExtrait[]): void => {
      list.forEach((codeExtrait) => {
        // Average length
        if (codeExtrait.code === "FIN_REV_SOL_DUR") {
          codeExtrait.extractedQuantities.length = average(
            codeExtrait.extractedQuantities.length,
            codeExtrait.extractedQuantities.number
          )
        }

        // Average height
        if (codeExtrait.code === "FIN_REV_SOL_DUR") {
          codeExtrait.extractedQuantities.height = average(
            codeExtrait.extractedQuantities.height,
            codeExtrait.extractedQuantities.number
          )
        }
      })
    },
    [average]
  )

  return useMemo(
    () => ({
      analyzeModel(modelId: number, ifc: ViewerIFC): Promise<void> {
        console.info("Analyse du modèle. Version:", version)
        if (!ifc || modelId === undefined) {
          console.error("ifcManager is undefined", ifc)
          console.error("or modelId", modelId)
          return Promise.resolve()
        }
        const ifcManager = ifc.viewer.IFC.loader.ifcManager
        setViewer(ifc)
        return parseBim(modelId, ifcManager, setProgress)
          .then((parsed: ParsedValues) => {
            setTypesToElementIdsMap(parsed.typesToElementIdsMap)
            const psetAcvs = parsed.psetAcvs
            let codeExtraits: CodeExtrait[]
            if (psetAcvs.length === 0) {
              openErrorSnackbar(new Error("Aucun code ACV valide dans cette maquette"))
              setProgress(100)
            } else {
              codeExtraits = groupPsetAcv(psetAcvs)
              setProgress(95)
              averageQuantitiesWhenNeeded(codeExtraits)
              setCodesExtraits(codeExtraits)
            }
          })
          .then(() => {
            console.info("End of parsing")
          })
      },
    }),
    [
      averageQuantitiesWhenNeeded,
      groupPsetAcv,
      openErrorSnackbar,
      setCodesExtraits,
      setProgress,
      setTypesToElementIdsMap,
      setViewer,
      version,
    ]
  )
}
