/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export function IconFileTypeXml({ width, height, ...props }: SvgIconProps): React.JSX.Element {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" sx={{ height, width }}>
      {/*  SVG from https://tablericons.com/ */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-file-type-xml"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="#2c3e50"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M14 3v4a1 1 0 0 0 1 1h4" />
        <path d="M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4" />
        <path d="M4 15l4 6" />
        <path d="M4 21l4 -6" />
        <path d="M19 15v6h3" />
        <path d="M11 21v-6l2.5 3l2.5 -3v6" />
      </svg>
    </SvgIcon>
  )
}
