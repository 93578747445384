import CalculateIcon from "@mui/icons-material/Calculate"
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify"
import GroupsIcon from "@mui/icons-material/Groups"
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks"
import SettingsIcon from "@mui/icons-material/Settings"
import ViewInArIcon from "@mui/icons-material/ViewInAr"
import { CircularProgress, Tooltip } from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import React, { useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { customPalette } from "../../../../theme"
import WarningDialog from "../../../components/dialog/warning-dialog"

const renderIcon = (id: string): React.JSX.Element | null => {
  switch (id) {
    case "generale":
      return <SettingsIcon sx={{ fontSize: "58px", color: "white" }} />
    case "team":
      return <GroupsIcon sx={{ fontSize: "58px", color: "white" }} />
    case "materiautheque":
      return <FormatAlignJustifyIcon sx={{ fontSize: "58px", color: "white" }} />
    case "model":
      return <ViewInArIcon sx={{ fontSize: "58px", color: "white" }} />
    case "calculate":
      return <CalculateIcon sx={{ fontSize: "58px", color: "white" }} />
    case "synthesis":
      return <LibraryBooksIcon sx={{ fontSize: "58px", color: "white" }} />
    default:
      return null
  }
}

type WarningDialogContent = {
  msg: string
  title: string
}

type IProps = {
  title: string
  iconId: string
  pageUrl: string
  disabled?: boolean
  tooltipTitle?: string
  isReadingMode?: boolean
  isWritingMode?: boolean
  isError?: boolean
  isReady?: boolean
  warningDialogContent?: WarningDialogContent
}

export default function CardSuccessItem({
  title,
  iconId,
  pageUrl,
  isWritingMode,
  isReadingMode,
  disabled,
  tooltipTitle,
  isError = false,
  isReady = true,
  warningDialogContent,
}: IProps): React.JSX.Element {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const isDisabled: boolean = useMemo(() => {
    if (disabled) {
      return true
    } else {
      return !isWritingMode && !isReadingMode
    }
  }, [disabled, isReadingMode, isWritingMode])

  function generateLink(): string {
    return isDisabled || isError ? "#" : pageUrl
  }

  function getLabel(): string {
    if (isWritingMode) {
      return "Gérer"
    } else if (isReadingMode) {
      return "Visualiser"
    } else {
      return "Accès restreint"
    }
  }

  function handleOpen(): void {
    if (!isDisabled) {
      setIsDialogOpen(true)
    }
  }

  return (
    <>
      <Tooltip title={isDisabled ? "" : tooltipTitle}>
        <Box>
          <Link
            to={generateLink()}
            style={{ textDecoration: "none", color: "inherit" }}
            onClick={warningDialogContent ? handleOpen : undefined}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              sx={{
                bgcolor: customPalette.lightGreen6,
                paddingX: 4,
                paddingY: 3,
                height: 180,
                borderRadius: 1.5,
                cursor: isDisabled ? "default" : "pointer",
              }}>
              {renderIcon(iconId)}
              <Typography color="white" sx={{ fontSize: "20px" }}>
                {title}
              </Typography>
            </Box>
          </Link>
          <Box display="flex" justifyContent="center" sx={{ py: "24px" }}>
            {isReady ? (
              <Link to={generateLink()}>
                <Button
                  sx={{ alignSelf: "center", width: 300 }}
                  disabled={isDisabled}
                  variant="contained"
                  onClick={warningDialogContent ? handleOpen : undefined}>
                  {getLabel()}
                </Button>
              </Link>
            ) : (
              <CircularProgress />
            )}
          </Box>
        </Box>
      </Tooltip>
      {warningDialogContent && (
        <WarningDialog
          title={warningDialogContent.title}
          contentText={warningDialogContent.msg}
          handleClose={() => setIsDialogOpen(false)}
          open={isDialogOpen}
        />
      )}
    </>
  )
}
