export enum BuildingFrameEnum {
  BOIS = "BOIS",
  BOIS_MASSIF = "BOIS_MASSIF",
  BETON = "BETON",
  ACIER = "ACIER",
}

export const buildingFrameEnumLabels = {
  [BuildingFrameEnum.BOIS]: "Bois",
  [BuildingFrameEnum.BOIS_MASSIF]: "Bois massif",
  [BuildingFrameEnum.BETON]: "Béton",
  [BuildingFrameEnum.ACIER]: "Acier",
}
