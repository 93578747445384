import { useMemo } from "react"
import { useHttp } from "./use-http"
import { InvitedUser } from "../dto/invited-user"
import { appConstants } from "../appConstants"

type InvitationHook = {
  fetchInvitation: (invitationId: string) => Promise<InvitedUser | undefined>
}

export function useInvitation(): InvitationHook {
  const { get } = useHttp()

  return useMemo(
    () => ({
      fetchInvitation(invitationId: string): Promise<InvitedUser | undefined> {
        return get(`${appConstants.apiEndpoints.USER}/invitation/${invitationId}`).then((response) => {
          if (response.status === 400) {
            return undefined
          } else {
            return response.json()
          }
        })
      },
    }),
    [get]
  )
}
