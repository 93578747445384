export enum ClimaticZoneEnum {
  H1a = "H1a",
  H1b = "H1b",
  H1c = "H1c",
  H2a = "H2a",
  H2b = "H2b",
  H2c = "H2c",
  H2d = "H2d",
  H3 = "H3",
}

export const climaticZones: ClimaticZoneEnum[] = [ClimaticZoneEnum.H1a]

export const climaticZoneEnumLabels = {
  [ClimaticZoneEnum.H1a]: "H1a",
  [ClimaticZoneEnum.H1b]: "H1b",
  [ClimaticZoneEnum.H1c]: "H1c",
  [ClimaticZoneEnum.H2a]: "H2a",
  [ClimaticZoneEnum.H2b]: "H2b",
  [ClimaticZoneEnum.H2c]: "H2c",
  [ClimaticZoneEnum.H2d]: "H2d",
  [ClimaticZoneEnum.H3]: "H3",
}
