import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import React, { ReactNode } from "react"
import { TitleFormTypography } from "../typography/title-form-typography"

interface IProps {
  title: string
  isOpen: boolean
  content: ReactNode

  handleClose(): void
}

export function InfoDialog({ title, isOpen, content, handleClose }: IProps): React.JSX.Element {
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <TitleFormTypography label={title} />
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Ok</Button>
      </DialogActions>
    </Dialog>
  )
}
