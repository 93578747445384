import { Download } from "@mui/icons-material"
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import InfoIcon from "@mui/icons-material/Info"
import LinkIcon from "@mui/icons-material/Link"
import SyncIcon from "@mui/icons-material/Sync"
import { Box, Button, CircularProgress, Grid, IconButton, Tooltip, Typography } from "@mui/material"
import React, { useContext, useState } from "react"
import { useParams } from "react-router-dom"
import { appConstants } from "../../../../../core/appConstants"
import { CalculationContext } from "../../../../../core/context/calculation/calculation-context"
import { CodesCompletionsContext } from "../../../../../core/context/code-acv/codes-completions-context"
import {
  CodesExtraitsListContext,
  CodesExtraitsListStore,
} from "../../../../../core/context/code-acv/codes-extraits-list-context"
import { SelectedPhaseContext } from "../../../../../core/context/selected-phase-context"
import { Material, materialStatusLabel, originMaterialRecordToLabel } from "../../../../../core/dto/material/material"
import { DeclarationTypeEnum } from "../../../../../core/enum/declarationTypeEnum"
import { FunctionalUnitEnum } from "../../../../../core/enum/functionalUnitEnum"
import { fromIniesId, toLabel } from "../../../../../core/enum/unitEnum"
import { useCode } from "../../../../../core/hooks/use-code"
import { useMaterial } from "../../../../../core/hooks/use-material"
import { formatToFrench } from "../../../../../core/services/date-service"
import { materialModalstyle } from "../material-modal-style"
import { TypologyBadge } from "./components/typology-badge"
import { IniesRecord } from "../../../../../core/dto/material/IniesRecord"
import { MaterialRecord } from "../../../../../core/dto/material/MaterialRecord"

type MaterialPageRightCardProps = {
  readonly selectedRow?: IniesRecord | MaterialRecord
  readonly material?: Material
  readonly isMaterial: boolean
  readonly handleAddEnvironmentalStatement?: (v: IniesRecord | MaterialRecord) => void
  readonly setDisplayMaterialModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  refreshSelectedCodeExtrait?: (materialDto: Material) => void
}

export function MaterialPageCard({
  selectedRow,
  material,
  isMaterial,
  handleAddEnvironmentalStatement,
  setDisplayMaterialModalOpen,
  refreshSelectedCodeExtrait,
}: MaterialPageRightCardProps): React.JSX.Element | null {
  const { getFicheConfigureeFile } = useMaterial()
  const { synchronizeProjectMaterial } = useCode()
  const { projectId } = useParams()
  const { selectedPhase } = useContext(SelectedPhaseContext)
  const { fetchCodesExtraits, setCodesExtraitsList } = useContext<CodesExtraitsListStore>(CodesExtraitsListContext)
  const [isSynchronizing, setIsSynchronizing] = useState<boolean>(false)
  const [actualMaterial, setActualMaterial] = useState<Material | undefined>(material)
  const { fetchCodeCompletionForVariant, setCodesCompletionsPerCodeParentV1, setCodesCompletionsPerCodeParentV2 } =
    useContext(CodesCompletionsContext)
  const { calculation } = useContext(CalculationContext)

  if (isMaterial && !actualMaterial) {
    return null
  }

  if (!isMaterial && !selectedRow) {
    return null
  }

  function getUrl(): string {
    return `${appConstants.web.baseInie}?id=${isMaterial ? actualMaterial?.iniesId : selectedRow?.iniesId}`
  }

  function getAllParameters(): string {
    if (isMaterial && actualMaterial) {
      let chaineConcatenee = `${actualMaterial.description || ""}`

      if (actualMaterial.parameters.length > 0) {
        chaineConcatenee += "<br/><br/><b> Paramètres : </b> <ul>"
        actualMaterial.parameters.forEach((chaine) => {
          chaineConcatenee += ` <li><b> ${chaine.name} : </b>  ${chaine.value} ${toLabel(
            fromIniesId(chaine.paramUnitId)
          )}  </li>
        `
          return chaine
        })
        chaineConcatenee += "</ul>"
      }
      return chaineConcatenee || ""
    } else if (!isMaterial && selectedRow instanceof MaterialRecord) {
      let chaineConcatenee = `${selectedRow?.description || ""} <br/><br/>`

      if (actualMaterial?.parameters) {
        chaineConcatenee += "<b> Paramètres : </b> <ul>"
        selectedRow.parameters.forEach((chaine) => {
          chaineConcatenee += ` <li><b> ${chaine.name} : </b>  ${chaine.value} ${toLabel(
            fromIniesId(chaine.paramUnitId)
          )}  </li>
        `
          return chaine
        })
        chaineConcatenee += "</ul>"
      }
      return chaineConcatenee || ""
    } else {
      return selectedRow?.description ?? ""
    }
  }

  function downloadFicheConfiguree(): void {
    if (selectedRow) {
      getFicheConfigureeFile(selectedRow.id).then((file) => {
        if (file) {
          const fileName = selectedRow.fdesName
          const element = document.createElement("a")
          element.href = URL.createObjectURL(file)
          element.download = `${fileName}.xml`
          element.click()
        }
      })
    } else if (actualMaterial) {
      getFicheConfigureeFile(actualMaterial.id).then((file) => {
        if (file) {
          const fileName = actualMaterial?.fdesName
          const element = document.createElement("a")
          element.href = URL.createObjectURL(file)
          element.download = `${fileName}.xml`
          element.click()
        }
      })
    }
  }

  function synchronizeMaterial(): void {
    if (isMaterial && actualMaterial?.iniesId && projectId && selectedPhase) {
      setIsSynchronizing(true)
      synchronizeProjectMaterial(projectId, actualMaterial?.id, selectedPhase)
        .then((materialDto: Material) => {
          fetchCodesExtraits(projectId, selectedPhase).then((codeExtraits) => {
            setCodesExtraitsList(codeExtraits.sort((a, b) => a.order.localeCompare(b.order)))
          })

          fetchCodeCompletionForVariant(projectId, calculation.variantId1).then((codesCompletions) =>
            setCodesCompletionsPerCodeParentV1(codesCompletions)
          )

          fetchCodeCompletionForVariant(projectId, calculation.variantId2).then((codesCompletions) =>
            setCodesCompletionsPerCodeParentV2(codesCompletions)
          )

          if (refreshSelectedCodeExtrait) {
            refreshSelectedCodeExtrait(materialDto)
          }
          setActualMaterial(materialDto)
        })
        .finally(() => setIsSynchronizing(false))
    }
  }

  function getFunctionalUnit(): FunctionalUnitEnum {
    return isMaterial
      ? FunctionalUnitEnum[actualMaterial?.functionalUnit as keyof typeof FunctionalUnitEnum]
      : FunctionalUnitEnum[selectedRow?.functionalUnit as keyof typeof FunctionalUnitEnum]
  }

  return isSynchronizing ? (
    <CircularProgress />
  ) : (
    <Box
      sx={{
        ...materialModalstyle,
        width: 1300,
        background: "#f6f6f6",
        display: "flex",
        flexDirection: "column",
        padding: "20px 30px 50px 30px",
      }}>
      <Box display="flex" sx={{ justifyContent: "flex-end" }}>
        <IconButton
          onClick={() => {
            setDisplayMaterialModalOpen(false)
          }}>
          <CloseOutlinedIcon />
        </IconButton>
      </Box>
      <Grid container sx={{ mb: 5 }}>
        <Grid item xs={1} sx={{ display: "flex", alignItems: "center" }}>
          {selectedRow?.declarationType === DeclarationTypeEnum.FICHE_CONFIGUREE ||
          actualMaterial?.declarationType === DeclarationTypeEnum.FICHE_CONFIGUREE ? (
            <IconButton onClick={downloadFicheConfiguree}>
              <Download />
            </IconButton>
          ) : (
            <>
              <IconButton href={getUrl()} target="_blank" rel="noopener noreferrer">
                <LinkIcon />
              </IconButton>
              {isMaterial && originMaterialRecordToLabel(actualMaterial).text === materialStatusLabel.OUTDATED && (
                <IconButton onClick={synchronizeMaterial}>
                  <SyncIcon />
                </IconButton>
              )}
            </>
          )}
        </Grid>
        <Grid item sx={{ display: "flex", alignItems: "center" }}>
          <TypologyBadge selectedRow={selectedRow} isMaterial={isMaterial} material={actualMaterial} />
        </Grid>
        <Grid item xs={8} sx={{ display: "flex", alignItems: "center", ml: "12px" }}>
          <Box display="flex" flexDirection="column">
            <Typography sx={{ fontWeight: "bold", overflow: "hidden", textOverflow: "ellipsis" }}>
              {isMaterial ? actualMaterial?.fdesName : selectedRow?.fdesName}
            </Typography>
            {isMaterial && (
              <Typography
                component="span"
                sx={{
                  fontWeight: "bold",

                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  color: actualMaterial instanceof Material ? originMaterialRecordToLabel(actualMaterial).color : "",
                }}>
                {`${actualMaterial instanceof Material ? `( ${originMaterialRecordToLabel(actualMaterial).text} )` : ""}`}
              </Typography>
            )}

            {!isMaterial && (
              <Typography
                component="span"
                sx={{
                  fontWeight: "bold",

                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  color: selectedRow instanceof MaterialRecord ? originMaterialRecordToLabel(selectedRow).color : "",
                }}>
                {`${selectedRow instanceof MaterialRecord ? `( ${originMaterialRecordToLabel(selectedRow).text} )` : ""}`}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={1} sx={{ display: "flex", justifyContent: "center" }}>
          <Tooltip
            title={
              <Typography variant="body2">
                {`Identifiant: `}
                {isMaterial &&
                selectedRow instanceof MaterialRecord &&
                selectedRow.declarationType === DeclarationTypeEnum.FICHE_CONFIGUREE
                  ? selectedRow.configuratorId
                  : selectedRow?.iniesId}
                {actualMaterial?.iniesId}
              </Typography>
            }
            placement="top">
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container rowSpacing={2} columnSpacing={3}>
        {/*FirstLine*/}
        <Grid item xs={1} />
        <Grid item xs={3}>
          <Typography sx={{ mb: 0.5 }}>Total Impact selon RE2020</Typography>
          <Typography variant="body2" sx={{ backgroundColor: "white", padding: "8px", minHeight: "2.5rem" }}>
            {isMaterial
              ? (actualMaterial?.re2020CarbonImpact ?? 0).toFixed(2)
              : (selectedRow?.re2020CarbonImpact ?? 0).toFixed(2)}
            kgeqCO<sub>2</sub>/{getFunctionalUnit()}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography sx={{ mb: 0.5 }}>Total du cycle de vie</Typography>
          <Typography variant="body2" sx={{ backgroundColor: "white", padding: "8px", minHeight: "2.5rem" }}>
            {isMaterial
              ? (actualMaterial?.totalLifeCycleCarbonImpact ?? 0).toFixed(1)
              : (selectedRow?.totalLifeCycleCarbonImpact ?? 0).toFixed(1)}{" "}
            kgeqCO<sub>2</sub>/{getFunctionalUnit()}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography sx={{ mb: 0.5 }}>Unité fonctionnelle</Typography>
          <Typography variant="body2" sx={{ backgroundColor: "white", padding: "8px", minHeight: "2.5rem" }}>
            {getFunctionalUnit()}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography sx={{ mb: 0.5 }}>Durée de Vie</Typography>
          <Typography variant="body2" sx={{ backgroundColor: "white", padding: "8px", minHeight: "2.5rem" }}>
            {isMaterial ? actualMaterial?.referenceLifeTime : selectedRow?.referenceLifeTime}
          </Typography>
        </Grid>
        <Grid item xs={1} />
        {/* second line */}
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Typography sx={{ mb: 0.5 }}>Caractéristiques</Typography>
          <Box
            fontSize={14}
            sx={{
              backgroundColor: "white",
              padding: "8px",
              minHeight: 58,
              maxHeight: 150,
              overflow: "hidden",
              overflowY: "scroll",
            }}>
            <Typography variant="body1" dangerouslySetInnerHTML={{ __html: getAllParameters() }} />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Typography color="#acacac" display="flex" justifyContent="flex-end">
            {selectedRow && (
              <Typography color="#acacac" display="flex" justifyContent="flex-end">
                Modifiée le
                {selectedRow instanceof IniesRecord
                  ? ` ${formatToFrench(selectedRow?.lastIniesUpdate)} depuis la base INIES.`
                  : ` ${formatToFrench(selectedRow?.lastModifiedDate)} par ${selectedRow?.lastModifiedUserName}.`}
              </Typography>
            )}
            {actualMaterial && (
              <Typography color="#acacac" display="flex" justifyContent="flex-end">
                {`Modifiée le ${formatToFrench(actualMaterial?.lastModifiedDate)} par ${
                  actualMaterial?.lastModifiedUserName
                }.`}
              </Typography>
            )}
          </Typography>
          {!isMaterial && (
            <Tooltip
              title={
                <Typography variant="body2">
                  {selectedRow instanceof MaterialRecord &&
                  originMaterialRecordToLabel(selectedRow).text === materialStatusLabel.DELETED
                    ? "Impossible d'ajouté une fiche supprimé"
                    : ""}
                </Typography>
              }
              placement="top">
              <Box display="flex" justifyContent="center" sx={{ mt: 3 }}>
                <Button
                  onClick={() => {
                    if (selectedRow && handleAddEnvironmentalStatement) handleAddEnvironmentalStatement(selectedRow)
                  }}
                  disabled={
                    selectedRow instanceof MaterialRecord &&
                    originMaterialRecordToLabel(selectedRow).text === materialStatusLabel.DELETED
                  }
                  variant="contained"
                  color="success"
                  size="large"
                  sx={{ color: "white" }}>
                  Ajouter au calcul
                </Button>
              </Box>
            </Tooltip>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}
