import { Box } from "@mui/material"
import React, { useCallback, useContext, useEffect, useState } from "react"
import DataGridOrganization, { DataGridType } from "../../../components/tables/display-user/data-grid-organization"
import { AdminOrganizationContext } from "../../../core/context/organization/admin-organization-context"
import { Collaborator } from "../../../core/dto/user/collaborator"
import { useOrganization } from "../../../core/hooks/use-organization"

export default function UserTableOrganizationPage(): React.JSX.Element {
  const [collaboratorList, setcollaboratorList] = useState<Collaborator[]>([])
  const { getCollaboratorsForOrganization } = useOrganization()
  const { organization } = useContext(AdminOrganizationContext)

  const refreshCollaborators: () => void = useCallback(() => {
    if (organization?.id) {
      getCollaboratorsForOrganization(organization?.id).then((newCollaboratorList: Collaborator[]) => {
        setcollaboratorList(newCollaboratorList)
      })
    }
  }, [getCollaboratorsForOrganization, organization?.id])

  useEffect(() => {
    refreshCollaborators()
  }, [refreshCollaborators])

  return (
    <Box>
      <DataGridOrganization
        dataCollab={collaboratorList}
        dataContact={[]}
        type={DataGridType.COLLAB}
        refreshCollab={refreshCollaborators}
        userList={collaboratorList}
        isForUltimateUser
      />
    </Box>
  )
}
