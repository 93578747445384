import React, { Dispatch, SetStateAction } from "react"
import { ConfirmationDialog } from "../../../../../components/dialog/confirmation-dialog"

type IProps = {
  action: () => Promise<any>
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export default function SynchroV1v2ConfirmationModal({ action, open, setOpen }: Readonly<IProps>): React.JSX.Element {
  return (
    <ConfirmationDialog
      id="confirm-synchronize-whole-list-additional-data"
      title="Confirmation de la synchronisation"
      contentText={
        <>
          Attention, cette action va remplacer la liste de données complémentaires en variante par la liste en base.
          <br />
          Êtes-vous sûr de vouloir écraser toutes les données complémentaires de votre variante ?
        </>
      }
      handleClose={() => setOpen(false)}
      action={action}
      open={open}
    />
  )
}
