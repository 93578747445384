/* eslint-disable max-len */
import { SvgIcon } from "@mui/material"
import React from "react"

type IProps = {
  style?: any
}

export function IconUserCheck(props: IProps): React.JSX.Element {
  const { style } = props
  return (
    <SvgIcon
      {...props}
      fillOpacity={0}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ width: style?.width ? style?.width : "24", height: style?.height ? style?.height : "24" }}>
      {/*  SVG from https://tablericons.com/ */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-user-check"
        strokeWidth="1.5"
        stroke={style?.color ? style.color : "#2c3e50"}
        strokeLinecap="round"
        strokeLinejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
        <path d="M6 21v-2a4 4 0 0 1 4 -4h4" />
        <path d="M15 19l2 2l4 -4" />
      </svg>
    </SvgIcon>
  )
}
