import { DefaultTypography } from "../../../../../components/typography/default-typography"

type IProps = {
  label: string
  value: string
}

export function KPIFiche({ label, value }: Readonly<IProps>) {
  return (
    <>
      <DefaultTypography label={label} fontSize="11px" lineHeight="26.5px" fontWeight={400} color="#8398A6" />
      <DefaultTypography label={value} fontSize="14px" lineHeight="24px" fontWeight={500} />
    </>
  )
}
