export enum OrganizationRoleEnum {
  ULTIMATE_USER = "ULTIMATE_USER",
  MEGA_USER = "MEGA_USER",
  SUPER_USER = "SUPER_USER",
  NORMAL_USER = "NORMAL_USER",
  TEST_USER = "TEST_USER",
  DISABLED = "DISABLED",
}

export enum OrganizationRoleEnumForSelect {
  TOUS = "TOUS",
  MEGA_USER = "MEGA_USER",
  SUPER_USER = "SUPER_USER",
  NORMAL_USER = "NORMAL_USER",
  TEST_USER = "TEST_USER",
}

export const organizationRoleEnumEnumLabels = {
  [OrganizationRoleEnumForSelect.MEGA_USER]: roleOnOrganizationToLabel(OrganizationRoleEnum.MEGA_USER),
  [OrganizationRoleEnumForSelect.SUPER_USER]: roleOnOrganizationToLabel(OrganizationRoleEnum.SUPER_USER),
  [OrganizationRoleEnumForSelect.NORMAL_USER]: roleOnOrganizationToLabel(OrganizationRoleEnum.NORMAL_USER),
  [OrganizationRoleEnumForSelect.TEST_USER]: roleOnOrganizationToLabel(OrganizationRoleEnum.TEST_USER),
  [OrganizationRoleEnumForSelect.TOUS]: "Tous",
}

export function roleOnOrganizationToLabel(role: OrganizationRoleEnum): string {
  if (role) {
    switch (role) {
      case OrganizationRoleEnum.MEGA_USER:
        return "Administrateur"
      case OrganizationRoleEnum.SUPER_USER:
        return "Chef de projets"
      case OrganizationRoleEnum.NORMAL_USER:
        return "Membre"
      case OrganizationRoleEnum.TEST_USER:
        return "Version d'essai"
      default:
        return ""
    }
  } else {
    return ""
  }
}
