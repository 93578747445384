import { useMemo } from "react"
import { appConstants } from "../appConstants"
import { Statistic } from "../dto/statistics/statistic"
import { streamToBlob } from "../services/file-service"
import { useHttp } from "./use-http"
import { resolveUrl } from "../services/http-service"

type UtilsHook = {
  getResultCsv(calculationId: string, variantNumber: number, typeExportCsv: string): Promise<File | undefined>
  generateStatistics(): Promise<Response>
  getAllStatistics(): Promise<Statistic[]>
  deleteStatistic(statisticId: Statistic): Promise<void>
}

export function useUtils(): UtilsHook {
  const { get, post, deleteRequest } = useHttp()

  return useMemo(
    () => ({
      getResultCsv(calculationId: string, variantNumber: number, typeExportCsv: string) {
        return get(`${appConstants.apiEndpoints.UTILS}/result-csv`, [
          {
            key: "calculationId",
            value: calculationId,
          },
          {
            key: "variantNumber",
            value: String(variantNumber),
          },
          {
            key: "typeExportCsv",
            value: typeExportCsv,
          },
        ]).then(async (res) => {
          const blob = await streamToBlob(res)
          if (blob) {
            return new File([blob], "example.csv", { type: "text/plain;charset=ISO-8859-1" })
          }
          return undefined
        })
      },
      async generateStatistics(): Promise<Response> {
        return post(appConstants.apiEndpoints.UTILS_STATISTICS_CREATE, "", [])
      },
      getAllStatistics(): Promise<Statistic[]> {
        return get(appConstants.apiEndpoints.UTILS_STATISTICS_LIST)
          .then((statisticsList) => statisticsList.json())
          .then((statisticsList) => statisticsList.map((statistic: any) => new Statistic(statistic)))
      },
      deleteStatistic(statistic: Statistic): Promise<void> {
        return deleteRequest(resolveUrl(appConstants.apiEndpoints.UTILS_STATISTIC, [statistic.id])).then(() => undefined)
      },
    }),
    [get, post, deleteRequest]
  )
}
