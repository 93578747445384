import { SvgIcon } from "@mui/material"
import React from "react"

type IProps = {
  style?: any
}

export function IconMapPin(props: IProps): React.JSX.Element {
  const { style } = props

  return (
    <SvgIcon
      {...props}
      fillOpacity={0}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ width: style?.width ? style?.width : "24", height: style?.height ? style?.height : "24" }}>
      {/*  SVG from https://tablericons.com/ */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-map-pin"
        strokeWidth="1.5"
        stroke="#2c3e50"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
        <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
      </svg>
    </SvgIcon>
  )
}
