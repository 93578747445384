import { MaterialHelper } from "../../components/ifc-displayer/helpers/MaterialHelper"

function numberToWord(num: number): string {
  const ones = [
    "",
    " one",
    " two",
    " three",
    " four",
    " five",
    " six",
    " seven",
    " eight",
    " nine",
    " ten",
    " eleven",
    " twelve",
    " thirteen",
    " fourteen",
    " fifteen",
    " sixteen",
    " seventeen",
    " eighteen",
    " nineteen",
  ]
  const tens = ["", "", " twenty", " thirty", " forty", " fifty", " sixty", " seventy", " eighty", " ninety"]
  const hundred = " hundred"
  let output = ""
  const numString = num.toString()

  if (num === 0) {
    return "dontAddBigSufix"
  }
  //the case of 10, 11, 12 ,13, .... 19
  if (num < 20) {
    output = ones[num]
    return output
  }

  //100 and more
  if (numString.length === 3) {
    output = ones[parseInt(numString.charAt(0), 10)] + hundred
    output += tens[parseInt(numString.charAt(1), 10)]
    output += ones[parseInt(numString.charAt(2), 10)]
    return output
  }

  output += tens[parseInt(numString.charAt(0), 10)]
  output += ones[parseInt(numString.charAt(1), 10)]

  return output
}

export function generateColor(name: string, occurence: string): string {
  const toGenerateName = `${name}${numberToWord(parseInt(occurence, 10))}` // TODO: Why do we need to convert the occurrence to a number ?

  let currentColor = "#"
  let hash = 0

  let i
  /* eslint-disable no-bitwise */
  for (i = 0; i < toGenerateName.length; i += 1) {
    hash = toGenerateName.charCodeAt(i) + ((hash << 5) - hash)
  }
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    const valueString = value.toString(16)
    currentColor += valueString.substring(valueString.length - 2)
  }

  return MaterialHelper.fixColor(`${currentColor}`)
}
