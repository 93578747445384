import { RoleEnum } from "../../enum/roleEnum"

export class ProjectInvitation {
  projectId?: string = ""
  roles: RoleEnum[] = [] // Role on a project
  userOrganizationName = ""
  firstName = ""
  lastName = ""
  email = ""
}
