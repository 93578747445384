import React, { useContext } from "react"
import { UserContext } from "../../../../core/context/user/user-context"
import { ConfirmationDialog } from "../../../../components/dialog/confirmation-dialog"

type IProps = {
  selectedCognitoId: string | undefined
  selectedUserName: string | undefined
  open: boolean
  handleClose(): void
  revokeRole(): Promise<void>
}

export default function RseeRevokeDialog({
  selectedCognitoId,
  selectedUserName,
  open,
  handleClose,
  revokeRole,
}: Readonly<IProps>): React.JSX.Element {
  const { user } = useContext(UserContext)

  return (
    <ConfirmationDialog
      id="revokeRole"
      title="Révoquation d'un rôle"
      contentText={
        selectedCognitoId === user?.cognitoUserId
          ? `Êtes-vous sûr de vouloir révoquer les droits à votre utilisateur ?`
          : `Êtes-vous sûr de vouloir révoquer les droits à ${selectedUserName} ?`
      }
      handleClose={handleClose}
      action={revokeRole}
      open={open}
    />
  )
}
