export enum FlatTypeEnum {
  TYPE_T1 = "TYPE_T1",
  LOGEMENTS_MIXTE = "LOGEMENTS_MIXTE",
  MAJORITAIREMENT_T4_T5 = "MAJORITAIREMENT_T4_T5",
}

export const flatTypeEnumLabels = {
  [FlatTypeEnum.TYPE_T1]: "T1",
  [FlatTypeEnum.LOGEMENTS_MIXTE]: "Logements Mixte",
  [FlatTypeEnum.MAJORITAIREMENT_T4_T5]: "Majoritairement T4 et T5",
}
