export enum RoofSlopedCoverEnum {
  ARDOISE_NATURELLE = "ARDOISE_NATURELLE",
  ARDOISE_FIBRE_DE_CIMENT = "ARDOISE_FIBRE_DE_CIMENT",
  COUVERTURE_METALLIQUE = "COUVERTURE_METALLIQUE",
  TUILE_TERRE = "TUILE_TERRE",
  BARDAGE_BOIS = "BARDAGE_BOIS",
}

export const roofSlopedCoverEnumLabels = {
  [RoofSlopedCoverEnum.ARDOISE_NATURELLE]: "Ardoise naturelle",
  [RoofSlopedCoverEnum.ARDOISE_FIBRE_DE_CIMENT]: "Ardoise en fibre de ciment",
  [RoofSlopedCoverEnum.COUVERTURE_METALLIQUE]: "Couverture métallique",
  [RoofSlopedCoverEnum.TUILE_TERRE]: "Tuile en terre cuite",
  [RoofSlopedCoverEnum.BARDAGE_BOIS]: "Bardeaux en bois",
}
