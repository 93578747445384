import React, { useContext } from "react"
import { ConfirmationDialog } from "../../../../components/dialog/confirmation-dialog"
import { UserContext } from "../../../../core/context/user/user-context"

type IProps = {
  selectedCognitoId: string | undefined
  selectedUserName: string | undefined
  labelActualRole: string
  open: boolean
  handleClose(): void
  revokeRole(): Promise<void>
}

export default function RevokeDialog({
  selectedCognitoId,
  selectedUserName,
  labelActualRole,
  open,
  handleClose,
  revokeRole,
}: IProps): React.JSX.Element {
  const { user } = useContext(UserContext)

  return (
    <ConfirmationDialog
      id="revokeRole"
      title="Révoquation d'un rôle"
      contentText={
        selectedCognitoId === user?.cognitoUserId
          ? `Êtes-vous sûr de vouloir enlever le rôle d${labelActualRole} à votre utilisateur ?`
          : `Êtes-vous sûr de vouloir révoquer le rôle d${labelActualRole} à ${selectedUserName} ?`
      }
      handleClose={handleClose}
      action={() => revokeRole()}
      open={open}
    />
  )
}
