import { Box, Typography } from "@mui/material"
import React from "react"
import { SubsetInfo } from "../../../../components/ifc-displayer/models/SubsetInfo"
import { CodeDisplayer } from "./CodeDisplayer"

type iProps = {
  subsetInfo: SubsetInfo[]
  handleClickedCodeManquant?: (subset: SubsetInfo) => void
  selectedCodeManquantElement: SubsetInfo | undefined
}

export function ExtractedMissingCodeDisplayer({
  subsetInfo,
  handleClickedCodeManquant,
  selectedCodeManquantElement,
}: Readonly<iProps>): React.JSX.Element {
  return (
    <Box borderRadius={3} boxShadow={1} width="90%" sx={{ background: "white", p: 2 }}>
      {subsetInfo.map((subset) => (
        <CodeDisplayer
          key={subset.id}
          highlightBorder={selectedCodeManquantElement?.id === subset.id}
          codeTitle={subset.name}
          codeColor={subset.codeColor}
          handleClickAcv={() => {
            if (handleClickedCodeManquant) handleClickedCodeManquant(subset)
          }}
          numberElements={subset.elementIds.length ?? 0}
        />
      ))}

      {subsetInfo.length === 0 && <Typography variant="body1"> Aucune objet non renseigné n'a été detecté</Typography>}
    </Box>
  )
}
