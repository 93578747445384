import {
  Close,
  FilterCenterFocus,
  Fullscreen,
  InsertDriveFile,
  NotInterested,
  Straighten,
  ThreeDRotation,
  ZoomIn,
  ZoomOut,
} from "@mui/icons-material"
import {
  Box,
  Button,
  createTheme,
  IconButton,
  InputAdornment,
  LinearProgress,
  Menu,
  MenuItem,
  PopoverOrigin,
  Snackbar,
  Switch,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography,
  useEventCallback,
} from "@mui/material"
import React, { forwardRef, useCallback, useContext, useEffect, useRef, useState } from "react"
import { theme } from "../../../theme"
import { pagesUrl } from "../../core/appConstants"
import { BimModelFileContext, BimModelFileStore } from "../../core/context/bim-model/bim-model-file-context"
import { ProjectContext, ProjectStore } from "../../core/context/project/project-context"
import { CodeExtraitDisplay } from "../../core/dto/code-extrait/CodeExtraitDisplay"
import { unzipOneFile } from "../../core/services/zip-services"
import IconCancel from "../custom-icons/icon-cancel"
import IconDeployedCode from "../custom-icons/icon-deployed-code"
import IconDownload from "../custom-icons/icon-download"
import IconLayersClear from "../custom-icons/icon-layers-clear"
import IconMop from "../custom-icons/icon-mop"
import IconOpenInNew from "../custom-icons/icon-open-in-new"
import IconPanToolAlt from "../custom-icons/icon-pan-tool-alt"
import IconPentagon from "../custom-icons/icon-pentagon"
import IconSelect from "../custom-icons/icon-select"
import IconSquareFoot from "../custom-icons/icon-square-foot"
import IconStarighten from "../custom-icons/icon-starighten"
import IconVisibility from "../custom-icons/icon-visibility"
import IconVisibilityOff from "../custom-icons/icon-visibility-off"
import { ErrorContext } from "../layout/error-snackbar"
import ViewerIFC, { ViewerIFCProps } from "./ViewerIFC"
import { viewerConstants } from "./constants/ViewerConstants"
import DisplayPropertiesIFC from "./display-properties-ifc"
import { CameraOrientations } from "./enums/CameraOrientation"
import { ViewerTypes } from "./enums/ViewerTypes"
import { CachingHelper } from "./helpers/CachingHelper"
import { SubsetInfo } from "./models/SubsetInfo"
import "./styles.scss"

interface DragHandleRef {
  startX: number
  startY: number
}

export type IfcDisplayerProps = {
  type: ViewerTypes
  file: File
  cancelUpload?: () => void
  reset?: () => void
  onChangeModel?(modelId: number, ifc: ViewerIFC): void
  setExternalProgress?(progress: number): void
  codesExtraits: CodeExtraitDisplay[]
  setSelectedCodeExtrait(code: CodeExtraitDisplay, disableViewerHighlight?: boolean): void
  setCodeManquantElements?(codeManquantElements: SubsetInfo[]): void
  setSelectedCodeManquantElement?(codeManuantElement: SubsetInfo): void
  setTabFullScreenToken?(token: string): void
  showFileName?: boolean
  fullscreenButton?: boolean
  fullscreenTabButton?: boolean
  height: string
  hasMenu?: boolean
}

function IfcDisplayerCore(
  {
    type,
    file,
    cancelUpload,
    onChangeModel,
    reset,
    setExternalProgress,
    codesExtraits,
    setSelectedCodeExtrait,
    showFileName,
    setCodeManquantElements,
    setSelectedCodeManquantElement,
    setTabFullScreenToken,
    fullscreenButton,
    fullscreenTabButton,
    height,
    hasMenu = true,
  }: IfcDisplayerProps,
  ref: any
): React.JSX.Element {
  const parentCanvasRef = useRef<HTMLElement>(null)
  const threeCanvasRef = useRef<HTMLCanvasElement>(null)
  const [internalProgress, setInternalProgress] = useState<number>(0)
  const [viewerBarProgress, setViewerBarProgress] = useState<number>(0)
  const viewerRef = useRef<ViewerIFC | undefined>(undefined)
  const [widthCanvas, setWidthCanvas] = useState<number>(0)
  const [heightCanvas, setHeightCanvas] = useState<number>(0)
  const [initialHeightParent, setInitialHeightParent] = useState<number>(0)
  const [initialWidthParent, setInitialWidthParent] = useState<number>(0)
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false)
  const [contentDrawer, setContentDrawer] = useState<string>("")
  const [contentDrawerJSON, setContentDrawerJSON] = useState<any>({})
  const [anchorCameraMenu, setAnchorCameraMenu] = useState(null)
  const [anchorSectionPlansMenu, setAnchorSectionPlansMenu] = useState(null)
  const [anchorMeasurementMenu, setAnchorMeasurementMenu] = useState(null)
  const [sectionPlansActive, setSectionPlansActive] = useState(true)
  const [measurementActive, setMeasurementActive] = useState(true)
  const [openViewerSnackbar, setOpenViewerSnackbar] = useState(false)
  const [messageViewerSnackbar, setMessageViewerSnackbar] = useState<string>("")
  const handleCloseViewerSnackbar = (): void => setOpenViewerSnackbar(false)
  const [isInHideMode, setIsInHideMode] = useState<boolean>(false)
  const [isInHidingIrrelevantCodes, setIsInHidingIrrelevantCodes] = useState<boolean>(false)
  const { project } = useContext<ProjectStore>(ProjectContext)
  const [currentFullScreenToken, setCurrentFullScreenToken] = useState<string | undefined>(undefined)
  const [loaded, setLoaded] = useState<boolean>(false)
  const [showPopup, setShowPopup] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<number>(0.5)
  const [position, setPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 })
  const [isDragging, setIsDragging] = useState<boolean>(false)
  const dragHandleRef = useRef<DragHandleRef | null>(null)

  useEffect(() => {
    // This is important to avoid bugs
    // Without this, the list in the subsetManager is not updated so the information inside the codes extraits are outdated
    if (viewerRef?.current?.manager?.subsetsManager) {
      viewerRef.current.manager.subsetsManager.setCodesExtraits(codesExtraits)
      viewerRef.current.manager.subsetsManager.setSelectedCodeExtrait = setSelectedCodeExtrait
    }
  }, [codesExtraits, setSelectedCodeExtrait])

  const handleMouseUp = useEventCallback(() => {
    setIsDragging(false)
    dragHandleRef.current = null
    window.removeEventListener("mouseup", handleMouseUp)
    window.removeEventListener("mousemove", handleMouseMove)
  })

  const handleMouseMove = useEventCallback((e: MouseEvent) => {
    if (!isDragging || !dragHandleRef.current) return
    setPosition({
      x: e.clientX - dragHandleRef.current.startX,
      y: e.clientY - dragHandleRef.current.startY,
    })
  })

  const handleMouseDown = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      setIsDragging(true)
      dragHandleRef.current = {
        startX: e.clientX - position.x,
        startY: e.clientY - position.y,
      }
      window.addEventListener("mouseup", handleMouseUp)
      window.addEventListener("mousemove", handleMouseMove)
    },
    [position, handleMouseUp, handleMouseMove]
  )

  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.key === "Escape" || e.code === "Escape") {
      setShowPopup(false)
    }
  }, [])

  // Destroy resources when component is destroyed
  useEffect(
    () => () => {
      deleteViewer()
    },
    []
  )

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown)
    return () => {
      window.removeEventListener("mouseup", handleMouseUp)
      window.removeEventListener("mousemove", handleMouseMove)
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [handleMouseUp, handleMouseMove, handleKeyDown])

  const { setFile, isCompressed, setIsCompressed } = useContext<BimModelFileStore>(BimModelFileContext)
  const openErrorSnackbar = useContext(ErrorContext)

  function deleteViewer(): void {
    console.info("start deleting viewer", viewerRef.current)
    viewerRef.current?.delete()
    viewerRef.current = undefined
  }

  // will delete/reset viewer when file is changed
  useEffect(() => {
    if (file) {
      deleteViewer()
    }
  }, [file])

  /**
   * @description Switch the canvas to fullscreen mode
   */
  function fullscreen(): void {
    if (parentCanvasRef.current) {
      parentCanvasRef.current.style.position = "fixed"
      parentCanvasRef.current.style.top = "15px"
      parentCanvasRef.current.style.left = "15px"
      parentCanvasRef.current.style.right = "15px"
      parentCanvasRef.current.style.bottom = "15px"
      parentCanvasRef.current.style.zIndex = "100"
      parentCanvasRef.current.style.marginTop = "50px"
      parentCanvasRef.current.style.height = "85vh"

      // turn on the fullscreen mode visibility
      setIsFullscreen(true)

      // set the width and height states to the fullscreen size
      setWidthCanvas(parentCanvasRef.current.clientWidth)
      setHeightCanvas(parentCanvasRef.current.clientHeight)

      // we need to call "resizeViewer() to update the renderer and camera of ifc viewer based on the fullscreen size
      // It's done in a useEffect
      // viewerRef.current?.manager.cameras.resizeViewer(current.clientWidth, current.clientHeight)
      viewerRef.current?.manager.cameras.resizeViewer(widthCanvas, parentCanvasRef.current.clientHeight)
    }
  }

  function setProgress(progress: number): void {
    setInternalProgress(progress)
    if (setExternalProgress) {
      setExternalProgress(progress)
    }
  }

  /**
   * @description Reset the canvas to the initial size
   */
  const resetFullScreenCanvas = useCallback(() => {
    if (parentCanvasRef.current) {
      parentCanvasRef.current.style.position = "relative"
      parentCanvasRef.current.style.top = "auto"
      parentCanvasRef.current.style.left = "auto"
      parentCanvasRef.current.style.right = "auto"
      parentCanvasRef.current.style.bottom = "auto"
      parentCanvasRef.current.style.inset = "0px"
      parentCanvasRef.current.style.zIndex = "1"
      parentCanvasRef.current.style.marginTop = "0px"
      parentCanvasRef.current.style.height = canvasStyle.height

      // turn off the fullscreen mode visibility
      setIsFullscreen(false)

      // set the width and height states to the non-fullscreen size
      setWidthCanvas(initialWidthParent)
      setHeightCanvas(initialHeightParent)

      // updated the renderer and camera of ifc viewer based on the non-fullscreen size
      viewerRef.current?.manager.cameras.resizeViewer(initialWidthParent, initialHeightParent)
    }
  }, [initialWidthParent, initialHeightParent, viewerRef.current])

  function openMessageViewerSnackbar(message: string): void {
    setMessageViewerSnackbar(message)
    setOpenViewerSnackbar(true)
  }

  function zoom(value: "in" | "out"): void {
    if (viewerRef.current) {
      if (value === "in") viewerRef.current?.manager.cameras.zoomIn()
      else viewerRef.current?.manager.cameras.zoomOut()
    }
  }

  function resetViewer(): void {
    if (viewerRef.current) {
      prepareFile()
    }
  }

  function prepareFile(): void {
    if (isCompressed) {
      unzipOneFile(file, setFile, openErrorSnackbar, setIsCompressed, cancelUpload)
    } else if (file) {
      if (viewerRef.current) {
        deleteViewer()
      }
      createViewer()
    }
  }

  function createViewer(): void {
    const options: ViewerIFCProps = {
      canvasRef: threeCanvasRef.current,
      size: { width: widthCanvas, height: heightCanvas },
      file,
      setProgress,
      setViewerBarProgress,
      setContentDrawer,
      onChangeModel,
      codesExtraits,
      setSelectedCodeExtrait,
      openViewerMessage: openMessageViewerSnackbar,
      setCodeManquantElements,
      setSelectedCodeManquantElement,
      updateIsInHideState,
      updateIsInHidingIrellevantCodesState,
      type,
    }
    viewerRef.current = new ViewerIFC(options)
    viewerRef.current?.loadIFC().catch(() => {
      console.error("error when loading ifc")
    })
  }

  function makePerspective(): void {
    if (viewerRef.current) {
      viewerRef.current?.manager.cameras.makePerspective()
    }
    handleMenuClose()
  }

  function makeIsometric(): void {
    if (viewerRef.current) {
      viewerRef.current?.manager.cameras.makeIsometric()
    }
    handleMenuClose()
  }

  async function orientCamera(orientation: CameraOrientations): Promise<void> {
    if (viewerRef.current) {
      await viewerRef.current?.manager.cameras.changeCameraOrientation(orientation)
    }
    handleMenuClose()
  }

  function createSectionPlan(): void {
    setShowPopup(false)
    viewerRef.current?.manager.sections.createSectionPlane()
    handleMenuClose()
  }

  function removeAllSectionPlans(): void {
    viewerRef.current?.manager.sections.deleteAllSectionPlanes()
    handleMenuClose()
  }

  function removeAllMeasurements(): void {
    viewerRef.current?.manager.measurements.deleteAllPointCoordinates()
    handleMenuClose()
  }

  function addPointCoordinatesMeasurement(): void {
    setShowPopup(false)
    viewerRef.current?.manager.measurements.addPointCoordinates()
    handleMenuClose()
  }

  function addTwoPointsMeasurement(): void {
    setShowPopup(false)
    viewerRef.current?.manager.measurements.addDimensionBetweenTwoPoints()
    handleMenuClose()
  }

  function addEdgeDistanceMeasurement(): void {
    setShowPopup(false)
    viewerRef.current?.manager.measurements.addEdgeDimension()
    handleMenuClose()
  }

  function addAreaPointsMeasurement(): void {
    setShowPopup(false)
    viewerRef.current?.manager.measurements.addAreaPoints()
    handleMenuClose()
  }

  function addAreaFaceMeasurement(): void {
    setShowPopup(false)
    viewerRef.current?.manager.measurements.addAreaByFace()
    handleMenuClose()
  }

  function addObjectSegmentsMeasurements(): void {
    setShowPopup(true)
    viewerRef.current?.manager.measurements.addObjectEdgesDimensions(inputValue)
    handleMenuClose()
  }

  function addFaceSegmentsMeasurements(): void {
    viewerRef.current?.manager.measurements.addSegmentsByFace()
    handleMenuClose()
  }

  function handleCameraMenuClick(event: any): void {
    setAnchorCameraMenu(event.currentTarget)
  }

  function handleSectionPlansMenuClick(event: any): void {
    viewerRef.current?.manager.measurements.abortDimentioning()
    setAnchorSectionPlansMenu(event.currentTarget)
  }

  function handleMeasurementMenuClick(event: any): void {
    setAnchorMeasurementMenu(event.currentTarget)
  }

  function handleMeasurementValueChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const value = Number(e.target.value)
    setInputValue(value)
    viewerRef.current?.manager.measurements.addObjectEdgesDimensions(value)
  }

  /**
   * Hide or show the unselected elements
   * @param hide - true to hide the unselected elements, false to show them
   */
  function hideUnselectedElements(hide: boolean): void {
    const applied = viewerRef.current?.manager.subsetsManager.hideUnselectedSubsets(hide)
    if (applied) {
      setIsInHideMode(hide)
    }
  }

  /**
   * Hide or show the unrelevant code elements
   * @param hide - true to hide the unrelevant code elements, false to show them
   */
  function hideUnrelevantCodeElements(hide: boolean): void {
    const applied = viewerRef.current?.manager.subsetsManager.hideUnrelevantCodeSubsets(hide)
    if (applied) setIsInHidingIrrelevantCodes(hide)
  }

  /**
   * Sets the fullscreen in a new browser tab
   */
  async function setFullScreenTab(): Promise<void> {
    const token = CachingHelper.createFullScreenToken()
    if (setTabFullScreenToken) setTabFullScreenToken(token)
    setCurrentFullScreenToken(token)
    if (viewerRef.current) {
      await CachingHelper.setFullScreen(token, viewerRef.current.manager.subsetsManager.selectedCodeExtrait)
    }
    window.open(`${pagesUrl.PROJECT_PAGE}/${project.id}/fullscreen/${token}`, "_blank", "noopener")
  }

  function updateIsInHideState(isHideMode: boolean): void {
    setIsInHideMode(isHideMode)
  }

  function updateIsInHidingIrellevantCodesState(isHidingIrellevantCodes: boolean): void {
    setIsInHidingIrrelevantCodes(isHidingIrellevantCodes)
  }

  const handleSectionPlansActiveChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    viewerRef.current?.manager.sections.setActive(event.target.checked)
    setSectionPlansActive(event.target.checked)
  }

  const handleMeasurementActiveChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    viewerRef.current?.manager.measurements.allowAddingDimensions(event.target.checked)
    setMeasurementActive(event.target.checked)
    if (!event.target.checked) setShowPopup(false)
  }

  const handleMenuClose = (): void => {
    setAnchorCameraMenu(null)
    setAnchorSectionPlansMenu(null)
    setAnchorMeasurementMenu(null)
  }

  useEffect(() => {
    const { current } = parentCanvasRef

    if (current) {
      setInitialWidthParent(current.clientWidth)
      setInitialHeightParent(current.clientHeight)
    }
  }, [])

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setWidthCanvas(entry.contentRect.width)
        setHeightCanvas(entry.contentRect.height)
      }
    })

    if (parentCanvasRef.current) {
      observer.observe(parentCanvasRef.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [])

  const previousWidthRef = useRef<number>(initialWidthParent)
  const previousHeightRef = useRef<number>(initialHeightParent)

  useEffect(() => {
    // This width canvas in fullscreen is weird. Like, one time it's "1151.59375" and next time it's "1153.59375".
    // So the useEffect is an infinite loop in fullscreen. To avoid this, here is a fault tolerance of 3px.
    if (
      widthCanvas < previousWidthRef.current - 3 ||
      previousWidthRef.current + 3 < widthCanvas ||
      heightCanvas < previousHeightRef.current - 10 ||
      previousHeightRef.current + 10 < heightCanvas
    ) {
      viewerRef.current?.manager.cameras.resizeViewer(widthCanvas, heightCanvas)
      previousWidthRef.current = widthCanvas
      previousHeightRef.current = heightCanvas
    }
  }, [widthCanvas, heightCanvas])

  useEffect(() => {
    function handleEchap(event: KeyboardEvent): void {
      if (event.key === "Escape") {
        resetFullScreenCanvas()
      }
      event.preventDefault()
    }

    window.addEventListener("keyup", handleEchap)
    return () => window.removeEventListener("keyup", handleEchap)
  }, [initialHeightParent, resetFullScreenCanvas])

  useEffect(() => {
    if (heightCanvas !== 0) {
      if (!viewerRef.current) {
        prepareFile()
      }
    }
  }, [heightCanvas, widthCanvas, file, setInternalProgress, setViewerBarProgress, onChangeModel])

  useEffect(() => {
    if (contentDrawer) {
      setContentDrawerJSON(JSON.parse(contentDrawer))
    }
  }, [contentDrawer])

  useEffect(() => {
    // reset internalProgress when it reaches 100 with a relaxed timeout
    if (viewerBarProgress + viewerConstants.startFileLoadingProgress === 100) {
      setTimeout(() => {
        setLoaded(true)
        if (viewerRef.current) {
          viewerRef.current.isLoadingFile = false
        }
      }, 100)
    }
  }, [viewerBarProgress, viewerRef.current])

  const anchorOrigin: PopoverOrigin = isFullscreen
    ? { vertical: "bottom", horizontal: "left" }
    : { vertical: "top", horizontal: "left" }
  const transformOrigin: PopoverOrigin = { vertical: "top", horizontal: "right" }

  function downloadBimModelFile(): void {
    if (file) {
      const url = window.URL.createObjectURL(file)
      const link = document.createElement("a")
      link.href = url
      link.download = `${file.name}`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    }
  }

  return (
    <>
      <Box id="ifc-displayer" sx={containerStyle} ref={ref}>
        <Box id="viewer-parent-canvas" sx={containerStyle} ref={parentCanvasRef}>
          {!loaded && (
            <LinearProgress variant="determinate" value={viewerBarProgress + viewerConstants.startFileLoadingProgress} />
          )}
          {showFileName && (
            <Typography translate="no" variant="subtitle1" sx={titleStyle}>
              <InsertDriveFile sx={{ marginRight: 1 }} /> Nom du fichier : {file.name}
            </Typography>
          )}

          <div id="viewer-canvas" style={canvasStyle} />

          {isFullscreen && (
            <Button variant="text" sx={closeStyle} onClick={() => resetFullScreenCanvas()}>
              FERMER
            </Button>
          )}
          <Snackbar
            open={openViewerSnackbar}
            autoHideDuration={3000}
            onClose={handleCloseViewerSnackbar}
            message={messageViewerSnackbar}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            ContentProps={{
              classes: {
                root: "viewer-snackbar",
              },
            }}
            action={
              <IconButton size="small" color="inherit" onClick={handleCloseViewerSnackbar}>
                <Close />
              </IconButton>
            }
          />
          {hasMenu && (
            <Box
              display="flex"
              flexDirection={isFullscreen ? "row" : "column"}
              justifyContent="center"
              sx={{
                position: "absolute",
                width: isFullscreen ? "100%" : "fit-content",
                zIndex: 1,
                margin: "10px",
                ...(!isFullscreen ? { top: "0px", right: "0px" } : { bottom: "0px" }),
              }}>
              <Tooltip title="Vues" placement="right">
                <IconButton size="small" onClick={handleCameraMenuClick}>
                  <ThreeDRotation sx={{ fontSize: "28px" }} />
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorCameraMenu}
                keepMounted
                open={Boolean(anchorCameraMenu)}
                onClose={handleMenuClose}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                PaperProps={{
                  style: { ...darkStyle },
                }}>
                <MenuItem className="menu-item-viewer" onClick={makePerspective}>
                  Perspective
                </MenuItem>
                <MenuItem className="menu-item-viewer" onClick={makeIsometric}>
                  Isometric
                </MenuItem>
                <MenuItem className="menu-item-viewer" onClick={() => orientCamera(CameraOrientations.TOP)}>
                  Vue de dessus
                </MenuItem>
                <MenuItem className="menu-item-viewer" onClick={() => orientCamera(CameraOrientations.FRONT)}>
                  Vue de devant
                </MenuItem>
                <MenuItem className="menu-item-viewer" onClick={() => orientCamera(CameraOrientations.LEFT)}>
                  Vue de gauche
                </MenuItem>
                <MenuItem className="menu-item-viewer" onClick={() => orientCamera(CameraOrientations.RIGHT)}>
                  Vue de droite
                </MenuItem>
                <MenuItem className="menu-item-viewer" onClick={() => orientCamera(CameraOrientations.BACK)}>
                  Vue de derrière
                </MenuItem>
                <MenuItem className="menu-item-viewer" onClick={() => orientCamera(CameraOrientations.BOTTOM)}>
                  Vue de dessous
                </MenuItem>
              </Menu>
              {fullscreenButton && (
                <Tooltip title="Plein écran" placement="right">
                  <IconButton onClick={() => (isFullscreen ? resetFullScreenCanvas() : fullscreen())} size="small">
                    <Fullscreen sx={{ fontSize: "30px" }} />
                  </IconButton>
                </Tooltip>
              )}
              {fullscreenTabButton && (
                <Tooltip title="Nouvel onglet" placement="right">
                  <IconButton onClick={setFullScreenTab} size="small">
                    <IconOpenInNew style={{ fontSize: "28px" }} />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Mesures" placement="right">
                <IconButton size="small" onClick={handleMeasurementMenuClick}>
                  <Straighten style={{ fontSize: "30px" }} />
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorMeasurementMenu}
                keepMounted
                open={Boolean(anchorMeasurementMenu)}
                onClose={handleMenuClose}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                PaperProps={{
                  style: { ...darkStyle },
                }}>
                <MenuItem className="menu-item-viewer">
                  <ThemeProvider theme={menuSwitchTheme}>
                    <Switch
                      checked={measurementActive}
                      onChange={handleMeasurementActiveChange}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{ marginLeft: "-10px" }}
                    />
                  </ThemeProvider>
                  <span>Active</span>
                </MenuItem>
                {/* TODO: re-enable this feature when the shaking stops due of using  'COORDINATE_TO_ORIGIN: false' configuration with ifc.js */}
                {/*<MenuItem className='menu-item-viewer' onClick={addPointCoordinatesMeasurement}>*/}
                {/*  <LocationOn style={{ fontSize: '24px', marginRight: '5px' }} />*/}
                {/*  Coordonnées des points*/}
                {/*</MenuItem>*/}
                <MenuItem className="menu-item-viewer" onClick={addTwoPointsMeasurement}>
                  <IconStarighten style={{ fontSize: "24px", marginRight: "5px" }} />
                  Distance entre deux points
                </MenuItem>
                <MenuItem className="menu-item-viewer" onClick={addEdgeDistanceMeasurement}>
                  <IconSquareFoot style={{ fontSize: "24px", marginRight: "5px" }} />
                  Longueur d'un segment
                </MenuItem>
                <MenuItem className="menu-item-viewer" onClick={addAreaPointsMeasurement}>
                  <IconPentagon style={{ fontSize: "24px", marginRight: "5px" }} />
                  Mesure de surface
                </MenuItem>
                <MenuItem className="menu-item-viewer" onClick={addAreaFaceMeasurement}>
                  <IconSelect style={{ fontSize: "24px", marginRight: "5px" }} />
                  Mesure de surface d'une face
                </MenuItem>
                <MenuItem className="menu-item-viewer" onClick={addFaceSegmentsMeasurements}>
                  <IconDeployedCode style={{ fontSize: "24px", marginRight: "5px" }} />
                  Segments d'une face
                </MenuItem>
                <MenuItem className="menu-item-viewer" onClick={removeAllMeasurements}>
                  <NotInterested sx={{ fontSize: "25px", marginRight: "5px" }} /> Supprimer toutes les mesures
                </MenuItem>
              </Menu>
              <Tooltip title="Agrandir" placement="right">
                <IconButton size="small" onClick={() => zoom("in")}>
                  <ZoomIn sx={{ fontSize: "30px" }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Réduire" placement="right">
                <IconButton size="small" onClick={() => zoom("out")}>
                  <ZoomOut sx={{ fontSize: "30px" }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Créer une coupe" placement="right">
                <IconButton size="small" onClick={handleSectionPlansMenuClick}>
                  <IconLayersClear style={{ fontSize: "32px" }} />
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorSectionPlansMenu}
                keepMounted
                open={Boolean(anchorSectionPlansMenu)}
                onClose={handleMenuClose}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                PaperProps={{
                  style: { ...darkStyle },
                }}>
                <MenuItem className="menu-item-viewer">
                  <ThemeProvider theme={menuSwitchTheme}>
                    <Switch
                      checked={sectionPlansActive}
                      onChange={handleSectionPlansActiveChange}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{ marginLeft: "-10px" }}
                    />
                  </ThemeProvider>
                  <span>Active</span>
                </MenuItem>
                <MenuItem className="menu-item-viewer" onClick={createSectionPlan}>
                  <IconPanToolAlt style={{ fontSize: "32px", marginRight: "5px", marginLeft: "-6px" }} /> Créer à partir du
                  face
                </MenuItem>
                <MenuItem className="menu-item-viewer" onClick={removeAllSectionPlans}>
                  <NotInterested sx={{ fontSize: "25px", marginRight: "5px" }} /> Supprimer tous les plans
                </MenuItem>
              </Menu>
              <Tooltip title="Centrer la vue" placement="right">
                <IconButton
                  size="small"
                  onClick={() => {
                    viewerRef.current?.manager.cameras.focus()
                  }}>
                  <FilterCenterFocus sx={{ fontSize: "30px" }} />
                </IconButton>
              </Tooltip>
              {isInHideMode ? (
                <Tooltip title="Dés-isoler" placement="right">
                  <IconButton size="small" onClick={() => hideUnselectedElements(false)}>
                    <IconVisibilityOff style={{ fontSize: "30px" }} />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Isoler" placement="right">
                  <IconButton size="small" onClick={() => hideUnselectedElements(true)}>
                    <IconVisibility style={{ fontSize: "30px" }} />
                  </IconButton>
                </Tooltip>
              )}
              {isInHidingIrrelevantCodes ? (
                <Tooltip title="Voir tous les éléments" placement="right">
                  <IconButton size="small" onClick={() => hideUnrelevantCodeElements(false)}>
                    <IconCancel style={{ fontSize: "30px" }} />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Masquer éléments non codés" placement="right">
                  <IconButton size="small" onClick={() => hideUnrelevantCodeElements(true)}>
                    <IconMop style={{ fontSize: "30px" }} />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Télécharger" placement="right">
                <IconButton size="small" onClick={() => downloadBimModelFile()}>
                  <IconDownload style={{ fontSize: "30px" }} />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
      </Box>
      {showPopup && (
        <Box
          sx={styles.popup}
          style={{ transform: `translate(${position.x}px, ${position.y}px)` }}
          onMouseDown={handleMouseDown}>
          <Box sx={{ verticalAligh: "baseline", display: "block" }}>
            <IconDeployedCode
              style={{ fontSize: "24px", marginRight: "5px", verticalAlign: "baseline", display: "inline-block" }}
            />
            <Box component="span" sx={{ verticalAlign: "super", display: "inline-block" }}>
              Longueur minimale du segment
            </Box>
          </Box>
          <TextField
            sx={{
              border: "1px solid #fff",
              borderRadius: "4px",
              backgroundColor: "#ccc",
              marginTop: "5px",
              verticalAlign: "baseline",
            }}
            value={inputValue}
            type="number"
            onChange={handleMeasurementValueChange}
            placeholder="Entrez le nombre ici"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-grip-horizontal" />
                </InputAdornment>
              ),
            }}
          />
          <Box component="span" sx={{ verticalAlign: "baseline", display: "inline-block", marginLeft: "10px" }}>
            m
          </Box>
        </Box>
      )}
      {contentDrawer.length > 0 && <DisplayPropertiesIFC contentDrawerJSON={contentDrawerJSON} />}
    </>
  )
}

export default forwardRef(IfcDisplayerCore)

const containerStyle = {
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  flexDirection: "column",
}

const titleStyle = {
  textAlign: "center",
  backgroundColor: theme.palette.primary.main,
  color: "white",
  borderRadius: "4px",
  boxShadow: "0 2px 2px rgba(0, 0, 0, 0.2)",
  position: "absolute",
  top: "15px",
  left: "15px",
  padding: "4px 8px",
  textTransform: "uppercase",
  fontWeight: "bold",
  display: "flex",
  alignContent: "center",
  zIndex: "2",
}

const closeStyle = {
  position: "absolute",
  top: "15px",
  right: "15px",
  cursor: "pointer",
  zIndex: "2",
}

let canvasStyle = {
  top: "auto",
  left: "auto",
  display: "block",
  outline: "none",
  borderRadius: "4px",
  border: "1px solid rgba(0,0,0,0.12)",
  backgroundColor: "white",
  width: "100%",
  height: "100%",
  minHeight: "500px",
}

const darkStyle = {
  backgroundColor: "#000000",
  color: "#FFFFFF",
}

const styles = {
  backgroundOverlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 9,
  },
  popup: {
    backgroundColor: "#000000",
    border: "1px solid #ccc",
    color: "#FFFFFF",
    borderRadius: "4px",
    padding: "16px",
    position: "absolute",
    zIndex: 99999,
    userSelect: "none",
    cursor: "move",
    verticalAlign: "baseline",
  },
}

const menuSwitchTheme = createTheme({
  components: {
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          // Controls default (unchecked) color for the thumb
          color: "#ccc",
        },
        colorPrimary: {
          "&.Mui-checked": {
            // Controls checked color for the thumb
            color: "#fff",
          },
        },
        track: {
          // Controls default (unchecked) color for the track
          opacity: 0.2,
          backgroundColor: "#fff",
          ".Mui-checked.Mui-checked + &": {
            // Controls checked color for the track
            opacity: 0.7,
            backgroundColor: "#fff",
          },
        },
      },
    },
  },
})
