import { Grid, Tooltip } from "@mui/material"
import React, { useContext } from "react"
import ZoneImg from "../../../../../../../assets/zone.png"
import { NumberInput } from "../../../../../../components/inputs/number-input/number-input"
import { BooleanRadioButtonInput } from "../../../../../../components/inputs/radio-button-input/BooleanRadioButtonInput"
import { EnumRadioButton } from "../../../../../../components/inputs/radio-button-input/EnumRadioButton"
import SelectInput from "../../../../../../components/inputs/select-input/select-input"
import { BuildingFacadeEnum, buildingFacadeEnumLabels } from "../../../../../../core/enum/beem-shot/BuildingFacadeEnum"
import { BuildingFrameEnum, buildingFrameEnumLabels } from "../../../../../../core/enum/beem-shot/BuildingFrameEnum"
import { calculTypeEnumLabelsOption } from "../../../../../../core/enum/beem-shot/CalculTypeEnum"
import { FacadeCoverEnum, facadeCoverEnumLabels } from "../../../../../../core/enum/beem-shot/FacadeCoverEnum"
import { FlatTypeEnum, flatTypeEnumLabels } from "../../../../../../core/enum/beem-shot/FlatTypeEnum"
import { FloorCoverEnum, floorCoverEnumLabels } from "../../../../../../core/enum/beem-shot/FloorCoverEnum"
import { FloorTypeEnum, floorTypeEnumLabels } from "../../../../../../core/enum/beem-shot/FloorTypeEnum"
import { FoundationTypeEnum, foundationTypeEnum } from "../../../../../../core/enum/beem-shot/FoundationTypeEnum"
import { heatingTypeEnumLabels } from "../../../../../../core/enum/beem-shot/HeatingTypeEnum"
import { OfficeTypeEnum, officeTypeEnumLabels } from "../../../../../../core/enum/beem-shot/OfficeTypeEnum"
import { ParkingEnum, parkingEnumLabels } from "../../../../../../core/enum/beem-shot/ParkingEnum"
import { RoofEnum, isToiturePente, roofEnumLabels } from "../../../../../../core/enum/beem-shot/RoofEnum"
import { RoofFlatCoverEnum, roofFlatCoverEnumLabels } from "../../../../../../core/enum/beem-shot/RoofFlatCoverEnum"
import { RoofSlopedCoverEnum, roofSlopedCoverEnumLabels } from "../../../../../../core/enum/beem-shot/RoofSlopedCoverEnum"
import { SchoolTypeEnum, schoolTypeEnumLabels } from "../../../../../../core/enum/beem-shot/SchoolTypeEnum"
import { ClimaticZoneEnum, climaticZoneEnumLabels } from "../../../../../../core/enum/climaticZoneEnum"
import { UsageEnum, usageEnumLabels } from "../../../../../../core/enum/usageEnum"
import { enumToSelectOptions } from "../../../../../../core/services/helper-service"
import { SimplifiedQuestionnaireContext } from "../../SimplifiedQuestionnaireContext"
import DividerSimplifiedQuestionnaire from "./DividerSimplifiedQuestionnaire"

export function SimplifiedQuestionnaire(): React.JSX.Element {
  const { form, errors, handleChange } = useContext(SimplifiedQuestionnaireContext)

  return form.usage ? (
    <Grid container display="flex" justifyContent="flex-start" spacing={2} sx={{ pt: 2 }}>
      <Grid item xs={12}>
        <DividerSimplifiedQuestionnaire label="Informations générales" />
      </Grid>
      <Grid item xs={12}>
        <EnumRadioButton
          id="typeCalcul"
          label="Données environnementales utilisées"
          value={form.typeCalcul}
          options={calculTypeEnumLabelsOption}
          handleChange={(e) => handleChange(e)}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectInput
          id="usage"
          label="Typologie de d'usage"
          selectedOption={form.usage}
          options={enumToSelectOptions(UsageEnum, usageEnumLabels).filter(
            (option) => option.value !== UsageEnum.MAISONS_INDIVIDUELLES_OU_ACCOLEES
          )}
          mode="event"
          handleEventChange={(e) => handleChange(e)}
          isBeemShot
          errors={errors}
        />
      </Grid>

      {form.usage === UsageEnum.LOGEMENTS_COLLECTIFS && (
        <>
          <Grid item xs={12}>
            <BooleanRadioButtonInput
              id="nombreLogement"
              label="Connaissez-vous le nombre de logements exact par typologie ?"
              value={form.nombreLogement}
              handleChange={(e) => handleChange(e)}
            />
          </Grid>
          {!form.nombreLogement && (
            <Grid item xs={12}>
              <SelectInput
                id="typeLogement"
                label="Type de logements"
                selectedOption={form.typeLogement}
                options={enumToSelectOptions(FlatTypeEnum, flatTypeEnumLabels)}
                mode="event"
                handleEventChange={(e) => handleChange(e)}
                isBeemShot
                errors={errors}
              />
            </Grid>
          )}
          {form.nombreLogement && (
            <>
              <Grid item xs={4}>
                <NumberInput
                  variant="outlined"
                  backgroundColor="white"
                  id="numberT1"
                  label="Nombre de T1"
                  mode="event"
                  handleEventChange={(e) => handleChange(e)}
                  value={form.numberT1}
                  error={errors}
                />
              </Grid>
              <Grid item xs={4}>
                <NumberInput
                  variant="outlined"
                  backgroundColor="white"
                  id="numberT2"
                  label="Nombre de T2"
                  mode="event"
                  handleEventChange={(e) => handleChange(e)}
                  value={form.numberT2}
                  error={errors}
                />
              </Grid>
              <Grid item xs={4}>
                <NumberInput
                  variant="outlined"
                  backgroundColor="white"
                  id="numberT3"
                  label="Nombre de T3"
                  mode="event"
                  handleEventChange={(e) => handleChange(e)}
                  value={form.numberT3}
                  error={errors}
                />
              </Grid>
              <Grid item xs={4}>
                <NumberInput
                  variant="outlined"
                  backgroundColor="white"
                  id="numberT4"
                  label="Nombre de T4"
                  mode="event"
                  handleEventChange={(e) => handleChange(e)}
                  value={form.numberT4}
                  error={errors}
                />
              </Grid>
              <Grid item xs={4}>
                <NumberInput
                  variant="outlined"
                  backgroundColor="white"
                  id="numberT5"
                  label="Nombre de T5"
                  mode="event"
                  handleEventChange={(e) => handleChange(e)}
                  value={form.numberT5}
                  error={errors}
                />
              </Grid>
            </>
          )}
        </>
      )}

      {form.usage === UsageEnum.BUREAUX && (
        <Grid item xs={12}>
          <SelectInput
            id="typeBureau"
            label="Type de bureaux"
            selectedOption={form.typeBureau}
            options={enumToSelectOptions(OfficeTypeEnum, officeTypeEnumLabels)}
            mode="event"
            handleEventChange={(e) => handleChange(e)}
            isBeemShot
            errors={errors}
          />
        </Grid>
      )}
      {form.usage === UsageEnum.ENSEIGNEMENT_PRIMAIRE_OU_SECONDAIRE && (
        <Grid item xs={12}>
          <SelectInput
            id="typeEnseignement"
            label="Type d'enseignement"
            selectedOption={form.typeEnseignement}
            options={enumToSelectOptions(SchoolTypeEnum, schoolTypeEnumLabels)}
            mode="event"
            handleEventChange={(e) => handleChange(e)}
            isBeemShot
            errors={errors}
          />
        </Grid>
      )}
      <Tooltip
        title={
          <img
            src={ZoneImg}
            alt="ZoneTooltip"
            style={{
              maxHeight: "300px",
            }}
            width="max-content"
          />
        }
        placement="right"
        arrow>
        <Grid item xs={6}>
          <SelectInput
            id="climaticZone"
            label="Localisation"
            selectedOption={form.climaticZone}
            options={enumToSelectOptions(ClimaticZoneEnum, climaticZoneEnumLabels)}
            mode="event"
            handleEventChange={(e) => handleChange(e)}
            isBeemShot
            errors={errors}
          />
        </Grid>
      </Tooltip>
      <Grid item xs={6}>
        <NumberInput
          variant="outlined"
          backgroundColor="white"
          id="altitude"
          label="Altitude"
          mode="event"
          handleEventChange={(e) => handleChange(e)}
          value={form.altitude}
          error={errors}
        />
      </Grid>
      <Grid item xs={12}>
        <DividerSimplifiedQuestionnaire label="Stationnement" />
      </Grid>

      <Grid item xs={12}>
        <SelectInput
          id="typeParking"
          label="Stationnement"
          selectedOption={form.typeParking}
          options={enumToSelectOptions(ParkingEnum, parkingEnumLabels)}
          mode="event"
          handleEventChange={(e) => handleChange(e)}
          isBeemShot
          errors={errors}
        />
      </Grid>
      {(form.typeParking === ParkingEnum.SOUTERRAIN || form.typeParking === ParkingEnum.SUPER_STRUCTURE) && (
        <>
          <Grid item xs={6}>
            <NumberInput
              variant='outlined'
              backgroundColor='white'
              id='nombreEtageParking'
              label="Nombre de niveaux"
              mode='event'
              handleEventChange={(e) => handleChange(e)}
              value={form.nombreEtageParking}
              error={errors}
            />
          </Grid>
          <Grid item xs={6}>
            <NumberInput
              id="nombrePlaceParking"
              variant="outlined"
              backgroundColor="white"
              label="Nombre de places"
              mode="event"
              handleEventChange={(e) => handleChange(e)}
              value={form.nombrePlaceParking}
              error={errors}
            />
          </Grid>
        </>
      )}
      {form.typeParking === ParkingEnum.EXTERIEUR && (
        <Grid item xs={6}>
          <NumberInput
            id="nombrePlaceParking"
            variant="outlined"
            backgroundColor="white"
            label="Nombre de places"
            mode="event"
            handleEventChange={(e) => handleChange(e)}
            value={form.nombrePlaceParking}
            error={errors}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <DividerSimplifiedQuestionnaire label="Dimensions du projet" />
      </Grid>
      <Grid item xs={6}>
        <NumberInput
          variant="outlined"
          backgroundColor="white"
          id="surfacePlancher"
          label="Surface Plancher (m²)"
          mode="event"
          handleEventChange={(e) => handleChange(e)}
          value={form.surfacePlancher}
          error={errors}
        />
      </Grid>
      <Grid item xs={6}>
        <NumberInput
          variant="outlined"
          backgroundColor="white"
          id="surfaceHabitable"
          label="Surface utile ou SHAB (m²)"
          mode="event"
          handleEventChange={(e) => handleChange(e)}
          value={form.surfaceHabitable}
          error={errors}
        />
      </Grid>
      <Grid item xs={6}>
        <NumberInput
          variant="outlined"
          backgroundColor="white"
          id="surfaceParcelle"
          label="Surface parcelle (m²)"
          mode="event"
          handleEventChange={(e) => handleChange(e)}
          value={form.surfaceParcelle}
          error={errors}
        />
      </Grid>
      <Grid item xs={6}>
        <NumberInput
          variant="outlined"
          backgroundColor="white"
          id="empriseAuSol"
          label="Emprise au sol (m²)"
          mode="event"
          handleEventChange={(e) => handleChange(e)}
          value={form.empriseAuSol}
          error={errors}
        />
      </Grid>
      <Grid item xs={6}>
        <NumberInput
          variant="outlined"
          backgroundColor="white"
          id="longueurMoyenneBatiment"
          label="Longueur moyenne du bâtiment (m)"
          mode="event"
          handleEventChange={(e) => handleChange(e)}
          value={form.longueurMoyenneBatiment}
          error={errors}
        />
      </Grid>
      <Grid item xs={6}>
        <NumberInput
          variant="outlined"
          backgroundColor="white"
          id="hauteurMoyenneEtage"
          label="Hauteur moyenne d'un étage (m)"
          mode="event"
          handleEventChange={(e) => handleChange(e)}
          value={form.hauteurMoyenneEtage}
          error={errors}
        />
      </Grid>
      <Grid item xs={6}>
        <NumberInput
          variant="outlined"
          backgroundColor="white"
          id="nombreEtage"
          label="Nombre de niveaux"
          mode="event"
          handleEventChange={(e) => handleChange(e)}
          value={form.nombreEtage}
          error={errors}
        />
      </Grid>
      <Grid item xs={6}>
        <NumberInput
          variant="outlined"
          backgroundColor="white"
          id="nombreAscenseur"
          label="Nombre d'ascenceurs"
          mode="event"
          handleEventChange={(e) => handleChange(e)}
          value={form.nombreAscenseur}
          error={errors}
        />
      </Grid>

      <Grid item xs={12}>
        <DividerSimplifiedQuestionnaire label="Structures et matériaux principaux" />
      </Grid>
      <Grid item xs={6}>
        <SelectInput
          id="typeFondation"
          label="Fondations"
          selectedOption={form.typeFondation}
          options={enumToSelectOptions(FoundationTypeEnum, foundationTypeEnum)}
          mode="event"
          handleEventChange={(e) => handleChange(e)}
          isBeemShot
          errors={errors}
        />
      </Grid>
      <Grid item xs={6}>
        <SelectInput
          id="typeSol"
          label="Plancher"
          selectedOption={form.typeSol}
          options={enumToSelectOptions(FloorTypeEnum, floorTypeEnumLabels)}
          mode="event"
          handleEventChange={(e) => handleChange(e)}
          isBeemShot
          errors={errors}
        />
      </Grid>
      <Grid item xs={6}>
        <SelectInput
          id="typeOssature"
          label="Ossature"
          selectedOption={form.typeOssature}
          options={enumToSelectOptions(BuildingFrameEnum, buildingFrameEnumLabels)}
          mode="event"
          handleEventChange={(e) => handleChange(e)}
          isBeemShot
          errors={errors}
        />
      </Grid>
      <Grid item xs={6}>
        <SelectInput
          id="typeFacade"
          label="Façade"
          selectedOption={form.typeFacade}
          options={enumToSelectOptions(BuildingFacadeEnum, buildingFacadeEnumLabels)}
          mode="event"
          handleEventChange={(e) => handleChange(e)}
          isBeemShot
          errors={errors}
        />
      </Grid>

      <Grid item xs={6}>
        <SelectInput
          id="typeToiture"
          label="Toiture et charpente"
          selectedOption={form.typeToiture}
          options={enumToSelectOptions(RoofEnum, roofEnumLabels)}
          mode="event"
          handleEventChange={(e) => handleChange(e)}
          isBeemShot
          errors={errors}
        />
      </Grid>

      <Grid item xs={6}>
        <SelectInput
          id="couvertureSol"
          label="Revêtement de sol"
          selectedOption={form.couvertureSol}
          options={enumToSelectOptions(FloorCoverEnum, floorCoverEnumLabels)}
          mode="event"
          handleEventChange={(e) => handleChange(e)}
          isBeemShot
          errors={errors}
        />
      </Grid>

      <Grid item xs={12}>
        <SelectInput
          id="couvertureFacade"
          label="Revêtement de façade"
          selectedOption={form.couvertureFacade}
          options={enumToSelectOptions(FacadeCoverEnum, facadeCoverEnumLabels)}
          mode="event"
          handleEventChange={(e) => handleChange(e)}
          isBeemShot
          errors={errors}
        />
      </Grid>
      {isToiturePente(form.typeToiture) ? (
        <Grid item xs={12}>
          <SelectInput
            id="couvertureToiturePente"
            label="Revêtement de la toiture"
            selectedOption={form.couvertureToiturePente}
            options={enumToSelectOptions(RoofSlopedCoverEnum, roofSlopedCoverEnumLabels)}
            mode="event"
            handleEventChange={(e) => handleChange(e)}
            isBeemShot
            errors={errors}
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <SelectInput
            id="couvertureToiturePlate"
            label="Revêtement de la toiture"
            selectedOption={form.couvertureToiturePlate}
            options={enumToSelectOptions(RoofFlatCoverEnum, roofFlatCoverEnumLabels)}
            mode="event"
            handleEventChange={(e) => handleChange(e)}
            isBeemShot
            errors={errors}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <DividerSimplifiedQuestionnaire label="Durabilité et énergie" />
      </Grid>
      <Grid item xs={12}>
        <BooleanRadioButtonInput
          id="reseauChaleur"
          label="Réseau de chaleur"
          value={form.reseauChaleur}
          handleChange={(e) => handleChange(e)}
        />
      </Grid>
      <Grid item xs={12}>
        <EnumRadioButton
          id="typeChauffage"
          label="Chauffage"
          value={form.typeChauffage}
          options={heatingTypeEnumLabels}
          handleChange={(e) => handleChange(e)}
        />
      </Grid>
      <Grid item xs={12}>
        <BooleanRadioButtonInput
          id="recuperationEauPluie"
          label="Récupération des eaux pluviales"
          value={form.recuperationEauPluie}
          handleChange={(e) => handleChange(e)}
        />
      </Grid>
      <Grid item xs={12}>
        <BooleanRadioButtonInput
          id="photovoltaique"
          label="Photovoltaïque"
          value={form.photovoltaique}
          handleChange={(e) => handleChange(e)}
        />
      </Grid>
      {form.photovoltaique && (
        <Grid item xs={6}>
          <NumberInput
            variant="outlined"
            backgroundColor="white"
            id="surfacePanneauPhotovoltaique"
            label="Surface de photovoltaïque (m²)"
            mode="event"
            handleEventChange={(e) => handleChange(e)}
            value={form.surfacePanneauPhotovoltaique}
            error={errors}
          />
        </Grid>
      )}
    </Grid>
  ) : (
    <div />
  )
}
