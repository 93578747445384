import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import React from "react"
import "./app-side-navbar.scss"

type IProps = {
  label: string
  action(): void
  open: boolean
  icon: React.JSX.Element | null
  isActive?: boolean
}

export function IconSideBar({ label, action, open, icon, isActive }: Readonly<IProps>): React.JSX.Element {
  return (
    <ListItem
      disablePadding
      sx={{
        display: "block",
        backgroundColor: isActive ? "#0BE2A0" : "",
        borderRadius: 2,
      }}>
      <ListItemButton
        onClick={action}
        sx={{
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
          color: isActive ? "black" : "white",
        }}>
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : "auto",
            justifyContent: "center",
            color: isActive ? "#000000" : "white",
          }}>
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={label}
          sx={{ opacity: open ? 1 : 0 }}
          primaryTypographyProps={{
            sx: {
              fontFamily: "Poppins",
              fontSize: "13px",
              fontWeight: 500,
              lineHeight: "24px",
              textAlign: "left",
            },
          }}
        />
      </ListItemButton>
    </ListItem>
  )
}
