import { useMemo } from "react"
import { appConstants } from "../appConstants"
import { HomePageIndicator } from "../dto/home-page-indicator"
import { SimpleInformationsProject } from "../dto/simple-project-information/SimpleInformationsProject"
import { useHttp } from "./use-http"

type UserHook = {
  getHomePageIndicator(): Promise<HomePageIndicator>
  getLastProject(limit: number): Promise<SimpleInformationsProject[]>
}

export function useHomePage(): UserHook {
  const { get } = useHttp()

  return useMemo(
    () => ({
      getHomePageIndicator(): Promise<HomePageIndicator> {
        return get(appConstants.apiEndpoints.HOME_PAGE_INDICATORS, []).then((response) => response.json())
      },

      getLastProject(limit = 10): Promise<SimpleInformationsProject[]> {
        return get(appConstants.apiEndpoints.HOME_LAST_PROJECTS, [
          {
            key: "limit",
            value: limit.toString(),
          },
        ])
          .then((response) => response.json())
          .then((data: any) => {
            const list: SimpleInformationsProject[] = data.map((record: SimpleInformationsProject) =>
              SimpleInformationsProject.fromDto(record)
            )
            return list
          })
      },
    }),
    [get]
  )
}
