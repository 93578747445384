import CheckBoxIcon from "@mui/icons-material/CheckBox"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import { Autocomplete, Box, Button, Checkbox, Chip, Paper, TextField, Tooltip } from "@mui/material"
import React from "react"

export type GenericOption = {
  id: string
  label: string
}

export enum OptionCustomLabel {
  ALL_OPTIONS = "( Tout )",
}

type IProps = {
  readonly id: string
  readonly label: string
  readonly options: GenericOption[]
  readonly value: string[]
  readonly disabled?: boolean
  readonly tooltip: string
  readonly onChange: (value: string[]) => void
  readonly applySync: () => void
  readonly error: boolean
  readonly helperText: string
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

function CustomPaper({ children }: React.HTMLAttributes<HTMLElement>, applySync: () => void): React.JSX.Element {
  return (
    <Paper elevation={18}>
      {children}
      <Box display="flex" justifyContent="center" sx={{ p: 1 }}>
        <Button
          onMouseDown={(event: React.MouseEvent) => {
            event.preventDefault()
          }}
          variant="contained"
          onClick={() => {
            applySync()
          }}
          sx={{ display: "flex", justifyContent: "center" }}>
          Appliquer
        </Button>
      </Box>
    </Paper>
  )
}

export function SelectFilterTableau({
  id,
  label,
  options,
  value,
  disabled,
  tooltip,
  onChange,
  applySync,
  error,
  helperText,
}: IProps): React.JSX.Element {
  function getOptionLabel(option: string): string {
    if (option === OptionCustomLabel.ALL_OPTIONS) {
      return OptionCustomLabel.ALL_OPTIONS
    }
    return options.find((project) => project.id === option)?.label ?? ""
  }

  function gerLabelRenderTag(actualValue: string[]): string {
    if (value.includes(OptionCustomLabel.ALL_OPTIONS)) {
      return "Tout"
    } else if (actualValue.length === 1) {
      return getOptionLabel(actualValue[0])
    } else if (actualValue.length > 1) {
      return "Valeurs multiples"
    }

    return ""
  }

  function handleChange(e: React.SyntheticEvent<Element, Event>, test: string[]): void {
    const isAllOptionIsSelected = test.includes(OptionCustomLabel.ALL_OPTIONS)

    if (!isAllOptionIsSelected && test.length < options.length) {
      onChange(test)
    } else if (isAllOptionIsSelected && test.length === options.length && test.length === value.length - 1) {
      onChange(test.filter((item) => item !== OptionCustomLabel.ALL_OPTIONS))
    } else if (
      (isAllOptionIsSelected && value.length === 0) ||
      (isAllOptionIsSelected && test.length <= options.length) ||
      (!isAllOptionIsSelected && test.length === options.length && options.length === value.length + 1)
    ) {
      onChange([OptionCustomLabel.ALL_OPTIONS, ...options.map((status: GenericOption) => status.id)])
    } else {
      onChange([])
    }
  }

  return (
    <Box>
      <Tooltip title={tooltip}>
        <Autocomplete
          multiple
          limitTags={2}
          disablePortal
          disabled={disabled}
          disableClearable
          disableCloseOnSelect
          id={id}
          options={[OptionCustomLabel.ALL_OPTIONS, ...options.map((option) => option.id)]}
          noOptionsText="Pas d'éléments proposés..."
          value={value}
          selectOnFocus
          onChange={handleChange}
          getOptionLabel={(e: string) => getOptionLabel(e)}
          isOptionEqualToValue={(option, values) => option === values}
          PaperComponent={(children) => CustomPaper(children, applySync)}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8, height: 1 }} checked={selected} />
              {getOptionLabel(option)}
            </li>
          )}
          renderInput={(params) => <TextField {...params} label={label} error={error} helperText={helperText} />}
          renderTags={(option) => <Chip label={gerLabelRenderTag(option)} />}
          sx={{ p: 3 }}
        />
      </Tooltip>
    </Box>
  )
}
